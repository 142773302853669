import {
  FETCH_OPERATORE,
  RESET_OPERATORE_ATTIVO,
  ADD_SPORTELLO_OPERATORE,
  REMOVE_SPORTELLO_OPERATORE
} from '../actions/types';

const INITIAL_STATE = {};

export default function(state = INITIAL_STATE,
  action) {
	switch(action.type) {
		case FETCH_OPERATORE:
			return action.payload.data || state;
		case RESET_OPERATORE_ATTIVO:
			return {};
    case ADD_SPORTELLO_OPERATORE:
      return {
        ...state,
				sportelli: [
					...state.sportelli,
					action.payload.data
				]

      };
    case REMOVE_SPORTELLO_OPERATORE:
      return {
				...state,
				sportelli: state.sportelli.filter(sportello => sportello.sportelloId !== action.payload)
			}
		default:
			return state;
	}

}
