import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Ink from 'react-ink'

import ConoscenzaLinguistica from './conoscenza_linguistica'

import { MdAdd } from 'react-icons/md'

class ConoscenzeLinguistiche extends Component {
  renderConoscenzeLinguistiche() {
    if (this.props.conoscenzeLinguistiche) {
      return this.props.conoscenzeLinguistiche.map((conoscenzaLinguistica) => {
        return (
          <ConoscenzaLinguistica
            key={conoscenzaLinguistica.conoscenzaLinguisticaId}
            conoscenzaLinguistica={conoscenzaLinguistica}
            candidatoId={this.props.candidatoId}
            canEdit={this.props.canEdit}
          />
        )
      })
    }
    return <span>Caricamento in corso...</span>
  }

  render() {
    return (
      <div className="card-box">
        <h4 className="header-title">Conoscenze Linguistiche</h4>
        {this.renderConoscenzeLinguistiche()}
        {this.props.canEdit ? (
          <div className="text-right">
            <Link
              to={`/app/candidati/${this.props.candidatoId}/conoscenze-linguistiche/new`}
              className="btn btn-sm btn-primary"
            >
              <Ink />
              <MdAdd />
              Aggiungi lingua
            </Link>
          </div>
        ) : null}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    candidatoId: state.candidatoAttivo.candidatoId,
    conoscenzeLinguistiche: state.candidatoAttivo.conoscenzeLinguistiche,
    canEdit:
      !!state.auth.user.permessi['conoscenzeLinguistiche'] &&
      state.candidatoAttivo.sportello &&
      state.candidatoAttivo.sportello.sportelloId === state.auth.user.sportelloCorrente.sportelloId,
  }
}

export default connect(mapStateToProps)(ConoscenzeLinguistiche)
