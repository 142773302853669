import React, { Component } from 'react'
import _find from 'lodash/find'
import { Field, Form } from 'react-final-form'
import { connect } from 'react-redux'

import BottoneAnnulla from './bottone_annulla'
import {
  fetchCandidato,
  addConoscenzaInformatica,
  updateConoscenzaInformatica,
} from '../../actions'
import Lookups from '../lookups/lookups'

class AddEditConoscenzaInformatica extends Component {
  ///////////////////////////////////////////////////////////////
  // SE EDIT E RELOAD PAGINA -> FETCH CANDIDATO
  componentDidMount() {
    // Ho già il candidato attivo se navigo normalmente
    // dal dettaglio candidato: questo controllo serve
    // nel caso in cui ricarico la pagina da qui (F5)
    if (this.props.match.params.id && !this.props.candidatoId) {
      this.props.fetchCandidato(this.props.match.params.id)
    }
  }

  ///////////////////////////////////////////////////////////////
  // SUBMIT
  onSubmit(values) {
    if (this.props.match.params.conoscenzaInformaticaId) {
      // Siamo in UPDATE
      this.props
        .updateConoscenzaInformatica(
          this.props.match.params.conoscenzaInformaticaId,
          values,
          this.props.match.params.id
        )
        .then((action) => {
          // Redirect a dettagli candidato
          this.props.history.goBack()
        })
    } else {
      // Siamo in NEW
      this.props.addConoscenzaInformatica(values, this.props.match.params.id).then((action) => {
        // Redirect a dettagli candidato
        this.props.history.goBack()
      })
    }
  }

  ///////////////////////////////////////////////
  // NEW / EDIT
  renderTitolo() {
    let titolo = ''
    if (this.props.match.params.conoscenzaInformatica) {
      titolo = 'Modifica ' + this.props.fields.conoscenza.value
    } else {
      titolo = 'Nuova conoscenza'
    }
    return <h1 className="page-title pull-left">{titolo}</h1>
  }

  ///////////////////////////////////////////////////////////////
  // RENDER
  render() {
    const initialValues = this.props.initialValues ? this.props.initialValues : {}

    //Link to={this.props.match.params.id ? `/app/candidati/${this.props.match.params.id}` : '/candidati'}
    return (
      <Form
        onSubmit={this.onSubmit.bind(this)}
        initialValues={initialValues}
        validate={validate}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit}>
            <div className="heading">
              {this.renderTitolo()}
              <BottoneAnnulla />
            </div>

            <div className="row">
              <div className="col-md-8">
                <div className="panel panel-border panel-primary">
                  <div className="panel-heading">
                    <h3 className="panel-title">Conoscenza informatica</h3>
                  </div>
                  <div className="panel-body">
                    <div className="row">
                      <div className="col-sm-6">
                        <Field
                          id="tipologia"
                          name="tipologia"
                          render={({ input, meta }) => (
                            <div
                              className={`form-group ${
                                meta.touched && meta.invalid ? 'has-error' : ''
                              }`}
                            >
                              <label htmlFor="tipologia">Tipologia</label>
                              <Lookups tipo="tipoConoscenzaInformatica" {...input} />
                              <div className="help-block">{meta.touched && meta.error}</div>
                            </div>
                          )}
                        />
                      </div>
                      <div className="col-sm-6">
                        <Field
                          id="conoscenza"
                          name="conoscenza"
                          render={({ input, meta }) => (
                            <div
                              className={`form-group ${
                                meta.touched && meta.invalid ? 'has-error' : ''
                              }`}
                            >
                              <label htmlFor="conoscenza">Conoscenza</label>
                              <input type="text" className="form-control" {...input} />
                              <div className="help-block">{meta.touched && meta.error}</div>
                            </div>
                          )}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-xs-12">
                        <div
                          className={`form-group ${
                            form.getFieldState('livello')?.touched &&
                            form.getFieldState('livello')?.invalid
                              ? 'has-error'
                              : ''
                          }`}
                        >
                          <label htmlFor="livello">Livello</label>
                          <div>
                            <div className="radio radio-primary radio-inline">
                              <Field
                                id="livello_base"
                                name="livello"
                                value="Base"
                                component="input"
                                type="radio"
                              />
                              <label htmlFor="livello_base">Base</label>
                            </div>
                            <div className="radio radio-primary radio-inline">
                              <Field
                                id="livello_buono"
                                name="livello"
                                value="Buono"
                                component="input"
                                type="radio"
                              />
                              <label htmlFor="livello_buono">Buono</label>
                            </div>
                            <div className="radio radio-primary radio-inline">
                              <Field
                                id="livello_avanzato"
                                name="livello"
                                value="Avanzato"
                                component="input"
                                type="radio"
                              />
                              <label htmlFor="livello_avanzato">Avanzato</label>
                            </div>
                          </div>
                          <div className="help-block">
                            {form.getFieldState('livello')?.touched &&
                              form.getFieldState('livello')?.error}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <button type="submit" className="btn btn-primary btn-lg">
                    Salva conoscenza &raquo;
                  </button>
                </div>
              </div>
              <div className="col-md-4"></div>
            </div>
          </form>
        )}
      />
    )
  }
}

function validate(values) {
  const errors = {}

  if (!values.tipologia) {
    errors.tipologia = 'Seleziona la tipologia'
  }

  if (!values.conoscenza) {
    errors.conoscenza = 'Indica la conoscenza'
  }

  if (!values.livello) {
    errors.livello = 'Seleziona il livello'
  }

  return errors
}

const mapStateToProps = (state, props) => {
  // Sono in edit, devo inizializzare il form
  if (props.match.params.conoscenzaInformaticaId) {
    return {
      initialValues: state.candidatoAttivo.conoscenzeInformatiche
        ? _find(state.candidatoAttivo.conoscenzeInformatiche.conoscenze, {
            conoscenzaInformaticaId: parseInt(props.match.params.conoscenzaInformaticaId),
          })
        : null,
      candidatoId: state.candidatoAttivo.candidatoId, // per sapere se ho già il candidato attivo su F5
    }
  }
  return {}
}

export default connect(mapStateToProps, {
  fetchCandidato,
  addConoscenzaInformatica,
  updateConoscenzaInformatica,
})(AddEditConoscenzaInformatica)
