import React, { Component } from 'react'
import AsyncSelect from 'react-select/async'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import _find from 'lodash/find'
import { connect } from 'react-redux'
import { Form, Field } from 'react-final-form'

import {
  createSlot,
  fetchSlot,
  saveSlot,
  deleteSlot,
  addAppuntamento,
  removeAppuntamento,
  fetchTypeaheadCandidati,
} from '../../actions'

class SlotAppuntamenti extends Component {
  constructor(props) {
    super(props)
    this.state = {
      candidato: {
        candidatoId: null,
        nomeCognome: null,
      },
    }
  }

  ///////////////////////////////////////////////////////////////
  // MOUNT
  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search)

    this.props.fetchSlot(this.props.match.params.slotId, query.get('start'), query.get('end'))
  }

  ///////////////////////////////////////////////////////////////
  // SUBMIT
  onSubmit(values) {
    if (this.props.match.params.slotId) {
      // Siamo in UPDATE
      this.props.saveSlot(values, this.props.match.params.slotId).then((action) => {
        // Redirect a calendario
        this.props.history.push(`/app/appuntamenti`)
      })
    } else {
      // Siamo in NEW
      this.props.createSlot(values).then((action) => {
        // Redirect a calendario
        this.props.history.push(`/app/appuntamenti`)
      })
    }
  }

  ///////////////////////////////////////////////////////////////
  // ELIMINA SLOT
  eliminaSlot() {
    this.props.deleteSlot(this.props.match.params.slotId).then((action) => {
      // Redirect a calendario
      this.props.history.push(`/app/appuntamenti`)
    })
  }

  ///////////////////////////////////////////////
  // GESTIONE AUTOCOMPLETE CANDIDATI
  getCandidatiOptions = (candidati) => {
    return candidati.map((candidato) => {
      return {
        value: candidato.candidatoId,
        label: candidato.nomeCognome,
        fotografia: candidato.fotografia,
      }
    })
  }

  getCandidati = (input) => {
    return this.props.fetchTypeaheadCandidati(input).then((action) => {
      return this.getCandidatiOptions(action.payload.data)
    })
  }

  onChangeCandidato(option) {
    this.setState({
      candidato: {
        candidatoId: option ? option.value : null,
        nomeCognome: option ? option.label : null,
      },
    })
  }

  clearAppuntamento() {
    this.setState({
      candidato: {
        candidatoId: null,
        nomeCognome: null,
      },
    })
  }

  addAppuntamento() {
    if (
      this.state.candidato.candidatoId &&
      !_find(this.props.appuntamenti, { candidatoId: this.state.candidato.candidatoId })
    ) {
      this.props.addAppuntamento(this.props.match.params.slotId, this.state.candidato.candidatoId)
    }
    this.clearAppuntamento()
  }

  renderOption(option) {
    return (
      <span>
        <img className="avatar" src={option.fotografia} alt="avatar" />
        {option.label}
      </span>
    )
  }

  ///////////////////////////////////////////////////////////////
  // RENDER
  render() {
    const initialValues = this.props.initialValues ? this.props.initialValues : {}

    return (
      <Form
        onSubmit={this.onSubmit.bind(this)}
        initialValues={initialValues}
        render={({ handleSubmit, form }) => (
          <div className="slot-appuntamenti">
            <h1 className="page-title">Slot appuntamenti</h1>

            <div className="card-box">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="dataInizio">Data inizio</label>
                      <Field
                        name="dataInizio"
                        id="dataInizio"
                        render={({ input }) => {
                          const { value, ...rest } = input
                          return (
                            <DatePicker
                              id="dataInizio"
                              selected={value ? new Date(moment(value).format('MM-DD-YYYY')) : null}
                              locale="it"
                              className="form-control"
                              dateFormat="dd/MM/yyyy"
                              value={new Date(value)}
                              {...rest}
                            />
                          )
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="oraInizio">Ora inizio</label>
                      <Field
                        type="time"
                        className="form-control"
                        id="oraInizio"
                        name="oraInizio"
                        component="input"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="dataFine">Data fine</label>
                      <Field
                        name="dataFine"
                        id="dataFine"
                        render={({ input }) => {
                          const { value, ...rest } = input
                          return (
                            <DatePicker
                              selected={value ? new Date(moment(value).format('MM-DD-YYYY')) : null}
                              locale="it"
                              className="form-control"
                              dateFormat="dd/MM/yyyy"
                              value={new Date(value)}
                              {...rest}
                            />
                          )
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="oraFine">Ora fine</label>
                      <Field
                        type="time"
                        className="form-control"
                        id="oraFine"
                        name="oraFine"
                        component="input"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="descrizione">Descrizione</label>
                      <Field
                        type="text"
                        className="form-control"
                        id="descrizione"
                        name="descrizione"
                        component="input"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="numeroMassimoCandidati">N° max candidati</label>
                      <Field
                        type="number"
                        className="form-control"
                        id="numeroMassimoCandidati"
                        name="numeroMassimoCandidati"
                        component="input"
                        min="1"
                        max="100"
                      />
                    </div>
                  </div>
                </div>
                {this.props.canEditSlot ? (
                  <button type="submit" className="btn btn-primary">
                    Salva slot appuntamenti
                  </button>
                ) : null}
                &nbsp;&nbsp;
                {this.props.match.params.slotId && this.props.canEditSlot ? (
                  <button
                    type="button"
                    onClick={this.eliminaSlot.bind(this)}
                    className="btn btn-danger"
                  >
                    Elimina slot
                  </button>
                ) : null}
              </form>

              {this.props.match.params.slotId && this.props.canEditAppuntamenti ? (
                <div style={{ marginTop: '40px' }}>
                  <div className="row">
                    <div className="col-md-6">
                      <h4>Appuntamenti</h4>
                      {this.props.appuntamenti && this.props.appuntamenti.length > 0 ? (
                        <ul className="candidati">
                          {this.props.appuntamenti.map((candidato) => (
                            <li key={candidato.candidatoId}>
                              <img
                                className="avatar"
                                src={candidato.fotografia}
                                alt="fotografia-candidato"
                              />
                              {candidato.nomeCognome}
                              {
                                /* Posso eliminare un candidato se:
                           1) E' mio il candidato
                           2) E' mio lo slot */
                                candidato.sportelloId === this.props.sportelloIdUser ||
                                this.props.sportelloIdSlot === this.props.sportelloIdUser ? (
                                  <a
                                    className="remove"
                                    href="/#"
                                    onClick={(event) => {
                                      event.preventDefault()
                                      this.props.removeAppuntamento(
                                        this.props.match.params.slotId,
                                        candidato.candidatoId
                                      )
                                    }}
                                  >
                                    &times;
                                  </a>
                                ) : null
                              }
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p>Nessun appuntamento presente</p>
                      )}
                    </div>
                    <div className="col-md-6">
                      {this.props.appuntamenti &&
                      this.props.appuntamenti.length <
                        form.getFieldState('numeroMassimoCandidati')?.value ? (
                        <div>
                          <h4>Nuovo appuntamento</h4>
                          <div className="form-group">
                            <AsyncSelect
                              name="candidato"
                              loadOptions={this.getCandidati}
                              placeholder="Seleziona..."
                              loadingPlaceholder="Caricamento..."
                              searchingText="Caricamento..."
                              noResultsText="Nessun risultato"
                              value={{
                                value: this.state.candidato.candidatoId,
                                label: this.state.candidato.nomeCognome,
                              }}
                              optionRenderer={this.renderOption}
                              onChange={this.onChangeCandidato.bind(this)}
                            />
                          </div>
                          <button
                            disabled={!this.state.candidato.candidatoId}
                            className="btn btn-success"
                            onClick={this.addAppuntamento.bind(this)}
                          >
                            Crea appuntamento
                          </button>
                        </div>
                      ) : (
                        <p>Numero massimo candidati raggiunto</p>
                      )}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        )}
      />
    )
  }
}

const mapStateToProps = (state) => {
  const sportelloIdSlot = state.slotAttivo.sportelloId
  const sportelloIdUser = state.auth.user.sportelloCorrente.sportelloId
  return {
    initialValues: state.slotAttivo,
    appuntamenti: state.slotAttivo.appuntamenti,
    canEditSlot:
      !!state.auth.user.permessi['modificaSlot'] &&
      (!sportelloIdSlot || sportelloIdSlot === sportelloIdUser),
    canEditAppuntamenti: !!state.auth.user.permessi['modificaAppuntamenti'],
    sportelloIdSlot: sportelloIdSlot,
    sportelloIdUser: sportelloIdUser,
  }
}

export default connect(mapStateToProps, {
  createSlot,
  fetchSlot,
  saveSlot,
  deleteSlot,
  addAppuntamento,
  removeAppuntamento,
  fetchTypeaheadCandidati,
})(SlotAppuntamenti)
