import React from 'react'
import { Route, Switch } from 'react-router'
import OperatoriList from '../../amministratore/operatori/operatori_list'
import CreateEditOperatore from '../../amministratore/operatori/create_edit_operatore'

const Operatori = (props) => {
  return (
    <div>
      <Switch>
        <Route exact path="/app/operatori/" component={OperatoriList} />
        <Route exact path="/app/operatori/new" component={CreateEditOperatore} />
        <Route exact path="/app/operatori/:operatoreId" component={CreateEditOperatore} />
      </Switch>
    </div>
  )
}

export default Operatori
