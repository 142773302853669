import cogoToast from 'cogo-toast'
import React, { Component } from 'react'
import { Dropdown, MenuItem } from 'react-bootstrap'
import { FaList, FaThLarge } from 'react-icons/fa'
import { MdAdd, MdArchive, MdHome, MdPublic } from 'react-icons/md'
import Ink from 'react-ink'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import swal from 'sweetalert'
import {
  addCandidatiGruppo,
  API_ROOT_URL,
  changeFilterCandidati,
  changePageCandidati,
  createInteressePerCandidato,
  deleteInteressePerCandidato,
  fetchCandidati,
  fetchGruppi,
  resetCandidato,
  sbloccaCandidato,
  setViewModeCandidati,
  toggleTipoRicercaCandidati,
} from '../../actions'
import Pager from '../pager/pager'
import FiltroCandidati from './filtro_candidati'
import RicercaAvanzata from './ricerca_avanzata'

class CandidatiList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      candidatiSelezionati: [],
      selezionaTutti: false,
      gruppoSelezionato: '',
      candidatoDaSbloccareId: null,
    }
  }

  componentDidMount() {
    // Reset candidato singolo
    this.props.resetCandidato()
    if (this.props.ruolo === 'operatore') {
      this.props.fetchGruppi()
    }

    const { filters } = this.props

    if (this.props.ruolo === 'agenzia' || this.props.ruolo === 'azienda') {
      this.props.changeFilterCandidati('tipoCandidati', 'di tutti gli sportelli')

      filters.tipoCandidati = 'di tutti gli sportelli'
    }

    this.props.fetchCandidati(this.props.filters)
  }

  componentDidUpdate(prevProps) {
    const { filters, pagination, pageRequested } = this.props
    console.log(filters)

    const pageChanged = pagination.page !== pageRequested
    const filterChanged =
      filters.tipoCandidati !== prevProps.filters.tipoCandidati ||
      filters.keywords !== prevProps.filters.keywords ||
      filters.comuneDisponibilita.codice !== prevProps.filters.comuneDisponibilita.codice ||
      filters.servizioRichiesto !== prevProps.filters.servizioRichiesto ||
      filters.statoServizio !== prevProps.filters.statoServizio ||
      filters.comuneDomicilio.codice !== prevProps.filters.comuneDomicilio.codice ||
      (filters.rangeEta !== prevProps.filters.rangeEta &&
        (filters.rangeEta.length === 0 || (filters.rangeEta.length >= 5 && filters.rangeEta.indexOf('-') > -1))) ||
      filters.sesso !== prevProps.filters.sesso ||
      filters.lingue !== prevProps.filters.lingue ||
      filters.obiettiviProfessionali !== prevProps.filters.obiettiviProfessionali ||
      filters.esperienzeLavorative !== prevProps.filters.esperienzeLavorative ||
      filters.formazione !== prevProps.filters.formazione ||
      filters.interesseCorsi !== prevProps.filters.interesseCorsi ||
      filters.invalidita !== prevProps.filters.invalidita ||
      filters.automunito !== prevProps.filters.automunito ||
      filters.livelloPatente !== prevProps.filters.livelloPatente ||
      filters.disponibilita_tempo !== prevProps.filters.disponibilita_tempo

    if (pageChanged) {
      this.props.fetchCandidati({
        ...filters,
        page: pageRequested,
      })
    }

    if (filterChanged) {
      this.props.fetchCandidati({
        ...filters,
        page: 1,
      })
    }
  }

  handleToggleTipoRicerca = (tipoRicerca) => (event) => {
    event.preventDefault()
    this.props.toggleTipoRicercaCandidati(tipoRicerca)
  }

  handleSelectCandidato = (candidatoId) => (event) => {
    if (event.target.checked) {
      // ADD
      this.setState({
        candidatiSelezionati: [...this.state.candidatiSelezionati, candidatoId],
      })
    } else {
      // REMOVE
      this.setState({
        candidatiSelezionati: this.state.candidatiSelezionati.filter((c) => c !== candidatoId),
      })
    }
  }

  handleSelectAll = (event) => {
    this.setState({
      selezionaTutti: event.target.checked,
      candidatiSelezionati: event.target.checked ? this.props.candidati.map((c) => c.candidatoId) : [],
    })
  }

  isSelezionato = (candidatoId) => {
    return !!this.state.candidatiSelezionati.find((c) => c === candidatoId)
  }

  handleAddCandidatiGruppo = (event) => {
    this.props.addCandidatiGruppo(this.state.gruppoSelezionato, this.state.candidatiSelezionati).then((res) => {
      this.setState({
        gruppoSelezionato: '',
        candidatiSelezionati: [],
      })

      if (res.payload.status === 200) {
        cogoToast.success('I candidati sono stati aggiunti al gruppo selezionato', {
          heading: 'Candidati aggiunti',
          position: 'top-right',
          hideAfter: 5,
        })
      }
    })
  }

  setViewMode(viewMode) {
    this.props.setViewModeCandidati(viewMode)
  }

  setViewModeThumbs() {
    this.setViewMode('thumbs')
  }

  setViewModeList(event) {
    event.preventDefault()
    this.setViewMode('list')
  }

  flattenObiettiviProfessionali(obiettivi) {
    // E' uno pseudo-array!! :S
    return Array.prototype.join.call(obiettivi, ', ')
  }

  handleChangeTipoCandidati = (type) => (event) => {
    event.preventDefault()
    this.props.changeFilterCandidati('tipoCandidati', type)
  }

  handleCreateInteresse = (candidatoId) => (event) => {
    this.handleAlertInteresse()
    this.props.createInteressePerCandidato(candidatoId)
  }

  handleDeleteInteresse = (candidatoId) => (event) => {
    this.props.deleteInteressePerCandidato(candidatoId)
  }

  handleChangeGruppoSelezionato = (event) => {
    this.setState({
      gruppoSelezionato: event.target.value,
    })
  }

  handleSblocca = (candidatoId, crediti) => (event) => {
    this.setState({
      candidatoDaSbloccareId: candidatoId,
    })
    this.handleUnlockCandidato(crediti)
  }

  handleConfirmSblocca = (event) => {
    this.props.sbloccaCandidato(this.state.candidatoDaSbloccareId)
  }

  renderLink(candidato) {
    let { ruolo } = this.props
    const token = localStorage.getItem('token')

    if ((ruolo === 'agenzia' && !candidato.sbloccato) || ruolo === 'azienda') {
      return (
        <a
          target="_blank"
          href={`${API_ROOT_URL}/candidati/${candidato.candidatoId}/curriculum_pdf?anonimo=true&t=${token}`}
          rel="noreferrer"
        >
          <img src={candidato.fotografia || '/images/senza-foto.jpg'} className="img-responsive" alt="candidato" />
        </a>
      )
    }
    return (
      <Link to={'/app/candidati/' + candidato.candidatoId}>
        <img src={candidato.fotografia || '/images/senza-foto.jpg'} className="img-responsive" alt="fotografia" />
      </Link>
    )
  }

  renderNome(candidato) {
    let { ruolo } = this.props
    if ((ruolo === 'agenzia' && !candidato.sbloccato) || ruolo === 'azienda') {
      return <span>Codice {candidato.candidatoId}</span>
    }
    return <span>{candidato.nome + ' ' + candidato.cognome}</span>
  }

  renderCandidatiThumbs() {
    const { ruolo, crediti } = this.props

    return this.props.candidati.map((candidato) => {
      return (
        <div key={candidato.candidatoId} className="col-xs-6 col-sm-4 col-lg-3 col-md-3">
          <div className="thumb">
            {this.renderLink(candidato)}
            <h4>{this.renderNome(candidato)}</h4>
            {(ruolo === 'agenzia' && !candidato.sbloccato) || ruolo === 'azienda' ? (
              <div className="separator" />
            ) : (
              <div className="candidato-id">{candidato.comuneDomicilio}</div>
            )}
            <p>
              {candidato.obiettiviProfessionali &&
                candidato.obiettiviProfessionali.length > 0 &&
                this.flattenObiettiviProfessionali(candidato.obiettiviProfessionali)}
              &nbsp;
            </p>
            <p className="sportello">
              {candidato.sportello} • {candidato.candidatoId}
            </p>

            {ruolo === 'azienda' && !candidato.interessato && (
              <button className="btn btn-primary btn-block" onClick={this.handleCreateInteresse(candidato.candidatoId)}>
                Esprimi interesse
              </button>
            )}
            {ruolo === 'azienda' && !!candidato.interessato && (
              <button className="btn btn-danger btn-block" onClick={this.handleDeleteInteresse(candidato.candidatoId)}>
                Revoca interesse
              </button>
            )}
            {ruolo === 'agenzia' && crediti > 0 && !candidato.sbloccato && (
              <button
                className="btn btn-primary btn-block"
                onClick={this.handleSblocca(candidato.candidatoId, crediti)}
              >
                Sblocca candidato
              </button>
            )}
            {ruolo === 'agenzia' && candidato.sbloccato && (
              <div className="badge badge-success sbloccato">SBLOCCATO</div>
            )}
          </div>
        </div>
      )
    })
  }

  renderCandidatiList() {
    let { ruolo } = this.props
    return this.props.candidati.map((candidato) => {
      return (
        <tr key={candidato.candidatoId}>
          {ruolo === 'operatore' && (
            <td>
              {/* <input type="checkbox" checked={this.isSelezionato(candidato.candidatoId)} onChange={this.handleSelectCandidato(candidato.candidatoId)} /> */}

              <div className="checkbox checkbox-primary">
                <input
                  type="checkbox"
                  checked={this.isSelezionato(candidato.candidatoId)}
                  onChange={this.handleSelectCandidato(candidato.candidatoId)}
                />
                <label />
              </div>
            </td>
          )}
          <td>{this.renderLink(candidato)}</td>
          <td>{candidato.candidatoId}</td>
          <td>{this.renderNome(candidato)}</td>
          <td>{candidato.statoOccupazionale}</td>
          <td className="hidden-xs">{this.flattenObiettiviProfessionali(candidato.obiettiviProfessionali)}</td>
          <td>{candidato.sportello}</td>
          <td>{candidato.comuneDomicilio}</td>
          {(ruolo === 'agenzia' && !candidato.sbloccato) || ruolo === 'azienda' ? null : (
            <td className="hidden-xs">
              <a href={'mailto:' + candidato.email}>{candidato.email}</a>
            </td>
          )}
        </tr>
      )
    })
  }

  renderCandidati() {
    const { ruolo } = this.props
    if (this.props.viewMode === 'thumbs') {
      return <div className="row">{this.renderCandidatiThumbs()}</div>
    }
    return (
      <div className="card-box list">
        <table className="table table-list">
          <thead>
            <tr>
              {ruolo === 'operatore' && (
                <th className="check-seleziona-tutti">
                  <div className="checkbox checkbox-primary">
                    <input type="checkbox" checked={this.state.selezionaTutti} onChange={this.handleSelectAll} />
                    <label />
                  </div>
                </th>
              )}
              <th>Foto</th>
              <th>ID</th>
              <th>Nome</th>
              <th>Stato occ.</th>
              <th className="hidden-xs">Obiettivi</th>
              <th>Sportello</th>
              <th>Comune</th>
              {ruolo !== 'agenzia' || ruolo !== 'azienda' ? <th className="hidden-xs">Email</th> : null}
            </tr>
          </thead>
          <tbody>{this.renderCandidatiList()}</tbody>
        </table>
      </div>
    )
  }

  handleAlertInteresse = () => {
    swal({
      icon: 'success',
      title: 'Interesse registrato',
      text: "Grazie per l'interesse a un nostro candidato. Un nostro operatore la contatterà appena possibile.",
      buttons: [false, 'Ok'],
    })
  }

  handleUnlockCandidato = (crediti) => {
    swal({
      icon: 'warning',
      title: 'Sblocco candidato?',
      text: `I tuoi crediti passeranno da ${crediti} a ${crediti - 1}.`,
      buttons: ['Annulla', 'Sì, sblocca candidato'],
      dangerMode: true,
    }).then((willUnlock) => {
      if (willUnlock) {
        this.handleConfirmSblocca()
      }
    })
  }

  render() {
    const {
      ruolo,
      pagination,
      changePageCandidati,
      tipoRicerca,
      filters,
      livelloVisibilitaCandidati,
      regioneChiusaFlag,
    } = this.props

    return (
      <div className="candidati-list">
        <div className="heading">
          {ruolo === 'operatore' ? (
            <Dropdown id="candidati-dropdown" className="profile">
              <Dropdown.Toggle bsStyle="link" className="page-title">
                Candidati &raquo; {filters.tipoCandidati}
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                <MenuItem eventKey="1" onClick={this.handleChangeTipoCandidati('sportello')}>
                  <MdHome />
                  Candidati dello sportello
                </MenuItem>
                {(livelloVisibilitaCandidati === 'regione' || livelloVisibilitaCandidati === 'tutta la rete') && (
                  <MenuItem eventKey="2" onClick={this.handleChangeTipoCandidati('regione')}>
                    <MdPublic />
                    Candidati della regione
                  </MenuItem>
                )}
                {livelloVisibilitaCandidati === 'tutta la rete' && !regioneChiusaFlag && (
                  <MenuItem eventKey="3" onClick={this.handleChangeTipoCandidati('tutta la rete')}>
                    <MdPublic />
                    Candidati di tutta la rete
                  </MenuItem>
                )}
                <MenuItem eventKey="4" onClick={this.handleChangeTipoCandidati('archiviati')}>
                  <MdArchive />
                  Candidati archiviati
                </MenuItem>
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <h1 className="page-title">Candidati {filters.tipoCandidati}</h1>
          )}

          {ruolo === 'operatore' && (
            <Link to="/app/candidati/new" className="btn btn-success btn-nuovo-candidato">
              <Ink />
              <MdAdd style={{ verticalAlign: 'middle' }} />
              Nuovo candidato
            </Link>
          )}
        </div>
        {(ruolo === 'operatore' || ruolo === 'amministratore') && (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Link to="/app/candidati/statistiche">Statistiche</Link>
          </div>
        )}

        <div className="filters">
          {tipoRicerca === 'keywords' && <FiltroCandidati />}
          <div className="view-mode">
            <a
              href="/#"
              onClick={this.setViewModeThumbs.bind(this)}
              className={this.props.viewMode === 'thumbs' ? 'selected' : ''}
            >
              <FaThLarge />
            </a>
            <a
              href="/#"
              onClick={this.setViewModeList.bind(this)}
              className={this.props.viewMode === 'list' ? 'selected' : ''}
            >
              <FaList />
            </a>
          </div>

          <div className="show-advanced-search">
            {tipoRicerca === 'keywords' ? (
              <a href="/#" onClick={this.handleToggleTipoRicerca('avanzata')}>
                Ricerca avanzata
              </a>
            ) : (
              <a href="/#" onClick={this.handleToggleTipoRicerca('keywords')}>
                Nascondi ricerca avanzata
              </a>
            )}
          </div>
        </div>

        {tipoRicerca === 'avanzata' && <RicercaAvanzata />}

        <Pager
          position={'bottom'}
          page={pagination.page}
          totalPages={pagination.totalPages}
          onChangePage={changePageCandidati}
        />

        {this.state.candidatiSelezionati.length > 0 && (
          <div className="row aggiungi-gruppo">
            <div className="col-sm-8">
              <select
                className="form-control"
                value={this.state.gruppoSelezionato}
                onChange={this.handleChangeGruppoSelezionato}
              >
                <option value="">-- Seleziona un gruppo --</option>
                {this.props.gruppi.map((g) => (
                  <option key={g.gruppoCvSinteticoId} value={g.gruppoCvSinteticoId}>
                    {g.nome}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-sm-4">
              <button
                type="button"
                className="btn btn-primary btn-block"
                disabled={!this.state.gruppoSelezionato}
                onClick={this.handleAddCandidatiGruppo}
              >
                Aggiungi {this.state.candidatiSelezionati.length} candidati selezionati al gruppo
              </button>
            </div>
          </div>
        )}

        {this.renderCandidati()}

        <Pager
          position={'bottom'}
          page={pagination.page}
          totalPages={pagination.totalPages}
          onChangePage={changePageCandidati}
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    candidati: state.candidati.data,
    pagination: state.candidati.pagination,
    pageRequested: state.candidati.pageRequested,
    viewMode: state.candidati.viewMode,
    tipoRicerca: state.candidati.tipoRicerca,
    filters: state.candidati.filters,
    ruolo: state.auth.user.ruolo,
    crediti: state.auth.user.crediti,
    livelloVisibilitaCandidati: state.auth.user.livelloVisibilitaCandidati,
    regioneChiusaFlag: state.auth.user.regioneChiusaFlag,
    gruppi: state.gruppi,
  }
}

export default connect(mapStateToProps, {
  fetchCandidati,
  setViewModeCandidati,
  changeFilterCandidati,
  toggleTipoRicercaCandidati,
  resetCandidato,
  changePageCandidati,
  createInteressePerCandidato,
  deleteInteressePerCandidato,
  sbloccaCandidato,
  fetchGruppi,
  addCandidatiGruppo,
})(CandidatiList)
