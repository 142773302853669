import React, { Component } from 'react'
import { Form, Field } from 'react-final-form'
import { connect } from 'react-redux'
import { cambiaPassword } from '../../actions'

class CambioPassword extends Component {
  cambiaPassword(values) {
    this.props.cambiaPassword(values.vecchiaPassword, values.nuovaPassword, this.props.history)
  }

  render() {
    return (
      <div className="cambio-password">
        <h1 className="page-title">Modifica password</h1>
        <div className="card-box">
          <Form
            onSubmit={this.cambiaPassword.bind(this)}
            validate={validate}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Field
                  name="vecchiaPassword"
                  id="vecchiaPassword"
                  render={({ input, meta }) => (
                    <div className={`form-group ${meta.touched && meta.invalid ? 'has-error' : ''}`}>
                      <label htmlFor="vecchiaPassword">Password attuale</label>
                      <input type="password" className="form-control" {...input} />
                      <div className="help-block">{meta.touched && meta.error}</div>
                    </div>
                  )}
                />
                <Field
                  name="nuovaPassword"
                  id="nuovaPassword"
                  render={({ input, meta }) => (
                    <div className={`form-group ${meta.touched && meta.invalid ? 'has-error' : ''}`}>
                      <label htmlFor="nuovaPassword">Nuova password</label>
                      <input type="password" className="form-control" {...input} />
                      <div className="help-block">{meta.touched && meta.error}</div>
                    </div>
                  )}
                />
                <Field
                  name="nuovaPasswordRipeti"
                  id="nuovaPasswordRipeti"
                  render={({ input, meta }) => (
                    <div className={`form-group ${meta.touched && meta.invalid ? 'has-error' : ''}`}>
                      <label htmlFor="nuovaPasswordRipeti">Nuova password (ripeti)</label>
                      <input type="password" className="form-control" {...input} />
                      <div className="help-block">{meta.touched && meta.error}</div>
                    </div>
                  )}
                />

                <button type="submit" className="btn btn-lg btn-primary">
                  Imposta nuova password
                </button>
              </form>
            )}
          />
        </div>
      </div>
    )
  }
}

function validate(values) {
  const errors = {}

  if (!values.vecchiaPassword) {
    errors.vecchiaPassword = 'Inserisci la password attuale'
  }

  if (!values.nuovaPassword) {
    errors.nuovaPassword = 'Inserisci la nuova password'
  }

  if (values.nuovaPassword && values.nuovaPassword.length < 8) {
    errors.nuovaPassword = 'La password deve essere lunga almeno 8 caratteri'
  }

  var atLeastOneLetter = /[A-Z]/i
  if (!atLeastOneLetter.test(values.nuovaPassword)) {
    errors.nuovaPassword = 'Inserisci almeno una lettera'
  }

  var atLeastOneDigit = /\d/
  if (values.nuovaPassword && !atLeastOneDigit.test(values.nuovaPassword)) {
    errors.nuovaPassword = 'Inserisci almeno una cifra numerica'
  }

  if (values.nuovaPassword && values.nuovaPassword === values.vecchiaPassword) {
    errors.nuovaPassword = 'La nuova password non può essere uguale a quella attuale'
  }

  if (!values.nuovaPasswordRipeti) {
    errors.nuovaPasswordRipeti = 'Inserisci nuovamente la nuova password'
  }

  if (values.nuovaPassword !== values.nuovaPasswordRipeti) {
    errors.nuovaPasswordRipeti = 'Le due password non coincidono'
  }

  return errors
}

export default connect(null, { cambiaPassword })(CambioPassword)
