import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Ink from 'react-ink'
import swal from 'sweetalert'

import { removeConoscenzaInformatica } from '../../actions'
import { MdAdd, MdModeEdit, MdRemoveCircle, MdLens } from 'react-icons/md'

class ConoscenzeInformatiche extends Component {
  constructor(props) {
    super(props)

    this.state = {
      conoscenzaInformaticaId: 0,
    }
  }

  onRemove() {
    this.props.removeConoscenzaInformatica(
      this.state.conoscenzaInformaticaId,
      this.props.candidatoId
    )
  }

  convertiLivelloInNumero(livello) {
    switch (livello) {
      case 'Base':
        return 1
      case 'Buono':
        return 2
      case 'Avanzato':
        return 3
    }
  }

  getCssClass(livello, riferimento) {
    if (this.convertiLivelloInNumero(livello) === this.convertiLivelloInNumero(riferimento)) {
      return 'active'
    }
    if (this.convertiLivelloInNumero(livello) > this.convertiLivelloInNumero(riferimento)) {
      return 'light'
    }
    return ''
  }

  renderConoscenzeInformatiche() {
    const { conoscenzeInformatiche, candidatoId } = this.props

    if (this.props.conoscenzeInformatiche) {
      return conoscenzeInformatiche.conoscenze.map((conoscenzaInformatica) => {
        return (
          <tr key={conoscenzaInformatica.conoscenzaInformaticaId}>
            <td className="tipologia">{conoscenzaInformatica.tipologia}</td>
            <td className="conoscenza">
              <b className="color">{conoscenzaInformatica.conoscenza}</b>
            </td>
            <td className={this.getCssClass(conoscenzaInformatica.livello, 'Base')}>
              <MdLens />
            </td>
            <td className={this.getCssClass(conoscenzaInformatica.livello, 'Buono')}>
              <MdLens />
            </td>
            <td className={this.getCssClass(conoscenzaInformatica.livello, 'Avanzato')}>
              <MdLens />
            </td>
            {this.props.canEdit ? (
              <td>
                <Link
                  to={`/app/candidati/${candidatoId}/conoscenze-informatiche/${conoscenzaInformatica.conoscenzaInformaticaId}`}
                  className="btn btn-sm btn-primary"
                >
                  <MdModeEdit />
                  <span>Modifica</span>
                </Link>
              </td>
            ) : null}
            {this.props.canEdit ? (
              <td>
                <a
                  className="btn btn-sm btn-primary btn-remove"
                  onClick={() => {
                    this.setState({
                      conoscenzaInformaticaId: conoscenzaInformatica.conoscenzaInformaticaId,
                    })
                    this.handleDeleteConoscenzaInformatica()
                  }}
                >
                  <MdRemoveCircle />
                  Elimina
                </a>
              </td>
            ) : null}
          </tr>
        )
      })
    }
    return (
      <tr>
        <td>Caricamento in corso...</td>
      </tr>
    )
  }
  handleDeleteConoscenzaInformatica = () => {
    swal({
      icon: 'warning',
      title: 'Eliminazione conoscenza informatica',
      text: 'Sei sicuro di vole eliminare questa conoscenza informatica? Questa azione è irreversibile.',
      buttons: ['Annulla', 'Si, elimina conoscenza linguistica'],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onRemove()
      }
    })
  }

  render() {
    const { conoscenzeInformatiche, candidatoId } = this.props

    return (
      <div className={'card-box conoscenze-informatiche panel-show-section'}>
        <h4 className="header-title">Conoscenze Informatiche</h4>
        <div className="row">
          <div className="col-md-8">
            <table className="livello">
              <thead>
                <tr>
                  <th>Tipologia</th>
                  <th>Conoscenza</th>
                  <th>Base</th>
                  <th>Buono</th>
                  <th>Avanzato</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>{this.renderConoscenzeInformatiche()}</tbody>
            </table>
            {this.props.canEdit ? (
              <Link
                to={`/app/candidati/${candidatoId}/conoscenze-informatiche/new`}
                className="btn btn-add btn-sm btn-primary"
              >
                <MdAdd />
                Aggiungi
              </Link>
            ) : null}
          </div>
          <div className="col-md-4">
            <div className="dettagli">
              {conoscenzeInformatiche ? (
                <div>
                  <p>
                    {conoscenzeInformatiche.eventualiCertificazioni ? (
                      <span>
                        <b>Certificazioni:</b> {conoscenzeInformatiche.eventualiCertificazioni}
                      </span>
                    ) : (
                      'Nessuna certificazione'
                    )}
                  </p>
                  <p>
                    {conoscenzeInformatiche.descrizioneCv ? (
                      <span>
                        <b>Descrizione CV:</b> {conoscenzeInformatiche.descrizioneCv}
                      </span>
                    ) : (
                      'Nessuna descrizione CV'
                    )}
                  </p>
                </div>
              ) : null}
              {this.props.canEdit ? (
                <Link
                  to={`/app/candidati/${candidatoId}/conoscenze-informatiche`}
                  className="btn btn-sm btn-default"
                >
                  <Ink />
                  <MdModeEdit />
                  Modifica
                </Link>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    candidatoId: state.candidatoAttivo.candidatoId,
    conoscenzeInformatiche: state.candidatoAttivo.conoscenzeInformatiche,
    canEdit:
      !!state.auth.user.permessi['conoscenzeInformatiche'] &&
      state.candidatoAttivo.sportello &&
      state.candidatoAttivo.sportello.sportelloId === state.auth.user.sportelloCorrente.sportelloId,
  }
}

export default connect(mapStateToProps, { removeConoscenzaInformatica })(ConoscenzeInformatiche)
