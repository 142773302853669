import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import moment from 'moment'

import {
  cambioSportelloOperatore,
  setFilterPannelloExportCsv,
  fetchAreeGeografiche,
  fetchSportelli,
} from '../../actions'

import PannelloExportCsv from './pannello_export_csv/pannello_export_csv'

class Home extends Component {
  componentDidMount() {
    // Se password scaduta >> Redirect a cambio password
    if (this.props.user.ruolo === 'modifica_password') {
      this.props.history.push('/app/cambio-password')
    }
    if (this.props.user.ruolo === 'amministratore') {
      this.props.fetchAreeGeografiche()
      this.props.fetchSportelli()
    }
    if (this.props.user.ruolo === 'subamministratore') {
      this.props.setFilterPannelloExportCsv('areaGeograficaId', this.props.user.areaGeograficaId)
      this.props.fetchSportelli()
    }
  }

  renderSportelli() {
    return this.props.user.sportelli.map((sportello) => (
      <option key={sportello.sportelloId} value={sportello.sportelloId}>
        {sportello.tipo} {sportello.nome}
      </option>
    ))
  }

  onChangeSportello(event) {
    this.props.cambioSportelloOperatore(event.target.value)
  }

  handleChangeField = (field) => (event) => {
    this.props.setFilterPannelloExportCsv(field, event.target.value)
  }

  render() {
    return (
      <div className="home">
        <div className="row">
          <div className="col-md-6">
            <h1 className="page-title">Buongiorno, {this.props.user.nome}!</h1>
          </div>

          <div className="col-md-6">
            {this.props.user.ruolo === 'operatore' && (
              <form className="form-inline pull-right">
                <div className="form-group">
                  <label htmlFor="sportello">Sportello:</label>&nbsp;&nbsp;
                  <select
                    className="form-control"
                    id="sportello"
                    value={this.props.user.sportelloCorrente.sportelloId}
                    onChange={this.onChangeSportello.bind(this)}
                  >
                    {this.renderSportelli()}
                  </select>
                </div>
              </form>
            )}
          </div>
        </div>

        {this.props.user.ruolo === 'modifica_password' ? (
          <div className="alert alert-danger password-scaduta">
            <h4 className="header-title">Attenzione: Password scaduta</h4>
            <p>
              La tua password è più vecchia di 90 giorni: per proseguire devi impostare una nuova password. Clicca qui
              sotto! :)
            </p>
            <Link to="/cambio-password" className="btn btn-primary">
              Cambia password &raquo;
            </Link>
          </div>
        ) : null}

        {this.props.user.ruolo === 'amministratore' || this.props.user.ruolo === 'subamministratore' ? (
          <div>
            {this.props.user.ruolo === 'amministratore' ? (
              <PannelloExportCsv
                onChange={this.handleChangeField}
                filtri={this.props.pannelloExportCsv}
                aree={this.props.areeGeografiche}
                sportelli={this.props.sportelli}
              />
            ) : (
              <PannelloExportCsv
                onChange={this.handleChangeField}
                filtri={this.props.pannelloExportCsv}
                aree={[
                  {
                    areaGeograficaId: this.props.user.areaGeograficaId,
                    nome: this.props.user.areaGeograficaNome,
                    regione: this.props.user.areaGeograficaNome,
                  },
                ]}
                sportelli={this.props.sportelli}
              />
            )}
            <img src="images/admin.jpg" className="img-responsive" alt="immagine-admin" />
          </div>
        ) : (
          <img src="images/home.jpg" className="img-responsive" alt="immagine-admin" />
        )}

        {this.props.user.dataUltimoLogin ? (
          <p>Il tuo ultimo accesso risale al {moment(this.props.user.dataUltimoLogin).format('LLL')}.</p>
        ) : (
          <p>Questo è il tuo primo accesso al portale.</p>
        )}
        {/*<div className="row">
        	<div className="col-lg-4">
        		<div className="card-box">
        			<div className="header-title">Da fare</div>
        		</div>
        	</div>
        	<div className="col-lg-4">
        		<div className="card-box">
        			<div className="header-title">Appuntamenti</div>
        		</div>
        	</div>
        	<div className="col-lg-4">
        		<div className="card-box">
        			<div className="header-title">Ultimi candidati</div>
        		</div>
        	</div>
        </div>*/}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    pannelloExportCsv: state.exportCsv,
    areeGeografiche: state.areeGeografiche,
    sportelli: state.sportelli,
  }
}

export default connect(mapStateToProps, {
  cambioSportelloOperatore,
  setFilterPannelloExportCsv,
  fetchAreeGeografiche,
  fetchSportelli,
})(Home)
