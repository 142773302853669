import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Field, Form } from 'react-final-form'

import {
  fetchSportello,
  createSportello,
  saveSportello,
  fetchAreeGeografiche,
  fetchSportelli,
} from '../../../actions'
import { getSportelliAreaGeografica } from '../../../reducers/reducer_sportelli'

import { FaStar } from 'react-icons/fa'

import './create_edit_sportello.less'
import { connect } from 'react-redux'

class CreateEditSportello extends Component {
  componentDidMount() {
    if (this.props.ruolo === 'subamministratore') {
      this.props.fetchAreeGeografiche(this.props.areaGeograficaId, this.props.areaGeograficaNome)
    } else {
      this.props.fetchAreeGeografiche()
    }

    this.props.fetchSportelli()

    if (this.props.match.params.sportelloId) {
      this.props.fetchSportello(this.props.match.params.sportelloId)
    }
  }

  onSubmit(values) {
    if (this.props.match.params.sportelloId) {
      const {sportelloId,tipologia,padreSportelloEmail,areaGeografica,areaGeograficaNome,operatori, ...rest} = values
      // Siamo in update
      this.props.saveSportello(rest, this.props.match.params.sportelloId).then((action) => {
        // Redirect a dettagli candidato
        this.props.history.push(`/app/sportelli`)
      })
    } else {
      // Siamo in new
      this.props.createSportello(values).then((action) => {
        // Set candidatoId e show Alert
        this.props.history.push(`/app/sportelli`)
      })
    }
  }

  renderAreeGeografiche() {
    if (this.props.areeGeografiche) {
      return this.props.areeGeografiche.map((areaGeografica) => (
        <option key={areaGeografica.areaGeograficaId} value={areaGeografica.areaGeograficaId}>
          {areaGeografica.nome}
        </option>
      ))
    }
    return null
  }

  renderSportelliAreaGeografica() {
    if (this.props.sportelliAreaGeografica) {
      return this.props.sportelliAreaGeografica.map((sportello) => (
        <option key={sportello.sportelloId} value={sportello.sportelloId}>
          {sportello.organizzazione} {sportello.nome}
        </option>
      ))
    }
    return null
  }

  renderOperatori(sportello) {
    if (!!sportello.operatori && sportello.operatori.length > 0) {
      return sportello.operatori.map((operatore) => {
        return (
          <li key={operatore.operatoreId}>
            <Link to={`/app/operatori/${operatore.operatoreId}`}>
              {operatore.cognome} {operatore.nome} {!!operatore.responsabileFlag && <FaStar />}
            </Link>
          </li>
        )
      })
    }
    return 'Questo sportello non ha operatori!'
  }

  ///////////////////////////////////////////////////////////////
  // RENDER
  render() {
    const { sportello } = this.props
    const initialValues = this.props.initialValues ? this.props.initialValues : {}

    return (
      <div className="edit-sportello">
        <div className="row">
          <div className="col-md-8">
            <h1 className="page-title">Sportello</h1>
            <div className="card-box">
              <Form
                onSubmit={this.onSubmit.bind(this)}
                initialValues={initialValues}
                validate={validate}
                render={({ handleSubmit, form }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-6">
                        <Field
                          name="nome"
                          id="nome"
                          render={({ input, meta }) => (
                            <div
                              className={`form-group ${
                                meta.touched && meta.invalid ? 'has-error' : ''
                              }`}
                            >
                              <label htmlFor="nome">Nome sportello</label>
                              <input type="text" className="form-control" {...input} />
                              <div className="help-block">{meta.touched && meta.error}</div>
                            </div>
                          )}
                        />
                      </div>
                      <div className="col-sm-6">
                        <Field
                          name="organizzazione"
                          id="organizzazione"
                          render={({ input, meta }) => (
                            <div
                              className={`form-group ${
                                meta.touched && meta.invalid ? 'has-error' : ''
                              }`}
                            >
                              <label htmlFor="organizzazione">Organizzazione</label>
                              <select className="form-control" id="organizzazione" {...input}>
                                <option value="">-- Seleziona --</option>
                                <option value="CISL">CISL</option>
                                <option value="IAL">IAL</option>
                              </select>
                              <div className="help-block">{meta.touched && meta.error}</div>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <Field
                          name="areaGeograficaId"
                          id="areaGeograficaId"
                          render={({ input, meta }) => (
                            <div
                              className={`form-group ${
                                meta.touched && meta.invalid ? 'has-error' : ''
                              }`}
                            >
                              <label htmlFor="areaGeografica">Regione</label>
                              <select className="form-control" id="areaGeografica" {...input}>
                                <option>--Seleziona--</option>
                                {this.renderAreeGeografiche()}
                              </select>
                              <div className="help-block">{meta.touched && meta.error}</div>
                            </div>
                          )}
                        />
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="padreSportelloId">Sportello padre</label>
                          <Field
                            name="padreSportelloId"
                            id="padreSportelloId"
                            render={({ input }) => (
                              <select className="form-control" {...input}>
                                <option value="">--Nessuno--</option>
                                {this.renderSportelliAreaGeografica()}
                              </select>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    {form.getFieldState('organizzazione')?.value === 'CISL' ? (
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="cislUst">UST CISL</label>
                            <Field
                              id="cislUst"
                              name="cislUst"
                              type="text"
                              className="form-control"
                              component="input"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="cislComprensorio">Comprensorio CISL</label>
                            <Field
                              id="cislComprensorio"
                              name="cislComprensorio"
                              type="text"
                              className="form-control"
                              component="input"
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="indirizzo">Indirizzo</label>
                          <Field
                            name="indirizzo"
                            id="indirizzo"
                            component="textarea"
                            rows="5"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="descrizioneOrariApertura">Orari apertura</label>
                          <Field
                            name="descrizioneOrariApertura"
                            id="descrizioneOrariApertura"
                            component="textarea"
                            rows="5"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="telefono">Telefono</label>
                          <Field
                            id="telefono"
                            name="telefono"
                            type="text"
                            className="form-control"
                            component="input"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="fax">Fax</label>
                          <Field
                            id="fax"
                            name="fax"
                            type="text"
                            className="form-control"
                            component="input"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="email">Email</label>
                          <Field
                            id="email"
                            name="email"
                            type="email"
                            className="form-control"
                            component="input"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="creditiSms">Crediti sms</label>
                          <Field
                            id="creditiSms"
                            name="creditiSms"
                            type="number"
                            className="form-control"
                            component="input"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="latitudine">Latitudine</label>
                          <Field
                            id="latitudine"
                            name="latitudine"
                            type="latitudine"
                            className="form-control"
                            component="input"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="longitudine">Longitudine</label>
                          <Field
                            id="longitudine"
                            name="longitudine"
                            type="longitudine"
                            className="form-control"
                            component="input"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <div className="checkbox checkbox-primary">
                            <Field
                              id="sitoWebFlag"
                              name="sitoWebFlag"
                              type="checkbox"
                              className="form-control"
                              component="input"
                            />
                            <label htmlFor="sitoWebFlag">Mostra su sito web</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <div className="checkbox checkbox-primary">
                            <Field
                              id="attivoFlag"
                              name="attivoFlag"
                              type="checkbox"
                              className="form-control"
                              component="input"
                            />
                            <label htmlFor="attivoFlag">Attivo</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="panel panel-color panel-blue" style={{ marginTop: 30 }}>
                      <div className="panel-heading">
                        <h3 className="panel-title">Privacy GDPR</h3>
                      </div>
                      <div className="panel-body">
                        <div className="form-group">
                          <label htmlFor="privacyTitolare">Titolare del trattamento</label>
                          <Field
                            id="privacyTitolare"
                            name="privacyTitolare"
                            type="text"
                            className="form-control"
                            component="input"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="privacyUstIndirizzo">Indirizzo completo UST</label>
                          <Field
                            id="privacyUstIndirizzo"
                            name="privacyUstIndirizzo"
                            type="text"
                            className="form-control"
                            component="input"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="privacyUstEmail">Email UST</label>
                          <Field
                            id="privacyUstEmail"
                            name="privacyUstEmail"
                            type="text"
                            className="form-control"
                            component="input"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="privacyResponsabileEmail">Email Responsabile</label>
                          <Field
                            id="privacyResponsabileEmail"
                            name="privacyResponsabileEmail"
                            type="email"
                            className="form-control"
                            component="input"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="privacyLinkInformativa">Link Informativa</label>
                          <Field
                            id="privacyLinkInformativa"
                            name="privacyLinkInformativa"
                            type="text"
                            className="form-control"
                            component="input"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="panel panel-color panel-pink" style={{ marginTop: 30 }}>
                      <div className="panel-heading">
                        <h3 className="panel-title">Mittenti</h3>
                      </div>
                      <div className="panel-body">
                        <div className="form-group">
                          <label htmlFor="mittenteEmail">Mittente e-mail</label>
                          <Field
                            id="mittenteEmail"
                            name="mittenteEmail"
                            type="email"
                            className="form-control"
                            component="input"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="mittenteSms">Mittente SMS</label>
                          <Field
                            id="mittenteSms"
                            name="mittenteSms"
                            type="text"
                            className="form-control"
                            component="input"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="mittenteNewsletter">Mittente newsletter</label>
                          <Field
                            id="mittenteNewsletter"
                            name="mittenteNewsletter"
                            type="email"
                            className="form-control"
                            component="input"
                          />
                        </div>
                      </div>
                    </div>

                    <button type="submit" className="btn btn-primary">
                      Salva sportello
                    </button>
                  </form>
                )}
              />
            </div>
          </div>
          <div className="col-md-4">
            <h1 className="page-title">Operatori</h1>
            <div className="card-box">
              <ul className="operatori list-unstyled">{this.renderOperatori(sportello)}</ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    initialValues: state.sportelloAttivo,
    sportello: state.sportelloAttivo,
    areaGeograficaId: state.auth.user.areaGeograficaId,
    areaGeograficaNome: state.auth.user.areaGeograficaNome,
    ruolo: state.auth.user.ruolo,
    areeGeografiche: state.areeGeografiche,
    sportelliAreaGeografica: getSportelliAreaGeografica(state),
  }
}

function validate(values) {
  const errors = {}

  if (!values.nome) {
    errors.nome = 'Inserisci il nome'
  }

  if (!values.areaGeograficaId) {
    errors.areaGeograficaId = 'Seleziona la regione'
  }

  if (!values.organizzazione) {
    errors.organizzazione = "Seleziona l'organizzazione"
  }

  return errors
}

export default connect(mapStateToProps, {
  fetchSportello,
  createSportello,
  saveSportello,
  fetchAreeGeografiche,
  fetchSportelli,
})(CreateEditSportello)
