export const LOGIN_START = 'login_start'
export const AUTH_USER = 'auth_user'
export const UNAUTH_USER = 'unauth_user'
export const AUTH_ERROR = 'auth_error'
export const FETCH_USER = 'fetch_user'
export const PASSWORD_CHANGED = 'password_changed'
export const RESET_PASSWORD = 'reset_password'
export const CHANGE_PASSWORD = 'change_password'
export const VERIFY_TOKEN = 'verify_token'
export const NOTIFICATION = 'notification'
export const CLEAR_NOTIFICATION = 'clear_notification'
export const IMPERSONA = 'impersona'

export const TOGGLE_MOBILE_MENU_OPEN = 'toggle_mobile_menu_open'

export const FETCH_LOOKUPS = 'fetch_lookups'
export const FETCH_COMUNI = 'fetch_comuni'
export const FETCH_CANDIDATI = 'fetch_candidati'
export const SET_VIEW_MODE_CANDIDATI = 'set_view_mode_candidati'
export const CHANGE_FILTER_CANDIDATI = 'change_filter_candidati'
export const TOGGLE_TIPO_RICERCA_CANDIDATI = 'toggle_tipo_ricerca_candidati'

export const CHANGE_PAGE_CANDIDATI = 'change_page_candidati'

export const FETCH_TYPEAHEAD_COMUNI = 'fetch_typeahead_comuni'
export const FETCH_TYPEAHEAD_COMUNI_STATI_ESTERI = 'fetch_typeahead_comuni_stati_esteri'
export const FETCH_TYPEAHEAD_POSIZIONI_PROFESSIONALI = 'fetch_typeahead_posizioni_professionali'
export const FETCH_TYPEAHEAD_CANDIDATI = 'fetch_typeahead_candidati'
export const VERIFICA_CODICE_FISCALE_CISL = 'verifica_codice_fiscale_cisl'

export const RESET_CANDIDATO = 'reset_candidato'
export const FETCH_CANDIDATO = 'fetch_candidato'
export const CREATE_CANDIDATO = 'create_candidato'
export const DELETE_CANDIDATO = 'delete_candidato'
export const ARCHIVE_CANDIDATO = 'archive_candidato'
export const UNARCHIVE_CANDIDATO = 'unarchive_candidato'
export const GET_STATISTICHE_CANDIDATO = 'statistiche_candidato'

export const GET_STATS = 'get_statistiche'

export const SAVE_ANAGRAFICA = 'save_anagrafica'
export const SAVE_SITO_WEB_FLAG = 'save_sito_web_flag'
export const SAVE_STAMPE = 'save_stampe'
export const SET_NOTE_AGENZIA = 'set_note_agenzia'
export const SAVE_NOTE_AGENZIA = 'save_note_agenzia'
export const SAVE_NOTE_AGENZIA_DA_OPERATORE = 'save_note_agenzia_da_operatore'
export const SAVE_STATO_OCCUPAZIONALE = 'save_stato_occupazionale'
export const SAVE_CONOSCENZE_INFORMATICHE = 'save_conoscenze_informatiche'
export const SAVE_INTERESSI_CORSI = 'save_interessi_corsi'
export const SAVE_ALTRE_COMPETENZE = 'save_altre_competenze'
export const SAVE_OBIETTIVI_PROFESSIONALI = 'save_obiettivi_professionali'
export const SET_CENTRO_DISPONIBILITA_GEOGRAFICA = 'set_centro_disponibilita_geografica'
export const SET_RAGGIO_DISPONIBILITA_GEOGRAFICA = 'set_raggio_disponibilita_geografica'
export const SAVE_DISPONIBILITA_GEOGRAFICA = 'save_disponibilita_geografica'
export const SAVE_ALTRE_INFORMAZIONI = 'save_altre_informazioni'
export const SET_VIDEOCV_URL = 'set_videocv_url'
export const SAVE_VIDEOCV_URL = 'save_videocv_url'
export const SAVE_USO_INTERNO = 'save_uso_interno'
export const SAVE_DESCRIZIONE_CV_SINTETICO = 'save_descrizione_cv_sintetico'
export const INVIA_EMAIL_SEGNALAZIONE = 'invia_email_segnalazione'
export const SAVE_NOTE_INTERNE = 'save_note_interne'
export const SAVE_PRIVACY = 'save_privacy'
export const CREATE_INTERESSE_PER_CANDIDATO = 'create_interesse_per_candidato'
export const DELETE_INTERESSE_PER_CANDIDATO = 'delete_interesse_per_candidato'

export const SAVE_NUOVO_SPORTELLO = 'save_nuovo_sportello'
export const CONFERMA_SPOSTAMENTO = 'conferma_spostamento'
export const ANNULLA_SPOSTAMENTO = 'annulla_spostamento'

export const SBLOCCA_CANDIDATO = 'sblocca_candidato'

export const SET_STATO_SERVIZIO = 'set_stato_servizio'
export const SET_NOTE_SERVIZIO = 'set_note_servizio'
export const SAVE_SERVIZIO = 'save_servizio'

export const ADD_ESPERIENZA_LAVORATIVA = 'add_esperienza_lavorativa'
export const REMOVE_ESPERIENZA_LAVORATIVA = 'remove_esperienza_lavorativa'
export const UPDATE_ESPERIENZA_LAVORATIVA = 'update_esperienza_lavorativa'
export const DUPLICATE_ESPERIENZA_LAVORATIVA = 'duplicate_esperienza_lavorativa'

export const ADD_TITOLO_STUDIO = 'add_titolo_studio'
export const REMOVE_TITOLO_STUDIO = 'remove_titolo_studio'
export const UPDATE_TITOLO_STUDIO = 'update_titolo_studio'

export const ADD_CORSO_FORMAZIONE = 'add_corso_formazione'
export const REMOVE_CORSO_FORMAZIONE = 'remove_corso_formazione'
export const UPDATE_CORSO_FORMAZIONE = 'update_corso_formazione'

export const ADD_CONOSCENZA_LINGUISTICA = 'add_conoscenza_linguistica'
export const REMOVE_CONOSCENZA_LINGUISTICA = 'remove_conoscenza_linguistica'
export const UPDATE_CONOSCENZA_LINGUISTICA = 'update_conoscenza_linguistica'

export const ADD_CONOSCENZA_INFORMATICA = 'add_conoscenza_informatica'
export const REMOVE_CONOSCENZA_INFORMATICA = 'remove_conoscenza_informatica'
export const UPDATE_CONOSCENZA_INFORMATICA = 'update_conoscenza_informatica'

export const SET_NEW_POSIZIONE_PROFESSIONALE = 'set_new_posizione_professionale'
export const CLEAR_NEW_POSIZIONE_PROFESSIONALE = 'clear_new_posizione_professionale'
export const ADD_OBIETTIVO_PROFESSIONALE = 'add_obiettivo_professionale'
export const SAVE_OBIETTIVO_PROFESSIONALE = 'save_obiettivo_professionale'
export const REMOVE_OBIETTIVO_PROFESSIONALE = 'remove_obiettivo_professionale'

export const ADD_DISPONIBILITA_GEOGRAFICA = 'add_disponibilita_geografica'
export const REMOVE_DISPONIBILITA_GEOGRAFICA = 'remove_disponibilita_geografica'

export const UPLOAD_ALLEGATI = 'upload_allegati'
export const REMOVE_ALLEGATO = 'remove_allegato'

export const FETCH_SLOTS = 'fetch_slots'
export const FETCH_SLOT = 'fetch_slot'
export const CREATE_SLOT = 'create_slot'
export const SAVE_SLOT = 'save_slot'
export const DELETE_SLOT = 'delete_slot'
export const ADD_APPUNTAMENTO = 'add_appuntamento'
export const REMOVE_APPUNTAMENTO = 'remove_appuntamento'

export const FETCH_GRUPPI = 'fetch_gruppi'
export const FETCH_GRUPPI_CANDIDATO = 'fetch_gruppi_candidato'
export const FETCH_GRUPPO = 'fetch_gruppo'
export const CREATE_GRUPPO = 'create_gruppo'
export const SAVE_GRUPPO = 'save_gruppo'
export const DELETE_GRUPPO = 'delete_gruppo'
export const ADD_CANDIDATO_GRUPPO = 'add_candidato_gruppo'
export const ADD_GRUPPO_CANDIDATO = 'add_gruppo_candidato'
export const REMOVE_CANDIDATO_GRUPPO = 'remove_candidato_gruppo'
export const INVIA_EMAIL_CV_GRUPPO = 'invia_email_cv_gruppo'
export const ADD_CANDIDATI_GRUPPO = 'add_candidati_gruppo'

export const FETCH_AREE_GEOGRAFICHE = 'fetch_aree_geografiche'
export const FETCH_AREA_GEOGRAFICA = 'fetch_area_geografica'
export const CREATE_AREA_GEOGRAFICA = 'create_area_geografica'
export const SAVE_AREA_GEOGRAFICA = 'save_area_geografica'
export const DELETE_AREA_GEOGRAFICA = 'delete_area_geografica'
export const RESET_AREA_GEOGRAFICA_ATTIVA = 'reset_area_geografica_attiva'

export const FETCH_SPORTELLI = 'fetch_sportelli'
export const FETCH_SPORTELLO = 'fetch_sportello'
export const CREATE_SPORTELLO = 'create_sportello'
export const SAVE_SPORTELLO = 'save_sportello'
export const DELETE_SPORTELLO = 'delete_sportello'
export const RESET_SPORTELLO_ATTIVO = 'reset_sportello_attivo'

export const FETCH_OPERATORI = 'fetch_operatori'
export const FILTER_OPERATORI = 'filter_operatori'
export const FETCH_OPERATORE = 'fetch_operatore'
export const CREATE_OPERATORE = 'create_operatore'
export const SAVE_OPERATORE = 'save_operatore'
export const DELETE_OPERATORE = 'delete_operatore'
export const RESET_OPERATORE_ATTIVO = 'reset_operatore_attivo'
export const ADD_SPORTELLO_OPERATORE = 'add_sportello_operatore'
export const REMOVE_SPORTELLO_OPERATORE = 'remove_sportello_operatore'

export const FETCH_SUBAMMINISTRATORI = 'fetch_subamministratori'
export const FETCH_SUBAMMINISTRATORE = 'fetch_subamministratore'
export const CREATE_SUBAMMINISTRATORE = 'create_subamministratore'
export const SAVE_SUBAMMINISTRATORE = 'save_subamministratore'
export const DELETE_SUBAMMINISTRATORE = 'delete_subamministratore'
export const RESET_SUBAMMINISTRATORE_ATTIVO = 'reset_subamministratore_attivo'

export const FETCH_PROVINCE = 'fetch_province'
export const REGISTRAZIONE_UTENTE = 'registrazione_utente'
export const RESET_STATO_REGISTRAZIONE = 'reset_stato_registrazione'
export const INVIO_FORM_SPORTELLO = 'invio_form_sportello'
export const RESET_STATO_INVIO_FORM_SPORTELLO = 'reset_stato_invio_form_sportello'
export const FETCH_SPORTELLI_PER_AREA = 'fetch_sportelli_per_area'

export const FETCH_AZIENDE = 'fetch_aziende'
export const FETCH_AZIENDA = 'fetch_azienda'
export const CREATE_AZIENDA = 'create_azienda'
export const SAVE_AZIENDA = 'save_azienda'
export const DELETE_AZIENDA = 'delete_azienda'
export const SET_KEYWORDS_AZIENDE = 'set_keywords_aziende'
export const SET_SPORTELLO_CORRENTE_FLAG_AZIENDE = 'set_sportello_corrente_flag_aziende'
export const CHANGE_PAGE_AZIENDE = 'change_page_aziende'
export const FETCH_JOB_VACANCIES_AZIENDA = 'fetch_job_vacancies_azienda'

export const FETCH_AGENZIE = 'fetch_agenzie'
export const FETCH_AGENZIA = 'fetch_agenzia'
export const CREATE_AGENZIA = 'create_agenzia'
export const SAVE_AGENZIA = 'save_agenzia'
export const DELETE_AGENZIA = 'delete_agenzia'
export const SET_KEYWORDS_AGENZIE = 'set_keywords_agenzie'
export const SET_SPORTELLO_CORRENTE_FLAG_AGENZIE = 'set_sportello_corrente_flag_agenzie'
export const CHANGE_PAGE_AGENZIE = 'change_page_agenzie'
export const FETCH_JOB_VACANCIES_AGENZIA = 'fetch_job_vacancies_agenzia'
export const ADD_CREDITI_AGENZIA = 'add_crediti_agenzia;'

export const FETCH_JOB_VACANCIES = 'fetch_job_vacancies'
export const FETCH_JOB_VACANCY = 'fetch_job_vacancy'
export const CREATE_JOB_VACANCY = 'create_job_vacancy'
export const SAVE_JOB_VACANCY = 'save_job_vacancy'
export const DELETE_JOB_VACANCY = 'delete_job_vacancy'
export const SET_KEYWORDS_JOB_VACANCIES = 'set_keywords_job_vacancies'
export const SET_PROVINCIA_JOB_VACANCIES = 'set_provincia_job_vacancies'
export const CHANGE_PAGE_JOB_VACANCIES = 'change_page_job_vacancies'
export const SET_SOLO_PROPRIETARIO_FLAG_JV = 'set_solo_proprietario_flag_jv'
export const SET_MOSTRA_JV_APERTE = 'set_mostra_jv_aperte'
export const CREATE_CANDIDATURA = 'create_candidatura'
export const DELETE_CANDIDATURA = 'delete_candidatura'

export const FETCH_OPERATORI_AZAG = 'fetch_operatori_azag'
export const SET_EDIT_OPERATORE_AZAG = 'set_edit_operatore_azag'
export const SAVE_OPERATORE_AZAG = 'set_operatore_azag'
export const DELETE_OPERATORE_AZAG = 'delete_operatore_azag'
export const CREATE_OPERATORE_AZAG = 'create_operatore_azag'

export const SEND_EMAIL = 'send_email'
export const SEND_SMS = 'send_sms'
export const RESET_STATO_INVIO_MESSAGGIO = 'reset_stato_invio_messaggio'

export const SEND_CONTATTO_APP = 'send_contatto_app'
export const RESET_STATO_CONTATTO_APP = 'reset_stato_contatto_app'

export const FETCH_NOTIFICHE = 'fetch_notifiche'
export const FETCH_NOTIFICHE_NON_LETTE = 'fetch_notifiche_non_lette'
export const SET_NOTIFICA_LETTA = 'set_notifica_letta'
export const CHANGE_PAGE_NOTIFICHE = 'change_page_notifiche'

export const CREATE_NOTA_DIARIO_CANDIDATO = 'create_nota_diario_candidato'
export const DELETE_NOTA_DIARIO_CANDIDATO = 'delete_nota_diario_candidato'

// MOVIMENTI
export const FETCH_MOVIMENTI_CREDITI_AGENZIE = 'fetch_movimenti_crediti_agenzie'
export const FETCH_ACQUISIZIONE_CANDIDATI_SPORTELLO = 'fetch_acquisizione_candidati_sportello'

// NEWSLETTERS LIST
export const FETCH_NEWSLETTERS = 'fetch_newsletters'
export const CHANGE_PAGE_NEWSLETTERS = 'change_page_newsletters'
export const DELETE_NEWSLETTER = 'delete_newsletter'
export const DUPLICATE_NEWSLETTER = 'duplicate_newsletter'

// NEWSLETTER DETAILS
export const RESET_NEWSLETTER = 'reset_newsletter'
export const FETCH_NEWSLETTER = 'fetch_newsletter'
export const CREATE_NEWSLETTER = 'create_newsletter'
export const SAVE_NEWSLETTER = 'save_newsletter'
export const SEND_NEWSLETTER = 'send_newsletter'
export const UPDATE_NEWSLETTER = 'update_newsletter'
export const ADD_PARAGRAFO_NEWSLETTER = 'create_paragrafo_newsletter'
export const REMOVE_PARAGRAFO_NEWSLETTER = 'delete_paragrafo_newsletter'
export const UPDATE_PARAGRAFO_NEWSLETTER = 'update_paragrafo_newsletter'
export const ADD_SPORTELLO_NEWSLETTER = 'add_sportello_newsletter'
export const REMOVE_SPORTELLO_NEWSLETTER = 'remove_sportello_newsletter'
export const FETCH_NUMERO_DESTINATARI = 'fetch_numero_destinatari'
export const REVOCA_CONSENSO_NEWSLETTER = 'revoca_consenso_newsletter'
export const UPLOAD_ALLEGATO_NEWSLETTER = 'upload_allegato_newsletter'
export const REMOVE_ALLEGATO_NEWSLETTER = 'remove_allegato_newsletter'

export const SET_FILTER_NOTE_DIARIO_ESCLUDI_AUTOMATICHE = 'set_filter_note_diario_escludi_automatiche'

// CREDITI SMS CONSUMATI
export const SET_FILTER_CREDITI_SMS_CONSUMATI = 'set_filter_crediti_sms_consumati'
export const FETCH_CREDITI_SMS_CONSUMATI = 'fetch_crediti_sms_consumati'
export const FETCH_CREDITI_SMS = 'fetch_crediti_sms'

// PANNELLO EXPORT CSV
export const SET_FILTER_PANNELLO_EXPORT_CSV = 'set_filter_pannello_export_csv'

//CORSI DI FORMAZIONE
export const CAMBIA_PAGINA_CORSI_FORMAZIONE = 'cambia_pagina_corsi_formazione'
export const SET_KEYWORDS_CORSI_FORMAZIONE = 'set_keywords_corsi_formazione'
export const FETCH_CORSI_FORMAZIONE = 'fetch_corsi_formazione'
export const FETCH_CORSI_FORMAZIONE_CANDIDATO = 'fetch_corsi_formazione_candidato'
export const SAVE_CORSO_FORMAZIONE = 'save_corso_formazione'
export const CREATE_CORSO_FORMAZIONE = 'create_corsi_formazione'
export const DELETE_CORSO_FORMAZIONE = 'delete_corso_formazione'
export const FETCH_CORSO_ATTIVO = 'fetch_corso_attivo'
export const SUBSCRIBE_CORSO = 'iscrizione_corso'
