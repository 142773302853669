import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import { fetchMovimentiCreditiAgenzie, fetchAcquisizioneCandidatiSportello } from '../../actions'

import './movimenti.less'

class Movimenti extends Component {
  constructor(props) {
    super(props)

    this.state = {
      mese: moment().format('M'),
      anno: moment().format('YYYY'),
    }
  }

  fetchData() {
    const { mese, anno } = this.state
    this.props.fetchMovimentiCreditiAgenzie(mese, anno)
    this.props.fetchAcquisizioneCandidatiSportello(mese, anno)
  }

  componentDidMount() {
    this.fetchData()
  }

  handleChangeFilter = field => event => {
    this.setState(
      {
        [field]: event.target.value,
      },
      () => {
        this.fetchData()
      },
    )
  }

  render() {
    const annoCorrente = moment().format('YYYY')
    const anni = [annoCorrente - 2, annoCorrente - 1, annoCorrente]
    const mesi = [
      { value: '1', label: 'Gennaio' },
      { value: '2', label: 'Febbraio' },
      { value: '3', label: 'Marzo' },
      { value: '4', label: 'Aprile' },
      { value: '5', label: 'Maggio' },
      { value: '6', label: 'Giugno' },
      { value: '7', label: 'Luglio' },
      { value: '8', label: 'Agosto' },
      { value: '9', label: 'Settembre' },
      { value: '10', label: 'Ottobre' },
      { value: '11', label: 'Novembre' },
      { value: '12', label: 'Dicembre' },
    ]

    const { movimentiCreditiAgenzie, acquisizioneCandidatiSportello } = this.props

    return (
      <div className="movimenti">
        <div className="filtro">
          <div className="row">
            <div className="col-md-3">
              <select className="form-control" value={this.state.mese} onChange={this.handleChangeFilter('mese')}>
                {mesi.map(mese => (
                  <option key={mese.value} value={mese.value}>
                    {mese.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-3">
              <select className="form-control" value={this.state.anno} onChange={this.handleChangeFilter('anno')}>
                {anni.map(anno => (
                  <option key={anno} value={anno}>
                    {anno}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <h1 className="page-title">Sblocco candidati da parte di Agenzie</h1>

        <div className="card-box movimenti-agenzie">
          <table className="table">
            <thead>
              <tr>
                <th>Data</th>
                <th>Agenzia</th>
                <th>Candidato</th>
                <th className="text-center">Crediti</th>
                <th>Note</th>
              </tr>
            </thead>
            <tbody>
              {movimentiCreditiAgenzie.map(m => (
                <tr key={m.movimentoCreditiAgenziaId}>
                  <td>{moment(m.data).format('DD/MM/YYYY')}</td>
                  <td>
                    {m.agenziaNome}<br />
                    <i>{m.agenziaMarchio}</i>
                  </td>
                  <td>
                    {m.candidatoId ? (
                      <span>
                        <img src={m.fotografia} className="avatar" /> {m.nome} {m.cognome}
                      </span>
                    ) : (
                      <span>RICARICA CREDITI</span>
                    )}
                  </td>
                  <td className="crediti" style={{ color: m.crediti >= 0 ? '#3cb552' : '#e91e63' }}>
                    {m.crediti}
                  </td>
                  <td className="note">{m.note}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <h1 className="page-title" style={{ marginTop: 40 }}>
          Cambio sportello candidati
        </h1>
        <div className="card-box">
          <table className="table">
            <thead>
              <tr>
                <th>Data</th>
                <th>Candidato</th>
                <th>Da sportello</th>
                <th>A sportello</th>
              </tr>
            </thead>
            <tbody>
              {acquisizioneCandidatiSportello.map(a => (
                <tr key={a.acquisizioneCandidatiSportelloId}>
                  <td>{moment(a.data).format('DD/MM/YYYY')}</td>
                  <td>
                    <img src={a.fotografia} className="avatar" /> {a.nome} {a.cognome}
                  </td>
                  <td>{a.sportelloCedente}</td>
                  <td>{a.sportelloDestinatario}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  movimentiCreditiAgenzie: state.movimentiCreditiAgenzie,
  acquisizioneCandidatiSportello: state.acquisizioneCandidatiSportello,
})

export default connect(
  mapStateToProps,
  { fetchMovimentiCreditiAgenzie, fetchAcquisizioneCandidatiSportello },
)(Movimenti)
