import React, { Component } from 'react'
import { MdBusinessCenter, MdDeleteForever, MdDomain, MdEdit, MdLocationOn } from 'react-icons/md'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import swal from 'sweetalert'
import { deleteJobVacancy } from '../../actions'

class JobVacanciesComplete extends Component {
  constructor(props) {
    super(props)

    this.state = {
      nomeNuovaJobVacancy: '',

      jobVacancyId: 0,
    }
  }

  onRemove() {
    this.props.deleteJobVacancy(this.state.jobVacancyId)
  }

  renderLogoAgenziaAzienda(agenzia, azienda, sportello) {
    if (agenzia) {
      return <MdBusinessCenter className="icona-agenzia" />
    } else if (azienda) {
      return <MdDomain className="icona-azienda" />
    } else if (sportello) {
      return <MdLocationOn className="icona-sportello" />
    }
  }

  renderNomeAgenziaAzienda(agenzia, marchioAgenzia, azienda, sportello) {
    if (agenzia) {
      return (
        <span>
          {agenzia}
          <br />
          <small>{marchioAgenzia}</small>
        </span>
      )
    } else if (azienda) {
      return <span>{azienda}</span>
    } else if (sportello) {
      return <span>{sportello}</span>
    }
  }

  renderLogoSportello(tipo) {
    if (!tipo) {
      return null
    }
    if (tipo.toUpperCase() === 'CISL') {
      return 'https://www.sportellolavorocisl.it/images/sportello-cisl.png'
    } else if (tipo.toUpperCase() === 'IAL') {
      return 'https://www.sportellolavorocisl.it/images/sportello-ial.png'
    }
    return null
  }

  renderJobVacancies(sportelloId) {
    return this.props.jobVacancies.map((jobVacancy) => (
      <tr key={jobVacancy.jobVacancyId}>
        <td>{jobVacancy.jobVacancyId}</td>
        <td>
          <span
            className={`label ${
              jobVacancy.statoVacancy.toLowerCase() === 'aperta' ? 'label-success' : 'label-default'
            }`}
          >
            {jobVacancy.statoVacancy}
          </span>
        </td>
        <td className="figura-professionale">
          <Link to={'/app/job-vacancies/' + jobVacancy.jobVacancyId}>{jobVacancy.figuraProfessionale}</Link>
        </td>
        <td className="riferimento">
          {this.renderLogoAgenziaAzienda(
            jobVacancy.agenziaRagioneSociale,
            jobVacancy.aziendaRagioneSociale,
            jobVacancy.sportelloNome
          )}
          {this.renderNomeAgenziaAzienda(
            jobVacancy.agenziaRagioneSociale,
            jobVacancy.agenziaMarchio,
            jobVacancy.aziendaRagioneSociale,
            jobVacancy.sportelloNome
          )}
        </td>
        <td>{jobVacancy.luogoLavoroComuneEsteso}</td>
        <td className="sportello">
          <img src={this.renderLogoSportello(jobVacancy.sportelloOrganizzazione)} alt="logo-sportello" />
          {jobVacancy.sportelloNome}
        </td>
        <td className="text-center numero-interessati">{jobVacancy.numeroInteressati}</td>
        <td className="text-right">
          {(this.props.ruolo === 'azienda' ||
            this.props.ruolo === 'agenzia' ||
            (this.props.ruolo === 'operatore' &&
              jobVacancy.sportelloId === sportelloId &&
              !!this.props.user.permessi.gestioneJv)) && (
            <div className="actions">
              <div className="btn-group" role="group">
                <span title="modifica">
                  <Link
                    to={'/app/job-vacancies/' + jobVacancy.jobVacancyId + '/edit'}
                    className="btn btn-sm btn-primary"
                  >
                    <MdEdit />
                  </Link>
                </span>
                <button
                  title="elimina"
                  className="btn btn-sm btn-danger"
                  onClick={() => {
                    this.setState({ jobVacancyId: jobVacancy.jobVacancyId })
                    this.handleDeleteJobVacancy()
                  }}
                >
                  <MdDeleteForever />
                </button>
              </div>
            </div>
          )}
        </td>
      </tr>
    ))
  }

  handleDeleteJobVacancy = () => {
    swal({
      type: 'warning',
      title: 'Eliminazione job vacancy',
      text: 'Sei sicuro di voler eliminare questa job vacancy? Questa azione è irreversibile.',
      buttons: ['Annulla', 'Sì, elimina job vacancy'],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onRemove()
      }
    })
  }
  render() {
    const { sportelloId } = this.props

    return (
      <div className="job-vacancies-complete">
        <div className="card-box">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Stato</th>
                  <th>Figura professionale</th>
                  <th>Riferimento</th>
                  <th>Luogo di lavoro</th>
                  <th>Sportello</th>
                  <th className="text-center">N° interessati</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>{this.renderJobVacancies(sportelloId)}</tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    sportelloId: state.auth.user.sportelloCorrente.sportelloId,
    user: state.auth.user,
  }
}

export default connect(mapStateToProps, { deleteJobVacancy })(JobVacanciesComplete)
