import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import swal from 'sweetalert'
import { MdAdd, MdCheck } from 'react-icons/md'

import { fetchSportelli, deleteSportello, resetSportelloAttivo } from '../../../actions'
import { getTotaleSmsAssegnati } from '../../../reducers/reducer_sportelli'
import FiltroOperatoriSportelli from '../filtro_operatori_sportelli/filtro_operatori_sportelli'

class SportelliList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      sportelloToRemoveId: null,
      filter: {
        organizzazione: '',
        areaGeograficaId: '',
        keywords: '',
      },
    }
  }

  componentDidMount() {
    this.props.fetchSportelli()
    this.props.resetSportelloAttivo()
  }

  handleChange = (field, value) => {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          [field]: value,
        },
      },
      () => {
        this.props.fetchSportelli(this.state.filter)
      }
    )
  }

  handleRemove = (sportelloId) => (event) => {
    event.preventDefault()
    this.setState({
      sportelloToRemoveId: sportelloId,
    })
    this.handleDeleteSportello()
  }

  handleConfirmRemove = () => {
    this.props.deleteSportello(this.state.sportelloToRemoveId)
    this.setState({
      sportelloToRemoveId: null,
    })
  }

  renderOrganizzazione(organizzazione) {
    if (organizzazione) {
      switch (organizzazione.toUpperCase()) {
        case 'CISL':
          return <img src="/images/sportello-cisl.png" alt="sportello-cisl" />
        case 'IAL':
          return <img src="/images/sportello-ial.png" alt="sportello-ial" />
        default:
          return <span>{organizzazione}</span>
      }
    }

    return null
  }

  renderResponsabili(responsabili) {
    return (
      responsabili &&
      responsabili.map((responsabile) => <li key={responsabile.responsabile}>{responsabile.responsabile}</li>)
    )
  }

  renderSportelli() {
    const { sportelli } = this.props

    return sportelli.map((sportello) => (
      <tr key={sportello.sportelloId}>
        <td>{sportello.sitoWebFlag && <MdCheck style={{ color: '#61B814', fontSize: 16 }} />}</td>
        <td>
          <Link to={'/app/sportelli/' + sportello.sportelloId}>
            {sportello.organizzazione} {sportello.nome}
          </Link>
        </td>
        {/* <td>{sportello.areaGeografica}</td> */}
        <td>{sportello.padreSportelloNome}</td>
        <td>
          <ul className="list-unstyled">{this.renderResponsabili(sportello.responsabili)}</ul>
        </td>
        <td>{this.renderOrganizzazione(sportello.organizzazione)}</td>
        <td className="creditiSms">{sportello.creditiSms}</td>
        <td>
          <button className="btn btn-sm btn-danger" onClick={this.handleRemove(sportello.sportelloId)}>
            &times; Elimina
          </button>
        </td>
      </tr>
    ))
  }

  renderTotaleSmsAssegnati() {
    return (
      this.props.sportelli && (
        <tr>
          <td colSpan="6" className="totaleSmsLabel">
            Totale
          </td>
          <td className="totaleSms">{this.props.totaleSms}</td>
          <td />
        </tr>
      )
    )
  }

  handleDeleteSportello = () => {
    swal({
      icon: 'warning',
      title: 'Eliminazione sportello',
      text: 'Sei sicuro di vole eliminare questo sportello? Questa azione è irreversibile.',
      buttons: ['Annulla', 'Si, elimina sportello'],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.handleConfirmRemove()
      }
    })
  }

  render() {
    return (
      <div className="sportelli-list">
        <h1 className="page-title">Sportelli</h1>

        <div className="card-box">
          <FiltroOperatoriSportelli values={this.state.filter} onChange={this.handleChange} />

          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Web</th>
                  <th>Nome</th>
                  <th>Regione</th>
                  <th>Sportello padre</th>
                  <th>Responsabile/i</th>
                  <th>Org.</th>
                  <th>Sms</th>
                  <th>Elimina</th>
                </tr>
              </thead>
              <tbody>
                {this.props.sportelli ? this.renderSportelli() : null}
                {this.renderTotaleSmsAssegnati()}
              </tbody>
            </table>
          </div>

          <Link to="/app/sportelli/new" className="btn btn-success">
            <div className="create-center">
              <MdAdd />
              Crea nuovo sportello
            </div>
          </Link>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    sportelli: state.sportelli,
    totaleSms: getTotaleSmsAssegnati(state),
  }
}

export default connect(mapStateToProps, { fetchSportelli, deleteSportello, resetSportelloAttivo })(SportelliList)
