import React, { Component } from 'react'
import { connect } from 'react-redux'

import { changeFilterCandidati } from '../../actions'

import FiltroComune from './filtro_comune'

class RicercaAvanzata extends Component {
  constructor(props) {
    super(props)

    this.state = {
      stati: [],
    }
  }

  changeServizioRichiesto = (event) => {
    this.props.changeFilterCandidati('servizioRichiesto', event.target.value)
    if (event.target.value === 'lavoro') {
      this.setState({
        stati: [
          { value: '', description: 'Tutti' },
          { value: 'non_richiesto', description: 'Non richiesto' },
          { value: 'cv_non_completo', description: 'CV non completo' },
          { value: 'cv_completo', description: 'CV completo' },
          { value: 'almeno_un_appuntamento', description: 'Almeno un appuntamento' },
          { value: 'ricollocato', description: 'Ricollocato' },
        ],
      })
    } else {
      this.setState({
        stati: [
          { value: '', description: 'Tutti' },
          { value: 'non_richiesto', description: 'Non richiesto' },
          { value: 'richiesto', description: 'Richiesto' },
          { value: 'effettuato', description: 'Effettuato' },
        ],
      })
    }
  }

  changeFilter = (fieldName) => (event) => {
    this.props.changeFilterCandidati(fieldName, event.target.value)
  }

  render() {
    const { filters } = this.props

    return (
      <div className="advanced-search">
        <div className="row">
          <div className="col-lg-3">
            <label>Obiettivi professionali</label>
            <input
              type="text"
              className="form-control"
              value={filters.obiettiviProfessionali}
              onChange={this.changeFilter('obiettiviProfessionali')}
            />
          </div>
          <div className="col-lg-3">
            <label>Comune disponibilità</label>
            <FiltroComune fieldName="comuneDisponibilita" value={filters.comuneDisponibilita} />
          </div>
          <div className="col-lg-3">
            <label>Età (da-a)</label>
            <input
              type="text"
              className="form-control"
              value={filters.rangeEta}
              onChange={this.changeFilter('rangeEta')}
            />
          </div>
          <div className="col-lg-3">
            <label>Sesso</label>
            <select className="form-control" value={filters.sesso} onChange={this.changeFilter('sesso')}>
              <option value="">Tutti</option>
              <option value="F">Femmina</option>
              <option value="M">Maschio</option>
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3">
            <label>Comune domicilio</label>
            <FiltroComune fieldName="comuneDomicilio" value={filters.comuneDomicilio} />
          </div>
          <div className="col-lg-3">
            <label>Conoscenze linguistiche</label>
            <input type="text" className="form-control" value={filters.lingue} onChange={this.changeFilter('lingue')} />
          </div>
          <div className="col-lg-3">
            <label>Esperienze Lavorative</label>
            <input
              type="text"
              className="form-control"
              value={filters.esperienzeLavorative}
              onChange={this.changeFilter('esperienzeLavorative')}
            />
          </div>
          <div className="col-lg-3">
            <label>Formazione</label>
            <input
              type="text"
              className="form-control"
              value={filters.formazione}
              onChange={this.changeFilter('formazione')}
            />
          </div>
        </div>
        {/* <div className="row">
          <div className="col-lg-3">
            <label>Interesse corsi</label>
            <input
              type="text"
              className="form-control"
              value={filters.interesseCorsi}
              onChange={this.changeFilter('interesseCorsi')}
            />
          </div>

          {ruolo === 'operatore' && (
            <div className="col-lg-3">
              <label>Servizio richiesto</label>
              <select
                className="form-control"
                value={filters.servizioRichiesto}
                onChange={this.changeServizioRichiesto}
              >
                <option value="">Tutti</option>
                <option value="lavoro">Ricerca lavoro</option>
                <option value="did">Presentazione DID</option>
                <option value="dimissioni">Dimissioni online</option>
                <option value="consulenza">Consulenza</option>
              </select>
            </div>
          )}

          {ruolo === 'operatore' && (
            <div className="col-lg-3">
              <label>Stato servizio</label>
              <select
                className="form-control"
                value={filters.statoServizio}
                onChange={this.changeFilter('statoServizio')}
              >
                {this.state.stati.map((stato) => (
                  <option value={stato.value} key={stato.value}>
                    {stato.description}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div> */}
        <div className="row">
          <div className="col-lg-3">
            <label>Invalidità</label>
            <select className="form-control" value={filters.invalidita} onChange={this.changeFilter('invalidita')}>
              <option value="">Tutti</option>
              <option value="non_invalido">Non Invalido</option>
              <option value="invalido">Invalido</option>
              <option value="disabile">Disabile</option>
              <option value="invalido_civile">Invalido civile</option>
              <option value="altro_soggetto_svantaggiato">Altro soggetto svantaggiato</option>
              <option value="collocamento_obbligatorio">Collocamento obbligatorio</option>
            </select>
          </div>
          <div className="col-lg-3">
            <label>Automunito</label>
            <select className="form-control" value={filters.automunito} onChange={this.changeFilter('automunito')}>
              <option value="">Tutti</option>
              <option value={true}>Sì</option>
              <option value={false}>No</option>
            </select>
          </div>
          <div className="col-lg-3">
            <label>Livello patente</label>
            <input
              type="text"
              className="form-control"
              value={filters.livelloPatente}
              onChange={this.changeFilter('livelloPatente')}
            />
          </div>
          <div className="col-lg-3">
            <label>Disponibilità tempo</label>
            <select
              className="form-control"
              value={filters.disponibilitaTempo}
              onChange={this.changeFilter('disponibilitaTempo')}
            >
              <option value="">Tutti</option>
              <option value="tempo_pieno">Tempo pieno</option>
              <option value="tempo_part_time">Part time</option>
              <option value="tempo_su_2_turni">Tempo su 2 turni</option>
              <option value="tempo_su_3_turni">Tempo su 3 turni</option>
              <option value="giorno_notte">Giorno/Notte</option>
              <option value="weekend">Weekend</option>
            </select>
          </div>
        </div>

        <div className="row" style={{ marginTop: 10 }} />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  filters: state.candidati.filters,
  ruolo: state.auth.user.ruolo,
})

export default connect(mapStateToProps, { changeFilterCandidati })(RicercaAvanzata)
