import React, { Component } from 'react'
import BottoneAnnulla from './bottone_annulla'
import { connect } from 'react-redux'
import { Form, Field } from 'react-final-form'

import { fetchCandidato, saveObiettiviProfessionali } from '../../actions'

class EditObiettiviProfessionali extends Component {
  componentDidMount() {
    // Ho già il candidato attivo se navigo normalmente
    // dal dettaglio candidato: questo controllo serve
    // nel caso in cui ricarico la pagina da qui (F5)
    if (!this.props.candidatoId) {
      this.props.fetchCandidato(this.props.match.params.id)
    }
  }

  onSubmit(props) {
    const { posizioniProfessionali, ...rest } = props
    this.props.saveObiettiviProfessionali(this.props.match.params.id, rest).then((action) => {
      this.props.history.goBack()
    })
  }

  render() {
    const initialValues = this.props.initialValues ? this.props.initialValues : {}

    return (
      <Form
        onSubmit={this.onSubmit.bind(this)}
        initialValues={initialValues}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="heading">
              <h1 className="page-title pull-left">Obiettivi professionali</h1>
              <BottoneAnnulla />
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="panel panel-border panel-primary">
                  <div className="panel-heading">
                    <h3 className="panel-title">Disponibilità contratto</h3>
                  </div>
                  <div className="panel-body">
                    <div className="form-group">
                      <div className="checkbox checkbox-primary">
                        <Field
                          type="checkbox"
                          id="disponibilitaContrattoTempoIndeterminatoFlag"
                          name="disponibilitaContrattoTempoIndeterminatoFlag"
                          component="input"
                        />
                        <label htmlFor="disponibilitaContrattoTempoIndeterminatoFlag">
                          Tempo indeterminato
                        </label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="checkbox checkbox-primary">
                        <Field
                          type="checkbox"
                          id="disponibilitaContrattoTempoDeterminatoFlag"
                          name="disponibilitaContrattoTempoDeterminatoFlag"
                          component="input"
                        />
                        <label htmlFor="disponibilitaContrattoTempoDeterminatoFlag">
                          Tempo determinato
                        </label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="checkbox checkbox-primary">
                        <Field
                          type="checkbox"
                          id="disponibilitaContrattoApprendistatoFlag"
                          name="disponibilitaContrattoApprendistatoFlag"
                          component="input"
                        />
                        <label htmlFor="disponibilitaContrattoApprendistatoFlag">
                          Apprendistato
                        </label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="checkbox checkbox-primary">
                        <Field
                          type="checkbox"
                          id="disponibilitaContrattoSomministrazioneFlag"
                          name="disponibilitaContrattoSomministrazioneFlag"
                          component="input"
                        />
                        <label htmlFor="disponibilitaContrattoSomministrazioneFlag">
                          Somministrazione
                        </label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="checkbox checkbox-primary">
                        <Field
                          type="checkbox"
                          id="disponibilitaContrattoLavoroOccasionaleFlag"
                          name="disponibilitaContrattoLavoroOccasionaleFlag"
                          component="input"
                        />
                        <label htmlFor="disponibilitaContrattoLavoroOccasionaleFlag">
                          Lavoro occasionale
                        </label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="checkbox checkbox-primary">
                        <Field
                          type="checkbox"
                          id="disponibilitaContrattoStageTirocinioFlag"
                          name="disponibilitaContrattoStageTirocinioFlag"
                          component="input"
                        />
                        <label htmlFor="disponibilitaContrattoStageTirocinioFlag">
                          Stage / Tirocinio
                        </label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="checkbox checkbox-primary">
                        <Field
                          type="checkbox"
                          id="disponibilitaContrattoVoucherFlag"
                          name="disponibilitaContrattoVoucherFlag"
                          component="input"
                        />
                        <label htmlFor="disponibilitaContrattoVoucherFlag">Voucher</label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="checkbox checkbox-primary">
                        <Field
                          type="checkbox"
                          id="disponibilitaContrattoLavoroAutonomoFlag"
                          name="disponibilitaContrattoLavoroAutonomoFlag"
                          component="input"
                        />
                        <label htmlFor="disponibilitaContrattoLavoroAutonomoFlag">
                          Lavoro autonomo
                        </label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="checkbox checkbox-primary">
                        <Field
                          type="checkbox"
                          id="disponibilitaContrattoStagionaleFlag"
                          name="disponibilitaContrattoStagionaleFlag"
                          component="input"
                        />
                        <label htmlFor="disponibilitaContrattoStagionaleFlag">Stagionale</label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="checkbox checkbox-primary">
                        <Field
                          type="checkbox"
                          id="disponibilitaContrattoLavoroChiamataFlag"
                          name="disponibilitaContrattoLavoroChiamataFlag"
                          component="input"
                        />
                        <label htmlFor="disponibilitaContrattoLavoroChiamataFlag">
                          Lavoro a chiamata
                        </label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="checkbox checkbox-primary">
                        <Field
                          type="checkbox"
                          id="disponibilitaContrattoCoCoCoFlag"
                          name="disponibilitaContrattoCoCoCoFlag"
                          component="input"
                        />
                        <label htmlFor="disponibilitaContrattoCoCoCoFlag">Co.Co.Co.</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="panel panel-border panel-primary">
                  <div className="panel-heading">
                    <h3 className="panel-title">Disponibilità tempo</h3>
                  </div>
                  <div className="panel-body">
                    <div className="form-group">
                      <div className="checkbox checkbox-primary">
                        <Field
                          type="checkbox"
                          id="disponibilitaTempoPienoFlag"
                          name="disponibilitaTempoPienoFlag"
                          component="input"
                        />
                        <label htmlFor="disponibilitaTempoPienoFlag">Tempo pieno</label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="checkbox checkbox-primary">
                        <Field
                          type="checkbox"
                          id="disponibilitaTempoPartTimeFlag"
                          name="disponibilitaTempoPartTimeFlag"
                          component="input"
                        />
                        <label htmlFor="disponibilitaTempoPartTimeFlag">Part-time</label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="checkbox checkbox-primary">
                        <Field
                          type="checkbox"
                          id="disponibilitaTempoDueTurniFlag"
                          name="disponibilitaTempoDueTurniFlag"
                          component="input"
                        />
                        <label htmlFor="disponibilitaTempoDueTurniFlag">Su 2 turni</label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="checkbox checkbox-primary">
                        <Field
                          type="checkbox"
                          id="disponibilitaTempoTreTurniFlag"
                          name="disponibilitaTempoTreTurniFlag"
                          component="input"
                        />
                        <label htmlFor="disponibilitaTempoTreTurniFlag">Su 3 turni</label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="checkbox checkbox-primary">
                        <Field
                          type="checkbox"
                          id="disponibilitaConvivenzaGiornoNotteFlag"
                          name="disponibilitaConvivenzaGiornoNotteFlag"
                          component="input"
                        />
                        <label htmlFor="disponibilitaConvivenzaGiornoNotteFlag">
                          Convivenza giorno e notte
                        </label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="checkbox checkbox-primary">
                        <Field
                          type="checkbox"
                          id="disponibilitaConvivenzaWeekendFlag"
                          name="disponibilitaConvivenzaWeekendFlag"
                          component="input"
                        />
                        <label htmlFor="disponibilitaConvivenzaWeekendFlag">
                          Convivenza weekend
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="panel panel-border panel-primary">
                  <div className="panel-heading">
                    <h3 className="panel-title">Trasferte</h3>
                  </div>
                  <div className="panel-body">
                    <div className="form-group">
                      <div className="checkbox checkbox-primary">
                        <Field
                          type="checkbox"
                          id="disponibilitaTrasferteFlag"
                          name="disponibilitaTrasferteFlag"
                          component="input"
                        />
                        <label htmlFor="disponibilitaTrasferteFlag">Disponibile a trasferte</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-center">
              <button type="submit" className="btn btn-primary btn-lg">
                Salva disponibiltà &raquo;
              </button>
            </div>
          </form>
        )}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    initialValues: state.candidatoAttivo.obiettiviProfessionali,
    candidatoId: state.candidatoAttivo.candidatoId, // per sapere se ho già il candidato attivo su F5
  }
}

export default connect(mapStateToProps, { fetchCandidato, saveObiettiviProfessionali })(
  EditObiettiviProfessionali
)
