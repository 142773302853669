import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Ink from 'react-ink'

import {
  fetchJobVacanciesAgenzia,
  fetchJobVacanciesAzienda,
  changePageJobVacancies,
} from '../../actions'

import JobVacanciesComplete from './job_vacancies_complete'
import Pager from '../pager/pager'

import './job_vacancies.less'

class JobVacanciesAziendeAgenzie extends Component {
  componentDidMount() {
    const { pathname } = this.props.location
    const { agenziaId, aziendaId } = this.props.match.params

    if (pathname.startsWith('/agenzie')) {
      this.props.fetchJobVacanciesAgenzia(agenziaId)
    } else if (pathname.startsWith('/aziende')) {
      this.props.fetchJobVacanciesAzienda(aziendaId)
    }
  }

  componentDidUpdate(prevProps) {
    const pageChanged = prevProps.pageRequested !== this.props.pagination.page

    const { pathname } = this.props.location
    const { agenziaId, aziendaId } = this.props.match.params

    if (pageChanged) {
      if (pathname.startsWith('/agenzie')) {
        this.props.fetchJobVacanciesAgenzia(agenziaId, this.props.pageRequested)
      } else if (pathname.startsWith('/aziende')) {
        this.props.fetchJobVacanciesAzienda(aziendaId, this.props.pageRequested)
      }
    }
  }

  render() {
    const { jobVacancies, ragioneSociale, ruolo, pagination, changePageJobVacancies } = this.props

    return (
      <div className="job-vacancies-list">
        <div>
          <h1 className="page-title">Job vacancies di {ragioneSociale}</h1>

          {jobVacancies && jobVacancies.length > 0 ? (
            <div>
              <Pager
                position={'top'}
                page={pagination.page}
                totalPages={pagination.totalPages}
                onChangePage={changePageJobVacancies}
              />

              <JobVacanciesComplete jobVacancies={jobVacancies} ruolo={ruolo} />

              <Pager
                position={'bottom'}
                page={pagination.page}
                totalPages={pagination.totalPages}
                onChangePage={changePageJobVacancies}
              />
            </div>
          ) : (
            <h4>{ragioneSociale} non ha ancora pubblicato nessuna job vacancy</h4>
          )}
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    jobVacancies: state.jobVacancies.data,
    ragioneSociale: state.jobVacancies.ragioneSociale,
    ruolo: state.auth.user.ruolo,
    pageRequested: state.jobVacancies.pageRequested,
    pagination: state.jobVacancies.pagination,
  }
}

export default connect(mapStateToProps, {
  fetchJobVacanciesAgenzia,
  fetchJobVacanciesAzienda,
  changePageJobVacancies,
})(JobVacanciesAziendeAgenzie)
