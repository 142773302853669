import { FETCH_AREA_GEOGRAFICA, RESET_AREA_GEOGRAFICA_ATTIVA } from '../actions/types'

const INITIAL_STATE = {}

export default function reducerAreaGeograficaAttiva(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_AREA_GEOGRAFICA:
      return action.payload.data || state
    case RESET_AREA_GEOGRAFICA_ATTIVA:
      return {}
    default:
      return state
  }
}
