import {
	FETCH_AZIENDA
} from '../actions/types';

const INITIAL_STATE = {
	data: {}
};

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case FETCH_AZIENDA:
			return {
				...state,
				data: action.payload.data
			};
		default:
			return state;
	}
}
