import {
	FETCH_SLOT,
	ADD_APPUNTAMENTO,
	REMOVE_APPUNTAMENTO } from '../actions/types';

import moment from 'moment';



const INITIAL_STATE = {};

const formatDateIso = (date) => {
	return date ? moment(date).format('YYYY-MM-DD') : null;
}

const formatTimeIso = (date) => {
	return date ? moment(date).format('HH:mm') : null;
}

const formatDateTimes = (slot) => {

	// ANAGRAFICA
	slot.dataInizio = formatDateIso(slot.dataOraInizio);
  slot.oraInizio = formatTimeIso(slot.dataOraInizio);
  slot.dataFine = formatDateIso(slot.dataOraFine);
	slot.oraFine = formatTimeIso(slot.dataOraFine);

	return slot;

}

export default function(state = INITIAL_STATE, action) {
	switch(action.type) {
		case FETCH_SLOT:
			return {...formatDateTimes(action.payload.data)};

    case ADD_APPUNTAMENTO: {
			return {
				...state,

				appuntamenti: [
					...state.appuntamenti,
					action.payload.data
				]
			}
		}

		case REMOVE_APPUNTAMENTO:
			return {
				...state,
				appuntamenti: state.appuntamenti.filter(appuntamento => appuntamento.candidatoId !== action.payload)
			}

		default:
			return state;
	}

}
