import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Candidato from '../candidato_edit/candidato'
import EditAnagrafica from '../candidato_edit/edit_anagrafica'
import CandidatiList from './candidati_list'
import CandidatiStatistiche from './candidati_statistiche'

const Candidati = (props) => {
  return (
    <div>
      <Switch>
        <Route exact path="/app/candidati">
          <CandidatiList />
        </Route>
        <Route path="/app/candidati/new" component={EditAnagrafica} />
        <Route path="/app/candidati/statistiche">
          <CandidatiStatistiche />
        </Route>
        <Route path="/app/candidati/:id">
          <Candidato />
        </Route>
      </Switch>
    </div>
  )
}

export default Candidati
