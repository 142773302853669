import { SET_NEW_POSIZIONE_PROFESSIONALE, CLEAR_NEW_POSIZIONE_PROFESSIONALE } from '../actions/types';

const INITIAL_STATE = '';

export default function(state = INITIAL_STATE, action) {
	switch(action.type) {
		case SET_NEW_POSIZIONE_PROFESSIONALE:
			return action.payload;
		case CLEAR_NEW_POSIZIONE_PROFESSIONALE:
			return '';
		default:
			return state;
	}
}