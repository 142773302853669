import React from 'react'
import { Switch, Route } from 'react-router'
import CorsiList from './corsi_list'
import RequireAuth from '../auth/require_auth'
import EditCorsiFormazione from './corsi_edit'
import Corso from './corso'

const GestioneCorsiFormazione = () => {
  return (
    <div>
      <Switch>
        <Route exact path="/app/corsi-di-formazione" component={CorsiList} />
        <Route path="/app/corsi-di-formazione/new" component={RequireAuth(['operatore'])(EditCorsiFormazione)} />
        <Route
          path="/app/corsi-di-formazione/:corsoId/edit"
          component={RequireAuth(['operatore'])(EditCorsiFormazione)}
        />
        <Route path="/app/corsi-di-formazione/:corsoFormazionePropostoId" component={Corso} />
      </Switch>
    </div>
  )
}
export default GestioneCorsiFormazione
