import React, { Component } from 'react'
import { connect } from 'react-redux'
import _debounce from 'lodash/debounce'
import { FaSearch } from 'react-icons/fa'

import { changeFilterCandidati } from '../../actions'

class FiltroCandidati extends Component {
  changeKeyword = (event) => {
    this.props.changeFilterCandidati('keywordsUI', event.target.value)
    this.changeKeywordDebounced('keywords', event.target.value)
  }

  changeKeywordDebounced = _debounce(this.props.changeFilterCandidati, 700)

  render() {
    return (
      <div className={!this.props.unstyled && 'filtro-candidati search-box'}>
        <input
          type="text"
          className="form-control"
          value={this.props.keywordsUI}
          onChange={this.changeKeyword}
          placeholder="Filtra per parole chiave..."
        />
        {!this.props.unstyled && <FaSearch />}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    keywordsUI: state.candidati.filters.keywordsUI,
  }
}

export default connect(mapStateToProps, { changeFilterCandidati })(FiltroCandidati)
