import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
  fetchAreeGeografiche,
  fetchSportelli,
  fetchCreditiSmsConsumati,
  setFilterCreditiSmsConsumati,
  API_ROOT_URL,
} from '../../../actions'

class CreditiSmsConsumati extends Component {
  componentDidMount() {
    this.props.fetchAreeGeografiche()
    this.props.fetchSportelli()
    this.props.fetchCreditiSmsConsumati(this.props.filter)
  }

  componentDidUpdate(prevProps) {
    const { daData, aData, areaGeograficaId, sportelloId } = this.props.filter
    if (
      daData !== prevProps.filter.daData ||
      aData !== prevProps.filter.aData ||
      sportelloId !== prevProps.filter.sportelloId
    ) {
      this.props.fetchCreditiSmsConsumati(this.props.filter)
    }

    if (areaGeograficaId !== prevProps.filter.areaGeograficaId) {
      this.props
        .fetchSportelli({
          areaGeograficaId,
        })
        .then(() => {
          this.props.fetchCreditiSmsConsumati(this.props.filter)
        })
    }
  }

  handleChangeField = (field) => (event) => {
    this.props.setFilterCreditiSmsConsumati(field, event.target.value)
  }

  renderOrganizzazione(organizzazione) {
    if (organizzazione) {
      switch (organizzazione.toUpperCase()) {
        case 'CISL':
          return <img src="/images/sportello-cisl.png" style={{ marginRight: '10px' }} alt="sportello-cisl" />
        case 'IAL':
          return <img src="/images/sportello-ial.png" style={{ marginRight: '10px' }} alt="sportello-ial" />
        default:
          return <span>{organizzazione}</span>
      }
    }
  }

  renderUrlDownloadButton() {
    const { daData, aData, areaGeograficaId, sportelloId } = this.props.filter
    const areaGeografica = areaGeograficaId ? `&area_geografica_id=${areaGeograficaId}` : ''
    const sportello = sportelloId ? `&sportello_id=${sportelloId}` : ''
    const token = localStorage.getItem('token')
    const href = `${API_ROOT_URL}/log_invio_sms?da_data=${daData}&a_data=${aData}${areaGeografica}${sportello}&t=${token}`
    return href
  }

  render() {
    return (
      <div className="creditiSmsConsumati">
        <h1 className="page-title">Crediti consumati</h1>
        <div className="card-box">
          <div className="filtro-crediti-sms-consumati">
            <div className="row">
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="organizzazione">Dal</label>
                  <input
                    type="date"
                    className="form-control"
                    value={this.props.filter.daData}
                    onChange={this.handleChangeField('daData')}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="organizzazione">Al</label>
                  <input
                    type="date"
                    className="form-control"
                    value={this.props.filter.aData}
                    onChange={this.handleChangeField('aData')}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="organizzazione">Area geografica</label>
                  <select
                    id="organizzazione"
                    className="form-control"
                    value={this.props.filter.areaGeograficaId}
                    onChange={this.handleChangeField('areaGeograficaId')}
                  >
                    <option value="">-- Tutte --</option>
                    {this.props.areeGeografiche.map((area) => (
                      <option key={area.areaGeograficaId} value={area.areaGeograficaId}>
                        {area.nome} - {area.regione}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="organizzazione">Sportello</label>
                  <select
                    id="organizzazione"
                    className="form-control"
                    value={this.props.filter.sportelloId}
                    onChange={this.handleChangeField('sportelloId')}
                  >
                    <option value="">-- Tutti --</option>
                    {this.props.sportelli.map((sportello) => (
                      <option value={sportello.sportelloId} key={sportello.sportelloId}>
                        {sportello.nome}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          {this.props.creditiSportelli.length > 0 && (
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Sportello</th>
                    <th>Area geografica</th>
                    <th>Crediti SMS</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.creditiSportelli &&
                    this.props.creditiSportelli.map((creditiSportello) => (
                      <tr key={creditiSportello.sportelloId}>
                        <td>
                          {this.renderOrganizzazione(creditiSportello.organizzazione)} {creditiSportello.nome}
                        </td>
                        <td>
                          {creditiSportello.areaGeograficaNome} - {creditiSportello.areaGeograficaRegione}
                        </td>
                        <td>{creditiSportello.creditiSms}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}
          {this.props.creditiSportelli.length > 0 && (
            <a
              type="btn"
              className="btn btn-primary"
              target="_blank"
              href={this.renderUrlDownloadButton()}
              rel="noreferrer"
            >
              Download dettagli invii
            </a>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    areeGeografiche: state.areeGeografiche,
    sportelli: state.sportelli,
    filter: state.creditiSmsConsumati.filter,
    creditiSportelli: state.creditiSmsConsumati.data,
  }
}

export default connect(mapStateToProps, {
  fetchAreeGeografiche,
  fetchSportelli,
  fetchCreditiSmsConsumati,
  setFilterCreditiSmsConsumati,
})(CreditiSmsConsumati)
