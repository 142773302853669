import React, { Component } from 'react'
import { connect } from 'react-redux'
import Switch from 'rc-switch'
import cogoToast from 'cogo-toast'

import {
  saveSitoWebFlag,
  saveNuovoSportello,
  confermaSpostamento,
  annullaSpostamento,
} from '../../actions'
import { isCvCompleto } from '../../reducers/reducer_candidato_attivo'

import { MdSwapHoriz, MdCancel } from 'react-icons/md'
import swal from 'sweetalert'

class AnagraficaAltreInfo extends Component {
  changeFlagSitoWeb = (sitoWebFlag) => {
    const { anagrafica, cvCompleto } = this.props

    if (
      (!!sitoWebFlag && !cvCompleto) ||
      (!!sitoWebFlag &&
        !!anagrafica.extracomunitarioFlag &&
        !anagrafica.extracomunitarioItalianoB1Flag)
    ) {
      return this.props.saveSitoWebFlag(this.props.candidatoId, false)
    }
    this.props.saveSitoWebFlag(this.props.candidatoId, sitoWebFlag)
  }

  handleRichiediSpostamento = (event) => {}

  handleConfirmRichiediSpostamento = (event) => {
    this.props.saveNuovoSportello(
      this.props.candidatoId,
      this.props.user.sportelloCorrente.sportelloId
    )
    cogoToast.success('La richiesta di spostamento del candidato è stata inoltrata correttamente', {
      heading: 'Richiesta avvenuta correttamente',
      position: 'top-right',
      hideAfter: 5,
    })
  }

  handleConfermaSpostamento = (event) => {
    this.props.confermaSpostamento(this.props.candidatoId)
  }

  handleAnnullaSpostamento = (event) => {
    this.props.annullaSpostamento(this.props.candidatoId)
  }

  renderExtracomunitarioPermesso(anagrafica) {
    if (!anagrafica.extracomunitarioPermessoSoggiornoFlag) {
      return <div>Senza il permesso di soggiorno</div>
    } else {
      return (
        <div>
          Con permesso di soggiorno per {anagrafica.extracomunitarioPermessoSoggiornoTipologia}{' '}
          (n°&nbsp;
          {anagrafica.extracomunitarioPermessoSoggiornoNumero} scad.&nbsp;
          {anagrafica.extracomunitarioPermessoSoggiornoScadenzaF})
        </div>
      )
    }
  }

  renderExtracomunitarioLinguaIta(linguaItaFlag) {
    if (!linguaItaFlag) {
      return <div>Senza conoscenza della lingua italiana almeno a livello B1</div>
    } else {
      return (
        <div>Con conoscenza della lingua italiana almeno a livello B1 (con certificazione)</div>
      )
    }
  }

  renderExtracomunitario() {
    const { anagrafica } = this.props

    if (!anagrafica.extracomunitarioFlag) {
      return 'No'
    }
    return (
      <div>
        {this.renderExtracomunitarioPermesso(anagrafica)}
        {this.renderExtracomunitarioLinguaIta(anagrafica.extracomunitarioItalianoB1Flag)}
      </div>
    )
  }

  renderIscrittoCisl() {
    const { anagrafica } = this.props

    if (!anagrafica.iscrittoCislFlag) {
      return 'No'
    }

    return (
      <div>
        {anagrafica.iscrittoCislFederazione} di {anagrafica.iscrittoCislTerritorio}
      </div>
    )
  }

  renderSwitchMessage() {
    const { anagrafica, cvCompleto, sitoWebFlag } = this.props

    if (!sitoWebFlag && !cvCompleto) {
      return 'Il candidato non può essere visibile sul sito pubblico perché il suo CV è incompleto'
    } else if (
      !sitoWebFlag &&
      !!anagrafica.extracomunitarioFlag &&
      !anagrafica.extracomunitarioItalianoB1Flag
    ) {
      return 'Il candidato non può essere visibile sul sito pubblico perché è un extracomunitario senza una conoscenza della lingua italiana almeno di livello B1 (con certificazione)'
    }
    return null
  }

  renderTastoSpostamentoSportello(sportello) {
    const canChangeSportello = this.props.canChangeSportello
    const candidatoMio = sportello.sportelloId === this.props.user.sportelloCorrente.sportelloId
    const candidatoInSpostamento = !!sportello.nuovoSportelloId

    // Se il candidato non è mio, non è in spostamento e posso richiederlo
    // => Tasto Prendi in carico
    if (this.props.canChangeSportello && !candidatoMio && !candidatoInSpostamento) {
      return (
        <button className="btn btn-xs btn-inverse" onClick={this.handleSpostamentoCandidato}>
          <MdSwapHoriz />
          Prendi in carico
        </button>
      )
    }

    // Se il candidato non è mio ed è in spostamento
    // => Tasto disattivato
    if (!candidatoMio && candidatoInSpostamento) {
      return (
        <button className="btn btn-xs btn-inverse" disabled>
          <MdSwapHoriz />
          In fase di trasferimento verso {sportello.nuovoSportello}
        </button>
      )
    }

    // Se il candidato è mio ed è in spostamento e posso approvarlo
    // => Tasto approva e Tasto annulla
    if (this.props.canChangeSportello && candidatoMio && candidatoInSpostamento) {
      return (
        <div>
          <button className="btn btn-success" onClick={this.handleConfermaSpostamento}>
            <MdSwapHoriz />
            Approva trasferimento verso {sportello.nuovoSportello}
          </button>
          <button className="btn btn-default" onClick={this.handleAnnullaSpostamento}>
            <MdCancel />
            Annulla trasferimento
          </button>
        </div>
      )
    }
  }

  handleSpostamentoCandidato = () => {
    swal({
      icon: 'warning',
      title: 'Presa in carico candidato',
      text: 'Sei sicuro di voler richiedere lo spostamento di questo candidato verso il tuo sportello?',
      buttons: ['Annulla', 'Si, richiedi il candidato'],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.handleConfirmRichiediSpostamento()
      }
    })
  }

  render() {
    const { anagrafica, sitoWebFlag, ultimaModifica, canChangeServizi, ruolo, sportello } =
      this.props

    return (
      <div className="altre-info">
        <div className="row">
          <div className="col-sm-6 right-border">
            <div className="title">Nascita</div>
            <div>
              {anagrafica.luogoNascitaEsteso}, {anagrafica.dataNascitaF}
            </div>
            <div>C.F. {anagrafica.codiceFiscale}</div>
          </div>
          <div className="col-sm-6">
            <div className="title">Residenza</div>
            <div>{anagrafica.residenzaIndirizzo}</div>
            <div>
              {anagrafica.residenzaCap} {anagrafica.residenzaComuneEsteso}
            </div>
          </div>
        </div>

        <hr />

        <div className="row">
          <div className="col-sm-6 right-border">
            <div className="title">Documento</div>
            <div>
              {anagrafica.documentoTipo} n° {anagrafica.documentoNumero}
            </div>
            <div>
              Scadenza{' '}
              {anagrafica.documentoScadenzaF ? `(scad. ${anagrafica.documentoScadenzaF})` : ''}
            </div>
          </div>
          <div className="col-sm-6">
            <div className="title">Cittadinanza</div>
            <div>{anagrafica.cittadinanza}</div>
            <div>Lingua madre {anagrafica.linguaMadre}</div>
          </div>
        </div>

        <hr />

        <div className="row">
          <div className="col-sm-6 right-border">
            <div className="title">Extracomunitario</div>
            {this.renderExtracomunitario()}
          </div>
          {ruolo === 'operatore' && (
            <div className="col-sm-6">
              <div className="title">Iscritto CISL</div>
              {this.renderIscrittoCisl()}
            </div>
          )}
        </div>

        <hr />

        <div className="row">
          <div className="col-sm-6 right-border">
            <div className="title">Visibile su sito pubblico</div>
            <div className="row">
              <div className="col-md-2">
                <Switch
                  checked={sitoWebFlag}
                  onChange={this.changeFlagSitoWeb}
                  disabled={!canChangeServizi || !this.props.canPublish || ruolo !== 'operatore'}
                />
              </div>
              <div className="col-md-10">
                <div className="switch-message">{this.renderSwitchMessage()}</div>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="title">Sportello {sportello.sportello}</div>

            {this.renderTastoSpostamentoSportello(sportello)}
          </div>
        </div>

        <hr />

        <div className="ultima-modifica">
          Ultima modifica: {ultimaModifica.dataF} {ultimaModifica.utente}
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    candidatoId: state.candidatoAttivo.candidatoId,
    cvCompleto: isCvCompleto(state),
    anagrafica: state.candidatoAttivo.anagrafica,
    sitoWebFlag: state.candidatoAttivo.sitoWebFlag,
    user: state.auth.user,
    ultimaModifica: state.candidatoAttivo.ultimaModifica,
    sportello: state.candidatoAttivo.sportello,
    canChangeSportello: !!state.auth.user.permessi['cambiaSportello'],
    canPublish: !!state.auth.user.permessi['pubblicazioneCandidato'],
  }
}

export default connect(mapStateToProps, {
  saveSitoWebFlag,
  saveNuovoSportello,
  confermaSpostamento,
  annullaSpostamento,
  isCvCompleto,
})(AnagraficaAltreInfo)
