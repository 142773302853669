import React from 'react'
import { Route, Switch } from 'react-router'
import Agenzia from '../agenzie/agenzia'
import AgenzieList from '../agenzie/agenzie_list'
import RequireAuth from '../auth/require_auth'
import JobVacanciesAziendeAgenzie from '../job_vacancies/job_vacancies_aziende_agenzie'

const GestioneAgenzie = (props) => {
  return (
    <div>
      <Switch>
        <Route
          exact
          path="/app/agenzie"
          component={RequireAuth(['operatore', 'amministratore'])(AgenzieList)}
        />
        <Route exact path="/app/agenzie/new" component={Agenzia} />
        <Route exact path="/app/agenzie/:agenziaId" component={Agenzia} />
        <Route
          exact
          path="/app/agenzie/:agenziaId/job-vacancies"
          component={JobVacanciesAziendeAgenzie}
        />
      </Switch>
    </div>
  )
}

export default GestioneAgenzie
