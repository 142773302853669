import cogoToast from 'cogo-toast'
import it from 'date-fns/locale/it'
import moment from 'moment'
import React, { Component } from 'react'
import AvatarEditor from 'react-avatar-editor'
import DatePicker, { registerLocale } from 'react-datepicker'
import { Field, Form } from 'react-final-form'
import { MdCloudUpload, MdPhotoCamera, MdRotateLeft, MdRotateRight, MdSearch } from 'react-icons/md'
import Ink from 'react-ink'
import { connect } from 'react-redux'
import AsyncSelect from 'react-select/async'
import Webcam from 'react-webcam'
import swal from 'sweetalert'
import {
  createCandidato,
  fetchCandidato,
  fetchTypeaheadComuni,
  fetchTypeaheadComuniConStatiEsteri,
  resetCandidato,
  saveAnagrafica,
  verificaCodiceFiscaleCisl,
} from '../../actions'
import Lookups from '../lookups/lookups'
import BottoneAnnulla from './bottone_annulla'

registerLocale('it', it)

class EditAnagrafica extends Component {
  ///////////////////////////////////////////////////////////////
  // CONSTRUCTOR
  constructor(props) {
    super(props)

    this.state = {
      imageState: 'initial',
      imageScale: 1,
      imageRotation: 0,
      uploadImageUrl: null,
      croppedImageUrl: null,
      nuovoCandidatoId: null,
      saving: false,
    }
  }

  componentDidMount() {
    // Ho già il candidato attivo se navigo normalmente
    // dal dettaglio candidato: questo controllo serve
    // nel caso in cui ricarico la pagina da qui (F5)
    if (!!this.props.match.params.id && !this.props.candidatoId) {
      this.props.fetchCandidato(this.props.match.params.id)
    }

    // Se nuovo, resetto il candidato attivo
    if (!this.props.match.params.id) {
      this.props.resetCandidato()
      //this.props.resetForm();
    }
  }

  ///////////////////////////////////////////////////////////////
  // GESTIONE FOTOGRAFIA / UPLOAD / WEBCAM
  handleScale() {
    const scale = parseFloat(this.refs.scale.value)
    this.setState({ imageScale: scale })
  }

  handleRotation = (direction) => (event) => {
    // console.log('direction', direction)
    let angle = this.state.imageRotation
    if (direction === 'left') {
      angle = angle - 90
    } else {
      angle = angle + 90
    }

    this.setState({ imageRotation: angle })
  }

  handleCancelPhoto() {
    this.setState({
      imageState: 'initial',
      imageScale: 1,
      uploadImageUrl: null,
      croppedImageUrl: null,
    })
  }

  handleFileUpload(event) {
    var self = this
    var reader = new FileReader()
    var file = event.target.files[0]

    reader.onload = function (upload) {
      self.setState({
        imageState: 'cropping',
        uploadImageUrl: upload.target.result,
      })
    }

    reader.readAsDataURL(file)
  }

  handleWebcamStart() {
    this.setState({ imageState: 'webcam' })
  }

  handleTakeSnapshot() {
    var screenshot = this.refs.webcam.getScreenshot()
    this.setState({
      imageState: 'cropping',
      uploadImageUrl: screenshot,
    })
  }

  handleSavePhoto() {
    var img = this.editor.getImageScaledToCanvas().toDataURL()

    this.props.fields.fotografia.onChange(img)

    this.setState({
      imageState: 'initial',
      croppedImageUrl: img,
    })
  }

  setEditorRef(editor) {
    if (editor) this.editor = editor
  }

  renderFotografia(initialValues) {
    switch (this.state.imageState) {
      case 'initial':
        this.state.croppedImageUrl = initialValues.fotografia || '/images/senza-foto.jpg'

        return (
          <tr>
            <td className="fotografia">
              <img src={this.state.croppedImageUrl} className="img-responsive" alt="foto-candidato" />
            </td>
            <td className="controlli">
              <div className="fileupload btn btn-rounded btn-default">
                <Ink />
                <MdCloudUpload /> Carica file
                <input type="file" onChange={this.handleFileUpload.bind(this)} className="upload" />
              </div>
              <br />
              <br />
              <button type="button" onClick={this.handleWebcamStart.bind(this)} className="btn btn-rounded btn-primary">
                <Ink />
                <MdPhotoCamera /> Webcam
              </button>
            </td>
          </tr>
        )
      case 'cropping':
        return (
          <tr>
            <td className="fotografia">
              <AvatarEditor
                ref={this.setEditorRef.bind(this)}
                image={this.state.uploadImageUrl}
                width={300}
                height={300}
                border={20}
                scale={this.state.imageScale}
                rotate={this.state.imageRotation}
              />
              <input
                name="scale"
                type="range"
                ref="scale"
                onChange={this.handleScale.bind(this)}
                min="1"
                max="2"
                step="0.01"
                defaultValue="1"
              />
              <div className="btn-group controlli-rotazione-foto" role="group">
                <button type="button" className="btn btn-default btn-rounded" onClick={this.handleRotation('left')}>
                  <MdRotateLeft />
                </button>
                <button type="button" className="btn btn-default btn-rounded" onClick={this.handleRotation('right')}>
                  <MdRotateRight />
                </button>
              </div>
            </td>
            <td className="controlli">
              <button
                type="button"
                onClick={this.handleCancelPhoto.bind(this)}
                className="btn btn-rounded btn-default btn-block"
              >
                <Ink />
                Annulla
              </button>
              <br />
              <br />
              <button
                type="button"
                onClick={this.handleSavePhoto.bind(this)}
                className="btn btn-rounded btn-primary btn-block"
              >
                <Ink />
                Salva foto
              </button>
            </td>
          </tr>
        )
      case 'webcam':
        return (
          <tr>
            <td className="fotografia">
              <Webcam ref="webcam" audio={false} width={222} height={167} screenshotFormat="image/jpeg" />
            </td>
            <td className="controlli">
              <button type="button" onClick={this.handleCancelPhoto.bind(this)} className="btn btn-rounded btn-default">
                <Ink />
                Annulla
              </button>
              <br />
              <br />
              <button
                type="button"
                onClick={this.handleTakeSnapshot.bind(this)}
                className="btn btn-rounded btn-primary"
              >
                <Ink />
                Scatta ora
              </button>
            </td>
          </tr>
        )
      default:
        return
    }
  }

  ///////////////////////////////////////////////////////////////
  // SUBMIT
  onSubmit(values) {
    // delete values.luogoNascitaEsteso,
    // delete values.residenzaComuneEsteso,
    // delete values.domicilioComuneEsteso,
    // delete values.residenzaComuneLat,
    // delete values.residenzaComuneLng,
    // delete values.domicilioComuneLat,
    // delete values.domicilioComuneLng,
    // delete values.dataNascitaF,
    // delete values.documentoScadenzaF,
    // delete values.extracomunitarioPermessoSoggiornoScadenzaF,
    // delete values.eta,

    const submitValues = values

    const {
      luogoNascitaEsteso,
      residenzaComuneEsteso,
      domicilioComuneEsteso,
      residenzaComuneLat,
      residenzaComuneLng,
      domicilioComuneLat,
      domicilioComuneLng,
      dataNascitaF,
      documentoScadenzaF,
      extracomunitarioPermessoSoggiornoScadenzaF,
      eta,
      ...rest
    } = submitValues

    this.setState({ saving: true })

    if (this.props.match.params.id) {
      // Siamo in update
      rest.domicilioComune = values.domicilioComune
      rest.luogoNascita = values.luogoNascita
      rest.residenzaComune = values.residenzaComune

      this.props.saveAnagrafica(this.props.match.params.id, rest).then((action) => {
        this.setState({ saving: false })
        // Redirect a dettagli candidato
        this.props.history.goBack()
      })
    } else {
      // Siamo in new
      rest.domicilioComune = values.domicilioComune?.value
      rest.luogoNascita = values.luogoNascita?.value
      rest.residenzaComune = values.residenzaComune?.value
      this.props.createCandidato(rest).then((action) => {
        this.setState({ saving: false })
        // Set candidatoId e show Alert
        this.setState({
          nuovoCandidatoId: action.payload.data.candidatoId,
        })
        this.handleCreazioneCandidato()
        this.props.history.goBack()
      })
    }
  }

  ///////////////////////////////////////////////////////////////
  // COPIA RESIDENZA -> DOMICILIO
  copiaResidenza(form) {
    form.change('domicilioIndirizzo', form.getFieldState('residenzaIndirizzo').value)
    form.change('domicilioCap', form.getFieldState('residenzaCap').value)
    form.change('domicilioComune', form.getFieldState('residenzaComune').value)
  }

  ///////////////////////////////////////////////////////////////
  // SET_ITALIANO
  setItaliano(form) {
    form.change('cittadinanza', 'Italiana')
    form.change('linguaMadre', 'Italiano')
  }

  ///////////////////////////////////////////////////////////////
  // GESTIONE COMUNI AUTOCOMPLETE
  getComuniOptions = (comuni) => {
    return comuni.map((comune) => {
      return {
        value: comune.comune,
        label: comune.comuneEsteso,
        cap: comune.cap,
      }
    })
  }

  getComuni = (input) => {
    return this.props.fetchTypeaheadComuni(input).then((action) => {
      return this.getComuniOptions(action.payload.data)
    })
  }

  getComuniConStatiEsteri = (input) => {
    return this.props.fetchTypeaheadComuniConStatiEsteri(input).then((action) => {
      return this.getComuniOptions(action.payload.data)
    })
  }

  verificaCodiceFiscale(form, codiceFiscale, event) {
    event.preventDefault()
    this.props.verificaCodiceFiscaleCisl(codiceFiscale).then((action) => {
      switch (action.payload.data.esitoWebServiceCisl) {
        case 'trovato':
          cogoToast.success('Il candidato è un iscritto CISL', {
            heading: 'Candidato iscritto',
            position: 'top-right',
            hideAfter: 5,
          })
          form.change(
            action.payload.data.anagrafica.nome.replace(/\w\S*/g, function (tStr) {
              return tStr.charAt(0).toUpperCase() + tStr.substr(1).toLowerCase()
            })
          )
          form.change(
            action.payload.data.anagrafica.cognome.replace(/\w\S*/g, function (tStr) {
              return tStr.charAt(0).toUpperCase() + tStr.substr(1).toLowerCase()
            })
          )
          form.change('iscrittoCislFlag', action.payload.data.anagrafica.iscrittoCislFlag)
          form.change('iscrittoCislFederazione', action.payload.data.anagrafica.iscrittoCislFederazione)
          form.change('iscrittoCislTerritorio', action.payload.data.anagrafica.iscrittoCislTerritorio)
          break
        case 'non trovato':
          cogoToast.warn('Il candidato non è iscritto CISL', {
            heading: 'Candidato non iscritto',
            position: 'top-right',
            hideAfter: 5,
          })
          break
        case 'timeout':
          cogoToast.warn('Il server CISL non ha risposto alla chiamata', {
            heading: 'Webservice CISL non disponibile',
            position: 'top-right',
            hideAfter: 5,
          })
          break
        default:
          break
      }
      // Se è già presente e non siamo proprio nella pagina del Candidato trovato con quel C.F.
      // allora mostro l'errore di C.F. già presente
      if (action.payload.data.candidatoPresenteFlag && action.payload.data.candidatoId !== this.props.candidatoId) {
        cogoToast.error('Attenzione: nel database esiste già un candidato con questo codice fiscale!', {
          heading: 'Candidato già presente',
          position: 'top-right',
          hideAfter: 10,
        })
      }
      form.change('sesso', action.payload.data.anagrafica.sesso)
      form.change('luogoNascita', {
        value: action.payload.data.anagrafica.luogoNascita,
        label: action.payload.data.anagrafica.luogoNascitaEsteso,
      })
      form.change('luogoNascitaEsteso', action.payload.data.anagrafica.luogoNascitaEsteso)
      form.change(
        'dataNascita',
        action.payload.data.anagrafica.dataNascita
          ? moment(action.payload.data.anagrafica.dataNascita).format('YYYY-MM-DD')
          : null
      )
    })
  }

  verificaIscrizioneCisl(form, codiceFiscale, event) {
    event.preventDefault()
    this.props.verificaCodiceFiscaleCisl(codiceFiscale).then((action) => {
      switch (action.payload.data.esitoWebServiceCisl) {
        case 'trovato':
          cogoToast.success('Il candidato è un iscritto CISL', {
            heading: 'Candidato iscritto',
            position: 'top-right',
            hideAfter: 5,
          })
          form.change('iscrittoCislFlag', action.payload.data.anagrafica.iscrittoCislFlag)
          form.change('iscrittoCislFederazione', action.payload.data.anagrafica.iscrittoCislFederazione)
          form.change('iscrittoCislTerritorio', action.payload.data.anagrafica.iscrittoCislTerritorio)
          break
        case 'non trovato':
          cogoToast.warn('Il candidato non è iscritto CISL', {
            heading: 'Candidato non iscritto',
            position: 'top-right',
            hideAfter: 5,
          })
          break
        case 'timeout':
          cogoToast.warn('Il server CISL non ha risposto alla chiamata', {
            heading: 'Webservice CISL non disponibile',
            position: 'top-right',
            hideAfter: 5,
          })
          break
        default:
          break
      }
    })
  }

  ///////////////////////////////////////////////
  // NEW / EDIT
  renderTitolo({ form: initialValues, isNew }) {
    let titolo = ''
    if (isNew) {
      const values = initialValues.getState().values
      titolo = `Nuovo candidato ${values.nome ? values.nome : ''} ${values.cognome ? values.cognome : ''}`
    }
    if (this.props.match.params.id) {
      titolo = `${initialValues.nome} ${initialValues.cognome} - Anagrafica`
    }

    return <h1 className="page-title pull-left">{titolo}</h1>
  }

  handleCreazioneCandidato = () => {
    swal({
      icon: 'success',
      title: 'Candidato creato correttamente',
      text: 'Complimenti, il candidato è stato creato correttamente. Ora puoi inserire le altre informazioni',
      button: {
        text: 'Ok, procedi',
        value: true,
        visible: true,
        className: 'swal-button-success',
        closeModal: true,
      },
    })
  }

  validate(values) {
    const errors = {}

    if (!values.sesso) {
      errors.sesso = 'Seleziona il sesso'
    }

    if (!values.codiceFiscale) {
      errors.codiceFiscale = 'Inserisci il codice fiscale'
    }

    if (!values.nome) {
      errors.nome = 'Inserisci il nome'
    }

    if (!values.cognome) {
      errors.cognome = 'Inserisci il cognome'
    }

    if (!values.luogoNascita) {
      errors.luogoNascita = 'Inserisci il luogo di nascita'
    }

    if (!values.dataNascita) {
      errors.dataNascita = 'Inserisci la data di nascita'
    }

    if (!validateCodiceFiscale(values.codiceFiscale)) {
      errors.codiceFiscale = 'Codice fiscale non valido'
    }

    if (!values.residenzaIndirizzo) {
      errors.residenzaIndirizzo = "Inserisci l'indirizzo di residenza"
    }

    if (!values.residenzaCap) {
      errors.residenzaCap = 'Inserisci il CAP di residenza'
    }

    if (!values.residenzaComune) {
      errors.residenzaComune = 'Inserisci il comune di residenza'
    }

    return errors
  }

  ///////////////////////////////////////////////////////////////
  // RENDER
  render() {
    const initialValues = this.props.initialValues ? this.props.initialValues : {}
    return (
      <>
        <Form
          onSubmit={(values) => {
            const submitValues = { ...values }

            this.onSubmit(submitValues)
          }}
          initialValues={initialValues}
          validate={this.validate}
          render={({ handleSubmit, form }) => (
            <>
              {form ? (
                <form onSubmit={handleSubmit} className="edit-anagrafica">
                  <div className="heading">
                    {this.renderTitolo(
                      Object.keys(initialValues).length === 0
                        ? { form, isNew: true }
                        : { form: initialValues, isNew: false }
                    )}
                    <BottoneAnnulla />
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="panel panel-border panel-primary">
                        <div className="panel-heading">
                          <h3 className="panel-title">Anagrafica Base</h3>
                        </div>
                        <div className="panel-body">
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label>Sesso</label>
                                <div>
                                  <div className="radio radio-primary radio-inline">
                                    <Field name="sesso" component="input" type="radio" value="M" id="sesso_M" />
                                    <label htmlFor="sesso_M">M</label>
                                  </div>
                                  <div className="radio radio-primary radio-inline">
                                    <Field name="sesso" id="sesso_F" component="input" type="radio" value="F" />
                                    <label htmlFor="sesso_F">F</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <Field
                                id="codiceFiscale"
                                name="codiceFiscale"
                                render={({ input, meta }) => (
                                  <div className={`form-group  ${meta.touched && meta.error ? 'has-error' : ''}`}>
                                    <label htmlFor="codiceFiscale">
                                      Codice fiscale
                                      {this.props.canCheckCfCisl && input.value !== '' ? (
                                        <a
                                          href="/#"
                                          onClick={(event) => this.verificaCodiceFiscale(form, input.value, event)}
                                        >
                                          <MdSearch className="check-cf" />
                                        </a>
                                      ) : null}
                                    </label>
                                    <input className="form-control" type="text" placeholder="" {...input} />
                                    <div className="help-block">{meta.touched && meta.error}</div>
                                  </div>
                                )}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-6">
                              <Field
                                id="nome"
                                name="nome"
                                render={({ input, meta }) => (
                                  <div className={`form-group  ${meta.touched && meta.error ? 'has-error' : ''}`}>
                                    <label htmlFor="nome">Nome</label>
                                    <input type="text" {...input} className="form-control" />
                                    <div className="help-block">{meta.touched && meta.error}</div>
                                  </div>
                                )}
                              />
                            </div>
                            <div className="col-sm-6">
                              <Field
                                id="cognome"
                                name="cognome"
                                render={({ input, meta }) => (
                                  <div className={`form-group  ${meta.touched && meta.error ? 'has-error' : ''}`}>
                                    <label htmlFor="cognome">Cognome</label>
                                    <input type="text" {...input} className="form-control" />
                                    <div className="help-block">{meta.touched && meta.error}</div>
                                  </div>
                                )}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-6">
                              <Field
                                name="luogoNascita"
                                id="luogo_nascita"
                                render={({ input, meta }) => {
                                  return (
                                    <div className={`form-group  ${meta.touched && meta.error ? 'has-error' : ''}`}>
                                      <label htmlFor="luogo_nascita">Luogo di nascita</label>
                                      <AsyncSelect
                                        placeholder={initialValues.luogoNascitaEsteso || 'seleziona...'}
                                        loadOptions={this.getComuniConStatiEsteri}
                                        loadingPlaceholder="Caricamento..."
                                        searchingText="Caricamento..."
                                        noResultsText="Nessun risultato"
                                        defaultOptions={[
                                          {
                                            value: initialValues.luogoNascita,
                                            label: initialValues.luogoNascitaEsteso,
                                          },
                                        ]}
                                        {...input}
                                      />
                                      <div className="help-block">{meta.touched && meta.error}</div>
                                    </div>
                                  )
                                }}
                              />
                            </div>
                            <div className="col-sm-6">
                              <Field
                                id="dataNascita"
                                name="dataNascita"
                                render={({ input, meta }) => {
                                  const { value, ...rest } = input
                                  return (
                                    <div className={`form-group  ${meta.touched && meta.error ? 'has-error' : ''}`}>
                                      <label htmlFor="dataNascita">Data di nascita</label>
                                      <DatePicker
                                        selected={value ? new Date(moment(value).format('MM-DD-YYYY')) : null}
                                        dateFormat="dd/MM/yyyy"
                                        locale="it"
                                        className="form-control"
                                        value={new Date(value)}
                                        {...rest}
                                      />
                                      <div className="help-block">{meta.touched && meta.error}</div>
                                    </div>
                                  )
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="panel panel-border panel-primary">
                        <div className="panel-heading">
                          <h3 className="panel-title">Documento e cittadinanza</h3>
                        </div>
                        <div className="panel-body">
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="documentoTipo">Tipologia</label>
                                <Field
                                  id="documentoTipo"
                                  name="documentoTipo"
                                  render={({ input }) => <Lookups tipo="tipoDocumento" {...input} />}
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="documentoRilasciatoDa">Rilasciato da</label>
                                <Field
                                  id="documentoRilasciatoDa"
                                  name="documentoRilasciatoDa"
                                  component="input"
                                  type="text"
                                  className="form-control"
                                  placeholder="Comune di Milano"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="documentoNumero">Numero</label>
                                <Field
                                  name="documentoNumero"
                                  component="input"
                                  className="form-control"
                                  id="documentoNumero"
                                  type="text"
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <div>
                                  <label htmlFor="documentoScadenza">Scadenza</label>
                                </div>

                                <Field
                                  id="documentoScadenza"
                                  name="documentoScadenza"
                                  render={({ input }) => {
                                    const { value, ...rest } = input
                                    return (
                                      <DatePicker
                                        selected={value ? new Date(moment(value).format('MM-DD-YYYY')) : null}
                                        dateFormat="dd/MM/yyyy"
                                        locale="it"
                                        className="form-control"
                                        value={new Date(value)}
                                        {...rest}
                                      />
                                    )
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="cittadinanza">
                                  Cittadinanza{' '}
                                  <a
                                    href="/#"
                                    onClick={(e) => {
                                      e.preventDefault()
                                      this.setItaliano(form)
                                    }}
                                  >
                                    <img className="bandiera" src="/images/it.png" alt="bandiera-italiana" />
                                  </a>
                                </label>
                                <Field
                                  type="text"
                                  component="input"
                                  className="form-control"
                                  name="cittadinanza"
                                  id="cittadinanza"
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="linguaMadre">Lingua madre</label>
                                <Field
                                  type="text"
                                  className="form-control"
                                  component="input"
                                  name="linguaMadre"
                                  id="linguaMadre"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* RESIDENZA E DOMICILIO SECONDA RIGA */}
                  <div className="row residenza domicilio">
                    <div className="col-md-6">
                      <div className="panel panel-border panel-success">
                        <div className="panel-heading">
                          <h3 className="panel-title">Residenza</h3>
                        </div>
                        <div className="panel-body">
                          <div className="row">
                            <div className="col-sm-12">
                              <Field
                                id="residenzaIndirizzo"
                                name="residenzaIndirizzo"
                                render={({ input, meta }) => (
                                  <div className={`form-group  ${meta.touched && meta.error ? 'has-error' : ''}`}>
                                    <label htmlFor="residenzaIndirizzo">Indirizzo</label>
                                    <input type="text" {...input} className="form-control" />
                                    <div className="help-block">{meta.touched && meta.error}</div>
                                  </div>
                                )}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-4">
                              <Field
                                id="residenzaCap"
                                name="residenzaCap"
                                render={({ input, meta }) => (
                                  <div className={`form-group  ${meta.touched && meta.error ? 'has-error' : ''}`}>
                                    <label htmlFor="residenzaCap">Indirizzo</label>
                                    <input type="text" {...input} className="form-control" />
                                    <div className="help-block">{meta.touched && meta.error}</div>
                                  </div>
                                )}
                              />
                            </div>
                            <div className="col-sm-8">
                              <Field
                                name="residenzaComune"
                                id="residenzaComune"
                                render={({ input, meta }) => (
                                  <div className={`form-group  ${meta.touched && meta.error ? 'has-error' : ''}`}>
                                    <label htmlFor="residenzaComune">Comune</label>
                                    <AsyncSelect
                                      name="residenzaComune"
                                      loadOptions={this.getComuniConStatiEsteri}
                                      placeholder={initialValues.residenzaComuneEsteso || 'Seleziona...'}
                                      defaultOptions={[
                                        {
                                          value: initialValues.residenzaComune,
                                          label: initialValues.residenzaComuneEsteso,
                                        },
                                      ]}
                                      loadingPlaceholder="Caricamento..."
                                      searchingText="Caricamento..."
                                      noResultsText="Nessun risultato"
                                      {...input}
                                    />
                                    <div className="help-block">{meta.touched && meta.error}</div>
                                  </div>
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="panel panel-border panel-success">
                        <div className="panel-heading">
                          <h3 className="panel-title">
                            Domicilio&nbsp;&nbsp;&nbsp;
                            <button
                              type="button"
                              onClick={(e) => {
                                e.preventDefault()
                                this.copiaResidenza(form)
                              }}
                              className="btn btn-xs btn-default btn-rounded"
                            >
                              <Ink />= Residenza
                            </button>
                          </h3>
                        </div>
                        <div className="panel-body">
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="form-group">
                                <label htmlFor="domicilioIndirizzo">Indirizzo</label>

                                <Field
                                  type="text"
                                  component="input"
                                  id="domicilioIndirizzo"
                                  name="domicilioIndirizzo"
                                  className="form-control"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-4">
                              <div className="form-group">
                                <label htmlFor="domicilioCap">Cap</label>
                                <Field
                                  type="text"
                                  component="input"
                                  id="domicilioCap"
                                  name="domicilioCap"
                                  maxLength="5"
                                  className="form-control"
                                />
                              </div>
                            </div>
                            <div className="col-sm-8">
                              <div className="form-group">
                                <label htmlFor="domicilioComune">Comune</label>
                                <Field
                                  name="domicilioComune"
                                  id="domicilioComune"
                                  render={({ input }) => (
                                    <AsyncSelect
                                      name="domicilioComune"
                                      placeholder={initialValues.domicilioComuneEsteso || 'Seleziona...'}
                                      loadOptions={this.getComuniConStatiEsteri}
                                      defaultOptions={[
                                        {
                                          value: initialValues.domicilioComune,
                                          label: initialValues.domicilioComuneEsteso,
                                        },
                                      ]}
                                      loadingPlaceholder="Caricamento..."
                                      searchingText="Caricamento..."
                                      noResultsText="Nessun risultato"
                                      {...input}
                                    />
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row foto contatti">
                    <div className="col-md-6">
                      <div className="panel panel-border panel-primary">
                        <div className="panel-heading">
                          <h3 className="panel-title">Fotografia</h3>
                        </div>
                        <div className="panel-body">
                          <table>
                            <tbody>{this.renderFotografia(initialValues)}</tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="panel panel-border panel-pink">
                        <div className="panel-heading">
                          <h3 className="panel-title">Contatti</h3>
                        </div>
                        <div className="panel-body">
                          <div className="form-group">
                            <label htmlFor="telefono">Telefono</label>
                            <Field
                              type="text"
                              component="input"
                              className="form-control"
                              id="telefono"
                              name="telefono"
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="cellulare">Cellulare</label>
                            <Field
                              type="text"
                              component="input"
                              className="form-control"
                              id="cellulare"
                              name="cellulare"
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <Field type="text" component="input" className="form-control" id="email" name="email" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row extracomunitario iscritto-cisl">
                    <div className="col-md-6">
                      <div className="panel panel-border panel-warning">
                        <div className="panel-heading">
                          <div className="checkbox checkbox-warning">
                            <Field
                              type="checkbox"
                              component="input"
                              id="extracomunitarioFlag"
                              name="extracomunitarioFlag"
                            />

                            <label htmlFor="extracomunitarioFlag">
                              <h3 className="panel-title">Extracomunitario</h3>
                            </label>
                          </div>
                        </div>
                        <div className="panel-body">
                          {form.getFieldState('extracomunitarioFlag')?.value ? (
                            <div className="form-group">
                              <div className="checkbox checkbox-primary">
                                <Field
                                  type="checkbox"
                                  component="input"
                                  id="extracomunitarioItalianoB1Flag"
                                  name="extracomunitarioItalianoB1Flag"
                                />

                                <label htmlFor="extracomunitarioItalianoB1Flag">
                                  Conoscenza lingua italiana almeno a livello B1 (con certificazione)
                                </label>
                              </div>
                            </div>
                          ) : null}
                          {form.getFieldState('extracomunitarioFlag')?.value ? (
                            <div className="form-group">
                              <div className="checkbox checkbox-primary">
                                <Field
                                  type="checkbox"
                                  component="input"
                                  id="extracomunitarioPermessoSoggiornoFlag"
                                  name="extracomunitarioPermessoSoggiornoFlag"
                                />

                                <label htmlFor="extracomunitarioPermessoSoggiornoFlag">Permesso di soggiorno</label>
                              </div>
                            </div>
                          ) : null}

                          {form.getFieldState('extracomunitarioFlag')?.value &&
                          initialValues.extracomunitarioPermessoSoggiornoFlag ? (
                            <div>
                              <div className="form-group">
                                <label htmlFor="extracomunitarioPermessoSoggiornoTipologia">Tipologia</label>
                                <Field
                                  id="extracomunitarioPermessoSoggiornoTipologia"
                                  name="extracomunitarioPermessoSoggiornoTipologia"
                                  render={({ input }) => <Lookups tipo="tipoPermessoSoggiorno" {...input} />}
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="extracomunitarioPermessoSoggiornoNumero">Numero</label>
                                <Field
                                  type="text"
                                  component="input"
                                  className="form-control"
                                  id="extracomunitarioPermessoSoggiornoNumero"
                                  name="extracomunitarioPermessoSoggiornoNumero"
                                />
                              </div>
                              <div className="form-group">
                                <div>
                                  <label htmlFor="extracomunitarioPermessoSoggiornoScadenza">Scadenza</label>
                                </div>

                                <Field
                                  id="extracomunitarioPermessoSoggiornoScadenza"
                                  name="extracomunitarioPermessoSoggiornoScadenza"
                                  className="form-control"
                                  render={({ input }) => {
                                    const { value, ...rest } = input
                                    return (
                                      <DatePicker
                                        selected={value ? new Date(moment(value).format('MM-DD-YYYY')) : null}
                                        dateFormat="dd/MM/yyyy"
                                        locale="it"
                                        className="form-control"
                                        value={new Date(value)}
                                        {...rest}
                                      />
                                    )
                                  }}
                                />
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      {this.props.canCheckCfCisl && (
                        <div className="panel panel-border panel-success">
                          <div className="panel-heading">
                            <div className="checkbox checkbox-success">
                              <Field name="iscrittoCislFlag" id="iscrittoCislFlag" component="input" type="checkbox" />
                              <label htmlFor="iscrittoCislFlag">
                                <h3 className="panel-title">Iscritto CISL</h3>
                              </label>
                            </div>
                            {form.getFieldState('codiceFiscale')?.value && (
                              <a
                                href="/#"
                                onClick={(event) =>
                                  this.verificaIscrizioneCisl(form, form.getFieldState('codiceFiscale')?.value, event)
                                }
                              >
                                <MdSearch /> Verifica iscrizione
                              </a>
                            )}
                          </div>
                          <div className="panel-body">
                            {form.getFieldState('iscrittoCislFlag')?.value ? (
                              <div>
                                <div className="form-group">
                                  <label htmlFor="iscrittoCislFederazione">Federazione</label>
                                  <Field
                                    id="iscrittoCislFederazione"
                                    name="iscrittoCislFederazione"
                                    render={({ input }) => <Lookups tipo="tipoFederazione" {...input} />}
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="iscrittoCislTerritorio">Territorio</label>
                                  <Field
                                    type="text"
                                    component="input"
                                    className="form-control"
                                    name="iscrittoCislTerritorio"
                                    id="iscrittoCislTerritorio"
                                  />
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="panel panel-border panel-blue">
                    <div className="panel-heading">
                      <h3 className="panel-title">Note CV in evidenza</h3>
                    </div>
                    <div className="panel-body">
                      <div className="form-group">
                        <label htmlFor="noteCv">Note Anagrafica</label>
                        <Field component="textarea" id="noteCv" className="form-control" row="4" name="noteCv" />
                      </div>
                    </div>
                  </div>

                  <div className="text-center">
                    <button type="submit" className="btn btn-primary btn-lg">
                      <Ink />
                      Salva candidato &raquo;
                    </button>
                  </div>
                </form>
              ) : null}
            </>
          )}
        />
      </>
    )
  }
}

function validateCodiceFiscale(codiceFiscale) {
  if (!codiceFiscale) {
    return false
  }

  var cf = codiceFiscale.toUpperCase()

  // Espressione regolare corretta con omocodia
  var cfReg = /^([A-Z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST]{1}[0-9LMNPQRSTUV]{2}[A-Z]{1}[0-9LMNPQRSTUV]{3}[A-Z]{1})$/

  if (!cfReg.test(cf)) {
    return false
  }
  var set1 = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  var set2 = 'ABCDEFGHIJABCDEFGHIJKLMNOPQRSTUVWXYZ'
  var setpari = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  var setdisp = 'BAKPLCQDREVOSFTGUHMINJWZYX'
  var s = 0,
    i
  for (i = 1; i <= 13; i += 2) {
    s += setpari.indexOf(set2.charAt(set1.indexOf(cf.charAt(i))))
  }
  for (i = 0; i <= 14; i += 2) {
    s += setdisp.indexOf(set2.charAt(set1.indexOf(cf.charAt(i))))
  }
  if (s % 26 !== cf.charCodeAt(15) - 'A'.charCodeAt(0)) {
    return false
  }
  return true
}

const mapStateToProps = (state, props) => {
  return {
    initialValues: state.candidatoAttivo.anagrafica,
    candidatoId: state.candidatoAttivo.candidatoId,
    canCheckCfCisl: state.auth.user.ruolo === 'operatore',
  }
}

export default connect(mapStateToProps, {
  fetchCandidato,
  resetCandidato,
  createCandidato,
  saveAnagrafica,
  fetchTypeaheadComuni,
  fetchTypeaheadComuniConStatiEsteri,
  verificaCodiceFiscaleCisl,
})(EditAnagrafica)
