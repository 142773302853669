import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

// roles:       array di ruoli
// permission:  stringa permesso editing sezione
// redirect:    se true nel caso di non autenticato fa redirect a Login
//              (utile per rotte, false per parti di componenti da non mostrare)
export default function requireAuth(roles, permission, redirect = true) {
  const requiredRoles = roles || null
  const requiredPermission = permission || ''

  return function wrapWithRequiredAuth(WrappedComponent) {
    class Authentication extends Component {
      checkRequiredAuth(auth) {
        if (!auth.authenticated) {
          console.log('NOT AUTHENTICATED')
          return false
        }
        if (requiredRoles && requiredRoles.indexOf(auth.user.ruolo) === -1) {
          console.log('MISSING REQUIRED ROLE ' + requiredRoles.join(' or '))
          return false
        }
        if (requiredPermission && !auth.user.permessi[requiredPermission]) {
          console.log('MISSING REQUIRED PERMISSION ' + requiredPermission)
          return false
        }
        // OK, puoi passare :)
        return true
      }

      componentDidMount() {
        // REDIRECT
        if (redirect && !this.checkRequiredAuth(this.props)) {
          this.props.history.push('/')
        }
      }

      componentDidUpdate(prevProps) {
        // REDIRECT
        if (
          redirect &&
          (this.props.authenticated !== prevProps.authenticated || this.props.user !== prevProps.user) &&
          !this.checkRequiredAuth(this.props)
        ) {
          this.props.history.push('/')
        }
      }

      render() {
        // RENDER SE AUTENTICATO
        return this.checkRequiredAuth(this.props) ? <WrappedComponent {...this.props} /> : null
      }
    }

    function mapStateToProps(state) {
      return {
        authenticated: state.auth.authenticated,
        user: state.auth.user,
      }
    }

    return withRouter(connect(mapStateToProps)(Authentication))
  }
}
