import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Ink from 'react-ink'
import { MdModeEdit } from 'react-icons/md'

import Occupato from './stato_occupazionale_occupato'
import Disoccupato from './stato_occupazionale_disoccupato'
import Did from './stato_occupazionale_did'
import CentroImpiego from './stato_occupazionale_centro_impiego'

import './stato_occupazionale.less'

class StatoOccupazionale extends Component {
  render() {
    const { candidatoId, statoOccupazionale, canEdit } = this.props

    if (!statoOccupazionale || !statoOccupazionale.stato) {
      if (canEdit) {
        return (
          <div className="card-box">
            <Link
              to={`/app/candidati/${candidatoId}/stato-occupazionale`}
              className="btn btn-primary btn-lg center-block"
            >
              <MdModeEdit />
              Crea stato occupazionale
            </Link>
          </div>
        )
      }
      return null
    }

    return (
      <div className={`card-box stato-occupazionale ${statoOccupazionale.stato.toLowerCase()}`}>
        <div className="row">
          <div className="col-md-6">
            <div className="stato">
              <h4 className="header-title">Attualmente {statoOccupazionale.stato}</h4>
              <Occupato statoOccupazionale={statoOccupazionale} />
              <Disoccupato statoOccupazionale={statoOccupazionale} />
              {!!statoOccupazionale.studenteFlag && (
                <div style={{ padding: 10, backgroundColor: '#f0f0f0', marginTop: 20 }}>
                  <b>Studente presso {statoOccupazionale.studenteIstituto}</b>
                  <br />
                  Ultimo anno frequentato: {statoOccupazionale.studenteUltimoAnnoFrequentato}
                </div>
              )}
            </div>
          </div>

          <div className="col-md-6">
            <Did statoOccupazionale={statoOccupazionale} />
          </div>
          {/*<div className="col-md-3">
						<CentroImpiego statoOccupazionale={statoOccupazionale} />
					</div>*/}
        </div>
        {canEdit ? (
          <div className="text-right">
            <Link
              to={`/app/candidati/${candidatoId}/stato-occupazionale`}
              className="btn btn-sm btn-blue"
            >
              <MdModeEdit />
              Modifica stato occupazionale
            </Link>
          </div>
        ) : null}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    candidatoId: state.candidatoAttivo.candidatoId,
    statoOccupazionale: state.candidatoAttivo.statoOccupazionale,
    canEdit:
      !!state.auth.user.permessi['statoOccupazionale'] &&
      state.candidatoAttivo.sportello &&
      state.candidatoAttivo.sportello.sportelloId === state.auth.user.sportelloCorrente.sportelloId,
  }
}

export default connect(mapStateToProps)(StatoOccupazionale)
