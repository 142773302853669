import { 
    FETCH_AZIENDE,
    DELETE_AZIENDA,
    SET_KEYWORDS_AZIENDE,
    SET_SPORTELLO_CORRENTE_FLAG_AZIENDE,
    CHANGE_PAGE_AZIENDE
} from '../actions/types';

const INITIAL_STATE = {
    data: [],
    pagination: {
		page: 1,
		totalPages: 1
	},
    keywords: '',
    sportelloCorrenteFlag: true,
    pageRequested: 1
};

export default function(state = INITIAL_STATE, action) {
	switch(action.type) {
		case FETCH_AZIENDE:
			return {
                ...state,
                data: action.payload.data.items,
                pagination: action.payload.data.pagination
            };
        case DELETE_AZIENDA:
			return {
                ...state,
                data: state.data.filter(azienda => azienda.aziendaId !== action.payload)
            };
        case SET_KEYWORDS_AZIENDE:
            return {
                ...state,
                keywords: action.payload
            };
        case SET_SPORTELLO_CORRENTE_FLAG_AZIENDE:
            return {
                ...state,
                sportelloCorrenteFlag: action.payload
            };
        case CHANGE_PAGE_AZIENDE:
            return {
                ...state,
                pageRequested: action.payload
            };
		default:
			return state;
	}
}
