import React, { Component } from 'react'
import { connect } from 'react-redux'
import swal from 'sweetalert'
import { Link } from 'react-router-dom'
import { MdDescription, MdFileDownload, MdAdd, MdEmail } from 'react-icons/md'

import { fetchGruppi, createGruppo, deleteGruppo, inviaEmailCvGruppo, API_ROOT_URL } from '../../actions'

class GruppiList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      nomeNuovoGruppo: '',
      gruppoCvSinteticoId: 0,
    }

    this.props.fetchGruppi()
  }

  onRemove() {
    this.props.deleteGruppo(this.state.gruppoCvSinteticoId)
  }

  inviaEmailCv = (gruppoId) => (event) => {
    event.preventDefault()
    this.props.inviaEmailCvGruppo(gruppoId)
  }

  renderGruppi() {
    const { gruppi } = this.props

    return gruppi.map((gruppo) => (
      <tr key={gruppo.gruppoCvSinteticoId}>
        <td>
          <Link to={'/app/gruppi/' + gruppo.gruppoCvSinteticoId}>{gruppo.nome}</Link>
        </td>
        <td className="numero-candidati text-center">
          <Link to={'/app/gruppi/' + gruppo.gruppoCvSinteticoId}>{gruppo.numeroCandidati}</Link>
        </td>
        <td>{gruppo.dataCreazione}</td>
        <td>
          <a
            target="_blank"
            href={`${API_ROOT_URL}/gruppi_cv_sintetico/${gruppo.gruppoCvSinteticoId}/crea_pdf?t=${localStorage.getItem(
              'token'
            )}`}
            className="btn btn-sm btn-primary btn-rounded"
            rel="noreferrer"
          >
            <MdDescription />
            Vedi PDF
          </a>{' '}
          &nbsp;&nbsp;
          <a
            target="_blank"
            href={`${API_ROOT_URL}/gruppi_cv_sintetico/${gruppo.gruppoCvSinteticoId}/crea_pdf?t=${localStorage.getItem(
              'token'
            )}&download=true`}
            className="btn btn-sm btn-primary btn-rounded"
            rel="noreferrer"
          >
            <MdFileDownload />
            Scarica PDF
          </a>
        </td>
        <td>
          <a
            target="_blank"
            href={`${API_ROOT_URL}/gruppi_cv_sintetico/${
              gruppo.gruppoCvSinteticoId
            }/crea_pdf_cv?anonimi=false&t=${localStorage.getItem('token')}`}
            className="btn btn-sm btn-primary btn-rounded"
            rel="noreferrer"
          >
            <MdFileDownload />
            Scarica PDF CV
          </a>
          <a
            target="_blank"
            href={`${API_ROOT_URL}/gruppi_cv_sintetico/${
              gruppo.gruppoCvSinteticoId
            }/crea_pdf_cv?anonimi=true&t=${localStorage.getItem('token')}`}
            className="btn btn-sm btn-primary btn-rounded"
            rel="noreferrer"
          >
            <MdFileDownload />
            Scarica PDF CV anonimi
          </a>
        </td>
        <td>
          <a
            target="_blank"
            href="/#"
            className="btn btn-sm btn-primary btn-rounded"
            onClick={this.inviaEmailCv(gruppo.gruppoCvSinteticoId)}
            rel="noreferrer"
          >
            <MdEmail />
            Invia e-mail con candidati
          </a>
        </td>
        <td>
          <button
            className="btn btn-sm btn-danger"
            onClick={() => {
              this.setState({
                gruppoCvSinteticoId: gruppo.gruppoCvSinteticoId,
              })
              this.handleDeleteGruppo()
            }}
          >
            &times; Elimina
          </button>
        </td>
      </tr>
    ))
  }

  creaNuovoGruppo(event) {
    event.preventDefault()
    this.props.createGruppo(this.state.nomeNuovoGruppo)
    this.setState({ nomeNuovoGruppo: '' })
  }

  handleDeleteGruppo = () => {
    swal({
      icon: 'warning',
      title: 'Eliminazione gruppo',
      text: 'Sei sicuro di vole eliminare questo gruppo? Questa azione è irreversibile.',
      buttons: ['Annulla', 'Sì, elimina gruppo'],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onRemove()
      }
    })
  }
  render() {
    return (
      <div className="gruppi-list">
        <h1 className="page-title">Gruppi di Candidati</h1>
        <div className="card-box">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th className="text-center">Numero candidati</th>
                  <th>Data creazione</th>
                  <th>Documento PDF</th>
                  <th>Curriculum candidati PDF</th>
                  <th>Email</th>
                  <th>Elimina</th>
                </tr>
              </thead>
              <tbody>{this.renderGruppi()}</tbody>
            </table>
          </div>

          <div className="nuovo-gruppo">
            <p>
              <b>Nuovo gruppo:</b>
            </p>
            <form onSubmit={(event) => this.creaNuovoGruppo(event)}>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  value={this.state.nomeNuovoGruppo}
                  onChange={(event) => this.setState({ nomeNuovoGruppo: event.target.value })}
                  placeholder="Nome nuovo gruppo"
                />
                <span className="input-group-btn">
                  <button type="submit" disabled={!this.state.nomeNuovoGruppo} className="btn btn-success">
                    <MdAdd />
                    Crea nuovo gruppo
                  </button>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    gruppi: state.gruppi,
  }
}

export default connect(mapStateToProps, {
  fetchGruppi,
  createGruppo,
  deleteGruppo,
  inviaEmailCvGruppo,
})(GruppiList)
