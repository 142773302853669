import React, { Component } from 'react'
import _find from 'lodash/find'
import { connect } from 'react-redux'
import { Field, Form } from 'react-final-form'

import BottoneAnnulla from './bottone_annulla'
import {
  fetchCandidato,
  addConoscenzaLinguistica,
  updateConoscenzaLinguistica,
} from '../../actions'
import TooltipConoscenzaLinguistica from './tooltip_conoscenza_linguistica'

class AddEditConoscenzaLinguistica extends Component {
  constructor(props) {
    super(props)
    this.state = { tooltip: '' }
  }

  ///////////////////////////////////////////////////////////////
  // SE EDIT E RELOAD PAGINA -> FETCH CANDIDATO
  componentDidMount() {
    // Ho già il candidato attivo se navigo normalmente
    // dal dettaglio candidato: questo controllo serve
    // nel caso in cui ricarico la pagina da qui (F5)
    if (this.props.match.params.id && !this.props.candidatoId) {
      this.props.fetchCandidato(this.props.match.params.id)
    }
  }

  ///////////////////////////////////////////////////////////////
  // SUBMIT
  onSubmit(values) {
    if (this.props.match.params.conoscenzaLinguisticaId) {
      // Siamo in UPDATE
      this.props
        .updateConoscenzaLinguistica(
          this.props.match.params.conoscenzaLinguisticaId,
          values,
          this.props.match.params.id
        )
        .then((action) => {
          // Redirect a dettagli candidato
          this.props.history.goBack()
        })
    } else {
      // Siamo in NEW
      this.props.addConoscenzaLinguistica(values, this.props.match.params.id).then((action) => {
        // Redirect a dettagli candidato
        this.props.history.goBack()
      })
    }
  }

  ///////////////////////////////////////////////
  // NEW / EDIT
  renderTitolo() {
    let titolo = ''
    if (this.props.match.params.conoscenzaLinguisticaId) {
      titolo = 'Modifica ' + this.props.fields.lingua.value
    } else {
      titolo = 'Nuova lingua'
    }
    return <h1 className="page-title pull-left">{titolo}</h1>
  }

  showTooltip(conoscenza, livello) {
    this.setState({ tooltip: TooltipConoscenzaLinguistica.getTooltip(conoscenza, livello) })
  }

  resetTooltip() {
    this.setState({ tooltip: '' })
  }

  ///////////////////////////////////////////////////////////////
  // RENDER
  render() {
    const initialValues = this.props.initialValues ? this.props.initialValues : {}

    return (
      <Form
        onSubmit={this.onSubmit.bind(this)}
        initialValues={initialValues}
        validate={validate}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit} className="add-edit-conoscenza-linguistica">
            <div className="heading">
              {this.renderTitolo()}
              <BottoneAnnulla />
            </div>

            <div className="row">
              <div className="col-md-8">
                <div className="panel panel-border panel-primary">
                  <div className="panel-heading">
                    <h3 className="panel-title">Conoscenza linguistica</h3>
                  </div>
                  <div className="panel-body">
                    <Field
                      name="lingua"
                      id="lingua"
                      render={({ input, meta }) => (
                        <div
                          className={`form-group ${
                            meta.touched && meta.invalid ? 'has-error' : ''
                          }`}
                        >
                          <label htmlFor="lingua">Lingua</label>
                          <input type="text" className="form-control" {...input} />
                          <div className="help-block">{meta.touched && meta.error}</div>
                        </div>
                      )}
                    />

                    <table className="table table-condensed">
                      <thead>
                        <tr>
                          <th>&nbsp;</th>
                          <th>A1</th>
                          <th>A2</th>
                          <th>B1</th>
                          <th>B2</th>
                          <th>C1</th>
                          <th>C2</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="tipoLivello">Comprensione ascolto</td>
                          <td>
                            <div className="radio radio-primary">
                              <Field
                                type="radio"
                                id="comprensioneAscolto_A1"
                                name="comprensioneAscolto"
                                onMouseOver={() => this.showTooltip('ComprensioneAscolto', 'A1')}
                                onMouseOut={() => this.resetTooltip()}
                                component="input"
                                value="A1"
                              />
                              <label />
                            </div>
                          </td>
                          <td>
                            <div className="radio radio-primary">
                              <Field
                                type="radio"
                                id="comprensioneAscolto_A2"
                                name="comprensioneAscolto"
                                onMouseOver={() => this.showTooltip('ComprensioneAscolto', 'A2')}
                                onMouseOut={() => this.resetTooltip()}
                                component="input"
                                value="A2"
                              />
                              <label />
                            </div>
                          </td>
                          <td>
                            <div className="radio radio-primary">
                              <Field
                                type="radio"
                                id="comprensioneAscolto_B1"
                                name="comprensioneAscolto"
                                onMouseOver={() => this.showTooltip('ComprensioneAscolto', 'B1')}
                                onMouseOut={() => this.resetTooltip()}
                                component="input"
                                value="B1"
                              />
                              <label />
                            </div>
                          </td>
                          <td>
                            <div className="radio radio-primary">
                              <Field
                                type="radio"
                                id="comprensioneAscolto_B2"
                                name="comprensioneAscolto"
                                onMouseOver={() => this.showTooltip('ComprensioneAscolto', 'B2')}
                                onMouseOut={() => this.resetTooltip()}
                                component="input"
                                value="B2"
                              />
                              <label />
                            </div>
                          </td>
                          <td>
                            <div className="radio radio-primary">
                              <Field
                                type="radio"
                                id="comprensioneAscolto_C1"
                                name="comprensioneAscolto"
                                onMouseOver={() => this.showTooltip('ComprensioneAscolto', 'C1')}
                                onMouseOut={() => this.resetTooltip()}
                                component="input"
                                value="C1"
                              />
                              <label />
                            </div>
                          </td>
                          <td>
                            <div className="radio radio-primary">
                              <Field
                                type="radio"
                                id="comprensioneAscolto_C2"
                                name="comprensioneAscolto"
                                onMouseOver={() => this.showTooltip('ComprensioneAscolto', 'C2')}
                                onMouseOut={() => this.resetTooltip()}
                                component="input"
                                value="C2"
                              />
                              <label />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="tipoLivello">Comprensione lettura</td>
                          <td>
                            <div className="radio radio-primary">
                              <Field
                                type="radio"
                                id="comprensioneLettura_A1"
                                name="comprensioneLettura"
                                onMouseOver={() => this.showTooltip('ComprensioneLettura', 'A1')}
                                onMouseOut={() => this.resetTooltip()}
                                component="input"
                                value="A1"
                              />
                              <label />
                            </div>
                          </td>
                          <td>
                            <div className="radio radio-primary">
                              <Field
                                type="radio"
                                id="comprensioneLettura_A2"
                                name="comprensioneLettura"
                                onMouseOver={() => this.showTooltip('ComprensioneLettura', 'A2')}
                                onMouseOut={() => this.resetTooltip()}
                                component="input"
                                value="A2"
                              />
                              <label />
                            </div>
                          </td>
                          <td>
                            <div className="radio radio-primary">
                              <Field
                                type="radio"
                                id="comprensioneLettura_B1"
                                name="comprensioneLettura"
                                onMouseOver={() => this.showTooltip('ComprensioneLettura', 'B1')}
                                onMouseOut={() => this.resetTooltip()}
                                component="input"
                                value="B1"
                              />
                              <label />
                            </div>
                          </td>
                          <td>
                            <div className="radio radio-primary">
                              <Field
                                type="radio"
                                id="comprensioneLettura_B2"
                                name="comprensioneLettura"
                                onMouseOver={() => this.showTooltip('ComprensioneLettura', 'B2')}
                                onMouseOut={() => this.resetTooltip()}
                                component="input"
                                value="B2"
                              />
                              <label />
                            </div>
                          </td>
                          <td>
                            <div className="radio radio-primary">
                              <Field
                                type="radio"
                                id="comprensioneLettura_C1"
                                name="comprensioneLettura"
                                onMouseOver={() => this.showTooltip('ComprensioneLettura', 'C1')}
                                onMouseOut={() => this.resetTooltip()}
                                component="input"
                                value="C1"
                              />
                              <label />
                            </div>
                          </td>
                          <td>
                            <div className="radio radio-primary">
                              <Field
                                type="radio"
                                id="comprensioneLettura_C2"
                                name="comprensioneLettura"
                                onMouseOver={() => this.showTooltip('ComprensioneLettura', 'C2')}
                                onMouseOut={() => this.resetTooltip()}
                                component="input"
                                value="C2"
                              />
                              <label />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="tipoLivello">Parlato interazione</td>
                          <td>
                            <div className="radio radio-primary">
                              <Field
                                type="radio"
                                id="parlatoInterazione_A1"
                                name="parlatoInterazione"
                                onMouseOver={() => this.showTooltip('ParlatoInterazione', 'A1')}
                                onMouseOut={() => this.resetTooltip()}
                                component="input"
                                value="A1"
                              />
                              <label />
                            </div>
                          </td>
                          <td>
                            <div className="radio radio-primary">
                              <Field
                                type="radio"
                                id="parlatoInterazione_A2"
                                name="parlatoInterazione"
                                onMouseOver={() => this.showTooltip('ParlatoInterazione', 'A2')}
                                onMouseOut={() => this.resetTooltip()}
                                component="input"
                                value="A2"
                              />
                              <label />
                            </div>
                          </td>
                          <td>
                            <div className="radio radio-primary">
                              <Field
                                type="radio"
                                id="parlatoInterazione_B1"
                                name="parlatoInterazione"
                                onMouseOver={() => this.showTooltip('ParlatoInterazione', 'B1')}
                                onMouseOut={() => this.resetTooltip()}
                                component="input"
                                value="B1"
                              />
                              <label />
                            </div>
                          </td>
                          <td>
                            <div className="radio radio-primary">
                              <Field
                                type="radio"
                                id="parlatoInterazione_B2"
                                name="parlatoInterazione"
                                onMouseOver={() => this.showTooltip('ParlatoInterazione', 'B2')}
                                onMouseOut={() => this.resetTooltip()}
                                component="input"
                                value="B2"
                              />
                              <label />
                            </div>
                          </td>
                          <td>
                            <div className="radio radio-primary">
                              <Field
                                type="radio"
                                id="parlatoInterazione_C1"
                                name="parlatoInterazione"
                                onMouseOver={() => this.showTooltip('ParlatoInterazione', 'C1')}
                                onMouseOut={() => this.resetTooltip()}
                                component="input"
                                value="C1"
                              />
                              <label />
                            </div>
                          </td>
                          <td>
                            <div className="radio radio-primary">
                              <Field
                                type="radio"
                                id="parlatoInterazione_C2"
                                name="parlatoInterazione"
                                onMouseOver={() => this.showTooltip('ParlatoInterazione', 'C2')}
                                onMouseOut={() => this.resetTooltip()}
                                component="input"
                                value="C2"
                              />
                              <label />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="tipoLivello">Parlato produzione orale</td>
                          <td>
                            <div className="radio radio-primary">
                              <Field
                                type="radio"
                                id="parlatoProduzioneOrale_A1"
                                name="parlatoProduzioneOrale"
                                onMouseOver={() => this.showTooltip('ParlatoProduzioneOrale', 'A1')}
                                onMouseOut={() => this.resetTooltip()}
                                component="input"
                                value="A1"
                              />
                              <label />
                            </div>
                          </td>
                          <td>
                            <div className="radio radio-primary">
                              <Field
                                type="radio"
                                id="parlatoProduzioneOrale_A2"
                                name="parlatoProduzioneOrale"
                                onMouseOver={() => this.showTooltip('ParlatoProduzioneOrale', 'A2')}
                                onMouseOut={() => this.resetTooltip()}
                                component="input"
                                value="A2"
                              />
                              <label />
                            </div>
                          </td>
                          <td>
                            <div className="radio radio-primary">
                              <Field
                                type="radio"
                                id="parlatoProduzioneOrale_B1"
                                name="parlatoProduzioneOrale"
                                onMouseOver={() => this.showTooltip('ParlatoProduzioneOrale', 'B1')}
                                onMouseOut={() => this.resetTooltip()}
                                component="input"
                                value="B1"
                              />
                              <label />
                            </div>
                          </td>
                          <td>
                            <div className="radio radio-primary">
                              <Field
                                type="radio"
                                id="parlatoProduzioneOrale_B2"
                                name="parlatoProduzioneOrale"
                                onMouseOver={() => this.showTooltip('ParlatoProduzioneOrale', 'B2')}
                                onMouseOut={() => this.resetTooltip()}
                                component="input"
                                value="B2"
                              />
                              <label />
                            </div>
                          </td>
                          <td>
                            <div className="radio radio-primary">
                              <Field
                                type="radio"
                                id="parlatoProduzioneOrale_C1"
                                name="parlatoProduzioneOrale"
                                onMouseOver={() => this.showTooltip('ParlatoProduzioneOrale', 'C1')}
                                onMouseOut={() => this.resetTooltip()}
                                component="input"
                                value="C1"
                              />
                              <label />
                            </div>
                          </td>
                          <td>
                            <div className="radio radio-primary">
                              <Field
                                type="radio"
                                id="parlatoProduzioneOrale_C2"
                                name="parlatoProduzioneOrale"
                                onMouseOver={() => this.showTooltip('ParlatoProduzioneOrale', 'C2')}
                                onMouseOut={() => this.resetTooltip()}
                                component="input"
                                value="C2"
                              />
                              <label />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="tipoLivello">Produzione scritta</td>
                          <td>
                            <div className="radio radio-primary">
                              <Field
                                type="radio"
                                id="produzioneScritta_A1"
                                name="produzioneScritta"
                                onMouseOver={() => this.showTooltip('ProduzioneScritta', 'A1')}
                                onMouseOut={() => this.resetTooltip()}
                                component="input"
                                value="A1"
                              />
                              <label />
                            </div>
                          </td>
                          <td>
                            <div className="radio radio-primary">
                              <Field
                                type="radio"
                                id="produzioneScritta_A2"
                                name="produzioneScritta"
                                onMouseOver={() => this.showTooltip('ProduzioneScritta', 'A2')}
                                onMouseOut={() => this.resetTooltip()}
                                component="input"
                                value="A2"
                              />
                              <label />
                            </div>
                          </td>
                          <td>
                            <div className="radio radio-primary">
                              <Field
                                type="radio"
                                id="produzioneScritta_B1"
                                name="produzioneScritta"
                                onMouseOver={() => this.showTooltip('ProduzioneScritta', 'B1')}
                                onMouseOut={() => this.resetTooltip()}
                                component="input"
                                value="B1"
                              />
                              <label />
                            </div>
                          </td>
                          <td>
                            <div className="radio radio-primary">
                              <Field
                                type="radio"
                                id="produzioneScritta_B2"
                                name="produzioneScritta"
                                onMouseOver={() => this.showTooltip('ProduzioneScritta', 'B2')}
                                onMouseOut={() => this.resetTooltip()}
                                component="input"
                                value="B2"
                              />
                              <label />
                            </div>
                          </td>
                          <td>
                            <div className="radio radio-primary">
                              <Field
                                type="radio"
                                id="produzioneScritta_C1"
                                name="produzioneScritta"
                                onMouseOver={() => this.showTooltip('ProduzioneScritta', 'C1')}
                                onMouseOut={() => this.resetTooltip()}
                                component="input"
                                value="C1"
                              />
                              <label />
                            </div>
                          </td>
                          <td>
                            <div className="radio radio-primary">
                              <Field
                                type="radio"
                                id="produzioneScritta_C2"
                                name="produzioneScritta"
                                onMouseOver={() => this.showTooltip('ProduzioneScritta', 'C2')}
                                onMouseOut={() => this.resetTooltip()}
                                component="input"
                                value="C2"
                              />
                              <label />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <div className="tooltip-livello">{this.state.tooltip}</div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="panel panel-border panel-primary">
                  <div className="panel-heading">
                    <h3 className="panel-title">Eventuali certificazioni</h3>
                  </div>
                  <div className="panel-body">
                    <div className="form-group">
                      <Field
                        component="textarea"
                        className="form-control"
                        rows="6"
                        id="eventualiCertificazioni"
                        name="eventualiCertificazioni"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-center">
              <button type="submit" className="btn btn-primary btn-lg">
                Salva conoscenza linguistica &raquo;
              </button>
            </div>
          </form>
        )}
      />
    )
  }
}

function validate(values) {
  const errors = {}

  if (!values.lingua) {
    errors.lingua = 'Indica il nome della lingua'
  }

  return errors
}

const mapStateToProps = (state, props) => {
  // Sono in edit, devo inizializzare il form
  if (props.match.params.conoscenzaLinguisticaId) {
    return {
      initialValues: _find(state.candidatoAttivo.conoscenzeLinguistiche, {
        conoscenzaLinguisticaId: parseInt(props.match.params.conoscenzaLinguisticaId),
      }),
      candidatoId: state.candidatoAttivo.candidatoId, // per sapere se ho già il candidato attivo su F5
    }
  }
  return {}
}

// connect: 1st argument mapStateToProps, 2nd mapDispatchToProps
// reduxForm: 1st form config, 2nd mapStateToProps, 3rd mapDipatchToProps
export default connect(mapStateToProps, {
  fetchCandidato,
  addConoscenzaLinguistica,
  updateConoscenzaLinguistica,
})(AddEditConoscenzaLinguistica)
