import React from 'react';
import moment from 'moment';

import './footer.less';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
      	<div className="row">
      		<div className="col-sm-6">
      			Sportello Lavoro CISL &copy; {moment().format('YYYY')}
      		</div>
      		<div className="col-sm-6 credits">
      			Realizzato da <a href="http://www.f2.net" target="_blank">F2.net</a>
      		</div>
      	</div>
      </div>
    </footer>
  );
}

export default Footer;
