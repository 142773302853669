import { FETCH_SPORTELLI, DELETE_SPORTELLO } from '../actions/types'

const INITIAL_STATE = []

export default function reducerSportelli(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_SPORTELLI:
      return action.payload.data
    case DELETE_SPORTELLO:
      return state.filter((sportello) => sportello.sportelloId !== action.payload)
    default:
      return state
  }
}

// SELECTOR
export function getSportelliAreaGeografica(globalState) {
  if (globalState.form.SportelloForm) {
    const areaGeograficaId = parseInt(globalState.form.SportelloForm.areaGeograficaId.value)
    return globalState.sportelli.filter((s) => s.areaGeograficaId === areaGeograficaId)
  }
  return []
}

export function getTotaleSmsAssegnati(globalState) {
  const sportelli = globalState.sportelli
  const totaleSmsAssegnati = sportelli.reduce((totale, sportello) => totale + sportello.creditiSms, 0)
  return totaleSmsAssegnati
}
