import { SEND_CONTATTO_APP, RESET_STATO_CONTATTO_APP } from '../actions/types';

const INITIAL_STATE = {
  inviato: false
};

export default function(state = INITIAL_STATE, action) {
	switch(action.type) {
		case SEND_CONTATTO_APP:
			return {
        ...state,
        inviato: true
      };
    case RESET_STATO_CONTATTO_APP:
			return {
        ...state,
        inviato: false
      };
		default:
			return state;
	}
}
