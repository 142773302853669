import React from 'react'
import { Route, Switch } from 'react-router'
// import Appuntamenti from '../appuntamenti/appuntamenti'
import CalendarioAppuntamenti from '../appuntamenti/calendario'
import SlotAppuntamenti from '../appuntamenti/slot_appuntamenti'
import RequireAuth from '../auth/require_auth'

const Appuntamenti = (props) => {
  return (
    <div>
      <Switch>
        <Route exact path="/app/appuntamenti" component={CalendarioAppuntamenti} />
        <Route
          exact
          path="/app/appuntamenti/new"
          component={RequireAuth(['operatore'])(SlotAppuntamenti)}
        />
        <Route
          exact
          path="/app/appuntamenti/:slotId"
          component={RequireAuth(['operatore'])(SlotAppuntamenti)}
        />
      </Switch>
    </div>
  )
}

export default Appuntamenti
