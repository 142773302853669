import React, { Component } from 'react'
import { Form, Field } from 'react-final-form'
import { connect } from 'react-redux'

import { fetchAreaGeografica, createAreaGeografica, saveAreaGeografica } from '../../../actions'

class CreateEditAreaGeografica extends Component {
  componentDidMount() {
    if (this.props.match.params.areaGeograficaId) {
      this.props.fetchAreaGeografica(this.props.match.params.areaGeograficaId)
    }
  }

  onSubmit(values) {
    if (this.props.match.params.areaGeograficaId) {
      // Siamo in update
      const {areaGeograficaId, ...rest} = values
      this.props.saveAreaGeografica(rest, this.props.match.params.areaGeograficaId).then((action) => {
        this.props.history.push(`/app/regioni`)
      })
    } else {
      // Siamo in new
      this.props.createAreaGeografica(values).then((action) => {
        this.props.history.push(`/app/regioni`)
      })
    }
  }

  ///////////////////////////////////////////////////////////////
  // RENDER
  render() {
    const initialValues = this.props.initialValues ? this.props.initialValues : {}

    return (
      <div className="edit-operatore">
        <h1 className="page-title">Regione</h1>

        <div className="card-box">
          <Form
            onSubmit={this.onSubmit.bind(this)}
            initialValues={initialValues}
            validate={validate}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <Field
                      name="nome"
                      id="nome"
                      render={({ input, meta }) => (
                        <div className={`form-group ${meta.touched && meta.invalid ? 'has-error' : ''}`}>
                          <label htmlFor="nome">Nome</label>
                          <input type="text" className="form-control" {...input} />
                          <div className="help-block">{meta.touched && meta.error}</div>
                        </div>
                      )}
                    />

                    <div className="form-group">
                      <label htmlFor="regione">Regione</label>
                      <Field
                        name="regione"
                        id="regione"
                        render={({ input }) => (
                          <select className="form-control" {...input}>
                            <option value="Abruzzo">Abruzzo</option>
                            <option value="Basilicata">Basilicata</option>
                            <option value="Calabria">Calabria</option>
                            <option value="Campania">Campania</option>
                            <option value="Emilia-Romagna">Emilia-Romagna</option>
                            <option value="Friuli-Venezia Giulia">Friuli-Venezia Giulia</option>
                            <option value="Lazio">Lazio</option>
                            <option value="Liguria">Liguria</option>
                            <option value="Lombardia">Lombardia</option>
                            <option value="Marche">Marche</option>
                            <option value="Molise">Molise</option>
                            <option value="Piemonte">Piemonte</option>
                            <option value="Puglia">Puglia</option>
                            <option value="Sardegna">Sardegna</option>
                            <option value="Sicilia">Sicilia</option>
                            <option value="Toscana">Toscana</option>
                            <option value="Trentino-Alto Adige">Trentino-Alto Adige</option>
                            <option value="Umbria">Umbria</option>
                            <option value="Valle d'Aosta">Valle d'Aosta</option>
                            <option value="Veneto">Veneto</option>
                          </select>
                        )}
                      />
                    </div>
                    <label>Gestione permessi</label>
                    <div className="form-group">
                      <div className="checkbox checkbox-primary">
                        <Field type="checkbox" id="chiusaFlag" name="chiusaFlag" component="input" />
                        <label htmlFor="chiusaFlag">Regione chiusa</label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="checkbox checkbox-primary">
                        <Field
                          type="checkbox"
                          id="noConfermaSpostamentoFlag"
                          name="noConfermaSpostamentoFlag"
                          component="input"
                        />
                        <label htmlFor="noConfermaSpostamentoFlag">
                          Evita conferma spostamento candidati tra sportelli della regione
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <button type="submit" className="btn btn-primary btn-salva">
                  Salva regione
                </button>
              </form>
            )}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    initialValues: state.areaGeograficaAttiva,
  }
}

function validate(values) {
  const errors = {}

  if (!values.nome) {
    errors.nome = 'Inserisci il nome'
  }

  return errors
}

export default connect(mapStateToProps, {
  fetchAreaGeografica,
  createAreaGeografica,
  saveAreaGeografica,
})(CreateEditAreaGeografica)
