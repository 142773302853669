import moment from 'moment';
import { FETCH_SLOTS } from '../actions/types';

const INITIAL_STATE = [];

const formatEvents = (appuntamenti) => appuntamenti.map((appuntamento) => {
  return {
    ...appuntamento,
    start: moment(appuntamento.dataOraInizio).toDate(),
    end: moment(appuntamento.dataOraFine).toDate(),
    title: appuntamento.descrizione
  }
});

export default function(state = INITIAL_STATE, action) {
	switch(action.type) {
		case FETCH_SLOTS:
			return formatEvents(action.payload.data);
		default:
			return state;
	}
}
