import {
  TOGGLE_MOBILE_MENU_OPEN
} from '../actions/types';

const initialState = {
  mobileMenuOpen: false
}

export default function (state = initialState, action) {
  switch(action.type) {
    case TOGGLE_MOBILE_MENU_OPEN:
      return { mobileMenuOpen: !state.mobileMenuOpen };
  }

  return state;
}
