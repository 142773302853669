import { FETCH_SPORTELLO, RESET_SPORTELLO_ATTIVO } from '../actions/types'

const INITIAL_STATE = {}

export default function reducerSportelloAttivo(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_SPORTELLO:
      return action.payload.data || state
    case RESET_SPORTELLO_ATTIVO:
      return {}
    default:
      return state
  }
}
