import React, { Component } from 'react'
import { connect } from 'react-redux'
import { MdAdd } from 'react-icons/md'
import { Link } from 'react-router-dom'
import swal from 'sweetalert'

import { fetchSubAmministratori, deleteSubAmministratore, resetSubAmministratoreAttivo } from '../../../actions'

class SubAmministratoriList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      amministratoreId: 0,
    }

    this.props.fetchSubAmministratori()
  }

  componentDidMount() {
    this.props.resetSubAmministratoreAttivo()
  }

  onRemove() {
    this.props.deleteSubAmministratore(this.state.amministratoreId)
  }

  renderSubAmministratori() {
    const { subAmministratori } = this.props

    if (subAmministratori) {
      return subAmministratori.map((subAmministratore) => (
        <tr key={subAmministratore.amministratoreId}>
          <td>
            <Link to={'/app/sub-amministratori/' + subAmministratore.amministratoreId}>
              {subAmministratore.nome} {subAmministratore.cognome}
            </Link>
          </td>
          <td>{subAmministratore.areaGeograficaNome}</td>
          <td>
            <a href={`mailto:${subAmministratore.email}`}>{subAmministratore.email}</a>
          </td>
          <td>
            <button
              className="btn btn-sm btn-danger"
              onClick={() => {
                this.setState({
                  amministratoreId: subAmministratore.amministratoreId,
                })
                this.handleDeleteSubAmministratore()
              }}
            >
              &times; Elimina
            </button>
          </td>
        </tr>
      ))
    }
    return null
  }

  handleDeleteSubAmministratore = () => {
    swal({
      icon: 'warning',
      title: 'Eliminazione sub-amministratore',
      text: 'Sei sicuro di vole eliminare questo sub-amministratore? Questa azione è irreversibile.',
      buttons: ['Annulla', 'Si, elimina sub-amministratore'],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onRemove()
      }
    })
  }

  render() {
    return (
      <div className="subamministratori-list">
        <h1 className="page-title">Sub-Amministratori</h1>
        <div className="card-box">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Regione</th>
                  <th>Email</th>
                  <th>Elimina</th>
                </tr>
              </thead>
              <tbody>
                {this.props.subAmministratori ? (
                  this.renderSubAmministratori()
                ) : (
                  <tr>
                    <td>Nessun sub-amministratore presente</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <Link to="/app/sub-amministratori/new" className="btn btn-success">
            <div className="create-center">
              <MdAdd />
              Crea nuovo Sub-Amministratore
            </div>
          </Link>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    subAmministratori: state.subAmministratori,
  }
}

export default connect(mapStateToProps, {
  fetchSubAmministratori,
  deleteSubAmministratore,
  resetSubAmministratoreAttivo,
})(SubAmministratoriList)
