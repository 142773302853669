import React, { Component } from 'react'
import { connect } from 'react-redux'

import { setNoteAgenzia, saveNoteAgenzia } from '../../actions'

class NoteAgenzia extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  handleSetNoteAgenzia = event => {
    this.props.setNoteAgenzia(event.target.value)
  }

  handleSaveNoteAgenzia = event => {
    this.props.saveNoteAgenzia(this.props.candidatoId, this.props.noteAgenzia)
  }

  render() {
    const { noteAgenzia } = this.props

    return (
      <div className="note-agenzia">
        <div className="form-group">
          <textarea className="form-control" rows="6" value={noteAgenzia} onChange={this.handleSetNoteAgenzia} />
        </div>

        <button className="btn btn-sm btn-primary" onClick={this.handleSaveNoteAgenzia}>
          Salva note
        </button>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    candidatoId: state.candidatoAttivo.candidatoId,
    noteAgenzia: state.candidatoAttivo.noteAgenzia,
  }
}

export default connect(
  mapStateToProps,
  { setNoteAgenzia, saveNoteAgenzia },
)(NoteAgenzia)
