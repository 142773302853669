import {
  FETCH_JOB_VACANCIES,
  FETCH_JOB_VACANCIES_AZIENDA,
  FETCH_JOB_VACANCIES_AGENZIA,
  DELETE_JOB_VACANCY,
  SET_KEYWORDS_JOB_VACANCIES,
  SET_PROVINCIA_JOB_VACANCIES,
  SET_SOLO_PROPRIETARIO_FLAG_JV,
  SET_MOSTRA_JV_APERTE,
  CHANGE_PAGE_JOB_VACANCIES,
  CREATE_CANDIDATURA,
  DELETE_CANDIDATURA,
} from '../actions/types'

const INITIAL_STATE = {
  data: [],
  pagination: {
    page: 1,
    totalPages: 1,
  },
  provincia: '',
  keywords: '',
  soloProprietarioFlag: true,
  mostraJvAperte: false,
  pageRequested: 1,
  ragioneSociale: '',
}

export default function reducerJobVacancy(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_JOB_VACANCIES:
      return {
        ...state,
        data: action.payload.data.items,
        pagination: action.payload.data.pagination,
      }
    case FETCH_JOB_VACANCIES_AZIENDA:
      return {
        ...state,
        data: action.payload.data.items,
        pagination: action.payload.data.pagination,
        ragioneSociale: action.payload.data.ragioneSociale,
      }
    case FETCH_JOB_VACANCIES_AGENZIA:
      return {
        ...state,
        data: action.payload.data.items,
        pagination: action.payload.data.pagination,
        ragioneSociale: action.payload.data.ragioneSociale,
      }
    case DELETE_JOB_VACANCY:
      return {
        ...state,
        data: state.data.filter((jobVacancy) => jobVacancy.jobVacancyId !== action.payload),
      }
    case SET_KEYWORDS_JOB_VACANCIES:
      return {
        ...state,
        keywords: action.payload,
      }
    case SET_PROVINCIA_JOB_VACANCIES:
      return {
        ...state,
        provincia: action.payload,
      }
    case SET_SOLO_PROPRIETARIO_FLAG_JV:
      return {
        ...state,
        soloProprietarioFlag: action.payload,
      }
    case SET_MOSTRA_JV_APERTE:
      return {
        ...state,
        mostraJvAperte: action.payload,
      }
    case CHANGE_PAGE_JOB_VACANCIES:
      return {
        ...state,
        pageRequested: action.payload,
      }
    case CREATE_CANDIDATURA:
      return {
        ...state,
        data: state.data.map((jv) => {
          if (jv.jobVacancyId === action.payload.data.jobVacancyId) {
            return {
              ...jv,
              interessato: true,
            }
          }
          return jv
        }),
      }
    case DELETE_CANDIDATURA:
      return {
        ...state,
        data: state.data.map((jv) => {
          if (jv.jobVacancyId === action.payload) {
            return {
              ...jv,
              interessato: false,
            }
          }
          return jv
        }),
      }
    default:
      return state
  }
}
