import { FETCH_MOVIMENTI_CREDITI_AGENZIE } from '../actions/types'

const INITIAL_STATE = []

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_MOVIMENTI_CREDITI_AGENZIE:
      return action.payload.data || []
    default:
      return state
  }
}
