import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { RIETextArea } from 'riek'
import { saveNoteAgenziaDaOperatore } from '../../actions'
import './note_agenzie.less'

class NoteAgenzie extends Component {
  handleChangeNota = movimentoCreditiAgenziaId => ({ note }) => {
    this.props.saveNoteAgenziaDaOperatore(movimentoCreditiAgenziaId, note)
  }

  handleDeleteNota = movimentoCreditiAgenziaId => event => {
    event.preventDefault()
    this.props.saveNoteAgenziaDaOperatore(movimentoCreditiAgenziaId, '')
  }

  render() {
    const { note, canEdit } = this.props

    return (
      <div className="note-agenzie">
        <table className="table">
          <thead>
            <tr>
              <th>Agenzia</th>
              <th>Data acquisizione</th>
              <th>Note</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {note.map(nota => (
              <tr key={nota.movimentoCreditiAgenziaId}>
                <td>{nota.agenziaNome}</td>
                <td>{moment(nota.data).format('DD/MM/YYYY')}</td>
                <td>
                  <RIETextArea
                    value={nota.note || (!!canEdit ? 'Inserisci una nota' : '')}
                    change={this.handleChangeNota(nota.movimentoCreditiAgenziaId)}
                    propName="note"
                    classEditing="form-control"
                    classLoading="loading"
                    className={!!nota.note ? '' : 'inserisci-nota'}
                    isDisabled={!canEdit}
                  />
                </td>
                <td>
                  {!!nota.note && (
                    <a className="delete-nota" href="#" onClick={this.handleDeleteNota(nota.movimentoCreditiAgenziaId)}>
                      &times;
                    </a>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }
}

export default connect(
  null,
  { saveNoteAgenziaDaOperatore },
)(NoteAgenzie)
