import React, { Component } from 'react';
import { connect } from 'react-redux';

import CompletezzaCvItem from './completezza_cv_item';

// Selector
import { isAnagraficaCompleta,
  isStatoOccupazionaleCompleto, 
  isEsperienzeLavorativeCompleto,
  isTitoloDiStudioCompleto, 
  isAltreCompetenzeCompleto, 
  isObiettiviProfessionaliCompleto, 
  isDisponibilitaContrattoCompleto, 
  isDisponibilitaGeograficaCompleto 
} from '../../reducers/reducer_candidato_attivo';

import './completezza_cv.less';

class CompletezzaCv extends Component {

  render() {

    const { AnagraficaCompleta, StatoOccupazionaleCompleto, EsperienzeLavorativeCompleto, TitoloDiStudioCompleto, AltreCompetenzeCompleto, ObiettiviProfessionaliCompleto, DisponibilitaContrattoCompleto, DisponibilitaGeograficaCompleto } = this.props;

    return (
      <div className="card-box completezza-cv">
        <h4 className="header-title">Stato completamento CV</h4>

        <div className="row">
          <div className="col-sm-6 col-md-3">
            <CompletezzaCvItem 
              name="Anagrafica e CV sintetico"
              isCompleto={AnagraficaCompleta}
              anchorName="anagrafica"
            />
          </div>
          <div className="col-sm-6 col-md-3">
            <CompletezzaCvItem
              name="Stato occupazionale"
              isCompleto={StatoOccupazionaleCompleto}
              anchorName="stato-occupazionale"
            />
          </div>
          <div className="col-sm-6 col-md-3">
            <CompletezzaCvItem
              name="Esperienze lavorative"
              isCompleto={EsperienzeLavorativeCompleto}
              anchorName="esperienze-lavorative"
            />
          </div>
          <div className="col-sm-6 col-md-3">
            <CompletezzaCvItem 
              name="Titolo di studio"
              isCompleto={TitoloDiStudioCompleto}
              anchorName="titoli-di-studio"
            />
          </div>
          <div className="col-sm-6 col-md-3">
            <CompletezzaCvItem
              name="Altre competenze"
              isCompleto={AltreCompetenzeCompleto}
              anchorName="altre-competenze"
            />
          </div>
          <div className="col-sm-6 col-md-3">
            <CompletezzaCvItem
              name="Obiettivi professionali"
              isCompleto={ObiettiviProfessionaliCompleto}
              anchorName="obiettivi-professionali"
            />
          </div>
          <div className="col-sm-6 col-md-3">
            <CompletezzaCvItem
              name="Disponibilità contratto"
              isCompleto={DisponibilitaContrattoCompleto}
              anchorName="disponibilita-contratto"
            />
          </div>
          <div className="col-sm-6 col-md-3">
            <CompletezzaCvItem
              name="Disponibilità geografica"
              isCompleto={DisponibilitaGeograficaCompleto}
              anchorName="disponibilita-geografica"
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    AnagraficaCompleta: isAnagraficaCompleta(state),
    StatoOccupazionaleCompleto: isStatoOccupazionaleCompleto(state),
    EsperienzeLavorativeCompleto: isEsperienzeLavorativeCompleto(state),
		TitoloDiStudioCompleto: isTitoloDiStudioCompleto(state),
		AltreCompetenzeCompleto: isAltreCompetenzeCompleto(state),
		ObiettiviProfessionaliCompleto: isObiettiviProfessionaliCompleto(state),
		DisponibilitaContrattoCompleto: isDisponibilitaContrattoCompleto(state),
		DisponibilitaGeograficaCompleto: isDisponibilitaGeograficaCompleto(state)
  }
}

export default connect(mapStateToProps)(CompletezzaCv);
