import React, { Component } from 'react'
import AsyncSelect from 'react-select/async'
import _find from 'lodash/find'
import { connect } from 'react-redux'
import { Form, Field } from 'react-final-form'

import {
  fetchGruppo,
  saveGruppo,
  addCandidatoGruppo,
  removeCandidatoGruppo,
  fetchTypeaheadCandidati,
} from '../../actions'

class Gruppo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      candidato: {
        candidatoId: null,
        nomeCognome: null,
      },
    }
    this.props.fetchGruppo(this.props.match.params.gruppoId)
  }

  ///////////////////////////////////////////////////////////////
  // SUBMIT
  onSubmit(values) {
    const { gruppoCvSinteticoId, sportelloId, dataCreazione, candidati, ...rest } = values
    this.props.saveGruppo(rest, this.props.match.params.gruppoId).then((action) => {
      // Redirect a elenco gruppi
      this.props.history.push(`/app/gruppi`)
    })
  }

  ///////////////////////////////////////////////
  // GESTIONE AUTOCOMPLETE CANDIDATI
  getCandidatiOptions = (candidati) => {
    return candidati.map((candidato) => {
      return {
        value: candidato.candidatoId,
        label: candidato.nomeCognome,
        fotografia: candidato.fotografia,
      }
    })
  }

  getCandidati = (input) => {
    return this.props.fetchTypeaheadCandidati(input).then((action) => {
      return this.getCandidatiOptions(action.payload.data)
    })
  }

  onChangeCandidato(option) {
    this.setState({
      candidato: {
        candidatoId: option ? option.value : null,
        nomeCognome: option ? option.label : null,
      },
    })
  }

  clearCandidato() {
    this.setState({
      candidato: {
        candidatoId: null,
        nomeCognome: null,
      },
    })
  }

  addCandidatoGruppo() {
    if (
      this.state.candidato.candidatoId &&
      !_find(this.props.candidati, { candidatoId: this.state.candidato.candidatoId })
    ) {
      this.props.addCandidatoGruppo(
        this.props.match.params.gruppoId,
        this.state.candidato.candidatoId
      )
    }
    this.clearCandidato()
  }

  renderOption(option) {
    return (
      <span>
        <img className="avatar" src={option.fotografia} alt="avatar" />
        {option.label}
      </span>
    )
  }

  ///////////////////////////////////////////////////////////////
  // RENDER
  render() {
    const initialValues = this.props.initialValues ? this.props.initialValues : {}
    return (
      <Form
        onSubmit={this.onSubmit.bind(this)}
        initialValues={initialValues}
        render={({ handleSubmit, form }) => (
          <div className="gruppo">
            <h1 className="page-title">Gruppo {form.getFieldState('nome')?.value}</h1>

            <div className="card-box">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="nome">Nome gruppo</label>
                  <Field
                    type="text"
                    className="form-control"
                    id="nome"
                    name="nome"
                    component="input"
                  />
                </div>
                <button
                  type="submit"
                  disabled={!form.getFieldState('nome')?.value}
                  className="btn btn-primary"
                >
                  Salva gruppo
                </button>
              </form>

              <div style={{ marginTop: '40px' }}>
                <div className="row">
                  <div className="col-md-6">
                    <h4>Candidati</h4>
                    {this.props.candidati && this.props.candidati.length > 0 ? (
                      <ul className="candidati">
                        {this.props.candidati.map((candidato) => (
                          <li key={candidato.candidatoId}>
                            <img
                              className="avatar"
                              src={candidato.fotografia}
                              alt="fotografia-candidato"
                            />
                            {candidato.nomeCognome}
                            <a
                              className="remove"
                              href="/#"
                              onClick={(event) => {
                                event.preventDefault()
                                this.props.removeCandidatoGruppo(
                                  this.props.match.params.gruppoId,
                                  candidato.candidatoId
                                )
                              }}
                            >
                              &times;
                            </a>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p>Nessun candidato assegnato al gruppo</p>
                    )}
                  </div>
                  <div className="col-md-6">
                    <div>
                      <h4>Aggiungi candidato al gruppo</h4>
                      <div className="form-group">
                        <AsyncSelect
                          name="candidato"
                          loadOptions={this.getCandidati}
                          placeholder="Seleziona..."
                          loadingPlaceholder="Caricamento..."
                          searchingText="Caricamento..."
                          noResultsText="Nessun risultato"
                          value={{
                            value: this.state.candidato.candidatoId,
                            label: this.state.candidato.nomeCognome,
                          }}
                          optionRenderer={this.renderOption}
                          onChange={this.onChangeCandidato.bind(this)}
                        />
                      </div>
                      <button
                        disabled={!this.state.candidato.candidatoId}
                        className="btn btn-success"
                        onClick={this.addCandidatoGruppo.bind(this)}
                      >
                        Aggiungi candidato
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    initialValues: state.gruppoAttivo,
    candidati: state.gruppoAttivo.candidati,
  }
}

export default connect(mapStateToProps, {
  fetchGruppo,
  saveGruppo,
  addCandidatoGruppo,
  removeCandidatoGruppo,
  fetchTypeaheadCandidati,
})(Gruppo)
