import React from 'react'
import { Route, Switch } from 'react-router'
import NewsletterDetails from './newsletter_details'
import NewsletterList from './newsletter_list'

const GestioneNewsletters = (props) => {
  return (
    <div>
      <Switch>
        <Route exact path="/app/newsletter" component={NewsletterList} />
        <Route exact path="/app/newsletter/new" component={NewsletterDetails} />
        <Route exact path="/app/newsletter/:newsletterId" component={NewsletterDetails} />
      </Switch>
    </div>
  )
}

export default GestioneNewsletters
