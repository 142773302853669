import {
  CAMBIA_PAGINA_CORSI_FORMAZIONE,
  DELETE_CORSO_FORMAZIONE,
  FETCH_CORSI_FORMAZIONE,
  FETCH_CORSI_FORMAZIONE_CANDIDATO,
  SAVE_CORSO_FORMAZIONE,
  SET_KEYWORDS_CORSI_FORMAZIONE,
  SUBSCRIBE_CORSO,
} from '../actions/types'

const INITIAL_STATE = {
  data: [],
  pagination: {
    page: 1,
    totalPages: 1,
  },
  pageRequested: 1,
  keywords: '',
}

const reducerCorsiFormazione = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CAMBIA_PAGINA_CORSI_FORMAZIONE:
      return {
        ...state,
        pageRequested: action.payload,
      }
    case SET_KEYWORDS_CORSI_FORMAZIONE:
      return {
        ...state,
        keywords: action.payload,
      }
    case FETCH_CORSI_FORMAZIONE_CANDIDATO:
      return {
        ...state,
        data: action.payload,
      }
    case FETCH_CORSI_FORMAZIONE:
      return {
        ...state,
        data: action.payload.data,
      }
    case SAVE_CORSO_FORMAZIONE:
      return {
        ...state,
        data: state.data.map((corso) => {
          if (corso.corsoFormazionePropostoId === action.payload.data.corsoFormazionePropostoId) {
            return {
              ...corso,
              ...action.payload.data,
            }
          }
          return corso
        }),
      }
    case SUBSCRIBE_CORSO:
      return {
        ...state,
        data: state.data.map((corso) => {
          if (corso.corsoFormazionePropostoId === action.payload.data.corsoFormazionePropostoId) {
            return {
              ...corso,
              interessato: true,
            }
          }
          return corso
        }),
      }
    case DELETE_CORSO_FORMAZIONE:
      return {
        ...state,
        data: state.data.filter((corso) => corso.corsoFormazionePropostoId !== action.payload),
      }
    default:
      return state
  }
}
export default reducerCorsiFormazione
