import React, { Component } from 'react'

import './paragrafo.less'

class Paragrafo extends Component {
  handleChange = event => {
    this.props.onChange(this.props.paragrafo.paragrafoId, event.target.name, event.target.value)
  }

  handleDelete = paragrafoId => event => {
    event.preventDefault()
    this.props.onDelete(paragrafoId)
  }

  render() {
    const { paragrafo } = this.props

    return (
      <div className="newsletter-paragrafo">

        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="titolo">Titolo</label>
              <input id="titolo" name="titolo" type="text" className="form-control" value={paragrafo.titolo} onChange={this.handleChange} />
            </div>
            <div className="form-group">
              <label htmlFor="testo">Testo</label>
              <textarea id="testo" name="testo" className="form-control" rows="8" value={paragrafo.testo} onChange={this.handleChange} />
            </div>
          </div>
          <div className="col-sm-6 text-right">
            <button className="btn btn-danger" onClick={this.handleDelete(paragrafo.paragrafoId)}>
              &times; Elimina
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default Paragrafo
