import { FETCH_SPORTELLI_PER_AREA } from '../actions/types'

const INITIAL_STATE = []

export default function reducerSportelliPerArea(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_SPORTELLI_PER_AREA:
      return action.payload.data
    default:
      return state
  }
}
