import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import ReactGA from 'react-ga'
import LoadingBar from 'react-redux-loading-bar'
import { BrowserRouter as Router } from 'react-router-dom'
import thunk from 'redux-thunk'
//import promise from 'redux-promise';
import { createLogger } from 'redux-logger'

import promiseMiddleware from './middleware/promiseMiddleware'
import reducers from './reducers'
import { AUTH_USER } from './actions/types'

import config from './config'
import App from './components/app/app'
import moment from 'moment'

// attivazione Sentry.io
// Raven.config('https://89bd2968f1084403bd912439bbfd57ba@sentry.io/105962').install();

// Meglio farlo solo se DEV (usare proprietà predicate)
const logger = createLogger({ collapsed: true })

//const createStoreWithMiddleware = applyMiddleware(thunk, promiseMiddleware, logger)(createStore);
//const createStoreWithMiddleware = applyMiddleware(thunk, promise)(createStore);
const store = createStore(reducers, applyMiddleware(thunk, promiseMiddleware, logger))

// Se ho un utente in localStorage, lo considero autenticato
// Su WillMount di App la fetchUser confermerà o meno la validità del token
const user = JSON.parse(localStorage.getItem('user'))
if (user) {
  store.dispatch({ type: AUTH_USER, payload: user })
}

// Inizializzazione Google Analytics
ReactGA.initialize(config.gaTrackingCode)

// Log PageView su Google Analytics
function logPageView() {
  ReactGA.set({ page: window.location.pathname })
  ReactGA.pageview(window.location.pathname)
}

moment.locale('it')

ReactDOM.render(
  <Provider store={store}>
    <div>
      <LoadingBar style={{ backgroundColor: '#60f545', zIndex: 2000 }} />
      <Router>
        <App />
      </Router>
    </div>
  </Provider>,
  document.querySelector('#root')
)
