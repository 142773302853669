import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, Switch, withRouter } from 'react-router-dom'
import { fetchUser } from '../../actions'
import GestioneAgenzie from '../agenzie/gestione_agenzie'
import AreeGeografiche from '../amministratore/aree_geografiche'
import CreditiSmsConsumati from '../amministratore/crediti_sms_consumati/crediti_sms_consumati'
import Operatori from '../amministratore/operatori'
import Sportelli from '../amministratore/sportelli'
import SubAmministratori from '../amministratore/subamministratori'
import Appuntamenti from '../appuntamenti/appuntamenti'
import CambioPassword from '../auth/cambio_password'
import RequireAuth from '../auth/require_auth'
import GestioneAziende from '../aziende/gestione_aziende'
import Candidati from '../candidati/candidati'
import CandidatiStatistiche from '../candidati/candidati_statistiche'
import CorsiFormazione from '../corsi_formazione/index'
import ContattoApp from '../contatto_app/contatto_app'
import Gruppi from '../gruppi/gruppi'
import Home from '../home/home'
import GestioneJobVacancies from '../job_vacancies/gestione_job_vacancies'
import InvioMessaggio from '../messaggio/invio_messaggio'
import Movimenti from '../movimenti/movimenti'
import GestioneNewsletters from '../newsletter/gestione_newsletters'
import Notifiche from '../notifiche/notifiche'

class AppLogged extends Component {
  componentDidMount() {
    // Let's try to get the user from the server
    // If I have a valid token, then I am already
    // logged even if I hit F5...
    this.props.fetchUser(this.props.history)
  }

  render() {
    return (
      <div className="container main-container">
        <Switch>
          <Route exact path="/app" component={Home} />
          <Route path="/app/cambio-password" component={CambioPassword} />
          <Route path="/app/invio-messaggio" component={RequireAuth(['operatore'])(InvioMessaggio)} />
          <Route path="/app/notifiche" component={RequireAuth(['operatore'])(Notifiche)} />
          <Route
            path="/app/candidati"
            component={RequireAuth(['operatore', 'candidato', 'agenzia', 'azienda'])(Candidati)}
          />
          <Route path="/app/appuntamenti" component={RequireAuth(['operatore', 'candidato'])(Appuntamenti)} />
          <Route
            path="/app/aziende"
            component={RequireAuth(['operatore', 'azienda', 'amministratore'])(GestioneAziende)}
          />
          <Route
            path="/app/agenzie"
            component={RequireAuth(['operatore', 'agenzia', 'amministratore'])(GestioneAgenzie)}
          />
          <Route path="/app/movimenti" component={RequireAuth(['operatore'])(Movimenti)} />
          <Route
            path="/app/job-vacancies"
            component={RequireAuth(['operatore', 'agenzia', 'azienda', 'candidato'])(GestioneJobVacancies)}
          />
          <Route path="/app/corsi-di-formazione" component={RequireAuth(['candidato', 'operatore'])(CorsiFormazione)} />
          <Route path="/app/gruppi" component={RequireAuth(['operatore'])(Gruppi)} />
          <Route path="/app/contatto-app" component={RequireAuth(['agenzia', 'azienda', 'candidato'])(ContattoApp)} />
          <Route path="/app/newsletter" component={RequireAuth(['operatore'])(GestioneNewsletters)} />
          <Route path="/app/regioni" component={RequireAuth(['amministratore'])(AreeGeografiche)} />
          <Route path="/app/sportelli" component={RequireAuth(['amministratore', 'subamministratore'])(Sportelli)} />
          <Route
            path="/app/operatori"
            component={RequireAuth(['amministratore', 'operatore', 'subamministratore'])(Operatori)}
          />
          <Route path="/app/sub-amministratori" component={RequireAuth(['amministratore'])(SubAmministratori)} />
          <Route path="/app/crediti-sms-consumati" component={RequireAuth(['amministratore'])(CreditiSmsConsumati)} />
          <Route
            path="/app/statistiche"
            component={RequireAuth(['amministratore', 'subamministratore'])(CandidatiStatistiche)}
          />
        </Switch>
      </div>
    )
  }
}
AppLogged = withRouter(AppLogged)

export default connect(null, { fetchUser })(AppLogged)
