import { SEND_EMAIL, SEND_SMS, RESET_STATO_INVIO_MESSAGGIO } from '../actions/types';

const INITIAL_STATE = {
  inviato: false
};

export default function(state = INITIAL_STATE, action) {
	switch(action.type) {
		case SEND_EMAIL:
    case SEND_SMS:
			return {
        ...state,
        inviato: true
      };
    case RESET_STATO_INVIO_MESSAGGIO:
			return {
        ...state,
        inviato: false
      };
		default:
			return state;
	}
}
