import React from 'react'
import { Route, Switch } from 'react-router'

import SubAmministratoriList from '../../amministratore/subamministratori/subamministratori_list'
import CreateEditSubAmministratore from '../../amministratore/subamministratori/create_edit_subamministratore'

const SubAmministratori = (props) => {
  return (
    <div>
      <Switch>
        <Route exact path="/app/sub-amministratori" component={SubAmministratoriList} />
        <Route path="/app/sub-amministratori/new" component={CreateEditSubAmministratore} />
        <Route path="/app/sub-amministratori/:amministratoreId" component={CreateEditSubAmministratore} />
      </Switch>
    </div>
  )
}

export default SubAmministratori
