import React from 'react'
import { Route, Switch } from 'react-router'
import RequireAuth from '../auth/require_auth'
import Azienda from '../aziende/azienda'
import AziendeList from '../aziende/aziende_list'
import JobVacanciesAziendeAgenzie from '../job_vacancies/job_vacancies_aziende_agenzie'

const GestioneAziende = (props) => {
  return (
    <div>
      <Switch>
        <Route
          exact
          path="/app/aziende"
          component={RequireAuth(['operatore', 'amministratore'])(AziendeList)}
        />
        <Route exact path="/app/aziende/new" component={Azienda} />
        <Route exact path="/app/aziende/:aziendaId" component={Azienda} />
        <Route
          exact
          path="/app/aziende/:aziendaId/job-vacancies"
          component={JobVacanciesAziendeAgenzie}
        />
      </Switch>
    </div>
  )
}

export default GestioneAziende
