import React, { Component } from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import Dropzone from 'react-dropzone'
import AsyncSelect from 'react-select/async'
import Ink from 'react-ink'
import { FaFileAlt, FaCamera } from 'react-icons/fa'
import { connect } from 'react-redux'

import { fetchAzienda, fetchTypeaheadComuni, saveAzienda, createAzienda, fetchSportelli } from '../../actions'
import Operatori from '../operatori/operatori'
import { Field, Form } from 'react-final-form'

class Azienda extends Component {
  componentDidMount() {
    if (this.props.match.params.aziendaId) {
      // Edit => Fetch Azienda
      this.props.fetchAzienda(this.props.match.params.aziendaId)
    }
    if (this.props.ruolo === 'amministratore') {
      this.props.fetchSportelli()
    }
  }

  ///////////////////////////////////////////////////////////////
  // GESTIONE COMUNI AUTOCOMPLETE
  getComuniOptions = (comuni) => {
    return comuni.map((comune) => {
      return {
        value: comune.comune,
        label: comune.comuneEsteso,
      }
    })
  }

  getComuni = (input) => {
    return this.props.fetchTypeaheadComuni(input).then((action) => {
      return this.getComuniOptions(action.payload.data)
    })
  }

  onChangeComune(option) {
    this.props.fields.comune.onChange(option ? option.value : null)
    this.props.fields.comuneEsteso.onChange(option ? option.label : null)
    this.props.touch('comune')
  }
  ///////////////////////////////////////////////////////////////

  onSubmit(values) {
    if (this.props.match.params.aziendaId) {
      // EDIT
      const { consensoPrivacyMarketingFlag, dichiarazioneJvFlag, ...rest } = values
      this.props.saveAzienda(rest).then((action) => {
        if (!this.props.isAzienda) {
          this.props.history.push('/app/aziende')
        }
      })
    } else {
      // NEW
      const newValues = { ...values }
      newValues.comune = newValues.comune.value
      this.props.createAzienda(newValues).then((action) => {
        if (!this.props.isAzienda) {
          this.props.history.push('/app/aziende')
        }
      })
    }
  }

  onDropImage = (files) => {
    var self = this
    var reader = new FileReader()
    var file = files[0]

    reader.readAsDataURL(file)

    reader.onload = function () {
      self.props.fields.logo.onChange(reader.result)
    }
  }

  onDropFile = (files) => {
    var self = this
    var reader = new FileReader()
    var file = files[0]

    reader.readAsDataURL(file)

    reader.onload = function () {
      self.props.fields.dichiarazioneJv.onChange(reader.result)
    }
  }

  render() {
    const initialValues = this.props.initialValues ? this.props.initialValues : {}
    const { isAzienda, ruolo, sportelli, permessoGestioneAziende } = this.props
    return (
      <Form
        onSubmit={this.onSubmit.bind(this)}
        initialValues={initialValues}
        validate={validate}
        render={({ handleSubmit, form }) => (
          <div className="azienda">
            <h1 className="page-title">Dettaglio {form.getFieldState('ragioneSociale')?.value}</h1>

            <Tabs defaultActiveKey={1} id="dettaglio-azienda" className="navtab-custom">
              <Tab eventKey={1} title="Anagrafica azienda">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="panel panel-border panel-blue">
                        <div className="panel-heading">
                          <h3 className="panel-title">Anagrafica dell'azienda</h3>
                        </div>
                        <div className="panel-body">
                          <div className="row">
                            <div className="col-md-6">
                              <Field
                                name="ragioneSociale"
                                id="ragioneSociale"
                                render={({ input, meta }) => (
                                  <div className={`form-group ${meta.touched && meta.invalid ? 'has-error' : ''}`}>
                                    <label htmlFor="ragioneSociale">Ragione sociale</label>
                                    <input type="text" className="form-control" {...input} />
                                    <div className="help-block">{meta.touched && meta.error}</div>
                                  </div>
                                )}
                              />
                            </div>
                            <div className="col-md-6">
                              <Field
                                name="logo"
                                id="logo"
                                render={({ input, meta }) => (
                                  <div className={`form-group ${meta.touched && meta.invalid ? 'has-error' : ''}`}>
                                    <label htmlFor="logo">Logo</label>
                                    <div className="file-upload">
                                      <Dropzone name="logo" multiple={false} accept="image/*" onDrop={this.onDropImage}>
                                        {({ getRootProps, getInputProps }) => (
                                          <>
                                            {input.value && <img src={input.value} alt="logo" {...getRootProps()} />}
                                            <div className="mask">
                                              <FaCamera />
                                            </div>
                                            {!input.value && (
                                              <div className="dropzone-text" {...getRootProps()}>
                                                Trascina qui il logo
                                                <input {...getInputProps()} />
                                                <br />
                                                oppure clicca per caricarlo
                                              </div>
                                            )}
                                          </>
                                        )}
                                      </Dropzone>
                                    </div>
                                    <div className="help-block">{meta.touched && meta.error}</div>
                                  </div>
                                )}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-6">
                              <Field
                                name="indirizzo"
                                id="indirizzo"
                                render={({ input, meta }) => (
                                  <div className={`form-group ${meta.touched && meta.invalid ? 'has-error' : ''}`}>
                                    <label htmlFor="indirizzo">Indirizzo</label>
                                    <input type="text" className="form-control" {...input} />
                                    <div className="help-block">{meta.touched && meta.error}</div>
                                  </div>
                                )}
                              />
                            </div>
                            <div className="col-md-6">
                              <Field
                                name="cap"
                                id="cap"
                                render={({ input, meta }) => (
                                  <div className={`form-group ${meta.touched && meta.invalid ? 'has-error' : ''}`}>
                                    <label htmlFor="cap">CAP</label>
                                    <input type="text" className="form-control" {...input} />
                                    <div className="help-block">{meta.touched && meta.error}</div>
                                  </div>
                                )}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-6">
                              <Field
                                name="comune"
                                render={({ input, meta }) => (
                                  <div className={`form-group ${meta.touched && meta.invalid ? 'has-error' : ''}`}>
                                    <label htmlFor="comune">Comune</label>
                                    <AsyncSelect
                                      id="comune"
                                      loadOptions={this.getComuni}
                                      placeholder={initialValues.comuneEsteso || 'Seleziona...'}
                                      loadingPlaceholder="Caricamento..."
                                      searchingText="Caricamento..."
                                      noResultsText="Nessun risultato"
                                      defaultOptions={[
                                        {
                                          value: initialValues.comune,
                                          label: initialValues.comuneEsteso,
                                        },
                                      ]}
                                      value={input.value.value}
                                      {...input}
                                    />
                                    <div className="help-block">{meta.touched && meta.error}</div>
                                  </div>
                                )}
                              />
                            </div>
                            <div className="col-md-6">
                              <Field
                                name="telefono"
                                id="telefono"
                                render={({ input, meta }) => (
                                  <div className={`form-group ${meta.touched && meta.invalid ? 'has-error' : ''}`}>
                                    <label htmlFor="telefono">Telefono</label>
                                    <input type="text" className="form-control" {...input} />
                                    <div className="help-block">{meta.touched && meta.error}</div>
                                  </div>
                                )}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-6">
                              <Field
                                name="email"
                                id="email"
                                render={({ input, meta }) => (
                                  <div className={`form-group ${meta.touched && meta.invalid ? 'has-error' : ''}`}>
                                    <label htmlFor="email">Email</label>
                                    <input type="text" className="form-control" {...input} />
                                    <div className="help-block">{meta.touched && meta.error}</div>
                                  </div>
                                )}
                              />
                            </div>
                            <div className="col-md-6">
                              <Field
                                name="pec"
                                id="pec"
                                render={({ input, meta }) => (
                                  <div className={`form-group ${meta.touched && meta.invalid ? 'has-error' : ''}`}>
                                    <label htmlFor="pec">PEC</label>
                                    <input type="text" className="form-control" id="pec" {...input} />
                                    <div className="help-block">{meta.touched && meta.error}</div>
                                  </div>
                                )}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-6">
                              <Field
                                name="partitaIva"
                                id="partitaIva"
                                render={({ input, meta }) => (
                                  <div className={`form-group ${meta.touched && meta.invalid ? 'has-error' : ''}`}>
                                    <label htmlFor="partitaIva">Partita IVA</label>
                                    <input type="text" className="form-control" {...input} />
                                    <div className="help-block">{meta.touched && meta.error}</div>
                                  </div>
                                )}
                              />
                            </div>
                            <div className="col-md-6">
                              <Field
                                name="codiceFiscale"
                                id="codiceFiscale"
                                render={({ input, meta }) => (
                                  <div className={`form-group ${meta.touched && meta.invalid ? 'has-error' : ''}`}>
                                    <label htmlFor="codiceFiscale">Codice fiscale</label>
                                    <input type="text" className="form-control" {...input} />
                                    <div className="help-block">{meta.touched && meta.error}</div>
                                  </div>
                                )}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-6">
                              <Field
                                name="settore"
                                id="settore"
                                render={({ input, meta }) => (
                                  <div className={`form-group ${meta.touched && meta.invalid ? 'has-error' : ''}`}>
                                    <label htmlFor="settore">Settore attività</label>
                                    <input type="text" className="form-control" {...input} />
                                    <div className="help-block">{meta.touched && meta.error}</div>
                                  </div>
                                )}
                              />
                            </div>
                            <div className="col-md-6">
                              <Field
                                name="ccnl"
                                id="ccnl"
                                render={({ input, meta }) => (
                                  <div className={`form-group ${meta.touched && meta.invalid ? 'has-error' : ''}`}>
                                    <label htmlFor="ccnl">CCNL applicato</label>
                                    <input type="text" className="form-control" {...input} />
                                    <div className="help-block">{meta.touched && meta.error}</div>
                                  </div>
                                )}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <div className="checkbox checkbox-blue">
                                  <Field
                                    id="permessoJobVacancyFlag"
                                    type="checkbox"
                                    name="permessoJobVacancyFlag"
                                    disabled={isAzienda}
                                    component="input"
                                  />
                                  <label htmlFor="permessoJobVacancyFlag">
                                    Può inserire job vacancies (dichiarazione&nbsp;ok)
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <Field
                                name="dichiarazioneJv"
                                id="dichiarazioneJv"
                                render={({ input, meta }) => (
                                  <div className={`form-group ${meta.touched && meta.invalid ? 'has-error' : ''}`}>
                                    <label htmlFor="dichiarazioneJv">Dichiarazione</label>
                                    <Dropzone
                                      className="file-upload"
                                      multiple={false}
                                      accept="application/pdf"
                                      onDrop={this.onDropFile}
                                    >
                                      {({ getRootProps, getInputProps }) => (
                                        <>
                                          {input.value && (
                                            <span className="dropzone-text file-uploaded">
                                              Allegato {(input.value.length / 1024).toFixed(0)} KB
                                            </span>
                                          )}
                                          <div className="mask">
                                            <FaFileAlt />
                                          </div>
                                          {!input.value && (
                                            <div className="dropzone-text" {...getRootProps()}>
                                              Trascina qui la dichiarazione
                                              <input {...getInputProps()} />
                                              <br />
                                              oppure clicca per caricarla
                                            </div>
                                          )}
                                        </>
                                      )}
                                    </Dropzone>
                                    <div className="help-block">{meta.touched && meta.error}</div>
                                  </div>
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="panel panel-border panel-purple">
                        <div className="panel-heading">
                          <h3 className="panel-title">Referente aziendale</h3>
                        </div>
                        <div className="panel-body">
                          <div className="row">
                            <div className="col-md-6">
                              <Field
                                name="referenteNome"
                                id="referenteNome"
                                render={({ input, meta }) => (
                                  <div className={`form-group ${meta.touched && meta.invalid ? 'has-error' : ''}`}>
                                    <label htmlFor="referenteNome">Nome referente aziendale</label>
                                    <input type="text" className="form-control" {...input} />
                                    <div className="help-block">{meta.touched && meta.error}</div>
                                  </div>
                                )}
                              />
                            </div>
                            <div className="col-md-6">
                              <Field
                                name="referenteCognome"
                                id="referenteCognome"
                                render={({ input, meta }) => (
                                  <div className={`form-group ${meta.touched && meta.invalid ? 'has-error' : ''}`}>
                                    <label htmlFor="referenteCognome">Cognome referente aziendale</label>
                                    <input type="text" className="form-control" {...input} />
                                    <div className="help-block">{meta.touched && meta.error}</div>
                                  </div>
                                )}
                              />
                            </div>
                          </div>
                          <Field
                            name="referenteRuolo"
                            id="referenteRuolo"
                            render={({ input, meta }) => (
                              <div className={`form-group ${meta.touched && meta.invalid ? 'has-error' : ''}`}>
                                <label htmlFor="referenteRuolo">Ruolo referente aziendale</label>
                                <input type="text" className="form-control" {...input} />
                                <div className="help-block">{meta.touched && meta.error}</div>
                              </div>
                            )}
                          />

                          <div className="row">
                            <div className="col-md-6">
                              <Field
                                name="referenteTelefono"
                                id="referenteTelefono"
                                render={({ input, meta }) => (
                                  <div className={`form-group ${meta.touched && meta.invalid ? 'has-error' : ''}`}>
                                    <label htmlFor="referenteTelefono">Telefono referente</label>
                                    <input type="text" className="form-control" {...input} />
                                    <div className="help-block">{meta.touched && meta.error}</div>
                                  </div>
                                )}
                              />
                            </div>
                            <div className="col-md-6">
                              <Field
                                id="referenteEmail"
                                name="referenteEmail"
                                render={({ input, meta }) => (
                                  <div className={`form-group ${meta.touched && meta.invalid ? 'has-error' : ''}`}>
                                    <label htmlFor="referenteEmail">Email referente</label>
                                    <input type="text" className="form-control" {...input} />
                                    <div className="help-block">{meta.touched && meta.error}</div>
                                  </div>
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {ruolo === 'amministratore' && (
                        <div className="panel panel-border panel-success">
                          <div className="panel-heading">
                            <h3 className="panel-title">Sportello di riferimento</h3>
                          </div>
                          <div className="panel-body">
                            <Field
                              name="sportelloId"
                              id="sportelloId"
                              render={({ input, meta }) => (
                                <div className={`form-group ${meta.touched && meta.invalid ? 'has-error' : ''}`}>
                                  <label htmlFor="sportelloId">Sportello più vicino</label>
                                  <select className="form-control" {...input}>
                                    <option value="">-- Seleziona --</option>
                                    {sportelli.map((sportello) => (
                                      <option key={sportello.sportelloId} value={sportello.sportelloId}>
                                        {sportello.organizzazione} {sportello.nome}{' '}
                                      </option>
                                    ))}
                                  </select>
                                  <div className="help-block">{meta.touched && meta.error}</div>
                                </div>
                              )}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {(isAzienda || permessoGestioneAziende) && (
                    <button type="submit" className="btn btn-primary btn-lg">
                      <Ink />
                      Salva azienda &raquo;
                    </button>
                  )}
                </form>
              </Tab>

              {this.props.match.params.aziendaId && (ruolo === 'operatore' || ruolo === 'amministratore') && (
                <Tab eventKey={2} title="Operatori">
                  <Operatori tipo="aziende" aziendaAgenziaId={this.props.match.params.aziendaId} />
                </Tab>
              )}
            </Tabs>
          </div>
        )}
      />
    )
  }
}

function validate(values) {
  const errors = {}

  // if (!values.sportelloId) {
  // 	errors.sportelloId = 'Inserisci lo sportello di riferimento';
  // }

  if (!values.ragioneSociale) {
    errors.ragioneSociale = 'Inserisci la ragione sociale'
  }

  if (!values.indirizzo) {
    errors.indirizzo = "Inserisci l'indirizzo"
  }

  if (!values.cap) {
    errors.cap = 'Inserisci il CAP'
  }

  if (!values.comune) {
    errors.comune = 'Inserisci il comune'
  }

  if (!values.telefono) {
    errors.telefono = 'Inserisci il telefono'
  }

  if (!values.email) {
    errors.email = "Inserisci l'email"
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Indirizzo email non valido'
  }

  if (!values.partitaIva) {
    errors.partitaIva = 'Inserisci la partita IVA'
  }

  if (!values.codiceFiscale) {
    errors.codiceFiscale = 'Inserisci il codice fiscale'
  }

  if (!values.referenteNome) {
    errors.referenteNome = 'Inserisci il nome del referente'
  }

  if (!values.referenteCognome) {
    errors.referenteCognome = 'Inserisci il cognome del referente'
  }
  if (!values.sportelloId) {
    errors.sportelloId = 'Inserisci sportello'
  }

  return errors
}

const mapStateToProps = (state, ownProps) => {
  let editObject = {}
  if (ownProps.match.params.aziendaId) {
    // EDIT
    editObject = {
      initialValues: state.aziendaAttiva.data,
      azienda: state.aziendaAttiva.data,
    }
  }
  return {
    ...editObject,
    permessoGestioneAziende: state.auth.user.permessi.gestioneAziende,
    operatori: state.operatori.data,
    sportelli: state.sportelli,
    ruolo: state.auth.user.ruolo,
    isAzienda: state.auth.user.ruolo === 'azienda',
  }
}

export default connect(mapStateToProps, {
  fetchAzienda,
  fetchTypeaheadComuni,
  saveAzienda,
  createAzienda,
  fetchSportelli,
})(Azienda)
