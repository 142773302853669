import { INVIO_FORM_SPORTELLO, RESET_STATO_INVIO_FORM_SPORTELLO } from '../actions/types';

const INITIAL_STATE = {
  inviato: false,
  errore: false
};

export default function(state = INITIAL_STATE, action) {
	switch(action.type) {
		case INVIO_FORM_SPORTELLO:
      if (action.error) {
        return {
          inviato: false,
          errore: true
        };
      }
			return {
        inviato: true,
        errore: false
      };
    case RESET_STATO_INVIO_FORM_SPORTELLO:
      return {
        ...INITIAL_STATE
      };
		default:
			return state;
	}
}
