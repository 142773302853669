import moment from 'moment';

import {
	FETCH_JOB_VACANCY
} from '../actions/types';

const INITIAL_STATE = {
	data: {
		candidatiInteressati: []
	}
};

export default function reducerJobVacancyAttiva(state = INITIAL_STATE, action) {
	switch (action.type) {
		case FETCH_JOB_VACANCY:
			return {
				...state,
				data: action.payload.data
			};
		default:
			return state;
	}
}

export function getJobVacancyData(globalState) {
	let jobVacancy = { ...globalState.jobVacancyAttiva.data };
	
	jobVacancy.dataInizio = moment(jobVacancy.dataInizio);
	if (jobVacancy.dataInizio.isValid()) {
		jobVacancy.dataInizio = jobVacancy.dataInizio.format('YYYY-MM-DD');
	} else {
		jobVacancy.dataInizio = '';
	}

	jobVacancy.dataScadenza = moment(jobVacancy.dataScadenza);
	if (jobVacancy.dataScadenza.isValid()) {
		jobVacancy.dataScadenza = jobVacancy.dataScadenza.format('YYYY-MM-DD');
	} else {
		jobVacancy.dataScadenza = '';
	}

	return jobVacancy;
}
