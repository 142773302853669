import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Ink from 'react-ink'

import { MdModeEdit } from 'react-icons/md'

class AltreInformazioni extends Component {
  renderDisabile() {
    if (this.props.altreInformazioni.disabileFlag) {
      return (
        <p>
          <b>Disabile</b>
        </p>
      )
    }
  }

  renderInvalidoCivile() {
    if (this.props.altreInformazioni.invalidoCivileFlag) {
      return (
        <p>
          <b>Invalido civile</b>
        </p>
      )
    }
  }

  renderAltroSoggettoSvantaggiato() {
    if (this.props.altreInformazioni.altriSoggettiSvantaggiatiFlag) {
      return (
        <p>
          <b>Soggetto svantaggiato</b>
        </p>
      )
    }
  }

  renderSoggettoACollocamentoObbligatorio() {
    if (this.props.altreInformazioni.collocamentoObbligatorioFlag) {
      return (
        <p>
          <b>Soggetto a collocamento obbligatorio</b>
        </p>
      )
    }
  }

  renderDescrizioneInvalidita() {
    const { descrizioneInvalidita } = this.props.altreInformazioni
    if (descrizioneInvalidita.length > 0) {
      return (
        <p>
          <b>Invalidità:</b> {descrizioneInvalidita}
        </p>
      )
    }
  }

  renderInvalidita() {
    return (
      <div>
        {this.renderDisabile()}
        {this.renderInvalidoCivile()}
        {this.renderAltroSoggettoSvantaggiato()}
        {this.renderSoggettoACollocamentoObbligatorio()}
        {this.renderDescrizioneInvalidita()}
      </div>
    )
  }

  render() {
    const { candidatoId, altreInformazioni, canEdit, usoInterno } = this.props

    if (!altreInformazioni) {
      return <div>Caricamento in corso...</div>
    }
    return (
      <div className="card-box altre-info">
        <h4 className="header-title">Altre informazioni</h4>

        <p>
          <b>Patente {altreInformazioni.patente}</b>
          {altreInformazioni.automunitoFlag ? ', Automunito' : ''}
        </p>
        <p>
          <b>Stato civile:</b> {altreInformazioni.statoCivile}
          {altreInformazioni.numeroFigli.length > 0
            ? ` con ${altreInformazioni.numeroFigli} figl${
                altreInformazioni.numeroFigli === '1' ? 'io' : 'i'
              }`
            : ''}
        </p>
        {this.renderInvalidita()}
        <div className="cv-sintetico">
          <p>
            <b>Note CV:</b> {altreInformazioni.noteCv}
          </p>
        </div>

        {canEdit ? (
          <div className="text-right">
            <Link
              to={`/app/candidati/${candidatoId}/altre-informazioni`}
              className="btn btn-sm btn-blue"
            >
              <Ink />
              <MdModeEdit />
              Modifica altre informazioni
            </Link>
          </div>
        ) : null}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    candidatoId: state.candidatoAttivo.candidatoId,
    altreInformazioni: state.candidatoAttivo.altreInformazioni,
    usoInterno: state.candidatoAttivo.usoInterno,
    canEdit:
      !!state.auth.user.permessi['altreInformazioni'] &&
      state.candidatoAttivo.sportello &&
      state.candidatoAttivo.sportello.sportelloId === state.auth.user.sportelloCorrente.sportelloId,
  }
}

export default connect(mapStateToProps)(AltreInformazioni)
