import { FETCH_AREE_GEOGRAFICHE, DELETE_AREA_GEOGRAFICA } from '../actions/types'

const INITIAL_STATE = []

export default function reducerAreeGeografiche(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_AREE_GEOGRAFICHE:
      return action.payload.data || []
    case DELETE_AREA_GEOGRAFICA:
      return state.filter((areaGeografica) => areaGeografica.areaGeograficaId !== action.payload)
    default:
      return state
  }
}
