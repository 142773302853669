import React from 'react'
import { Route, Switch } from 'react-router'
import Login from '../auth/login'
import './public.less'

const Public = (props) => (
  <div className="public">
    <Switch>
      <Route exact path="/" component={Login}></Route>
    </Switch>
  </div>
)

export default Public
