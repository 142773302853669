export default class TooltipConoscenzaLinguistica {

  static getTooltip(conoscenza, livello) {
    switch(conoscenza) {
      case 'ComprensioneAscolto':
        return this.getTooltipComprensioneAscolto(livello);
      case 'ComprensioneLettura':
        return this.getTooltipComprensioneLettura(livello);
      case 'ParlatoInterazione':
        return this.getTooltipParlatoInterazione(livello);
      case 'ParlatoProduzioneOrale':
        return this.getTooltipParlatoProduzioneOrale(livello);
      case 'ProduzioneScritta':
        return this.getTooltipProduzioneScritta(livello);
    }
  }

  static getTooltipComprensioneAscolto(livello) {
    switch(livello) {
      case 'A1':
        return `Riesco a riconoscere parole che mi sono familiari ed espressioni
          molto semplici riferite a me stesso, alla mia famiglia e al mio ambiente,
          purché le persone parlino lentamente e chiaramente.`;
      case 'A2':
        return `Riesco a capire espressioni e parole di uso molto frequente relative
          a ciò che mi riguarda direttamente (per esempio informazioni di base sulla
          mia persona e sulla mia famiglia, gli acquisti, l'ambiente circostante e il lavoro).
          Riesco ad afferrare l'essenziale di messaggi e annunci brevi, semplici e chiari.`;
      case 'B1':
        return `Riesco a capire gli elementi principali in un discorso chiaro in
          lingua standard su argomenti familiari, che affronto frequentemente al lavoro,
          a scuola, nel tempo libero ecc. Riesco a capire l'essenziale di molte
          trasmissioni radiofoniche e televisive su argomenti di attualità o temi
          di mio interesse personale o professionale, purché il discorso sia
          relativamente lento e chiaro.`;
      case 'B2':
        return `Riesco a capire discorsi di una certa lunghezza e conferenze e a
          seguire argomentazioni anche complesse purché il tema mi sia relativamente
          familiare. Riesco a capire la maggior parte dei notiziari e delle trasmissioni
          TV che riguardano fatti d'attualità e la maggior parte dei film in lingua standard.`;
      case 'C1':
        return `Riesco a capire un discorso lungo anche se non é chiaramente
          strutturato e le relazioni non vengono segnalate, ma rimangono implicite.
          Riesco a capire senza troppo sforzo le trasmissioni televisive e i film.`;
      case 'C2':
        return `Non ho nessuna difficoltà a capire qualsiasi lingua parlata,
          sia dal vivo sia trasmessa, anche se il discorso é tenuto in modo veloce
          da un madrelingua, purché abbia il tempo di abituarmi all'accento.`;
    }
  }

  static getTooltipComprensioneLettura(livello) {
    switch(livello) {
      case 'A1':
        return `Riesco a capire i nomi e le persone che mi sono familiari e frasi
          molto semplici, per esempio quelle di annunci, cartelloni, cataloghi.`;
      case 'A2':
        return `Riesco a leggere testi molto brevi e semplici e a trovare informazioni
          specifiche e prevedibili in materiale di uso quotidiano, quali pubblicità,
          programmi, menù e orari. Riesco a capire lettere personali semplici e brevi.`;
      case 'B1':
        return `Riesco a capire testi scritti di uso corrente legati alla sfera
          quotidiana o al lavoro. Riesco a capire la descrizione di avvenimenti,
          di sentimenti e di desideri contenuta in lettere personali.`;
      case 'B2':
        return `Riesco a leggere articoli e relazioni su questioni d’attualità
        in cui l’autore prende posizione ed esprime un punto di vista determinato.
        Riesco a comprendere un testo narrativo contemporaneo.`;
      case 'C1':
        return `Riesco a capire testi letterari e informativi lunghi e complessi
          e so apprezzare le differenze di stile. Riesco a capire articoli specialistici
          e istruzioni tecniche piuttosto lunghe, anche quando non appartengono al mio settore..`;
      case 'C2':
        return `Riesco a capire con facilità praticamente tutte le forme di lingua scritta
          inclusi i testi teorici, strutturalmente o linguisticamante complessi,
          quali manuali, articoli specialistici e opere letterarie.`;
    }
  }

  static getTooltipParlatoInterazione(livello) {
    switch(livello) {
      case 'A1':
        return `Riesco a interagire in modo semplice se l’interlocutore é disposto a ripetere o a
          riformulare più lentamente certe cose e mi aiuta a formulare ciò che cerco di dire.
          Riesco a porre e a rispondere a domande semplici su argomenti molto familiari o che
          riguardano bisogni immediati.`;
      case 'A2':
        return `Riesco a comunicare affrontando compiti semplici e di routine che
          richiedano solo uno scambio semplice e diretto di informazioni su argomenti e
          attività consuete. Riesco a partecipare a brevi conversazioni, anche se di
          solito non capisco abbastanza per riuscire a sostenere la conversazione.`;
      case 'B1':
        return `Riesco ad affrontare molte delle situazioni che si possono presentare
          viaggiando in una zona dove si parla la lingua. Riesco a partecipare, senza
          essermi preparato, a conversazioni su argomenti familiari, di interesse
          personale o riguardanti la vita quotidiana (per esempio la famiglia, gli hobby,
          il lavoro, i viaggi e i fatti di attualità).`;
      case 'B2':
        return `Riesco a comunicare con un grado di spontaneità e scioltezza sufficiente
          per interagire in modo normale con parlanti nativi. Riesco a partecipare
          attivamente a una discussione in contesti familiari, esponendo e sostenendo le mie opinioni.`;
      case 'C1':
        return `Riesco ad esprimermi in modo sciolto e spontaneo senza dover cercare
          troppo le parole. Riesco ad usare la lingua in modo flessibile ed efficace
          nelle relazioni sociali e professionali. Riesco a formulare idee e opinioni
          in modo preciso e a collegare abilmente i miei interventi con quelli di altri interlocutori.`;
      case 'C2':
        return `Riesco a partecipare senza sforzi a qualsiasi conversazione e discussione
          ed ho familiarità con le espressioni idiomatiche e colloquiali. Riesco ad
          esprimermi con scioltezza e a rendere con precisione sottili sfumature di
          significato. In caso di difficoltà, riesco a ritornare sul discorso e a
          riformularlo in modo cosí scorrevole che difficilmente qualcuno se ne accorge.`;
    }
  }

  static getTooltipParlatoProduzioneOrale(livello) {
    switch(livello) {
      case 'A1':
        return `Riesco a usare espressioni e frasi semplici per descrivere il luogo
          dove abito e la gente che conosco.`;
      case 'A2':
        return `Riesco ad usare una serie di espressioni e frasi per descrivere
        con parole semplici la mia famiglia ed altre persone, le mie condizioni
        di vita, la carriera scolastica e il mio lavoro attuale o il più recente.`;
      case 'B1':
        return `Riesco a descrivere, collegando semplici espressioni, esperienze
          ed avvenimenti, i miei sogni, le mie speranze e le mie ambizioni. Riesco
          a motivare e spiegare brevemente opinioni e progetti. Riesco a narrare
          una storia e la trama di un libro o di un film e a descrivere le mie impressioni.`;
      case 'B2':
        return `Riesco a esprimermi in modo chiaro e articolato su una vasta gamma
          di argomenti che mi interessano. Riesco a esprimere un’ opinione su un argomento
          d’attualità, indicando vantaggi e svantaggi delle diverse opzioni.`;
      case 'C1':
        return `Riesco a presentare descrizioni chiare e articolate su argomenti
          complessi, integrandovi temi secondari, sviluppando punti specifici e
          concludendo il tutto in modo appropriato.`;
      case 'C2':
        return `Riesco a presentare descrizioni o argomentazioni chiare e scorrevoli,
          in uno stile adeguato al contesto e con una struttura logica efficace,
          che possa aiutare il destinatario a identificare i punti salienti da rammentare.`;
    }
  }

  static getTooltipProduzioneScritta(livello) {
    switch(livello) {
      case 'A1':
        return `Riesco a scrivere una breve e semplice cartolina , ad esempio per
          mandare i saluti delle vacanze. Riesco a compilare moduli con dati personali
          scrivendo per esempio il mio nome, la nazionalità e l’indirizzo sulla
          scheda di registrazione di un albergo.`;
      case 'A2':
        return `Riesco a prendere semplici appunti e a scrivere brevi messaggi su
          argomenti riguardanti bisogni immediati. Riesco a scrivere una lettera
          personale molto semplice, per esempio per ringraziare qualcuno.`;
      case 'B1':
        return `Riesco a scrivere testi semplici e coerenti su argomenti a me noti
          o di mio interesse. Riesco a scrivere lettere personali esponendo esperienze
          e impressioni.`;
      case 'B2':
        return `Riesco a scrivere testi chiari e articolati su un’ampia gamma di
          argomenti che mi interessano. Riesco a scrivere saggi e relazioni, fornendo
          informazioni e ragioni a favore o contro una determinata opinione. Riesco a
          scrivere lettere mettendo in evidenza il significato che attribuisco
          personalmente agli avvenimenti e alle esperienze.`;
      case 'C1':
        return `Riesco a scrivere testi chiari e ben strutturati sviluppando
        analiticamente il mio punto di vista. Riesco a scrivere lettere, saggi e
        relazioni esponendo argomenti complessi, evidenziando i punti che ritengo
        salienti. Riesco a scegliere lo stile adatto ai lettori ai quali intendo rivolgermi.`;
      case 'C2':
        return `Riesco a scrivere testi chiari, scorrevoli e stilisticamente
          appropriati. Riesco a scrivere lettere, relazioni e articoli complessi,
          supportando il contenuto con una struttura logica efficace che aiuti il
          destinatario a identificare i punti salienti da rammentare. Riesco a
          scrivere riassunti e recensioni di opere letterarie e di testi specialisti.`;
    }
  }


}
