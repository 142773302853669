import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Ink from 'react-ink'

import { MdModeEdit } from 'react-icons/md'

class AltreCompetenze extends Component {
  render() {
    const { candidatoId, altreCompetenze, canEdit } = this.props

    return (
      <div className="card-box altre-competenze">
        <h4 className="header-title">Altre competenze</h4>
        {altreCompetenze ? (
          <div className="row">
            <div className="col-md-4">
              <div className="competenza">
                <h5 className="header-title">Comunicative</h5>
                <p>{altreCompetenze.comunicative}</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="competenza">
                <h5 className="header-title">Organizzative</h5>
                <p>{altreCompetenze.organizzative}</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="competenza">
                <h5 className="header-title">Altre</h5>
                <p>{altreCompetenze.altre}</p>
              </div>
            </div>
          </div>
        ) : null}
        {canEdit ? (
          <div className="text-right">
            <Link
              to={`/app/candidati/${candidatoId}/altre-competenze`}
              className="btn btn-sm btn-success"
            >
              <Ink />
              <MdModeEdit />
              Modifica altre competenze
            </Link>
          </div>
        ) : null}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    candidatoId: state.candidatoAttivo.candidatoId,
    altreCompetenze: state.candidatoAttivo.altreCompetenze,
    canEdit:
      !!state.auth.user.permessi['altreCompetenze'] &&
      state.candidatoAttivo.sportello &&
      state.candidatoAttivo.sportello.sportelloId === state.auth.user.sportelloCorrente.sportelloId,
  }
}

export default connect(mapStateToProps)(AltreCompetenze)
