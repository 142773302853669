import React, { Component } from 'react'
import { Field, Form } from 'react-final-form'
import { connect } from 'react-redux'
import {
  createSubAmministratore,
  fetchAreeGeografiche,
  fetchSubAmministratore,
  saveSubAmministratore,
} from '../../../actions'
import './create_edit_subamministratore.less'

class CreateEditSubAmministratore extends Component {
  constructor(props) {
    super(props)

    this.state = {
      templatePermessi: '',
      nuovoSportelloId: 0,
    }
  }

  componentDidMount() {
    this.props.fetchAreeGeografiche()
    if (this.props.match.params.amministratoreId) {
      this.props.fetchSubAmministratore(this.props.match.params.amministratoreId)
    }
  }

  onSubmit(values) {
    if (this.props.match.params.amministratoreId) {
      // Siamo in update
      const { amministratoreId, utenteId, superadminFlag, login, areaGeografica, areaGeograficaNome, ...rest } = values
      this.props.saveSubAmministratore(rest, this.props.match.params.amministratoreId).then((action) => {
        this.props.history.push(`/app/sub-amministratori`)
      })
    } else {
      // Siamo in new
      this.props.createSubAmministratore(values).then((action) => {
        this.props.history.push(`/app/sub-amministratori`)
      })
    }
  }

  renderAreeGeografiche() {
    if (this.props.areeGeografiche) {
      return this.props.areeGeografiche.map((areaGeografica) => (
        <option key={areaGeografica.areaGeograficaId} value={areaGeografica.areaGeograficaId}>
          {areaGeografica.nome}
        </option>
      ))
    }
    return null
  }

  ///////////////////////////////////////////////////////////////
  // RENDER
  render() {
    const initialValues = this.props.initialValues ? this.props.initialValues : {}
    return (
      <Form
        onSubmit={this.onSubmit.bind(this)}
        validate={validate}
        initialValues={initialValues}
        render={({ handleSubmit, form }) => (
          <div className="edit-operatore">
            <h1 className="page-title">Sub-amministratore</h1>

            <div className="card-box">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <Field
                      name="nome"
                      id="nome"
                      render={({ input, meta }) => (
                        <div className={`form-group ${meta.touched && meta.invalid ? 'has-error' : ''}`}>
                          <label htmlFor="nome">Nome</label>
                          <input type="text" className="form-control" {...input} />
                          <div className="help-block">{meta.touched && meta.error}</div>
                        </div>
                      )}
                    />
                  </div>
                  <div className="col-md-6">
                    <Field
                      name="cognome"
                      id="cognome"
                      render={({ input, meta }) => (
                        <div className={`form-group ${meta.touched && meta.invalid ? 'has-error' : ''}`}>
                          <label htmlFor="cognome">Cognome</label>
                          <input type="text" className="form-control" {...input} />
                          <div className="help-block">{meta.touched && meta.error}</div>
                        </div>
                      )}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Field
                      name="email"
                      id="email"
                      render={({ input, meta }) => (
                        <div className={`form-group ${meta.touched && meta.invalid ? 'has-error' : ''}`}>
                          <label htmlFor="email">E-mail</label>
                          <input type="email" className="form-control" {...input} />
                          <div className="help-block">{meta.touched && meta.error}</div>
                        </div>
                      )}
                    />
                  </div>
                  <div className="col-md-6">
                    <Field
                      name="password"
                      id="password"
                      render={({ input, meta }) => (
                        <div className={`form-group ${meta.touched && meta.invalid ? 'has-error' : ''}`}>
                          <label htmlFor="password">Password</label>
                          <input type="text" className="form-control" {...input} />
                          <div className="help-block">{meta.touched && meta.error}</div>
                        </div>
                      )}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="areaGeografica">Regione</label>
                      <Field
                        name="areaGeograficaId"
                        id="areaGeograficaId"
                        render={({ input, meta }) => (
                          <div className={`form-group ${meta.touched && meta.invalid ? 'has-error' : ''}`}>
                            <select className="form-control" id="areaGeografica" {...input}>
                              <option value="">-- Seleziona --</option>
                              {this.renderAreeGeografiche()}
                            </select>
                            <div className="help-block">{meta.touched && meta.error}</div>
                          </div>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="attivoFlag">Accesso al portale</label>
                      <div className="checkbox checkbox-primary">
                        <Field name="attivoFlag" id="attivoFlag" type="checkbox" component="input" />
                        <label htmlFor="attivoFlag">Attivo</label>
                      </div>
                    </div>
                  </div>
                </div>

                <button type="submit" className="btn btn-primary btn-salva">
                  Salva operatore
                </button>
              </form>
            </div>
          </div>
        )}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    initialValues: state.subAmministratoreAttivo,
    areeGeografiche: state.areeGeografiche,
  }
}

function validate(values) {
  const errors = {}

  if (!values.nome) {
    errors.nome = 'Inserisci il nome'
  }
  if (!values.cognome) {
    errors.cognome = 'Inserisci il cognome'
  }

  if (!values.email) {
    errors.email = 'Inserisci un indirizzo email'
  }

  if (!values.password) {
    errors.password = 'Inserisci una password'
  }
  if (!values.areaGeograficaId) {
    errors.areaGeograficaId = 'Inserisci una regione'
  }

  return errors
}

export default connect(mapStateToProps, {
  fetchSubAmministratore,
  createSubAmministratore,
  saveSubAmministratore,
  fetchAreeGeografiche,
})(CreateEditSubAmministratore)
