import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Ink from 'react-ink'
import { FaSearch } from 'react-icons/fa'
import { MdDescription, MdFileDownload, MdAdd, MdEdit } from 'react-icons/md'
import swal from 'sweetalert'

import {
  fetchAziende,
  deleteAzienda,
  setKeywordsAziende,
  setSportelloCorrenteFlagAziende,
  changePageAziende,
  API_ROOT_URL,
} from '../../actions'
import Pager from '../pager/pager'

class AziendeList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      nomeNuovaAzienda: '',
      aziendaId: 0,
    }
  }

  componentDidMount() {
    this.props.fetchAziende(
      this.props.keywords,
      this.props.sportelloCorrenteFlag,
      this.props.pageRequested
    )
  }

  componentDidUpdate(prevProps) {
    const filterChanged =
      prevProps.keywords !== this.props.keywords ||
      prevProps.sportelloCorrenteFlag !== this.props.sportelloCorrenteFlag

    const pageChanged = prevProps.pageRequested !== this.props.pagination.page

    if (filterChanged) {
      this.props.changePageAziende(1)
    }

    if (filterChanged || pageChanged) {
      this.props.fetchAziende(
        this.props.keywords,
        this.props.sportelloCorrenteFlag,
        filterChanged ? 1 : this.props.pageRequested
      )
    }
  }

  onRemove() {
    this.props.deleteAzienda(this.state.aziendaId)
  }

  changeKeywords = (event) => {
    this.props.setKeywordsAziende(event.target.value)
  }

  changeSportelloCorrenteFlag = (event) => {
    this.props.setSportelloCorrenteFlagAziende(event.target.checked)
  }

  renderAziendePermessoJv(permessoJv, dichiarazioneJv, aziendaId) {
    const token = localStorage.getItem('token')

    if (dichiarazioneJv && permessoJv) {
      return (
        <a
          href={`${API_ROOT_URL}/aziende/${aziendaId}/download_dichiarazione_jv?t=${token}`}
          target="_blank"
          className="label label-success"
        >
          JV
        </a>
      )
    } else if (dichiarazioneJv && !permessoJv) {
      return (
        <a
          href={`${API_ROOT_URL}/aziende/${aziendaId}/download_dichiarazione_jv?t=${token}`}
          target="_blank"
          className="label label-warning"
        >
          JV
        </a>
      )
    } else if (!dichiarazioneJv && permessoJv) {
      return <div className="label label-success">JV</div>
    } else {
      // !dichiarazione && !permessoJv
      return <div className="label label-danger">JV</div>
    }
  }

  renderAziende(aziende, permessoGestioneAziende, ruolo, sportelloId) {
    return aziende.map((azienda) => (
      <tr key={azienda.aziendaId}>
        <td>
          {this.renderAziendePermessoJv(
            azienda.permessoJobVacancyFlag,
            azienda.dichiarazioneJvFlag,
            azienda.aziendaId
          )}
        </td>
        <td>{azienda.ragioneSociale}</td>
        <td>{azienda.comune}</td>
        <td>{azienda.telefono}</td>
        <td>
          <a href={`mailto:${azienda.email}`}>{azienda.email}</a>
        </td>
        <td>
          {azienda.referenteNome} {azienda.referenteCognome}
        </td>
        {ruolo === 'operatore' && (
          <td className="text-right">
            {azienda.numeroJv > 0 && (
              <Link
                className="btn btn-sm btn-default"
                style={{ marginRight: 10 }}
                to={'/app/aziende/' + azienda.aziendaId + '/job-vacancies'}
              >
                Vedi <b>{azienda.numeroJv}</b> JV
              </Link>
            )}
          </td>
        )}
        <td className="text-right">
          {((!!permessoGestioneAziende && ruolo === 'amministratore') ||
            (!!permessoGestioneAziende && azienda.sportelloId === sportelloId)) && (
            <div className="actions">
              <Link
                className="btn btn-sm btn-primary"
                style={{ marginRight: 10 }}
                to={'/app/aziende/' + azienda.aziendaId}
              >
                <MdEdit />
                Modifica
              </Link>
              <button
                className="btn btn-sm btn-danger"
                onClick={() => {
                  this.setState({ aziendaId: azienda.aziendaId })
                  this.handleDeleteAzienda()
                }}
              >
                &times; Elimina
              </button>
            </div>
          )}
        </td>
      </tr>
    ))
  }

  handleDeleteAzienda = () => {
    swal({
      icon: 'warning',
      title: 'Eliminazione azienda',
      text: 'Sei sicuro di vole eliminare questa azienda? Questa azione è irreversibile.',
      buttons: ['Annulla', 'Si, elimina azienda'],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onRemove()
      }
    })
  }

  render() {
    const {
      keywords,
      permessoGestioneAziende,
      ruolo,
      sportelloCorrenteFlag,
      sportelloId,
      changePageAziende,
      aziende,
      pagination,
      pageRequested,
    } = this.props

    return (
      <div className="aziende-list">
        <h1 className="page-title">Aziende</h1>

        {!!permessoGestioneAziende && (
          <Link to="/app/aziende/new" className="btn btn-success pull-right">
            <Ink />
            <MdAdd style={{ verticalAlign: 'middle' }} />
            Nuova azienda
          </Link>
        )}

        <div
          className="filtro-aziende search-box form-group"
          style={{ width: 300, display: 'inline-block', marginBottom: 30 }}
        >
          <input
            type="text"
            className="form-control"
            value={keywords}
            onChange={this.changeKeywords}
            placeholder="Filtra per parole chiave..."
          />
          <FaSearch />
        </div>

        {ruolo !== 'amministratore' && (
          <div style={{ display: 'inline-block', marginLeft: 10 }}>
            <div className="checkbox checkbox-primary">
              <input
                type="checkbox"
                id="sportelloCorrente"
                checked={sportelloCorrenteFlag}
                onChange={this.changeSportelloCorrenteFlag}
              />
              <label htmlFor="sportelloCorrente">Solo sportello corrente</label>
            </div>
          </div>
        )}

        <Pager
          position={'top'}
          page={pagination.page}
          totalPages={pagination.totalPages}
          onChangePage={changePageAziende}
        />

        <div className="card-box" style={{ marginBottom: 10 }}>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>JV</th>
                  <th>Ragione sociale</th>
                  <th>Comune</th>
                  <th>Telefono</th>
                  <th>Email</th>
                  <th>Referente</th>
                  {ruolo !== 'amministratore' && <th></th>}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.renderAziende(aziende, permessoGestioneAziende, ruolo, sportelloId)}
              </tbody>
            </table>
          </div>
        </div>

        <Pager
          position={'bottom'}
          page={pagination.page}
          totalPages={pagination.totalPages}
          onChangePage={changePageAziende}
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    aziende: state.aziende.data,
    pagination: state.aziende.pagination,
    pageRequested: state.aziende.pageRequested,
    keywords: state.aziende.keywords,
    sportelloCorrenteFlag: state.aziende.sportelloCorrenteFlag,
    permessoGestioneAziende: state.auth.user.permessi.gestioneAziende,
    ruolo: state.auth.user.ruolo,
    sportelloId: state.auth.user.sportelloCorrente.sportelloId,
  }
}

export default connect(mapStateToProps, {
  fetchAziende,
  deleteAzienda,
  setKeywordsAziende,
  setSportelloCorrenteFlagAziende,
  changePageAziende,
})(AziendeList)
