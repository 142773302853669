import { REGISTRAZIONE_UTENTE, RESET_STATO_REGISTRAZIONE } from '../actions/types';

const INITIAL_STATE = {
  registrato: false,
  errore: false
};

export default function(state = INITIAL_STATE, action) {
	switch(action.type) {
		case REGISTRAZIONE_UTENTE:
      if (action.error) {
        return {
          registrato: false,
          errore: true
        };
      }
			return {
        registrato: true,
        errore: false
      };
    case RESET_STATO_REGISTRAZIONE:
      return {
        ...INITIAL_STATE
      };
		default:
			return state;
	}
}
