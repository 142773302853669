import React, { Component } from 'react'
import { connect } from 'react-redux'
import Ink from 'react-ink'
import Switch from 'rc-switch'
import cogoToast from 'cogo-toast'

import { saveStampe, inviaEmailSegnalazione, API_ROOT_URL } from '../../actions'

import { MdDescription, MdFileDownload } from 'react-icons/md'

import './anagrafica_stampe.less'

class AnagraficaStampe extends Component {
  constructor(props) {
    super(props)

    this.state = {
      cvAnonimo: false,
      nascondiAziendeCorrenti: false,
    }
  }

  changeCvAnonimo = (value) => {
    this.setState({
      cvAnonimo: value,
    })
  }

  changeNascondiAziendeCorrenti = (value) => {
    this.setState({
      nascondiAziendeCorrenti: value,
    })
  }

  changeFlagStampa = (flagName) => (value) => {
    this.props.saveStampe(this.props.candidatoId, {
      [flagName]: value,
    })
  }

  handleInviaSegnalazione = (event) => {
    this.props.inviaEmailSegnalazione(this.props.candidatoId).then((res) => {
      if (res.payload.status === 200) {
        cogoToast.success("L'e-mail con il CV sintetico è stata inviata", {
          heading: 'Email inviata',
          position: 'top-right',
          hideAfter: 5,
        })
      }
    })
  }

  renderVediCvLink() {
    const { candidatoId } = this.props
    const token = localStorage.getItem('token')

    const qAnonimo = this.state.cvAnonimo ? '&anonimo=true' : ''
    const qNascondiInCorso = this.state.nascondiAziendeCorrenti
      ? '&nascondi_aziende_correnti=true'
      : ''

    return `${API_ROOT_URL}/candidati/${candidatoId}/curriculum_pdf?t=${token}${qAnonimo}${qNascondiInCorso}`
  }

  renderStampaCvLink() {
    const { candidatoId } = this.props
    const token = localStorage.getItem('token')

    const qAnonimo = this.state.cvAnonimo ? '&anonimo=true' : ''
    const qNascondiInCorso = this.state.nascondiAziendeCorrenti
      ? '&nascondi_aziende_correnti=true'
      : ''

    return `${API_ROOT_URL}/candidati/${candidatoId}/curriculum_pdf?t=${token}&download=true${qAnonimo}${qNascondiInCorso}`
  }

  render() {
    const { candidatoId, stampe, consensoPrivacyFlag, canChangeServizi } = this.props
    const token = localStorage.getItem('token')

    return (
      <div className="stampe">
        <div className="row">
          <div className="col-sm-7">
            <p>
              <Switch checked={this.state.cvAnonimo} onChange={this.changeCvAnonimo} /> Stampa
              anonimo
            </p>
            <p>
              <Switch
                checked={this.state.nascondiAziendeCorrenti}
                onChange={this.changeNascondiAziendeCorrenti}
              />{' '}
              Nascondi aziende con rapporti di lavoro in corso
            </p>
            <hr></hr>
            <p>
              <Switch
                checked={stampe.fotoInCvFlag}
                onChange={this.changeFlagStampa('fotoInCvFlag')}
                disabled={!canChangeServizi}
              />{' '}
              Stampa foto in CV
            </p>
            <p>
              <Switch
                checked={stampe.dichiarazioneArt76InCvFlag}
                onChange={this.changeFlagStampa('dichiarazioneArt76InCvFlag')}
                disabled={!canChangeServizi}
              />{' '}
              Stampa dichiarazione Art. 76 DPR
            </p>
            <p>
              <Switch
                checked={stampe.invaliditaInCvFlag}
                onChange={this.changeFlagStampa('invaliditaInCvFlag')}
                disabled={!canChangeServizi}
              />{' '}
              Stampa invalidità
            </p>
          </div>
          <div className="col-sm-5">
            <div>
              <a
                target="_blank"
                href={this.renderVediCvLink()}
                className="btn btn-lg btn-block btn-primary btn-vedi-curriculum"
              >
                <MdDescription />
                Vedi curriculum
              </a>
            </div>
            <div>
              <a href={this.renderStampaCvLink()} className="btn btn-lg btn-block btn-default">
                <Ink />
                <MdFileDownload />
                Scarica curriculum
              </a>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-lg btn-block btn-purple btn-invia-email"
                onClick={this.handleInviaSegnalazione}
              >
                Inviami curriculum sintetico
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    candidatoId: state.candidatoAttivo.candidatoId,
    stampe: state.candidatoAttivo.stampe,
    consensoPrivacyFlag: state.candidatoAttivo.consensoPrivacyFlag,
    canPrintPrivacy: state.auth.user.ruolo === 'operatore',
  }
}

export default connect(mapStateToProps, { saveStampe, inviaEmailSegnalazione })(AnagraficaStampe)
