import {
  FETCH_SUBAMMINISTRATORE,
  RESET_SUBAMMINISTRATORE_ATTIVO
} from '../actions/types';

const INITIAL_STATE = {};

export default function(state = INITIAL_STATE, action) {
	switch(action.type) {
		case FETCH_SUBAMMINISTRATORE:
			return action.payload.data || state;
		case RESET_SUBAMMINISTRATORE_ATTIVO:
			return {};
		default:
			return state;
	}

}
