import React, { Component } from 'react'

import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'

class Pager extends Component {
  isFirstPage() {
    let { page } = this.props
    page = parseInt(page)
    return !page || page === 1
  }

  isLastPage() {
    let { page, totalPages } = this.props
    page = parseInt(page)
    return page === totalPages
  }

  setPreviousPage = (event) => {
    const { page, onChangePage } = this.props
    event.preventDefault()
    const nextPage = this.isFirstPage() ? 1 : page - 1
    onChangePage(nextPage)
  }

  setNextPage = (event) => {
    const { page, totalPages, onChangePage } = this.props
    event.preventDefault()
    const nextPage = this.isLastPage() ? totalPages : page + 1
    onChangePage(nextPage)
  }

  render() {
    const { position, page, totalPages } = this.props

    return (
      <div className={`pager ${position}`}>
        <a
          href="#"
          onClick={this.setPreviousPage}
          className={`prev-page ${this.isFirstPage() ? 'disabled' : ''}`}
        >
          <FaChevronLeft />
        </a>
        <span className="current-page">{page}</span>
        <a
          href="#"
          onClick={this.setNextPage}
          className={`next-page ${this.isLastPage() ? 'disabled' : ''}`}
        >
          <FaChevronRight />
        </a>
        <span className="of">di</span>
        <span className="total-pages">{totalPages}</span>
      </div>
    )
  }
}

export default Pager
