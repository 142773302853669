import React, { Component } from 'react';
import { connect } from 'react-redux';
import Ink from 'react-ink';
import moment from 'moment';

import { savePrivacyFlag, savePrivacyMarketingFlag, API_ROOT_URL } from '../../actions';

class AnagraficaPrivacy extends Component {

  changeConsenso = event => {
    this.props.savePrivacyFlag(this.props.candidatoId, event.target.value)
  }

  changeConsensoMkt = event => {
    this.props.savePrivacyMarketingFlag(this.props.candidatoId, event.target.value)
  }

  render() {

    const { canPrintPrivacy, candidatoId, consensoPrivacyFlag, consensoPrivacyMarketingFlag, consensoPrivacyData } = this.props

    const token = localStorage.getItem('token');

    return (
      <div>
        {canPrintPrivacy &&
					<div className="text-center">
						<a target="_blank" href={`${API_ROOT_URL}/candidati/${candidatoId}/consenso_privacy_pdf?t=${token}`} className={`btn btn-lg informativa-privacy ${consensoPrivacyFlag ? 'btn-default' : 'btn-danger'}`}><Ink />Stampa informativa privacy</a>
					</div>
        }

        <table className="table" style={{marginTop: 50}}>
          <tbody>
            <tr>
              <td>Consenso marketing Titolare</td>
              <td><label htmlFor="cpf_true"><input id="cpf_true" type="radio" name="consensoPrivacyFlag" checked={consensoPrivacyFlag === true} value={true} onChange={this.changeConsenso} /> Sì</label></td>
              <td><label htmlFor="cpf_false"><input id="cfp_false" type="radio" name="consensoPrivacyFlag" checked={consensoPrivacyFlag === false} value={false} onChange={this.changeConsenso} /> No</label></td>
            </tr>
            <tr>
              <td>Consenso marketing Altri titolari</td>
              <td><label htmlFor="cpmf_true"><input id="cpmf_true" type="radio" name="consensoPrivacyMarketingFlag" checked={consensoPrivacyMarketingFlag === true} value={true} onChange={this.changeConsensoMkt} /> Sì</label></td>
              <td><label htmlFor="cpmf_false"><input id="cpmf_false" type="radio" name="consensoPrivacyMarketingFlag" checked={consensoPrivacyMarketingFlag === false} value={false} onChange={this.changeConsensoMkt} /> No</label></td>
            </tr>
            {!!consensoPrivacyData &&
              <tr>
                <td colSpan="3">Consenso rilasciato il <b style={{ color: '#3bafda' }}>{moment(consensoPrivacyData).format('DD/MM/YYYY')}</b></td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    );
  }
}

function mapStateToProps(state) {
	return {
		candidatoId: state.candidatoAttivo.candidatoId,
		consensoPrivacyFlag: state.candidatoAttivo.consensoPrivacyFlag,
		consensoPrivacyMarketingFlag: state.candidatoAttivo.consensoPrivacyMarketingFlag,
		consensoPrivacyData: state.candidatoAttivo.consensoPrivacyData,
		canPrintPrivacy: state.auth.user.ruolo === 'operatore'
	}
}

export default connect(mapStateToProps, { savePrivacyFlag, savePrivacyMarketingFlag })(AnagraficaPrivacy);