import React, { Component, PropTypes } from 'react'
import { Form, Field } from 'react-final-form'
import { Link } from 'react-router-dom'

import BottoneAnnulla from './bottone_annulla'
import { fetchCandidato, saveAltreCompetenze } from '../../actions'

import Ink from 'react-ink'
import { connect } from 'react-redux'

class EditAltreCompetenze extends Component {
  componentDidMount() {
    // Ho già il candidato attivo se navigo normalmente
    // dal dettaglio candidato: questo controllo serve
    // nel caso in cui ricarico la pagina da qui (F5)
    if (!this.props.candidatoId) {
      this.props.fetchCandidato(this.props.match.params.id)
    }
  }

  onSubmit(props) {
    this.props.saveAltreCompetenze(this.props.match.params.id, props).then((action) => {
      this.props.history.goBack()
    })
  }

  render() {
    const initialValues = this.props.initialValues ? this.props.initialValues : {}

    return (
      <Form
        onSubmit={this.onSubmit.bind(this)}
        initialValues={initialValues}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="heading">
              <h1 className="page-title pull-left">Altre competenze</h1>
              <BottoneAnnulla />
            </div>

            <div className="row">
              <div className="col-md-4">
                <div className="panel panel-border panel-success">
                  <div className="panel-heading">
                    <h3 className="panel-title">Comunicative</h3>
                  </div>
                  <div className="panel-body">
                    <div className="form-group">
                      <Field
                        className="form-control"
                        rows="6"
                        id="comunicative"
                        name="comunicative"
                        component="textarea"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="panel panel-border panel-success">
                  <div className="panel-heading">
                    <h3 className="panel-title">Organizzative</h3>
                  </div>
                  <div className="panel-body">
                    <div className="form-group">
                      <Field
                        className="form-control"
                        rows="6"
                        id="organizzative"
                        name="organizzative"
                        component="textarea"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="panel panel-border panel-success">
                  <div className="panel-heading">
                    <h3 className="panel-title">Altre</h3>
                  </div>
                  <div className="panel-body">
                    <div className="form-group">
                      <Field
                        className="form-control"
                        rows="6"
                        id="altre"
                        name="altre"
                        component="textarea"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-center">
              <button type="submit" className="btn btn-success btn-lg">
                Salva altre competenze &raquo;
              </button>
            </div>
          </form>
        )}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    initialValues: state.candidatoAttivo.altreCompetenze,
    candidatoId: state.candidatoAttivo.candidatoId, // per sapere se ho già il candidato attivo su F5
  }
}

export default connect(mapStateToProps, { fetchCandidato, saveAltreCompetenze })(
  EditAltreCompetenze
)
