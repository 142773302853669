import React, { Component } from 'react'
import _find from 'lodash/find'
import { MdAdd, MdStore } from 'react-icons/md'
import { connect } from 'react-redux'
import { FaStar } from 'react-icons/fa'
import { Form, Field } from 'react-final-form'

import {
  fetchOperatore,
  createOperatore,
  saveOperatore,
  fetchSportelli,
  addSportelloOperatore,
  removeSportelloOperatore,
} from '../../../actions'

class CreateEditOperatore extends Component {
  constructor(props) {
    super(props)

    this.state = {
      templatePermessi: '',
      nuovoSportelloId: 0,
      isResponsabile: false,
    }
  }

  componentDidMount() {
    this.props.fetchSportelli()
    if (this.props.match.params.operatoreId) {
      this.props.fetchOperatore(this.props.match.params.operatoreId)
    }
  }

  clearNuovoSportello() {
    this.setState({
      nuovoSportelloId: 0,
    })
  }

  addSportello() {
    if (
      this.state.nuovoSportelloId &&
      !_find(this.props.sportelliOperatore, {
        sportelloId: parseInt(this.state.nuovoSportelloId, 10),
      })
    ) {
      this.props.addSportelloOperatore(
        this.props.match.params.operatoreId,
        this.state.nuovoSportelloId,
        this.state.isResponsabile
      )
    }
    this.clearNuovoSportello()
  }

  removeSportello(event, sportelloId) {
    event.preventDefault()
    this.props.removeSportelloOperatore(this.props.match.params.operatoreId, sportelloId)
  }

  onSubmit(values) {
    if (this.props.match.params.operatoreId) {
      // Siamo in update
      const {
        operatoreId,
        livello,
        utenteId,
        ultimoSportelloId,
        ultimoSportello,
        eliminatoFlag,
        sportelli,
        templatePermessi,
        ...rest
      } = values
      this.props.saveOperatore(rest, this.props.match.params.operatoreId).then((action) => {
        this.props.history.push(`/app/operatori`)
      })
    } else {
      const {templatePermessi, ...rest} = values
      // Siamo in new
      this.props.createOperatore(rest).then((action) => {
        this.props.history.push(`/app/operatori`)
      })
    }
  }

  renderSportelli() {
    if (this.props.sportelli) {
      return this.props.sportelli.map((sportello) => (
        <option key={sportello.sportelloId} value={sportello.sportelloId}>
          {sportello.organizzazione} {sportello.nome}
        </option>
      ))
    }
    return null
  }

  changeTemplate = (form, input) => (event) => {
    input.onChange(event.target.value)
    this.setState({ templatePermessi: event.target.value })
    switch (event.target.value) {
      case 'IAL': {
        form.change('permessoAnagraficaFlag', true)
        form.change('permessoStatoOccupazionaleFlag', true)
        form.change('permessoConoscenzeInformaticheFlag', true)
        form.change('permessoConoscenzeLinguisticheFlag', true)
        form.change('permessoInteressiCorsiFlag', true)
        form.change('permessoAltreCompetenzeFlag', true)
        form.change('permessoObiettiviProfessionaliFlag', true)
        form.change('permessoAltreInformazioniFlag', true)
        form.change('permessoUsoInternoFlag', true)
        form.change('permessoEsperienzeLavorativeFlag', true)
        form.change('permessoTitoliStudioFlag', true)
        form.change('permessoCorsiFormazioneFlag', true)
        form.change('permessoModificaAppuntamentiFlag', true)
        form.change('permessoModificaSlotFlag', true)
        form.change('permessoAllegatiFlag', true)
        form.change('permessoEliminaCandidatoFlag', true)
        form.change('permessoArchiviaCandidatoFlag', true)
        form.change('permessoCambiaSportelloFlag', true)
        form.change('permessoVisualizzazioneCandidatiAltriSportelliFlag', true)
        form.change('permessoVisualizzazioneJvCompleteFlag', true)
        form.change('permessoGestioneJvFlag', true)
        form.change('permessoGestioneJvHomeFlag', false)
        form.change('permessoPubblicazioneCandidatoFlag', false)
        form.change('permessoGestioneAziendeFlag', true)
        form.change('permessoGestioneAgenzieFlag', true)
        form.change('permessoNewsletterFlag', false)
        form.change('permessoInvioSmsFlag', false)
        break
      }
      case 'CISL': {
        form.change('permessoAnagraficaFlag', true)
        form.change('permessoStatoOccupazionaleFlag', false)
        form.change('permessoConoscenzeInformaticheFlag', false)
        form.change('permessoConoscenzeLinguisticheFlag', false)
        form.change('permessoInteressiCorsiFlag', false)
        form.change('permessoAltreCompetenzeFlag', false)
        form.change('permessoObiettiviProfessionaliFlag', false)
        form.change('permessoAltreInformazioniFlag', true)
        form.change('permessoUsoInternoFlag', false)
        form.change('permessoEsperienzeLavorativeFlag', false)
        form.change('permessoTitoliStudioFlag', false)
        form.change('permessoCorsiFormazioneFlag', false)
        form.change('permessoModificaAppuntamentiFlag', true)
        form.change('permessoModificaSlotFlag', false)
        form.change('permessoAllegatiFlag', true)
        form.change('permessoEliminaCandidatoFlag', false)
        form.change('permessoArchiviaCandidatoFlag', false)
        form.change('permessoCambiaSportelloFlag', true)
        form.change('permessoVisualizzazioneCandidatiAltriSportelliFlag', true)
        form.change('permessoVisualizzazioneJvCompleteFlag', false)
        form.change('permessoGestioneJvFlag', false)
        form.change('permessoGestioneJvHomeFlag', false)
        form.change('permessoPubblicazioneCandidatoFlag', false)
        form.change('permessoGestioneAziendeFlag', false)
        form.change('permessoGestioneAgenzieFlag', false)
        form.change('permessoNewsletterFlag', false)
        form.change('permessoInvioSmsFlag', false)
        break
      }
      default:
        break
    }
  }

  ///////////////////////////////////////////////////////////////
  // RENDER
  render() {
    const initialValues = this.props.initialValues ? this.props.initialValues : {}

    return (
      <div className="edit-operatore">
        <h1 className="page-title">Operatore</h1>

        <div className="card-box">
          <Form
            onSubmit={this.onSubmit.bind(this)}
            initialValues={initialValues}
            validate={validate}
            render={({ handleSubmit, form }) => (
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <Field
                      name="nome"
                      id="nome"
                      render={({ input, meta }) => (
                        <div className={`form-group ${meta.touched && meta.invalid ? 'has-error' : ''}`}>
                          <label htmlFor="nome">Nome</label>
                          <input type="text" className="form-control" {...input} />
                          <div className="help-block">{meta.touched && meta.error}</div>
                        </div>
                      )}
                    />
                  </div>
                  <div className="col-md-6">
                    <Field
                      name="cognome"
                      id="cognome"
                      render={({ input, meta }) => (
                        <div className={`form-group ${meta.touched && meta.invalid ? 'has-error' : ''}`}>
                          <label htmlFor="cognome">Cognome</label>
                          <input type="text" className="form-control" {...input} />
                          <div className="help-block">{meta.touched && meta.error}</div>
                        </div>
                      )}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="codiceFiscale">Codice fiscale</label>
                      <Field
                        type="text"
                        className="form-control"
                        id="codiceFiscale"
                        name="codiceFiscale"
                        component="input"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="indirizzo">Indirizzo</label>
                      <Field type="text" className="form-control" id="indirizzo" name="indirizzo" component="input" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="telefono">Telefono</label>
                      <Field type="text" className="form-control" id="telefono" name="telefono" component="input" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="cellulare">Cellulare</label>
                      <Field type="text" className="form-control" id="cellulare" name="cellulare" component="input" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Field
                      name="email"
                      id="email"
                      render={({ input, meta }) => (
                        <div className={`form-group ${meta.touched && meta.invalid ? 'has-error' : ''}`}>
                          <label htmlFor="email">E-mail</label>
                          <input type="email" className="form-control" {...input} />
                          <div className="help-block">{meta.touched && meta.error}</div>
                        </div>
                      )}
                    />
                  </div>
                  <div className="col-md-6">
                    <Field
                      name="password"
                      id="password"
                      render={({ input, meta }) => (
                        <div className={`form-group ${meta.touched && meta.invalid ? 'has-error' : ''}`}>
                          <label htmlFor="password">Password</label>
                          <input type="password" className="form-control" {...input} />
                          <div className="help-block">{meta.touched && meta.error}</div>
                        </div>
                      )}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="templatePermessi">Template permessi</label>
                      <Field
                        name="templatePermessi"
                        id="templatePermessi"
                        render={({ input }) => (
                          <select className="form-control" {...input} onChange={this.changeTemplate(form, input)}>
                            <option value="">-- Seleziona --</option>
                            <option value="CISL">Operatore CISL</option>
                            <option value="IAL">Operatore IAL</option>
                          </select>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="attivoFlag">Accesso al portale</label>
                      <div className="checkbox checkbox-primary">
                        <Field
                          type="checkbox"
                          className="form-control"
                          id="attivoFlag"
                          name="attivoFlag"
                          component="input"
                        />
                        <label htmlFor="attivoFlag">Attivo</label>
                      </div>
                    </div>
                  </div>
                </div>

                <h5 className="header-title">Permessi operatore</h5>
                <div className="row">
                  <div className="col-lg-3 col-sm-6">
                    <div className="form-group">
                      <div className="checkbox checkbox-primary">
                        <Field
                          type="checkbox"
                          className="form-control"
                          id="permessoAnagraficaFlag"
                          name="permessoAnagraficaFlag"
                          component="input"
                        />
                        <label htmlFor="permessoAnagraficaFlag">Modifica Anagrafica</label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="checkbox checkbox-primary">
                        <Field
                          type="checkbox"
                          className="form-control"
                          id="permessoStatoOccupazionaleFlag"
                          name="permessoStatoOccupazionaleFlag"
                          component="input"
                        />
                        <label htmlFor="permessoStatoOccupazionaleFlag">Modifica Stato occupazionale</label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="checkbox checkbox-primary">
                        <Field
                          type="checkbox"
                          className="form-control"
                          id="permessoConoscenzeInformaticheFlag"
                          name="permessoConoscenzeInformaticheFlag"
                          component="input"
                        />
                        <label htmlFor="permessoConoscenzeInformaticheFlag">Modifica Conoscenze informatiche</label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="checkbox checkbox-primary">
                        <Field
                          type="checkbox"
                          className="form-control"
                          id="permessoConoscenzeLinguisticheFlag"
                          name="permessoConoscenzeLinguisticheFlag"
                          component="input"
                        />
                        <label htmlFor="permessoConoscenzeLinguisticheFlag">Modifica Conoscenze linguistiche</label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="checkbox checkbox-primary">
                        <Field
                          type="checkbox"
                          className="form-control"
                          id="permessoInteressiCorsiFlag"
                          name="permessoInteressiCorsiFlag"
                          component="input"
                        />
                        <label htmlFor="permessoInteressiCorsiFlag">Modifica Interessi corsi</label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="checkbox checkbox-primary">
                        <Field
                          type="checkbox"
                          className="form-control"
                          id="permessoAltreCompetenzeFlag"
                          name="permessoAltreCompetenzeFlag"
                          component="input"
                        />
                        <label htmlFor="permessoAltreCompetenzeFlag">Modifica Altre competenze</label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="checkbox checkbox-primary">
                        <Field
                          type="checkbox"
                          className="form-control"
                          id="permessoCancellazioneNoteDiarioFlag"
                          name="permessoCancellazioneNoteDiarioFlag"
                          component="input"
                        />
                        <label htmlFor="permessoCancellazioneNoteDiarioFlag">Elimina note diario altri operatori</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6">
                    <div className="form-group">
                      <div className="checkbox checkbox-primary">
                        <Field
                          type="checkbox"
                          className="form-control"
                          id="permessoObiettiviProfessionaliFlag"
                          name="permessoObiettiviProfessionaliFlag"
                          component="input"
                        />
                        <label htmlFor="permessoObiettiviProfessionaliFlag">Modifica Obiettivi professionali</label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="checkbox checkbox-primary">
                        <Field
                          type="checkbox"
                          className="form-control"
                          id="permessoAltreInformazioniFlag"
                          name="permessoAltreInformazioniFlag"
                          component="input"
                        />
                        <label htmlFor="permessoAltreInformazioniFlag">Modifica Altre informazioni</label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="checkbox checkbox-primary">
                        <Field
                          type="checkbox"
                          className="form-control"
                          id="permessoUsoInternoFlag"
                          name="permessoUsoInternoFlag"
                          component="input"
                        />
                        <label htmlFor="permessoUsoInternoFlag">Modifica dati uso interno</label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="checkbox checkbox-primary">
                        <Field
                          type="checkbox"
                          className="form-control"
                          id="permessoEsperienzeLavorativeFlag"
                          name="permessoEsperienzeLavorativeFlag"
                          component="input"
                        />
                        <label htmlFor="permessoEsperienzeLavorativeFlag">Modifica Esperienze lavorative</label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="checkbox checkbox-primary">
                        <Field
                          type="checkbox"
                          className="form-control"
                          id="permessoTitoliStudioFlag"
                          name="permessoTitoliStudioFlag"
                          component="input"
                        />
                        <label htmlFor="permessoTitoliStudioFlag">Modifica Titoli di studio</label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="checkbox checkbox-primary">
                        <Field
                          type="checkbox"
                          className="form-control"
                          id="permessoCorsiFormazioneFlag"
                          name="permessoCorsiFormazioneFlag"
                          component="input"
                        />
                        <label htmlFor="permessoCorsiFormazioneFlag">Modifica Corsi di formazione</label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="checkbox checkbox-primary">
                        <Field
                          type="checkbox"
                          className="form-control"
                          id="permessoNewsletterFlag"
                          name="permessoNewsletterFlag"
                          component="input"
                        />
                        <label htmlFor="permessoNewsletterFlag">Gestione newsletter</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6">
                    <div className="form-group">
                      <div className="checkbox checkbox-primary">
                        <Field
                          type="checkbox"
                          className="form-control"
                          id="permessoAllegatiFlag"
                          name="permessoAllegatiFlag"
                          component="input"
                        />
                        <label htmlFor="permessoAllegatiFlag">Modifica Allegati</label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="checkbox checkbox-primary">
                        <Field
                          type="checkbox"
                          className="form-control"
                          id="permessoModificaAppuntamentiFlag"
                          name="permessoModificaAppuntamentiFlag"
                          component="input"
                        />
                        <label htmlFor="permessoModificaAppuntamentiFlag">Gestione Appuntamenti</label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="checkbox checkbox-primary">
                        <Field
                          type="checkbox"
                          className="form-control"
                          id="permessoModificaSlotFlag"
                          name="permessoModificaSlotFlag"
                          component="input"
                        />
                        <label htmlFor="permessoModificaSlotFlag">Gestione Slot appuntamenti</label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="checkbox checkbox-primary">
                        <Field
                          type="checkbox"
                          className="form-control"
                          id="permessoEliminaCandidatoFlag"
                          name="permessoEliminaCandidatoFlag"
                          component="input"
                        />
                        <label htmlFor="permessoEliminaCandidatoFlag">Eliminazione candidati</label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="checkbox checkbox-primary">
                        <Field
                          type="checkbox"
                          className="form-control"
                          id="permessoArchiviaCandidatoFlag"
                          name="permessoArchiviaCandidatoFlag"
                          component="input"
                        />
                        <label htmlFor="permessoArchiviaCandidatoFlag">Archiviazione candidati</label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="checkbox checkbox-primary">
                        <Field
                          type="checkbox"
                          className="form-control"
                          id="permessoCambiaSportelloFlag"
                          name="permessoCambiaSportelloFlag"
                          component="input"
                        />
                        <label htmlFor="permessoCambiaSportelloFlag">Presa in carico candidato</label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="checkbox checkbox-primary">
                        <Field
                          type="checkbox"
                          className="form-control"
                          id="permessoInvioSmsFlag"
                          name="permessoInvioSmsFlag"
                          component="input"
                        />
                        <label htmlFor="permessoInvioSmsFlag">Invio sms</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6">
                    {/* <div className="form-group">
                  <div className="checkbox checkbox-primary">
                    <input type="checkbox" id="permessoVisualizzazioneCandidatiAltriSportelliFlag" {...permessoVisualizzazioneCandidatiAltriSportelliFlag} />
                    <label htmlFor="permessoVisualizzazioneCandidatiAltriSportelliFlag">
                      Visualizzazione Candidati di altri sportelli
                    </label>
                  </div>
                </div> */}
                    <div className="form-group">
                      <div className="checkbox checkbox-primary">
                        <Field
                          type="checkbox"
                          className="form-control"
                          id="permessoVisualizzazioneJvCompleteFlag"
                          name="permessoVisualizzazioneJvCompleteFlag"
                          component="input"
                        />
                        <label htmlFor="permessoVisualizzazioneJvCompleteFlag">Visualizzazione JV complete</label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="checkbox checkbox-primary">
                        <Field
                          type="checkbox"
                          className="form-control"
                          id="permessoGestioneJvFlag"
                          name="permessoGestioneJvFlag"
                          component="input"
                        />
                        <label htmlFor="permessoGestioneJvFlag">Permesso gestione JV</label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="checkbox checkbox-primary">
                        <Field
                          type="checkbox"
                          className="form-control"
                          id="permessoGestioneJvHomeFlag"
                          name="permessoGestioneJvHomeFlag"
                          component="input"
                        />
                        <label htmlFor="permessoGestioneJvHomeFlag">Gestione JV Home sito pubblico</label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="checkbox checkbox-primary">
                        <Field
                          type="checkbox"
                          className="form-control"
                          id="permessoPubblicazioneCandidatoFlag"
                          name="permessoPubblicazioneCandidatoFlag"
                          component="input"
                        />
                        <label htmlFor="permessoPubblicazioneCandidatoFlag">Pubbl. candidati sito pubblico</label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="checkbox checkbox-primary">
                        <Field
                          type="checkbox"
                          className="form-control"
                          id="permessoGestioneAziendeFlag"
                          name="permessoGestioneAziendeFlag"
                          component="input"
                        />
                        <label htmlFor="permessoGestioneAziendeFlag">Gestione aziende</label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="checkbox checkbox-primary">
                        <Field
                          type="checkbox"
                          className="form-control"
                          id="permessoGestioneAgenzieFlag"
                          name="permessoGestioneAgenzieFlag"
                          component="input"
                        />
                        <label htmlFor="permessoGestioneAgenzieFlag">Gestione agenzie</label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="checkbox checkbox-primary">
                        <Field
                          type="checkbox"
                          className="form-control"
                          id="permessoStatisticheFlag"
                          name="permessoStatisticheFlag"
                          component="input"
                        />
                        <label htmlFor="permessoStatisticheFlag">Visualizza statistiche</label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="checkbox checkbox-primary">
                        <Field
                          type="checkbox"
                          className="form-control"
                          id="permessoGestioneCorsiFormazioneFlag"
                          name="permessoGestioneCorsiFormazioneFlag"
                          component="input"
                        />
                        <label htmlFor="permessoGestioneCorsiFormazioneFlag">Gestione corsi formazione</label>
                      </div>
                    </div>
                  </div>
                </div>

                <h5 className="header-title">Livello visibilità candidati</h5>
                <div className="form-group">
                  <Field
                    name="livelloVisibilitaCandidati"
                    render={({ input }) => (
                      <select className="form-control" {...input}>
                        <option value="tutta la rete">Tutta la rete</option>
                        <option value="regione">Regione</option>
                        <option value="sportello">Sportello</option>
                      </select>
                    )}
                  />
                </div>

                <button type="submit" className="btn btn-primary btn-salva">
                  Salva operatore
                </button>

                {this.props.match.params.operatoreId && this.props.sportelliOperatore ? (
                  <div>
                    <h5 className="header-title">Sportelli assegnati</h5>
                    <div className="row">
                      <div className="col-md-6">
                        <ul className="lista-sportelli">
                          {this.props.sportelliOperatore.map((sportello) => (
                            <li key={sportello.sportelloId}>
                              <MdStore />
                              {sportello.nome} {!!sportello.isResponsabile && <FaStar className="responsabile" />}
                              <a
                                className="remove"
                                href="/#"
                                onClick={(event) => this.removeSportello(event, sportello.sportelloId)}
                              >
                                &times;
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="col-md-6">
                        <select
                          className="form-control"
                          value={this.state.nuovoSportelloId}
                          onChange={(event) => this.setState({ nuovoSportelloId: event.target.value })}
                        >
                          <option value="0">-- Seleziona sportello --</option>
                          {this.renderSportelli()}
                        </select>
                        <div className="form-group">
                          <div className="checkbox checkbox-primary">
                            <input
                              type="checkbox"
                              id="isResponsabile"
                              checked={this.state.isResponsabile}
                              onChange={(event) => this.setState({ isResponsabile: event.target.checked })}
                            />
                            <label htmlFor="isResponsabile">Responsabile</label>
                          </div>
                        </div>
                        <button
                          type="button"
                          className="btn btn-success btn-aggiungi-sportello"
                          onClick={this.addSportello.bind(this)}
                        >
                          <MdAdd />
                          Aggiungi sportello
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}
              </form>
            )}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    initialValues: state.operatoreAttivo,
    sportelliOperatore: state.operatoreAttivo.sportelli,
    sportelli: state.sportelli,
  }
}

function validate(values) {
  const errors = {}

  if (!values.nome) {
    errors.nome = 'Inserisci il nome'
  }
  if (!values.cognome) {
    errors.cognome = 'Inserisci il cognome'
  }

  if (!values.email) {
    errors.email = 'Inserisci un indirizzo email'
  }

  if (!values.password) {
    errors.password = 'Inserisci una password'
  }

  return errors
}

export default connect(mapStateToProps, {
  fetchOperatore,
  createOperatore,
  saveOperatore,
  fetchSportelli,
  addSportelloOperatore,
  removeSportelloOperatore,
})(CreateEditOperatore)
