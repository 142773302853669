import React, { Component, PropTypes } from 'react'
import { connect } from 'react-redux'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'

import { fetchSlots, fetchSportelli } from '../../actions'

import 'react-big-calendar/lib/css/react-big-calendar.css'

const Event = ({ event }) => {
  return (
    <span>
      <p className="descrizione">{event.title}</p>
      {event.numeroAppuntamenti ? (
        <p className="numero-appuntamenti">{`${event.numeroAppuntamenti} / ${event.numeroMassimoCandidati}`}</p>
      ) : null}
    </span>
  )
}

const localizer = momentLocalizer(moment)

class CalendarioAppuntamenti extends Component {
  constructor(props) {
    super(props)

    // Voglio l'ora su 24 ore
    let timeRangeFormat = ({ start, end }, culture, local) =>
      local.format(start, 'HH:mm', culture) + ' - ' + local.format(end, 'HH:mm', culture)

    this.state = {
      slotSelectable: false,

      messages: {
        date: 'Data',
        time: 'Ora',
        event: 'Appuntamento',
        allDay: 'Tutto il giorno',
        previous: '<',
        next: '>',
        today: 'Oggi',
        month: 'Mese',
        week: 'Settimana',
        day: 'Giorno',
        agenda: 'Agenda',
      },

      formats: {
        selectRangeFormat: timeRangeFormat,
        eventTimeRangeFormat: timeRangeFormat,
        agendaTimeFormat: timeRangeFormat,
        agendaTimeRangeFormat: timeRangeFormat,
      },

      events: this.props.events,
      filtroSportelloId: this.props.sportelloCorrenteId,
    }
  }

  componentDidMount() {
    
    // Calendar.momentLocalizer(moment)
    this.props.fetchSlots(this.props.sportelloCorrenteId)
    // if (this.props.isOperatore) {
    //   this.props.fetchSportelli();
    // }
  }

  changeSlotSelectable() {
    this.setState({
      slotSelectable: !this.state.slotSelectable,
    })
  }

  creaSlotAppuntamenti(slot) {
    // After selecting, DaySlot fires a setState (to set "selecting" to false)
    // ==> I have to wait a moment, otherwise I receive an error because of
    // the setState on Unmounted component :S
    setTimeout(() => {
      const start = moment(slot.start).format('YYYY-MM-DD HH:mm')
      const end = moment(slot.end).format('YYYY-MM-DD HH:mm')
      this.props.history.push(`/app/appuntamenti/new?start=${start}&end=${end}`)
    }, 100)
  }

  modificaSlotAppuntamenti(slotId) {
    if (this.props.canEditAppuntamenti) {
      this.props.history.push(`/app/appuntamenti/${slotId}`)
    }
  }

  changeFiltroSportelloId(filtroSportelloId) {
    this.setState({ filtroSportelloId: filtroSportelloId })
    this.props.fetchSlots(filtroSportelloId)
  }

  renderSportelliOptions() {
    if (this.props.sportelli) {
      return this.props.sportelli.map((sportello) => (
        <option key={sportello.sportelloId} value={sportello.sportelloId}>
          {sportello.nome}
        </option>
      ))
    }
    return null
  }

  render() {
    return (
      <div className="calendario">
        {this.props.isOperatore ? (
          <div className="form-inline">
            Sportello:
            <select
              className="form-control"
              value={this.state.filtroSportelloId}
              onChange={(event) => this.changeFiltroSportelloId(event.target.value)}
            >
              {this.renderSportelliOptions()}
            </select>
          </div>
        ) : null}

        <div className="card-box" style={{ height: 550 + 'px' }}>
          <Calendar
            localizer={localizer}
            selectable={this.state.slotSelectable}
            events={this.props.events}
            defaultView="week"
            messages={this.state.messages}
            formats={this.state.formats}
            min={new Date(2016, 1, 1, 8, 0, 0)}
            max={new Date(2016, 1, 1, 20, 0, 0)}
            components={{ event: Event }}
            onSelectSlot={(slot) => this.creaSlotAppuntamenti(slot)}
            onSelectEvent={(event) => this.modificaSlotAppuntamenti(event.slotId)}
          />
        </div>

        <div className="checkbox checkbox-primary">
          <input
            type="checkbox"
            id="slotSelectable"
            checked={this.state.slotSelectable}
            onChange={this.changeSlotSelectable.bind(this)}
          />
          {this.props.canEditSlot &&
          parseInt(this.state.filtroSportelloId, 10) === this.props.sportelloCorrenteId ? (
            <label htmlFor="slotSelectable">Abilita creazione slot</label>
          ) : null}
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    events: state.slots,
    sportelli: state.auth.user.sportelli,
    sportelloCorrenteId: state.auth.user.sportelloCorrente.sportelloId,
    canEditSlot: !!state.auth.user.permessi['modificaSlot'],
    canEditAppuntamenti: !!state.auth.user.permessi['modificaAppuntamenti'],
    isOperatore: state.auth.user.ruolo === 'operatore',
  }
}

export default connect(mapStateToProps, { fetchSlots, fetchSportelli })(CalendarioAppuntamenti)
