import React from 'react'

import { API_ROOT_URL } from '../../../actions'

const PannelloExportCsv = ({ onChange, filtri, aree, sportelli }) => {
  const renderUrlExportCsv = (endpoint = 'candidati') => {
    const { areaGeograficaId, sportelloId } = filtri
    const areaGeografica = areaGeograficaId ? `&area_geografica_id=${areaGeograficaId}` : ''
    const sportello = sportelloId ? `&sportello_id=${sportelloId}` : ''
    const token = localStorage.getItem('token')
    const href = `${API_ROOT_URL}/${endpoint}?t=${token}${areaGeografica}${sportello}${
      endpoint.startsWith('candidati') ? '&export=csv' : ''
    }`
    return href
  }

  return (
    <div className="pannello-export-csv card-box">
      <div className="row">
        <div className="col-md-3 col-lg-4">
          <div className="form-group">
            <label htmlFor="organizzazione">Area geografica</label>
            <select
              id="areaGeografica"
              className="form-control"
              value={filtri.areaGeograficaId}
              onChange={onChange('areaGeograficaId')}
            >
              <option value="">-- Tutte --</option>
              {aree.map((areaGeografica) => {
                return (
                  <option key={areaGeografica.areaGeograficaId} value={areaGeografica.areaGeograficaId}>
                    {areaGeografica.nome} - {areaGeografica.regione}
                  </option>
                )
              })}
            </select>
          </div>
        </div>
        <div className="col-md-3 col-lg-4">
          <div className="form-group">
            <label htmlFor="organizzazione">Sportello</label>
            <select
              id="sportelli"
              className="form-control"
              value={filtri.sportelloId}
              onChange={onChange('sportelloId')}
            >
              <option value="">-- Tutti --</option>
              {sportelli
                .filter((sportello) => {
                  return (
                    !filtri.areaGeograficaId || sportello.areaGeograficaId === parseInt(filtri.areaGeograficaId, 10)
                  )
                })
                .map((sportello) => (
                  <option value={sportello.sportelloId} key={sportello.sportelloId}>
                    {sportello.organizzazione} - {sportello.nome}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div className="col-sm-6 col-md-3 col-lg-2">
          <a href={renderUrlExportCsv()} target="_blank" className="btn btn-primary" rel="noreferrer">
            Esporta candidati
          </a>
        </div>
        <div className="col-sm-6 col-md-3 col-lg-2">
          <a
            href={renderUrlExportCsv('note_diario/export')}
            target="_blank"
            className="btn btn-primary"
            rel="noreferrer"
          >
            Esporta note diario
          </a>
        </div>
      </div>
    </div>
  )
}

export default PannelloExportCsv
