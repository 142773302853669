import { FETCH_SUBAMMINISTRATORI, DELETE_SUBAMMINISTRATORE } from '../actions/types';

const INITIAL_STATE = [];

export default function(state = INITIAL_STATE, action) {
	switch(action.type) {

		case FETCH_SUBAMMINISTRATORI:
			return action.payload.data;

    case DELETE_SUBAMMINISTRATORE:
			return state.filter(subAmministratore => subAmministratore.amministratoreId !== action.payload);

		default:
			return state;
	}
}
