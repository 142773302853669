import React, { Component } from 'react'
import { Form, Field } from 'react-final-form'
import { connect } from 'react-redux'

import BottoneAnnulla from './bottone_annulla'
import { fetchCandidato, saveConoscenzeInformatiche } from '../../actions'

class EditConoscenzeInformatiche extends Component {
  // static contextTypes = {
  // 	router: PropTypes.object
  // };

  componentDidMount() {
    // Ho già il candidato attivo se navigo normalmente
    // dal dettaglio candidato: questo controllo serve
    // nel caso in cui ricarico la pagina da qui (F5)
    if (!this.props.candidatoId) {
      this.props.fetchCandidato(this.props.match.params.id)
    }
  }

  onSubmit(props) {
    const { conoscenze, ...rest } = props

    this.props.saveConoscenzeInformatiche(this.props.match.params.id, rest).then((action) => {
      this.props.history.goBack()
    })
  }

  render() {
    const initialValues = this.props.initialValues ? this.props.initialValues : {}

    return (
      <Form
        onSubmit={this.onSubmit.bind(this)}
        initialValues={initialValues}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="heading">
              <h1 className="page-title pull-left">Conoscenze Informatiche</h1>
              <BottoneAnnulla />
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="panel panel-border panel-primary">
                  <div className="panel-heading">
                    <h3 className="panel-title">Eventuali certificazioni</h3>
                  </div>
                  <div className="panel-body">
                    <div className="form-group">
                      <Field
                        className="form-control"
                        rows="6"
                        id="eventualiCertificazioni"
                        name="eventualiCertificazioni"
                        component="textarea"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="panel panel-border panel-primary">
                  <div className="panel-heading">
                    <h3 className="panel-title">Descrizione CV</h3>
                  </div>
                  <div className="panel-body">
                    <div className="form-group">
                      <Field
                        className="form-control"
                        rows="6"
                        id="descrizioneCv"
                        name="descrizioneCv"
                        component="textarea"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-center">
              <button type="submit" className="btn btn-primary btn-lg">
                Salva conoscenze informatiche &raquo;
              </button>
            </div>
          </form>
        )}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    initialValues: state.candidatoAttivo.conoscenzeInformatiche,
    candidatoId: state.candidatoAttivo.candidatoId, // per sapere se ho già il candidato attivo su F5
  }
}

export default connect(mapStateToProps, { fetchCandidato, saveConoscenzeInformatiche })(
  EditConoscenzeInformatiche
)
