import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Ink from 'react-ink'

import { MdModeEdit } from 'react-icons/md'

class CvSintetico extends Component {
  render() {
    const { candidatoId, descrizioneCvSintetico, canEdit } = this.props

    // if (!descrizioneCvSintetico) {
    //   return <div>Caricamento in corso...</div>;
    // }
    return (
      <div className="card-box cv-sintetico">
        <div className="cv-sintetico">
          <h4>CV SINTETICO</h4>
          <p>{descrizioneCvSintetico}</p>
        </div>

        <div className="text-right">
          {canEdit ? (
            <Link
              to={`/app/candidati/${candidatoId}/cv-sintetico`}
              className="btn btn-sm btn-success"
            >
              <Ink />
              <MdModeEdit />
              Modifica CV sintetico
            </Link>
          ) : null}
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    candidatoId: state.candidatoAttivo.candidatoId,
    descrizioneCvSintetico: state.candidatoAttivo.descrizioneCvSintetico,
    canEdit:
      state.candidatoAttivo.sportello &&
      state.candidatoAttivo.sportello.sportelloId === state.auth.user.sportelloCorrente.sportelloId,
  }
}

export default connect(mapStateToProps)(CvSintetico)
