import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Ink from 'react-ink'
import swal from 'sweetalert'
import { MdModeEdit, MdRemoveCircle } from 'react-icons/md'

import { removeCorsoFormazione } from '../../actions'

class CorsoFormazione extends Component {
  onRemove() {
    this.props.removeCorsoFormazione(
      this.props.corsoFormazione.corsoFormazioneId,
      this.props.candidatoId
    )
  }

  handleDeleteCorsoFormazione = () => {
    swal({
      icon: 'warning',
      title: 'Eliminazione corso di formazione',
      text: 'Sei sicuro di vole eliminare questo corso di formazione? Questa azione è irreversibile.',
      buttons: ['Annulla', 'Si, elimina corso di formazione'],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onRemove()
      }
    })
  }

  render() {
    const { corsoFormazione, candidatoId, canEdit } = this.props

    return (
      <div className={'panel panel-color panel-purple panel-show-section'}>
        <div className="panel-heading">
          <h3 className="panel-title">
            {corsoFormazione.titolo}
            {canEdit ? (
              <span className="pull-right">
                <Link
                  to={`/app/candidati/${candidatoId}/corsi-formazione/${corsoFormazione.corsoFormazioneId}`}
                  className="btn btn-sm btn-purple"
                >
                  <MdModeEdit />
                  <span>Modifica</span>
                </Link>
                <a
                  className="btn btn-sm btn-purple btn-remove"
                  onClick={() => this.handleDeleteCorsoFormazione()}
                >
                  <MdRemoveCircle />
                  <span>Elimina</span>
                </a>
              </span>
            ) : null}
          </h3>
        </div>
        <div className="panel-body">
          <div className="row">
            <div className="col-sm-6">
              <p>Presso {corsoFormazione.enteErogatore}</p>
              <p>
                Dal {corsoFormazione.dataInizioMese}/{corsoFormazione.dataInizioAnno} al{' '}
                {corsoFormazione.dataFineMese}/{corsoFormazione.dataFineAnno}
              </p>
              <p>Attestazione: {corsoFormazione.attestazioneRilasciata}</p>
            </div>
            <div className="col-sm-6">
              <div className="dettagli">
                <p>{corsoFormazione.descrizioneCv || 'Nessuna descrizione CV'}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(null, { removeCorsoFormazione })(CorsoFormazione)
