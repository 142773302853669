import cogoToast from 'cogo-toast'
import _uniqBy from 'lodash/uniqBy'
import moment from 'moment'
import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import { connect } from 'react-redux'
import swal from 'sweetalert'
import {
  addParagrafoNewsletter,
  addSportelloNewsletter,
  createNewsletter,
  fetchGruppi,
  fetchNewsletter,
  fetchNumeroDestinatari,
  removeParagrafoNewsletter,
  removeSportelloNewsletter,
  resetNewsletter,
  saveNewsletter,
  sendNewsletter,
  updateNewsletter,
  updateParagrafoNewsletter,
  uploadAllegatoNewsletter,
  removeAllegatoNewsletter,
} from '../../actions'
import Paragrafo from './paragrafo'
import SportelloDaSelezionare from './sportello_da_selezionare'
import SportelloSelezionato from './sportello_selezionato'

class NewsletterDetails extends Component {
  constructor(props) {
    super(props)

    this.state = {
      areaGeograficaSelezionata: '',
    }
  }

  componentDidMount() {
    this.props.fetchGruppi(true)
    this.props.resetNewsletter()

    if (this.props.match.params.newsletterId) {
      this.props.fetchNewsletter(this.props.match.params.newsletterId).then(() => {
        this.calcolaDestinatari()
      })
    }
  }

  calcolaDestinatari() {
    let destinatari = []

    if (this.props.newsletter.gruppoCvSinteticoId) {
      destinatari = {
        gruppoCvSinteticoId: this.props.newsletter.gruppoCvSinteticoId,
      }
    } else {
      destinatari = {
        sportelli: this.props.newsletter.sportelli.map((d) => d.sportelloId),
      }
    }
    this.props.fetchNumeroDestinatari(destinatari)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.newsletterId !== this.props.match.params.newsletterId) {
      this.props.fetchNewsletter(this.props.match.params.newsletterId).then(() => {
        this.calcolaDestinatari()
      })
    }

    if (
      prevProps.newsletter.gruppoCvSinteticoId !== this.props.newsletter.gruppoCvSinteticoId ||
      prevProps.newsletter.sportelli !== this.props.newsletter.sportelli
    ) {
      this.calcolaDestinatari()
    }
  }

  getAreeGeografiche() {
    // Prendo gli sportelli non ancora selezionati
    // Faccio la unique sull'areaGeograficaId
    // E creo quindi un array di oggetti con areaGeograficaId, nome
    return _uniqBy(
      this.props.sportelli.filter((sportello) => {
        return !this.props.newsletter.sportelli.find((s) => s.sportelloId === sportello.sportelloId)
      }),
      'areaGeograficaId'
    ).map((s) => ({
      areaGeograficaId: s.areaGeograficaId,
      nome: s.areaGeograficaNome,
    }))
  }

  getSportelliNonSelezionatiPerAreaGeografica() {
    return this.props.sportelli.filter((sportello) => {
      const isSelezionato = !!this.props.newsletter.sportelli.find((s) => s.sportelloId === sportello.sportelloId)

      return (
        !isSelezionato &&
        (!this.state.areaGeograficaSelezionata ||
          sportello.areaGeograficaId === parseInt(this.state.areaGeograficaSelezionata))
      )
    })
  }

  handleChangeAreaGeografica = (event) => {
    this.setState({
      areaGeograficaSelezionata: event.target.value,
    })
  }

  handleAddAllSportelli = (event) => {
    this.getSportelliNonSelezionatiPerAreaGeografica().forEach((sportello) => {
      this.props.addSportelloNewsletter(sportello)
    })
  }

  handleChange = (event) => {
    this.props.updateNewsletter(event.target.name, event.target.value)
  }

  handleAddParagrafo = (event) => {
    event.preventDefault()
    this.props.addParagrafoNewsletter()
  }

  handleSalva = (event) => {
    event.preventDefault()

    if (this.props.match.params.newsletterId) {
      this.props.saveNewsletter(this.props.newsletter).then(() => this.props.history.push('/app/newsletter'))
    } else {
      this.props.createNewsletter(this.props.newsletter).then(() => this.props.history.push('/app/newsletter'))
    }
  }

  handleSendProva = (event) => {
    event.preventDefault()

    this.props.sendNewsletter(this.props.newsletter, true).then((res) => {
      if (res.payload.status === 200) {
        cogoToast.success("L'invio di prova è stato accodato correttamente", {
          heading: 'Prova inviata',
          position: 'top-right',
          hideAfter: 5,
        })

        if (res.payload.data.erroriInvio.length > 0) {
          cogoToast.warn("E' avvenuto almeno un errore nell'invio. Contattare l'assistenza", {
            heading: 'Errori invio',
            position: 'top-right',
            hideAfter: 5,
          })
        }

        if (!this.props.match.params.newsletterId && res.payload.data.newsletterId) {
          this.props.history.push(`/app/newsletter/${res.payload.data.newsletterId}`)
        }
      }
    })
  }

  handleConfirmSend = () => {
    this.props.sendNewsletter(this.props.newsletter, false).then((res) => {
      if (res.payload.status === 200) {
        cogoToast.success("L'invio è stato accodato correttamente", {
          heading: 'Invio accodato',
          position: 'top-right',
          hideAfter: 5,
        })

        if (res.payload.data.erroriInvio.length > 0) {
          cogoToast.warn("E' avvenuto almeno un errore nell'invio. Contattare l'assistenza", {
            heading: 'Errori invio',
            position: 'top-right',
            hideAfter: 5,
          })
        }

        this.props.history.push('/app/newsletter')

        // if (!this.props.match.params.newsletterId && res.payload.data.newsletterId) {
        //   this.props.history.push(`/newsletter/${res.payload.data.newsletterId}`)
        // }
      }
    })
  }

  handleAlertInvioNewsletter = (newsletter) => {
    swal({
      type: 'warning',
      title: 'Invio newsletter',
      text: `Stai per inviare una newsletter a ${newsletter.numeroDestinatari} candidati. Vuoi procedere?`,
      buttons: ['Annulla', 'Sì, invia newsletter'],
    }).then((willInvite) => {
      if (willInvite) {
        this.handleConfirmSend()
      }
    })
  }

  onDropFile = (allegatoId) => (files) => {
    const value = []
    let valueToBeRendered = ''
    files.forEach((file) => {
      value.push(file)
      valueToBeRendered = file.path
    })

    this.props.uploadAllegatoNewsletter(allegatoId, value, valueToBeRendered)
  }

  handleRemoveAllegato = (allegatoId) => {
    swal({
      icon: 'warning',
      title: 'Eliminazione allegato',
      text: 'Sei sicuro di vole eliminare questo allegato? Questa azione è irreversibile.',
      buttons: ['Annulla', 'Si, elimina allegato'],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.props.removeAllegatoNewsletter(allegatoId)
      }
    })
  }

  renderAllegati = (allegatoId, newsletter) => {
    if (newsletter[allegatoId] && newsletter[allegatoId].length > 0) {
      return (
        <li>
          <a rel="noreferrer" target="_blank" href={newsletter[allegatoId]}>
            {/* {{allegato.path} ({(allegato.size / (1024 * 1024)).toFixed(2)} MB)} */}
            {newsletter[`${allegatoId}rendered`] ? newsletter[`${allegatoId}rendered`] : newsletter[allegatoId].split('/')?.slice(-1)?.[0]}
          </a>
          <a
            className="remove-link"
            href={newsletter[allegatoId] || '/#'}
            onClick={(event) => {
              event.preventDefault()

              this.handleRemoveAllegato(allegatoId)
            }}
          >
            &times;
          </a>
        </li>
      )
    }
  }

  render() {
    const { newsletter, gruppi, emailOperatore } = this.props

    return (
      <div className="newsletter-details">
        <h1 className="page-title">Invio Newsletter</h1>
        <form>
          <div className="panel detinatari panel-border panel-primary">
            <div className="panel-heading">
              <h3 className="panel-title">Oggetto</h3>
            </div>
            <div className="panel-body">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="oggetto"
                  name="oggetto"
                  value={newsletter.oggetto}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>
          <div className="panel detinatari panel-border panel-primary">
            <div className="panel-heading">
              <h3 className="panel-title">Allegati</h3>
            </div>
            <div className="panel-body">
              <div className="row">
                <div className="col-md-6">
                  <h4>ALLEGATO 1</h4>
                  {!(newsletter['allegato1'] && newsletter['allegato1'].length > 0) && (
                    <div className="dropzone">
                      <Dropzone
                        maxSize={500000}
                        multiple={false}
                        onDropRejected={(files) => {
                          files[0].errors.forEach((err) =>
                            cogoToast.error(
                              err.code === 'file-too-large' && 'Il file deve essere più piccolo di 500KB',
                              {
                                heading: 'Errori invio',
                                position: 'top-right',
                                hideAfter: 5,
                              }
                            )
                          )
                        }}
                        onDropAccepted={this.onDropFile('allegato1')}
                        accept={['.pdf']}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()}>
                            Trascina qui i file
                            <input {...getInputProps()} />
                            <br />
                            oppure clicca per selezionarli
                          </div>
                        )}
                      </Dropzone>
                    </div>
                  )}
                  <div>{this.renderAllegati('allegato1', newsletter)}</div>
                </div>

                <div className="col-md-6">
                  <h4>ALLEGATO 2</h4>
                  {!(newsletter['allegato2'] && newsletter['allegato2'].length > 0) && (
                    <div className="dropzone">
                      <Dropzone
                        maxSize={500000}
                        multiple={false}
                        onDropAccepted={this.onDropFile('allegato2')}
                        onDropRejected={(files) => {
                          files[0].errors.forEach((err) =>
                            cogoToast.error(
                              err.code === 'file-too-large' && 'Il file deve essere più piccolo di 500KB',
                              {
                                heading: 'Errori invio',
                                position: 'top-right',
                                hideAfter: 5,
                              }
                            )
                          )
                        }}
                        accept={['.pdf']}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()}>
                            Trascina qui i file
                            <input {...getInputProps()} />
                            <br />
                            oppure clicca per selezionarli
                          </div>
                        )}
                      </Dropzone>
                    </div>
                  )}
                  <div>{this.renderAllegati('allegato2', newsletter)}</div>
                </div>
              </div>
            </div>
          </div>

          <div className="panel destinatari panel-border panel-primary">
            <div className="panel-heading">
              <h3 className="panel-title">Selezione destinatari</h3>
            </div>
            <div className="panel-body">
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <div className="radio radio-primary radio-inline">
                      <input
                        type="radio"
                        id="tipo_sportelli"
                        name="tipoDestinatari"
                        value="sportelli"
                        checked={newsletter.tipoDestinatari === 'sportelli'}
                        onChange={this.handleChange}
                      />
                      <label htmlFor="tipo_sportelli">Sportelli</label>
                    </div>
                    <div className="radio radio-primary radio-inline">
                      <input
                        type="radio"
                        id="tipo_gruppo"
                        name="tipoDestinatari"
                        value="gruppo"
                        checked={newsletter.tipoDestinatari === 'gruppo'}
                        onChange={this.handleChange}
                      />
                      <label htmlFor="tipo_gruppo">Gruppo</label>
                    </div>
                  </div>

                  {newsletter.tipoDestinatari === 'gruppo' ? (
                    <div className="selezione-gruppo">
                      {gruppi.length > 0 ? (
                        <select
                          id="gruppoCvSinteticoId"
                          name="gruppoCvSinteticoId"
                          className="form-control"
                          value={newsletter.gruppoCvSinteticoId}
                          onChange={this.handleChange}
                        >
                          <option value="">-- Seleziona un gruppo --</option>
                          {gruppi.map((gruppo) => (
                            <option key={gruppo.gruppoCvSinteticoId} value={gruppo.gruppoCvSinteticoId}>
                              {gruppo.nome}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <div>Nessun gruppo presente</div>
                      )}
                    </div>
                  ) : (
                    <div className="selezione-sportelli">
                      <select
                        id="areaGeografica"
                        className="form-control"
                        value={this.state.areaGeograficaSelezionata}
                        onChange={this.handleChangeAreaGeografica}
                      >
                        <option value="">-- Tutte le regioni --</option>
                        {this.getAreeGeografiche().map((a) => (
                          <option key={a.areaGeograficaId} value={a.areaGeograficaId}>
                            {a.nome}
                          </option>
                        ))}
                      </select>

                      <button
                        type="button"
                        className="btn btn-default"
                        style={{ margin: '15px 0' }}
                        onClick={this.handleAddAllSportelli}
                      >
                        Aggiungi tutti gli sportelli visualizzati
                      </button>

                      <table className="table">
                        <tbody>
                          {this.getSportelliNonSelezionatiPerAreaGeografica().map((s) => (
                            <SportelloDaSelezionare
                              key={s.sportelloId}
                              sportello={s}
                              onAddSportello={this.props.addSportelloNewsletter}
                            />
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
                <div className="col-sm-6">
                  <div className="destinatari-selezionati">
                    <h3>Destinatari selezionati</h3>
                    {newsletter.gruppoCvSinteticoId && gruppi && gruppi.length > 0 ? (
                      <div className="gruppo-selezionato">
                        Gruppo{' '}
                        {gruppi.find((g) => g.gruppoCvSinteticoId === parseInt(newsletter.gruppoCvSinteticoId)).nome}
                      </div>
                    ) : (
                      <div className="sportelli-selezionati">
                        {newsletter.sportelli.map((s) => (
                          <SportelloSelezionato
                            key={s.sportelloId}
                            sportello={s}
                            onRemoveSportello={this.props.removeSportelloNewsletter}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="panel paragrafi panel-border panel-primary">
            <div className="panel-heading">
              <h3 className="panel-title">Contenuto newsletter</h3>
            </div>
            <div className="panel-body">
              {newsletter.paragrafi.map((paragrafo) => (
                <Paragrafo
                  key={paragrafo.paragrafoId}
                  paragrafo={paragrafo}
                  onChange={this.props.updateParagrafoNewsletter}
                  onDelete={this.props.removeParagrafoNewsletter}
                />
              ))}
              <button type="button" className="btn btn-success" onClick={this.handleAddParagrafo}>
                Aggiungi paragrafo
              </button>
            </div>
          </div>

          <div className="panel paragrafi panel-border panel-danger">
            {newsletter.dataInvio ? (
              <div className="inviata">
                <div className="panel-heading">
                  <h3 className="panel-title">
                    Inviata il {moment(newsletter.dataInvio).format('DD/MM/YYYY')} a {newsletter.numeroDestinatari}{' '}
                    destinatari.
                  </h3>
                </div>
                <div className="panel-body">
                  <p>La newsletter è già stata inviata e non può essere modificata.</p>
                </div>
              </div>
            ) : (
              <div className="non-inviata">
                <div className="panel-heading">
                  <h3 className="panel-title">Pronti per l'invio?</h3>
                </div>
                {!newsletter.oggetto || newsletter.numeroDestinatari === 0 || newsletter.paragrafi.length === 0 ? (
                  <div className="panel-body">
                    <p>Non siamo ancora pronti per l'invio: manca qualcosa di importante.</p>
                    <p style={{ marginBottom: 10 }}>Controlla che ci siano:</p>
                    <ul>
                      <li>Oggetto</li>
                      <li>Almeno un destinatario</li>
                      <li>Almeno un paragrafo di contenutoa</li>
                    </ul>
                  </div>
                ) : (
                  <div className="panel-body">
                    <div className="row">
                      <div className="col-sm-6">
                        <button type="button" className="btn btn-primary btn-lg btn-block" onClick={this.handleSalva}>
                          Salva
                        </button>
                      </div>
                      <div className="col-sm-6">
                        <button
                          type="button"
                          className="btn btn-default btn-lg btn-block"
                          onClick={this.handleSendProva}
                        >
                          Invia una prova a {emailOperatore}
                        </button>
                      </div>
                    </div>

                    <div className="invia">
                      <button
                        type="button"
                        className="btn btn-danger btn-lg btn-block"
                        onClick={() => this.handleAlertInvioNewsletter(newsletter)}
                      >
                        Salva e invia a {newsletter.numeroDestinatari} candidati
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </form>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  newsletter: state.newsletterAttiva,
  sportelli: state.auth.user.sportelli,
  gruppi: state.gruppi,
  emailOperatore: state.auth.user.email,
})

const actions = {
  resetNewsletter,
  fetchNewsletter,
  fetchNumeroDestinatari,
  createNewsletter,
  saveNewsletter,
  sendNewsletter,
  updateNewsletter,
  addParagrafoNewsletter,
  removeParagrafoNewsletter,
  updateParagrafoNewsletter,
  addSportelloNewsletter,
  removeSportelloNewsletter,
  uploadAllegatoNewsletter,
  fetchGruppi,
  removeAllegatoNewsletter,
}

export default connect(mapStateToProps, actions)(NewsletterDetails)
