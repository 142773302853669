import React, { Component } from 'react'
import { connect } from 'react-redux'
import { clearNotification, logoutUser } from '../../actions'
import NotificationSystem from 'react-notification-system'

class Notification extends Component {
  // componentDidUpdate(prevProps) {
  //   if (this.props.notification && prevProps.notification !== this.props.notification && this.props.notification.level) {

  //     const { title, message, level, position, autoDismiss } = this.props.notification;

  //     this.refs.notificationSystem.addNotification({
  //       title,
  //       message,
  //       level,
  //       position,
  //       autoDismiss
  //     });

  //     // Notifica segnalata => la cancello
  //     this.props.clearNotification();

  //   }
  // }

  render() {
    return <NotificationSystem ref="notificationSystem" />
  }
}

function mapStateToProps(state) {
  return {
    notification: state.notification,
  }
}

export default connect(mapStateToProps, { clearNotification, logoutUser })(Notification)
