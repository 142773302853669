import { FETCH_NOTIFICHE, CHANGE_PAGE_NOTIFICHE, FETCH_NOTIFICHE_NON_LETTE, SET_NOTIFICA_LETTA } from '../actions/types'

const INITIAL_STATE = {
  notifiche: [],
  notificheNonLette: [],
  pagination: {
    page: 1,
    totalPages: 1,
  },
  pageRequested: 1,
}

export default function notificheReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_NOTIFICHE:
      return {
        ...state,
        notifiche: action.payload.data.items,
        pagination: action.payload.data.pagination,
      }
    case CHANGE_PAGE_NOTIFICHE:
      return {
        ...state,
        pageRequested: action.payload,
      }
    case FETCH_NOTIFICHE_NON_LETTE:
      return {
        ...state,
        notificheNonLette: action.payload.data,
      }
    case SET_NOTIFICA_LETTA:
      return {
        ...state,
        notifiche: state.notifiche.map((notifica) => {
          if (notifica.notificaId === action.payload.data) {
            return {
              ...notifica,
              ...action.payload.data,
            }
          }
          return notifica
        }),
      }
    default:
      return state
  }
}

///////////////////////////
// Selectors
//////////////////////////
export function getNotificheSportelloCorrente(globalState) {
  const notificheNonLette = globalState.notifiche.notificheNonLette.notifiche
  return notificheNonLette || []
}

export function getNotificheAltriSportelli(globalState) {
  const notificheNonLette = globalState.notifiche.notificheNonLette.sportelli || []
  return notificheNonLette
}
