import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Ink from 'react-ink'

import { sendCredenzialiEmail, sendCredenzialiSms, impersona, API_ROOT_URL } from '../../actions'

import { MdVisibility, MdDescription, MdPhoneAndroid, MdEmail } from 'react-icons/md'
import { FaMagic } from 'react-icons/fa'

class AnagraficaCredenziali extends Component {
  constructor(props) {
    super(props)

    this.state = {
      passwordVisible: false,
      credenzialiVisible: false,
    }
  }

  toggleCredenzialiVisibile(event) {
    event.preventDefault()
    this.setState({
      credenzialiVisible: !this.state.credenzialiVisible,
    })
  }

  togglePasswordVisibile(event) {
    event.preventDefault()
    this.setState({
      passwordVisible: !this.state.passwordVisible,
    })
  }

  handleImpersona = (event) => {
    this.props.impersona(this.props.candidatoId)
  }

  render() {
    const { account, candidatoId, isMioSportello } = this.props

    return (
      <div className="credenziali">
        <div className="row">
          <div className="col-md-7">
            <div className="username-password">
              <p>Username: {account.login}</p>
              <p>
                Password: {this.state.passwordVisible ? account.password : '******'}{' '}
                <a href="#" onClick={this.togglePasswordVisibile.bind(this)}>
                  <MdVisibility />
                </a>
              </p>
              {!!isMioSportello && (
                <button className="btn btn-pink" onClick={this.handleImpersona}>
                  <FaMagic /> Accedi come
                </button>
              )}
            </div>
          </div>
          <div className="col-md-5">
            <div>
              <a
                target="_blank"
                href={`${API_ROOT_URL}/candidati/${candidatoId}/credenziali_pdf?t=${localStorage.getItem('token')}`}
                rel="noreferrer"
                className="btn btn-lg btn-block btn-default"
              >
                <MdDescription />
                Stampa credenziali
              </a>
            </div>
          </div>
        </div>

        {(this.props.canSendEmail || this.props.canSendSms) && (
          <div>
            <hr />
            <div className="text-center">
              {this.props.canSendEmail ? (
                <button
                  className="btn btn-lg btn-primary btn-rounded"
                  onClick={() => this.props.sendCredenzialiEmail(candidatoId)}
                >
                  <MdEmail />
                  Invia e-mail
                </button>
              ) : null}{' '}
              &nbsp;&nbsp;
              {this.props.canSendSms ? (
                <button
                  className="btn btn-lg btn-primary btn-rounded"
                  onClick={() => this.props.sendCredenzialiSms(candidatoId)}
                >
                  <MdPhoneAndroid />
                  Invia SMS
                </button>
              ) : null}
            </div>
          </div>
        )}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    candidatoId: state.candidatoAttivo.candidatoId,
    account: state.candidatoAttivo.account,
    canSendEmail:
      state.candidatoAttivo.anagrafica &&
      state.candidatoAttivo.anagrafica.email &&
      state.candidatoAttivo.anagrafica.email.length >= 9,
    canSendSms:
      state.candidatoAttivo.anagrafica &&
      state.candidatoAttivo.anagrafica.cellulare &&
      state.candidatoAttivo.anagrafica.cellulare.length >= 9 &&
      state.auth.user.permessi.invioSms,
    isMioSportello: state.candidatoAttivo.sportello.sportelloId === state.auth.user.sportelloCorrente.sportelloId,
  }
}

export default connect(mapStateToProps, { sendCredenzialiEmail, sendCredenzialiSms, impersona })(AnagraficaCredenziali)
