import _take from 'lodash/take'
import moment from 'moment'
import React, { Component } from 'react'
import { Dropdown, MenuItem } from 'react-bootstrap'
import { FaUserCircle } from 'react-icons/fa'
import {
  MdAssignment,
  MdEdit,
  MdExitToApp,
  MdFileUpload,
  MdMail,
  MdMessage,
  MdNotifications,
  MdNotificationsActive,
  MdPersonAdd,
  MdSettings,
  MdThumbUp,
  MdWarning,
} from 'react-icons/md'
import Ink from 'react-ink'
import { connect } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import { Link, withRouter } from 'react-router-dom'
import {
  cambioSportelloOperatore,
  fetchCreditiSms,
  fetchNotificheNonLette,
  logoutUser,
  setNotificaLetta,
  toggleMobileMenuOpen,
} from '../../../actions'
import config from '../../../config'
import { getNotificheAltriSportelli, getNotificheSportelloCorrente } from '../../../reducers/reducer_notifiche'
import './top_header.less'

class TopHeader extends Component {
  constructor(props) {
    super(props)

    this.state = {
      logo: 'sportello_lavoro_logo',
    }
  }

  componentDidMount() {
    if (this.props.isAuthenticated && this.props.user.ruolo === 'operatore') {
      this.props.fetchNotificheNonLette()
    }
    if (this.props.isAuthenticated && this.props.user.ruolo === 'amministratore') {
      this.props.fetchCreditiSms()
    }
  }

  componentDidUpdate(prevProps) {
    // Primo fetch notifiche
    if (!prevProps.isAuthenticated && this.props.isAuthenticated && this.props.user.ruolo === 'operatore') {
      this.props.fetchNotificheNonLette()
    }

    if (
      this.props.notificheNonLette !== prevProps.notificheNonLette &&
      this.props.isAuthenticated &&
      this.props.user.ruolo === 'operatore'
    ) {
      clearTimeout(this.timeout)
      this.startPoll()
    }

    if (!prevProps.isAuthenticated && this.props.isAuthenticated && this.props.user.ruolo === 'amministratore') {
      this.props.fetchCreditiSms()
    }
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  }

  startPoll() {
    this.timeout = setTimeout(() => this.props.fetchNotificheNonLette(), 30000)
  }

  logout(event) {
    event.preventDefault()
    this.props.logoutUser(this.props.history)
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  }

  renderOrganizzazione(sportelloCorrente) {
    if (sportelloCorrente.tipo === 'agenzia') {
      return <img src={sportelloCorrente.logo} alt="sportello-logo" />
    }

    let tipo = sportelloCorrente.tipo || ''

    switch (tipo.toUpperCase()) {
      case 'CISL':
        return <img src="/images/sportello-cisl.png" className="img-circle" alt="sportello-cisl" />
      case 'IAL':
        return <img src="/images/sportello-ial.png" className="img-circle" alt="sportello-ial" />
      default:
        return <FaUserCircle style={{ fontSize: '20px' }} />
    }
  }

  clickNotifica = (notifica) => (event) => {
    event.preventDefault()
    this.props.setNotificaLetta(notifica.notificaId).then(() => this.props.fetchNotificheNonLette())

    if (notifica.aziendaId) {
      this.props.history.push(`/app/aziende/${notifica.aziendaId}`)
    } else if (notifica.agenziaId) {
      this.props.history.push(`/app/agenzie/${notifica.agenziaId}`)
    } else if (notifica.candidatoId) {
      this.props.history.push(`/app/candidati/${notifica.candidatoId}`)
    } else if (notifica.jobVacancyId) {
      this.props.history.push(`/app/job-vacancies/${notifica.jobVacancyId}`)
    }
  }

  renderIconaNotifiche(tipo) {
    switch (tipo.toLowerCase()) {
      case 'registrazione_azienda':
      case 'registrazione_agenzia':
      case 'registrazione_candidato':
        return <MdPersonAdd className="icona-registrazione" />
      case 'upload_dichiarazione_azienda':
      case 'upload_dichiarazione_agenzia':
        return <MdFileUpload className="icona-upload" />
      case 'pubblicazione_jv':
        return <MdAssignment className="icona-pubblicazione" />
      case 'candidatura_jv':
        return <MdThumbUp className="icona-candidatura" />
      case 'modifica_candidato':
        return <MdEdit className="icona-modifica" />
      case 'cambio_sportello':
        return <MdWarning className="icona-cambio-sportello" />
      default:
        return <MdMessage />
    }
  }

  renderTipoNotifiche(tipo) {
    switch (tipo.toLowerCase()) {
      case 'registrazione_azienda':
        return <span>Registrazione azienda</span>
      case 'registrazione_agenzia':
        return <span>Registrazione agenzia</span>
      case 'registrazione_candidato':
        return <span>Registrazione candidato</span>
      case 'upload_dichiarazione_azienda':
        return <span>Upload dichiarazione Azienda</span>
      case 'upload_dichiarazione_agenzia':
        return <span>Upload dichiarazione Agenzia</span>
      case 'pubblicazione_jv':
        return <span>Pubblicazione JV</span>
      case 'candidatura_jv':
        return <span>Candidatura JV</span>
      case 'modifica_candidato':
        return <span>Modifica curriculum</span>
      case 'cambio_sportello':
        return <span>Cambio sportello</span>
      default:
        return <span>Messaggio</span>
    }
  }

  renderNotificheSportelloCorrente() {
    const ultimeCinqueNotifiche = _take(this.props.notificheSportelloCorrente, 5)

    return ultimeCinqueNotifiche.map((notifica) => {
      return (
        <MenuItem
          key={notifica.notificaId}
          eventKey={notifica.notificaId}
          className="media"
          onClick={this.clickNotifica(notifica)}
        >
          <div className="pull-left">{this.renderIconaNotifiche(notifica.tipo)}</div>
          <div className="media-body">
            <h5 className="media-heading">{this.renderTipoNotifiche(notifica.tipo)}</h5>
            <p>
              <small>{notifica.testo}</small>
            </p>
            <p className="media-info">{moment(notifica.dataCreazione).format('DD MMMM YYYY [alle] HH:mm')}</p>
          </div>
        </MenuItem>
      )
    })
  }

  renderNotificheAltriSportelli() {
    //const ultimeCinqueNotifiche = _take(this.props.altriSportelliNotifiche, 5)

    return this.props.altriSportelliNotifiche.map((sportello) => {
      return (
        <MenuItem
          key={sportello.sportelloId}
          eventKey={sportello.sportelloId}
          className="media"
          onClick={() => this.props.cambioSportelloOperatore(sportello.sportelloId)}
        >
          <div className="media-body">
            <table style={{ width: '100%' }}>
              <tbody>
                <tr>
                  <td>
                    <h5 className="media-heading">{sportello.sportelloNome}</h5>
                  </td>
                  <td className="text-right">
                    <span style={{ fontWeight: 600, color: '#3bafda' }}>{sportello.numeroNotifiche}</span>
                  </td>
                </tr>
              </tbody>

              {/* {moment(sportello.dataCreazione).format('DD MMMM YYYY [alle] HH:mm')} */}
            </table>
          </div>
        </MenuItem>
      )
    })
  }

  // changeLogo = event => {
  // 	let newLogo = 'sportello_lavoro_logo';

  // 	if (this.state.logo === 'sportello_lavoro_logo') {
  // 		newLogo = 'logo_congresso';
  // 	}

  // 	this.setState({
  // 		logo: newLogo
  // 	});
  // }

  renderTitle() {
    let { user } = this.props

    if (user.sportelloCorrente) {
      return (
        <span style={{ display: 'flex' }}>
          <span className="hidden-xs">{user.sportelloCorrente.nome}</span>
          {this.renderOrganizzazione(user.sportelloCorrente)}
          <span className="hidden-xs">{user.nome + ' ' + user.cognome}</span>
          <Ink />
        </span>
      )
    }

    return null
  }

  renderNotificheAltriSportelliNumero = () => {
    const altriSportelliNotifiche = this.props.altriSportelliNotifiche
    let somma = 0
    altriSportelliNotifiche.forEach((sportello) => (somma += sportello.numeroNotifiche))
    return somma
  }

  render() {
    let { isAuthenticated, user, notificheSportelloCorrente, altriSportelliNotifiche, creditiSms } = this.props

    return (
      <div className="top-header">
        <div className="container">
          <div className="logo">
            {/* <Link to={isAuthenticated ? '/app' : '/'} onClick={this.changeLogo}>
              <img alt="" src={`/images/${this.state.logo}.png`} />
              <span>Sportello Lavoro</span>
            </Link>
            <MdExplore />
            <span>Sportello Lavoro CISL</span> */}
            <Link to={isAuthenticated ? '/app' : '/'}>
              <img src={`/images/${config.logo}`} alt="logo" />
              <span>Sportello Lavoro</span>
            </Link>
          </div>
          {isAuthenticated ? (
            <div className="nav navbar-nav navbar-right pull-right create-center">
              {user.ruolo === 'operatore' && (
                <div className="btn-group btn-operatore">
                  <div className="btn btn-primary">
                    <Link to="/app/invio-messaggio">
                      <Ink />
                      <MdMail />
                    </Link>
                  </div>
                </div>
              )}

              {user.ruolo === 'operatore' && (
                <Dropdown id="notification-dropdown" className="notification notifiche-sportello-corrente">
                  <Dropdown.Toggle bsStyle="primary" noCaret>
                    <MdNotificationsActive />
                    <span
                      className={`badge badge-xs badge-pink ${
                        notificheSportelloCorrente?.length === 0 ? 'invisible' : ''
                      }`}
                    >
                      {notificheSportelloCorrente?.length}
                    </span>
                    <Ink />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu">
                    {notificheSportelloCorrente?.length > 0 ? (
                      this.renderNotificheSportelloCorrente()
                    ) : (
                      <MenuItem header>Nessuna nuova notifica</MenuItem>
                    )}
                    <LinkContainer to="/app/notifiche">
                      <MenuItem eventKey={0} className="see-all">
                        Vedi tutte le notifiche &raquo;
                      </MenuItem>
                    </LinkContainer>
                  </Dropdown.Menu>
                </Dropdown>
              )}

              {user.ruolo === 'operatore' && (
                <Dropdown id="notification-dropdown" className="notification notifiche-altri-sportelli">
                  <Dropdown.Toggle bsStyle="primary" noCaret>
                    <MdNotifications />
                    <span
                      className={`badge badge-xs badge-purple ${
                        altriSportelliNotifiche?.length === 0 ? 'invisible' : ''
                      }`}
                    >
                      {this.renderNotificheAltriSportelliNumero()}
                    </span>
                    <Ink />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu" style={{ overflowY: 'auto', height: 300 }}>
                    {altriSportelliNotifiche?.length > 0 ? (
                      this.renderNotificheAltriSportelli()
                    ) : (
                      <MenuItem header>Nessuna nuova notifica</MenuItem>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              )}

              {user.ruolo === 'amministratore' && (
                <div className="crediti-sms">
                  Crediti sms: <b>{creditiSms}</b>
                </div>
              )}

              <Dropdown id="profile-dropdown" className="profile">
                <Dropdown.Toggle bsStyle="primary" noCaret>
                  {this.renderTitle()}
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu">
                  <LinkContainer to="/app/cambio-password">
                    <MenuItem eventKey="1">
                      <MdSettings />
                      Cambia password
                    </MenuItem>
                  </LinkContainer>
                  <MenuItem eventKey="2" onClick={this.logout.bind(this)}>
                    <MdExitToApp />
                    Esci
                  </MenuItem>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          ) : (
            <ul className="nav navbar-nav navbar-right pull-right">
              <li>
                <Link to="/">
                  <FaUserCircle style={{ verticalAlign: 'middle' }} /> Area riservata
                </Link>
              </li>
            </ul>
          )}
          <div
            className={`navbar-toggle ${this.props.mobileMenuOpen ? 'open' : ''}`}
            onClick={this.props.toggleMobileMenuOpen}
          >
            <div className="lines">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    mobileMenuOpen: state.header.mobileMenuOpen,
    user: state.auth.user,
    isAuthenticated: state.auth.authenticated,
    sportelli: state.auth.user.sportelli,
    notificheNonLette: state.notifiche.notificheNonLette,
    notificheSportelloCorrente: getNotificheSportelloCorrente(state),
    altriSportelliNotifiche: getNotificheAltriSportelli(state),
    creditiSms: state.creditiSms,
  }
}

TopHeader = withRouter(TopHeader)

export default connect(mapStateToProps, {
  toggleMobileMenuOpen,
  logoutUser,
  fetchNotificheNonLette,
  setNotificaLetta,
  fetchCreditiSms,
  cambioSportelloOperatore,
})(TopHeader)
