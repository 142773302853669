import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Ink from 'react-ink'

import CorsoFormazione from './corso_formazione'

import { MdAdd } from 'react-icons/md'

class CorsiFormazione extends Component {
  renderCorsiFormazione() {
    if (this.props.corsiFormazione) {
      return this.props.corsiFormazione.map((corsoFormazione) => {
        return (
          <CorsoFormazione
            key={corsoFormazione.corsoFormazioneId}
            corsoFormazione={corsoFormazione}
            candidatoId={this.props.candidatoId}
            canEdit={this.props.canEdit}
          />
        )
      })
    }
    return <span>Caricamento in corso...</span>
  }

  render() {
    return (
      <div className="card-box">
        <h4 className="header-title">Corsi di formazione</h4>
        {this.renderCorsiFormazione()}
        {this.props.canEdit ? (
          <div className="text-right">
            <Link
              to={`/app/candidati/${this.props.candidatoId}/corsi-formazione/new`}
              className="btn btn-sm btn-purple"
            >
              <Ink />
              <MdAdd />
              Aggiungi corso
            </Link>
          </div>
        ) : null}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    candidatoId: state.candidatoAttivo.candidatoId,
    corsiFormazione: state.candidatoAttivo.corsiFormazione,
    canEdit:
      !!state.auth.user.permessi['corsiFormazione'] &&
      state.candidatoAttivo.sportello &&
      state.candidatoAttivo.sportello.sportelloId === state.auth.user.sportelloCorrente.sportelloId,
  }
}

export default connect(mapStateToProps)(CorsiFormazione)
