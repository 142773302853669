import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form, Field } from 'react-final-form'
import { MdVpnKey } from 'react-icons/md'
import { FaUserCircle } from 'react-icons/fa'

import { loginUser } from '../../actions/index'
import config from '../../config'
import { Link } from 'react-router-dom'

class Login extends Component {
  componentDidMount() {
    if (this.props.authenticated) {
      // Se sono autenticato è inutile mostrare il login
      // => Redirect a Home
      this.props.history.push('/app')
    }
  }

  handleFormSubmit({ username, password }) {
    this.props.loginUser({ username, password, browserHistory: this.props.history })
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger" role="alert">
          {this.props.errorMessage}
        </div>
      )
    }
  }

  render() {
    return (
      <div className="login">
        <div className="logo">
          {/*<MdExplore /> Sportello lavoro*/}
          <img src={`/images/${config.logo}`} alt="logo" />
        </div>
        <Form
          validate={validate}
          onSubmit={this.handleFormSubmit.bind(this)}
          render={({ handleSubmit, invalid }) => (
            <form onSubmit={handleSubmit} className="form-horizontal">
              <div className="form-group">
                <div className="col-xs-12">
                  <Field
                    component="input"
                    type="text"
                    className="form-control"
                    placeholder="Username"
                    name="username"
                  />
                  <div className="form-control-feedback">
                    <FaUserCircle style={{ verticalAlign: 'middle' }} />
                  </div>
                </div>
              </div>

              <div className="form-group">
                <div className="col-xs-12">
                  <Field
                    component="input"
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    name="password"
                  />
                  <div className="form-control-feedback">
                    <MdVpnKey style={{ verticalAlign: 'middle' }} />
                  </div>
                </div>
              </div>
              <Link to="/password-dimenticata">Hai dimenticato la password?</Link>

              <div className="form-group text-right action">
                <div className="col-xs-12">
                  <button
                    type="submit"
                    disabled={invalid || this.props.loading}
                    className="btn  btn-custom btn-login btn-login-border-custom"
                  >
                    {this.props.loading ? 'Accesso in corso...' : 'Accedi'}
                  </button>
                </div>
              </div>
            </form>
          )}
        />

        {this.renderAlert()}

        {/*<div className="footer">
        	<img src="images/cisl.png" />
        </div>*/}
      </div>
    )
  }
}
function validate(values) {
  const errors = {}

  if (!values.username) {
    errors.username = 'Inserisci il nome utente'
  }

  if (!values.password) {
    errors.password = 'Inserici la password'
  }

  return errors
}

function mapStateToProps(state) {
  return {
    loading: state.auth.loading,
    errorMessage: state.auth.error,
    authenticated: state.auth.authenticated,
  }
}

export default connect(mapStateToProps, { loginUser })(Login)
