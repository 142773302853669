import {
  NOTIFICATION,
  CLEAR_NOTIFICATION
} from '../actions/types';

export default function(state = {}, action) {
  switch(action.type) {
    case NOTIFICATION:
      return action.payload;
    case CLEAR_NOTIFICATION:
      return {};
  }

  return state;
}
