import React, { Component } from 'react'
import { connect } from 'react-redux'

import { revocaConsenso } from '../../actions'

class RevocaConsenso extends Component {

  constructor(props) {
    super(props)

    this.state = {
      confermato: false
    }
  }

  handleRevoca = event => {
    this.props.revocaConsenso(this.props.location.query.t)
    .then(() => {
      this.setState({
        confermato: true
      })
    })
  }


  render() {

    const { t, e } = this.props.location.query
    const { confermato } = this.state

    return (
      <div className="container revoca-consenso">
        <h1 className="page-title">Conferma revoca consenso</h1>

        {!t || !e ? 
          <div>
            <p>Errore di autenticazione</p>
          </div>
        :
          <div className="row">
            <div className="col-sm-6">
              {!confermato ?
                <div className="card-box">
                  <p className="lead">Confermi l'intenzione di revocare il tuo consenso alla ricezione di newsletter all'indirizzo {e}?</p>
                  <p>Non riceverai più comunicazioni da parte dello Sportello Lavoro Cisl, se non strettamente inerenti i servizi da te richiesti.</p>
                  <button type="button" className="btn btn-primary btn-lg" style={{ marginTop: 20 }}
                    onClick={this.handleRevoca}>
                    Sì, confermo la revoca del consenso.
                  </button>
                </div>
              :
                <div className="alert alert-success">Hai correttamente revocato il tuo consenso.</div>
              }
            </div>
          </div>
        }
      </div>
    )
  }
}

export default connect(null, { revocaConsenso })(RevocaConsenso)
