import React, { Component } from 'react'
import AsyncSelect from 'react-select/async'
import _find from 'lodash/find'
import moment from 'moment'
import { connect } from 'react-redux'
import { Field, Form } from 'react-final-form'

import BottoneAnnulla from './bottone_annulla'

import {
  fetchCandidato,
  addEsperienzaLavorativa,
  updateEsperienzaLavorativa,
  fetchTypeaheadComuniConStatiEsteri,
  fetchTypeaheadPosizioniProfessionali,
} from '../../actions'

import Lookups from '../lookups/lookups'

class AddEditEsperienzaLavorativa extends Component {
  constructor(props) {
    super(props)

    this.state = {
      posizioneProfessionale: {},
    }
  }

  ///////////////////////////////////////////////////////////////
  // SE EDIT E RELOAD PAGINA -> FETCH CANDIDATO
  componentDidMount() {
    // Inizializzazione autocomplete Posizioni professionali
    this.props.fetchTypeaheadPosizioniProfessionali('a')

    // Ho già il candidato attivo se navigo normalmente
    // dal dettaglio candidato: questo controllo serve
    // nel caso in cui ricarico la pagina da qui (F5)
    if (this.props.match.params.id && !this.props.candidatoId) {
      this.props.fetchCandidato(this.props.match.params.id).then((action) => {
        // Setto la posizione professionale per l'autocomplete
        this.setState({
          posizioneProfessionale: {
            value: this.props.initialValues?.posizioneProfessionale,
            label: this.props.initialValues?.posizioneProfessionale,
          },
        })
        //this.refs.selectPosizioniProfessionali.highlightFirstSelectableOption();
      })
    } else {
      this.setState({
        posizioneProfessionale: {
          value: this.props.initialValues.posizioneProfessionale,
          label: this.props.initialValues.posizioneProfessionale,
        },
      })
    }
  }

  ///////////////////////////////////////////////////////////////
  // GESTIONE COMUNI AUTOCOMPLETE
  getComuniOptions = (comuni) => {
    return comuni.map((comune) => {
      return {
        value: comune.comuneEsteso,
        label: comune.comuneEsteso,
      }
    })
  }

  getComuniConStatiEsteri = (input) => {
    return this.props.fetchTypeaheadComuniConStatiEsteri(input).then((action) => {
      return this.getComuniOptions(action.payload.data)
    })
  }

  onChangeIndirizzo(option) {
    this.props.fields.indirizzo.onChange(option ? option.value : null)
  }

  ///////////////////////////////////////////////
  // GESTIONE AUTOCOMPLETE POSIZIONI
  getPosizioniOptions = (posizioni) => {
    return posizioni.map((posizione) => {
      return {
        value: posizione,
        label: posizione,
      }
    })
  }

  searchChange = (input) => {
    const self = this
    return this.props.fetchTypeaheadPosizioniProfessionali(input).then(function (action) {
      return self.getPosizioniOptions(action.payload.data)
    })
  }

  renderOption(item) {
    return (
      <div className="simple-option">
        {!!item.newOption ? <i style={{ color: '#61B814' }}>&raquo; Crea voce "{item.label}"</i> : item.label}
      </div>
    )
  }

  ///////////////////////////////////////////////////////////////
  // SUBMIT
  onSubmit(values) {
    console.log(this.props.match.params.esperienzaLavorativaId)
    const { indirizzo, posizioneProfessionale, esperienzaLavorativaId, ...rest } = values
    const submitValues = { ...rest, indirizzo: indirizzo?.value, posizioneProfessionale: posizioneProfessionale?.value }
    if (this.props.match.params.esperienzaLavorativaId) {
      // Siamo in UPDATE
      this.props
        .updateEsperienzaLavorativa(
          this.props.match.params.esperienzaLavorativaId,
          submitValues,
          this.props.match.params.id
        )
        .then((action) => {
          // Redirect a dettagli candidato
          this.props.history.goBack()
        })
    } else {
      // Siamo in NEW
      this.props.addEsperienzaLavorativa(submitValues, this.props.match.params.id).then((action) => {
        // Redirect a dettagli candidato
        this.props.history.goBack()
      })
    }
  }

  ///////////////////////////////////////////////
  // NEW / EDIT
  renderTitolo() {
    let titolo = ''
    if (this.props.match.params.esperienzaLavorativaId) {
      titolo = 'Modifica esperienza'
    } else {
      titolo = 'Nuova esperienza'
    }
    return <h1 className="page-title pull-left">{titolo}</h1>
  }

  ///////////////////////////////////////////////////////////////
  // RENDER
  render() {
    const initialValues = this.props.initialValues ? this.props.initialValues : {}
    return (
      <Form
        onSubmit={this.onSubmit.bind(this)}
        validate={validate}
        initialValues={initialValues}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit}>
            <div className="heading">
              {this.renderTitolo()}
              <BottoneAnnulla />
            </div>

            <div className="row">
              <div className="col-md-8">
                <div className="panel panel-border panel-primary">
                  <div className="panel-heading">
                    <h3 className="panel-title">Esperienza lavorativa</h3>
                  </div>
                  <div className="panel-body">
                    <div className="row">
                      <div className="col-sm-6">
                        <div
                          className={`form-group ${
                            form.getFieldState('dataInizioMese')?.touched &&
                            form.getFieldState('dataInizioAnno')?.toucehd &&
                            form.getFieldState('dataInizioMese')?.invalid
                              ? 'has-error'
                              : ''
                          }`}
                        >
                          <label htmlFor="dataInizioMese">Inizio</label>
                          {/*<DatePicker
                           id="dataInizio"
                           selected={dataInizio.value ? moment(dataInizio.value) : null}
                           onChange={(momentDate) => dataInizio.onChange(momentDate.format('YYYY-MM-DD'))}
                           locale="it-IT"
                           className="form-control"/>*/}
                          <div className="row">
                            <div className="col-sm-6">
                              <Field
                                name="dataInizioMese"
                                id="dataInizioMese"
                                render={({ input }) => (
                                  <select className="form-control" {...input}>
                                    <option value="">Seleziona mese</option>
                                    <option value="1">Gennaio</option>
                                    <option value="2">Febbraio</option>
                                    <option value="3">Marzo</option>
                                    <option value="4">Aprile</option>
                                    <option value="5">Maggio</option>
                                    <option value="6">Giugno</option>
                                    <option value="7">Luglio</option>
                                    <option value="8">Agosto</option>
                                    <option value="9">Settembre</option>
                                    <option value="10">Ottobre</option>
                                    <option value="11">Novembre</option>
                                    <option value="12">Dicembre</option>
                                  </select>
                                )}
                              />
                            </div>
                            <div className="col-sm-6">
                              <Field
                                name="dataInizioAnno"
                                type="number"
                                min="1930"
                                max={moment().format('YYYY')}
                                className="form-control"
                                id="dataInizioAnno"
                                placeholder="Inserisci anno"
                                component="input"
                              />

                              {/* <div className="help-block">
                                {dataInizioMese.touched && dataInizioMese.error}
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="dataFineMese">Fine</label>
                          {/*<DatePicker
                      id="dataFine"
                      selected={dataFine.value ? moment(dataFine.value) : null}
                      onChange={(momentDate) => dataFine.onChange(momentDate.format('YYYY-MM-DD'))}
                      locale="it-IT"
                      className="form-control"/>*/}
                          <div className="row">
                            <div className="col-sm-6">
                              <Field
                                id="dataFineMese"
                                name="dataFineMese"
                                render={({ input }) => (
                                  <select className="form-control" {...input}>
                                    <option value="">Seleziona mese</option>
                                    <option value="1">Gennaio</option>
                                    <option value="2">Febbraio</option>
                                    <option value="3">Marzo</option>
                                    <option value="4">Aprile</option>
                                    <option value="5">Maggio</option>
                                    <option value="6">Giugno</option>
                                    <option value="7">Luglio</option>
                                    <option value="8">Agosto</option>
                                    <option value="9">Settembre</option>
                                    <option value="10">Ottobre</option>
                                    <option value="11">Novembre</option>
                                    <option value="12">Dicembre</option>
                                  </select>
                                )}
                              />
                            </div>
                            <div className="col-sm-6">
                              <Field
                                id="dataFineAnno"
                                name="dataFineAnno"
                                type="number"
                                min="1930"
                                max={moment().format('YYYY')}
                                className="form-control"
                                placeholder="Inserisci anno"
                                component="input"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="azienda">Azienda</label>
                          <Field type="text" className="form-control" id="azienda" name="azienda" component="input" />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="indirizzo">Località</label>
                          <Field
                            name="indirizzo"
                            render={({ input }) => (
                              <AsyncSelect
                                placeholder={initialValues.indirizzo || 'Seleziona...'}
                                loadOptions={this.getComuniConStatiEsteri}
                                loadingPlaceholder="Caricamento..."
                                searchingText="Caricamento..."
                                noResultsText="Nessun risultato"
                                defaultOptions={[
                                  {
                                    value: initialValues.indirizzo,
                                    label: initialValues.indirizzo,
                                  },
                                ]}
                                {...input}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="settoreAttivita">Settore attività</label>
                          <Field
                            type="text"
                            className="form-control"
                            id="settoreAttivita"
                            name="settoreAttivita"
                            component="input"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <Field
                          name="posizioneProfessionale"
                          id="posizioneProfessionale"
                          render={({ input, meta }) => (
                            <div className={`form-group ${meta.touched && meta.invalid ? 'has-error' : ''}`}>
                              <label htmlFor="posizioneProfessionale">Posizione professionale</label>
                              <AsyncSelect
                                placeholder={initialValues.posizioneProfessionale || 'seleziona...'}
                                loadOptions={this.searchChange}
                                loadingPlaceholder="Caricamento..."
                                searchingText="Caricamento..."
                                noResultsText="Nessun risultato"
                                defaultOptions={[
                                  {
                                    value: initialValues.posizioniProfessionali,
                                    label: initialValues.posizioniProfessionali,
                                  },
                                ]}
                                {...input}
                              />
                              <div className="help-block">{meta.touched && meta.error}</div>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <Field
                          name="tipoContratto"
                          id="tipoContratto"
                          render={({ input, meta }) => (
                            <div className={`form-group ${meta.touched && meta.invalid ? 'has-error' : ''}`}>
                              <label htmlFor="tipoContratto">Tipo contratto</label>
                              <Lookups tipo="tipoContratto" {...input} />
                              <div className="help-block">{meta.touched && meta.error}</div>
                            </div>
                          )}
                        />
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="tipoQualificaLegale">Qualifica legale</label>
                          <Field
                            id="tipoQualificaLegale"
                            name="tipoQualificaLegale"
                            render={({ input }) => <Lookups tipo="tipoQualificaLegale" {...input} />}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        {form.getFieldState('tipoContratto')?.value === 'Altro' ? (
                          <div className="form-group">
                            <label htmlFor="altroTipo">Altro</label>
                            <Field
                              type="text"
                              className="form-control"
                              id="altroTipo"
                              name="altroTipo"
                              component="input"
                            />
                          </div>
                        ) : null}
                      </div>
                      <div className="col-sm-6">
                        <div className="checkbox checkbox-primary">
                          <Field type="checkbox" component="input" id="nascondiDaCvFlag" name="nascondiDaCvFlag" />
                          <label htmlFor="nascondiDaCvFlag">
                            <h3 className="panel-title">Mostra su CV?</h3>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label htmlFor="mansioni">Mansioni</label>
                          <Field className="form-control" rows="4" id="mansioni" name="mansioni" component="textarea" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                {form.getFieldState('dataFineMese')?.value && form.getFieldState('dataFineAnno')?.value ? (
                  <div className="panel panel-border panel-danger">
                    <div className="panel-heading">
                      <h3 className="panel-title">Esperienza terminata</h3>
                    </div>
                    <div className="panel-body">
                      <div className="form-group">
                        <label htmlFor="motivazioneTermine">Motivazione</label>
                        <Field
                          id="motivazioneTermine"
                          name="motivazioneTermine"
                          render={({ input }) => <Lookups tipo="tipoMotivazioneFine" {...input} />}
                        />
                      </div>
                      {form.getFieldState('motivazioneTermine')?.value === 'Altro' ? (
                        <div className="form-group">
                          <label htmlFor="motivazioneAltro">Altra motivazione</label>
                          <Field
                            type="text"
                            className="form-control"
                            id="motivazioneAltro"
                            name="motivazioneAltro"
                            component="input"
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="text-center">
              <button type="submit" className="btn btn-primary btn-lg">
                Salva esperienza lavorativa &raquo;
              </button>
            </div>
          </form>
        )}
      />
    )
  }
}

function validate(values) {
  const errors = {}

  if (!values.dataInizioMese || !values.dataInizioAnno) {
    errors.dataInizioMese = 'Indica la data di inizio'
  }

  if (!values.tipoContratto) {
    errors.tipoContratto = 'Indica il tipo di contratto'
  }

  if (!values.posizioneProfessionale) {
    errors.posizioneProfessionale = 'Inserisci la posizione professionale'
  }

  return errors
}

const mapStateToProps = (state, props) => {
  // Sono in edit, devo inizializzare il form

  if (props.match.params.esperienzaLavorativaId) {
    return {
      initialValues: _find(state.candidatoAttivo.esperienzeLavorative, {
        esperienzaLavorativaId: parseInt(props.match.params.esperienzaLavorativaId),
      }),
      candidatoId: state.candidatoAttivo.candidatoId, // per sapere se ho già il candidato attivo su F5
      esperienzaLavorativa: _find(state.candidatoAttivo.esperienzeLavorative, {
        esperienzaLavorativaId: parseInt(props.match.params.esperienzaLavorativaId),
      }),
      posizioniProfessionali: state.posizioniProfessionali,
    }
  }
  return {
    initialValues: {},
    posizioniProfessionali: state.posizioniProfessionali,
  }
}

export default connect(mapStateToProps, {
  fetchCandidato,
  addEsperienzaLavorativa,
  updateEsperienzaLavorativa,
  fetchTypeaheadComuniConStatiEsteri,
  fetchTypeaheadPosizioniProfessionali,
})(AddEditEsperienzaLavorativa)
