import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Ink from 'react-ink'
import cogoToast from 'cogo-toast'

import {
  MdModeEdit,
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdPlace,
  MdZoomOutMap,
} from 'react-icons/md'

import { saveDisponibilitaGeografica } from '../../actions'
import PosizioniProfessionali from './posizioni_professionali'
import DisponibilitaGeografica from './disponibilita_geografica'

class obiettiviProfessionali extends Component {
  saveDisponibilitaGeografica() {
    const { lat, lng, raggio } = this.props.obiettiviProfessionali.disponibilitaGeografica
    this.props.saveDisponibilitaGeografica(this.props.candidatoId, lat, lng, raggio)
    cogoToast.success('Disponibilità geografica salvata correttamente', {
      heading: 'Salvataggio avvenuto',
      position: 'top-right',
      hideAfter: 5,
    })
  }

  renderDisponibilitaContratto() {
    const {
      disponibilitaContrattoTempoIndeterminatoFlag,
      disponibilitaContrattoTempoDeterminatoFlag,
      disponibilitaContrattoApprendistatoFlag,
      disponibilitaContrattoSomministrazioneFlag,
      disponibilitaContrattoLavoroOccasionaleFlag,
      disponibilitaContrattoStageTirocinioFlag,
      disponibilitaContrattoVoucherFlag,
      disponibilitaContrattoLavoroAutonomoFlag,
      disponibilitaContrattoStagionaleFlag,
      disponibilitaContrattoLavoroChiamataFlag,
      disponibilitaContrattoCoCoCoFlag,
    } = this.props.obiettiviProfessionali

    return (
      <ul>
        <li className={disponibilitaContrattoTempoIndeterminatoFlag ? 'sel' : ''}>
          {disponibilitaContrattoTempoIndeterminatoFlag ? (
            <MdCheckBox />
          ) : (
            <MdCheckBoxOutlineBlank />
          )}{' '}
          Tempo indeterminato
        </li>
        <li className={disponibilitaContrattoTempoDeterminatoFlag ? 'sel' : ''}>
          {disponibilitaContrattoTempoDeterminatoFlag ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />}{' '}
          Tempo determinato
        </li>
        <li className={disponibilitaContrattoApprendistatoFlag ? 'sel' : ''}>
          {disponibilitaContrattoApprendistatoFlag ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />}{' '}
          Apprendistato
        </li>
        <li className={disponibilitaContrattoSomministrazioneFlag ? 'sel' : ''}>
          {disponibilitaContrattoSomministrazioneFlag ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />}{' '}
          Somministrazione
        </li>
        <li className={disponibilitaContrattoLavoroOccasionaleFlag ? 'sel' : ''}>
          {disponibilitaContrattoLavoroOccasionaleFlag ? (
            <MdCheckBox />
          ) : (
            <MdCheckBoxOutlineBlank />
          )}{' '}
          Lavoro occasionale
        </li>
        <li className={disponibilitaContrattoStageTirocinioFlag ? 'sel' : ''}>
          {disponibilitaContrattoStageTirocinioFlag ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />}{' '}
          Stage / Tirocinio
        </li>
        <li className={disponibilitaContrattoVoucherFlag ? 'sel' : ''}>
          {disponibilitaContrattoVoucherFlag ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />} Voucher
        </li>
        <li className={disponibilitaContrattoLavoroAutonomoFlag ? 'sel' : ''}>
          {disponibilitaContrattoLavoroAutonomoFlag ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />}{' '}
          Lavoro autonomo
        </li>
        <li className={disponibilitaContrattoStagionaleFlag ? 'sel' : ''}>
          {disponibilitaContrattoStagionaleFlag ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />}{' '}
          Stagionale
        </li>
        <li className={disponibilitaContrattoLavoroChiamataFlag ? 'sel' : ''}>
          {disponibilitaContrattoLavoroChiamataFlag ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />}{' '}
          Lavoro a chiamata
        </li>
        <li className={disponibilitaContrattoCoCoCoFlag ? 'sel' : ''}>
          {disponibilitaContrattoCoCoCoFlag ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />} Co.Co.Co.
        </li>
      </ul>
    )
  }

  renderDisponibilitaTempo() {
    const {
      disponibilitaTempoPienoFlag,
      disponibilitaTempoPartTimeFlag,
      disponibilitaTempoDueTurniFlag,
      disponibilitaTempoTreTurniFlag,
      disponibilitaConvivenzaGiornoNotteFlag,
      disponibilitaConvivenzaWeekendFlag,
    } = this.props.obiettiviProfessionali

    return (
      <ul>
        <li className={disponibilitaTempoPienoFlag ? 'sel' : ''}>
          {disponibilitaTempoPienoFlag ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />} Tempo pieno
        </li>
        <li className={disponibilitaTempoPartTimeFlag ? 'sel' : ''}>
          {disponibilitaTempoPartTimeFlag ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />} Part-time
        </li>
        <li className={disponibilitaTempoDueTurniFlag ? 'sel' : ''}>
          {disponibilitaTempoDueTurniFlag ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />} Su 2 turni
        </li>
        <li className={disponibilitaTempoTreTurniFlag ? 'sel' : ''}>
          {disponibilitaTempoTreTurniFlag ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />} Su 3 turni
        </li>
        <li className={disponibilitaConvivenzaGiornoNotteFlag ? 'sel' : ''}>
          {disponibilitaConvivenzaGiornoNotteFlag ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />}{' '}
          Convivenza giorno e notte
        </li>
        <li className={disponibilitaConvivenzaWeekendFlag ? 'sel' : ''}>
          {disponibilitaConvivenzaWeekendFlag ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />}{' '}
          Convivenza weekend
        </li>
      </ul>
    )
  }

  renderDisponibilitaTrasferte() {
    const { disponibilitaTrasferteFlag } = this.props.obiettiviProfessionali

    return (
      <ul>
        <li className={disponibilitaTrasferteFlag ? 'sel' : ''}>
          {disponibilitaTrasferteFlag ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />} Disponibile a
          trasferte
        </li>
      </ul>
    )
  }

  render() {
    const { candidatoId, obiettiviProfessionali, canEdit } = this.props
    const { lat, lng, raggio } = this.props.obiettiviProfessionali
      ? this.props.obiettiviProfessionali.disponibilitaGeografica
      : { lat: 0, lng: 0, raggio: 0 }

    return (
      <div className="card-box obiettivi-professionali">
        <h4 className="header-title">Obiettivi professionali</h4>
        <div className="row posizioni-professionali">
          <div className="col-md-6">
            {obiettiviProfessionali ? <PosizioniProfessionali /> : null}
          </div>
        </div>

        {obiettiviProfessionali ? (
          <div className="row disponibilita">
            <div className="col-md-6">
              <a className="anchor" id="disponibilita-contratto">
                {' '}
              </a>
              <h5 className="header-title">Disponibilità contratto</h5>
              <ul>{this.renderDisponibilitaContratto()}</ul>
            </div>
            <div className="col-md-6">
              <h5 className="header-title">Disponibilità tempo</h5>
              <ul>{this.renderDisponibilitaTempo()}</ul>

              <h5 className="header-title">Trasferte</h5>
              <ul>{this.renderDisponibilitaTrasferte()}</ul>
            </div>
          </div>
        ) : null}

        {canEdit ? (
          <div className="text-right">
            <Link
              to={`/app/candidati/${candidatoId}/obiettivi-professionali`}
              className="btn btn-sm btn-primary"
            >
              <Ink />
              <MdModeEdit />
              Modifica disponibilità
            </Link>
          </div>
        ) : null}

        <a className="anchor" id="disponibilita-geografica">
          {' '}
        </a>
        <h5 className="header-title">Disponibilità geografica</h5>
        <div className="row disponibilita-geografica">
          <div className="col-md-6">
            <DisponibilitaGeografica />
          </div>
          <div className="col-md-6">
            <div className="coords">
              <p>
                <MdPlace />
                Centro:{' '}
                {lat ? (
                  `${lat.toFixed(2)} - ${lng.toFixed(2)}`
                ) : (
                  <span className="non-selezionato">Non selezionato</span>
                )}
              </p>
              <p>
                <MdZoomOutMap />
                Raggio:{' '}
                <b>
                  {raggio ? (
                    `${(raggio / 1000).toFixed(0)} Km`
                  ) : (
                    <span className="non-selezionato">Non selezionato</span>
                  )}
                </b>
              </p>
            </div>
            {canEdit ? (
              <button
                onClick={() => this.saveDisponibilitaGeografica()}
                className="btn btn-sm btn-primary"
                disabled={!(lat && lng && raggio)}
              >
                <Ink />
                Salva disponibilità geografica
              </button>
            ) : null}
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    candidatoId: state.candidatoAttivo.candidatoId,
    obiettiviProfessionali: state.candidatoAttivo.obiettiviProfessionali,
    canEdit:
      !!state.auth.user.permessi['obiettiviProfessionali'] &&
      state.candidatoAttivo.sportello &&
      state.candidatoAttivo.sportello.sportelloId === state.auth.user.sportelloCorrente.sportelloId,
  }
}

export default connect(mapStateToProps, { saveDisponibilitaGeografica })(obiettiviProfessionali)
