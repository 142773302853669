import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { getStatisticheCandidatiBySportello, getStatisticheCandidati } from '../../actions'
import StatisticheGrafico from './statistiche_grafico'

const CandidatiStatistiche = ({
  permessoStatistiche,
  sportelloId,
  getStatisticheCandidatiBySportello,
  getStatisticheCandidati,
  statistiche,
  ruolo,
}) => {
  const [hasMounted, setHasMounted] = useState(false)
  useEffect(() => {
    if (ruolo === 'operatore' && !!permessoStatistiche) {
      getStatisticheCandidatiBySportello(sportelloId)
      setHasMounted(true)
    }
    if (ruolo === 'amministratore' || ruolo === 'subamministratore') {
      getStatisticheCandidati()
      setHasMounted(true)
    }
  }, [getStatisticheCandidati, getStatisticheCandidatiBySportello, permessoStatistiche, ruolo, sportelloId])

  const datasetGraficoUno = [
    { name: 'Minori di 15', value: statistiche.minore15, color: '#c7e6ff' },
    { name: 'Da 15 a 29', value: statistiche.da15a29, color: '#8bc7f9' },
    { name: 'Da 30 a 45', value: statistiche.da30a45, color: '#1f94f3' },
    { name: 'Da 46 a 55', value: statistiche.da46a55, color: '#086fc4' },
    { name: 'Maggiori di 55', value: statistiche.maggiore55, color: '#004680' },
  ]
  const coloriGraficoUno = ['#c7e6ff', '#8bc7f9', '#1f94f3', '#086fc4', '#004680']

  const datasetItalianiStranieri = [
    {
      name: 'Italiani',
      value: statistiche.italiani,
      color: '#2196f3',
    },
    {
      name: 'Stranieri',
      value: statistiche.stranieri,
      color: '#ffc104',
    },
  ]
  const coloriItalianiStranieri = ['#2196f3', '#ffc104']

  const datasetMaschiFemmine = [
    {
      name: 'Maschi',
      value: statistiche.maschi,
      color: '#0eb1fd',
    },
    {
      name: 'Femmine',
      value: statistiche.femmine,
      color: '#f98baf',
    },
  ]
  const coloriMaschiFemmine = ['#0eb1fd', '#f98baf']

  return !!permessoStatistiche || ruolo === 'amministratore' || ruolo === 'subamministratore' ? (
    <div>
      <h1 className="page-title">Statistiche Candidati</h1>

      {hasMounted && (
        <>
          <div className="row">
            <div className="col-md-3">
              <div className="card-box">
                <p className="margin-0 bold">Candidati</p>
                <div className="bold text-big">{statistiche.candidati}</div>
              </div>
            </div>
            <div className="col-md-9">
              <div className="card-box">
                <StatisticheGrafico
                  titolo="Fasce d'età"
                  nCandidati={statistiche.candidati}
                  COLORS={coloriGraficoUno}
                  dataset={datasetGraficoUno}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="card-box">
                <StatisticheGrafico
                  titolo="Nazionalità"
                  nCandidati={statistiche.candidati}
                  COLORS={coloriItalianiStranieri}
                  dataset={datasetItalianiStranieri}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="card-box">
                <StatisticheGrafico
                  titolo="Sesso"
                  nCandidati={statistiche.candidati}
                  dataset={datasetMaschiFemmine}
                  COLORS={coloriMaschiFemmine}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  ) : (
    <div className="flex-center">
      <h1>Richiedi il permesso per visualizzare le statistiche candidati</h1>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    permessoStatistiche: state.auth.user.permessi.statistiche,
    sportelloId: state.auth.user.sportelloCorrente.sportelloId,
    statistiche: state.candidati.statistiche,
    ruolo: state.auth.user.ruolo,
  }
}

export default connect(mapStateToProps, { getStatisticheCandidatiBySportello, getStatisticheCandidati })(
  CandidatiStatistiche
)
