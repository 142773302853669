import React from 'react';

///////////////////////////////////////
// DID
const Did = ({statoOccupazionale}) => {
	return (
		<div className="panel panel-border panel-blue panel-raised">
            <div className="panel-heading">
                <h3 className="panel-title">Presentazione DID</h3>
            </div>
            <div className="panel-body">
                <p>
                    {statoOccupazionale.didFlag ? 
                        `DID presentata il ${statoOccupazionale.didDataPresentazioneF} con n° protocollo ${statoOccupazionale.didProtocollo}, operatore PSP ${statoOccupazionale.didOperatorePsp ? statoOccupazionale.didOperatorePsp : 'non indicato'}` : 
                        'DID non presentata'}
                </p>
            </div>
        </div>
	);

}

export default Did;