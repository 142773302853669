import React, { Component, PropTypes } from 'react'
import Ink from 'react-ink'
import { withRouter } from 'react-router'

class BottoneAnnulla extends Component {
  // static contextTypes = {
  // 	router: PropTypes.object
  // };

  goBack(event) {
    event.preventDefault()
    this.props.history.goBack()
  }

  render() {
    return (
      <a href="" onClick={this.goBack.bind(this)} className="btn btn-default pull-right">
        <Ink />
        &laquo; Annulla
      </a>
    )
  }
}
BottoneAnnulla = withRouter(BottoneAnnulla)
export default BottoneAnnulla
