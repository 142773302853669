import { FETCH_CORSO_ATTIVO } from '../actions/types'

const INITIAL_STATE = {
  data: {
    // candidati: [],
  },
}

const reducerCorsoAttivo = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CORSO_ATTIVO:
      return {
        ...state,
        data: action.payload,
      }
    default:
      return state
  }
}
export default reducerCorsoAttivo
