import { loadingBarReducer } from 'react-redux-loading-bar'
import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import acquisizioneCandidatiSportelloReducer from './reducer_acquisizione_candidati_sportello'
import agenziaAttivaReducer from './reducer_agenzia_attiva'
import agenzieReducer from './reducer_agenzie'
import areaGeograficaAttivaReducer from './reducer_area_geografica_attiva'
import areeGeograficheReducer from './reducer_aree_geografiche'
import authReducer from './reducer_auth'
import aziendaAttivaReducer from './reducer_azienda_attiva'
import aziendeReducer from './reducer_aziende'
import candidatiReducer from './reducer_candidati'
import candidatoAttivoReducer from './reducer_candidato_attivo'
import comuniReducer from './reducer_comuni'
import contattoAppReducer from './reducer_contatto_app'
import reducerCorsiFormazione from './reducer_corsi_formazione'
import reducerCorsoAttivo from './reducer_corso_attivo'
import creditiSmsReducer from './reducer_crediti_sms'
import creditiSmsConsumatiReducer from './reducer_crediti_sms_consumati'
import pannelloExportCsv from './reducer_export_csv'
import formPubblicoReducer from './reducer_form_pubblico'
import gruppiReducer from './reducer_gruppi'
import gruppoAttivoReducer from './reducer_gruppo_attivo'
import headerReducer from './reducer_header'
import jobVacanciesReducer from './reducer_job_vacancies'
import jobVacancyAttivaReducer from './reducer_job_vacancy_attiva'
import lookupsReducer from './reducer_lookups'
import messaggiReducer from './reducer_messaggi'
import movimentiCreditiAgenzieReducer from './reducer_movimenti_crediti_agenzia'
import newslettersReducer from './reducer_newsletters'
import newsletterAttivaReducer from './reducer_newsletter_attiva'
import newPosizioneProfessionaleReducer from './reducer_new_posizione_professionale'
import notificationReducer from './reducer_notification'
import notificheReducer from './reducer_notifiche'
import operatoreAttivoReducer from './reducer_operatore_attivo'
import operatoriReducer from './reducer_operatori'
import operatoriAzagReducer from './reducer_operatori_azag'
import posizioniProfessionaliReducer from './reducer_posizioni_professionali'
import provinceReducer from './reducer_province'
import registrazioneReducer from './reducer_registrazione'
import slotsReducer from './reducer_slots'
import slotAttivoReducer from './reducer_slot_attivo'
import sportelliReducer from './reducer_sportelli'
import sportelliPerAreaReducer from './reducer_sportelli_per_area'
import sportelloAttivoReducer from './reducer_sportello_attivo'
import subAmministratoreAttivoReducer from './reducer_subamministratore_attivo'
import subAmministratoriReducer from './reducer_subamministratori'

const rootReducer = combineReducers({
  loadingBar: loadingBarReducer,
  auth: authReducer,
  notification: notificationReducer,
  header: headerReducer,
  lookups: lookupsReducer,
  comuni: comuniReducer,
  posizioniProfessionali: posizioniProfessionaliReducer,
  candidati: candidatiReducer,
  candidatoAttivo: candidatoAttivoReducer,
  newPosizioneProfessionale: newPosizioneProfessionaleReducer,
  slots: slotsReducer,
  slotAttivo: slotAttivoReducer,
  gruppi: gruppiReducer,
  gruppoAttivo: gruppoAttivoReducer,
  registrazione: registrazioneReducer,
  formPubblico: formPubblicoReducer,
  areeGeografiche: areeGeograficheReducer,
  areaGeograficaAttiva: areaGeograficaAttivaReducer,
  sportelli: sportelliReducer,
  sportelliPerArea: sportelliPerAreaReducer,
  sportelloAttivo: sportelloAttivoReducer,
  province: provinceReducer,
  operatori: operatoriReducer,
  operatoreAttivo: operatoreAttivoReducer,
  subAmministratori: subAmministratoriReducer,
  subAmministratoreAttivo: subAmministratoreAttivoReducer,
  aziende: aziendeReducer,
  aziendaAttiva: aziendaAttivaReducer,
  agenzie: agenzieReducer,
  agenziaAttiva: agenziaAttivaReducer,
  jobVacancies: jobVacanciesReducer,
  corsiFormazione: reducerCorsiFormazione,
  corsoFormazioneAttivo: reducerCorsoAttivo,
  jobVacancyAttiva: jobVacancyAttivaReducer,
  operatoriAzag: operatoriAzagReducer,
  messaggi: messaggiReducer,
  contattoApp: contattoAppReducer,
  notifiche: notificheReducer,
  movimentiCreditiAgenzie: movimentiCreditiAgenzieReducer,
  acquisizioneCandidatiSportello: acquisizioneCandidatiSportelloReducer,
  newsletters: newslettersReducer,
  newsletterAttiva: newsletterAttivaReducer,
  creditiSmsConsumati: creditiSmsConsumatiReducer,
  form: formReducer,
  creditiSms: creditiSmsReducer,
  exportCsv: pannelloExportCsv,
})

export default rootReducer
