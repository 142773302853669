import { FETCH_OPERATORI, DELETE_OPERATORE } from '../actions/types';

const INITIAL_STATE = [];

export default function(state = INITIAL_STATE, action) {
	switch(action.type) {
		case FETCH_OPERATORI:
			return action.payload.data || [];
		case DELETE_OPERATORE:
			return state.filter(operatore => operatore.operatoreId !== action.payload);
		default:
			return state;
	}
}
