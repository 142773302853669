import {
	FETCH_GRUPPO,
	ADD_CANDIDATO_GRUPPO,
	REMOVE_CANDIDATO_GRUPPO } from '../actions/types';

const INITIAL_STATE = {};

export default function(state = INITIAL_STATE, action) {
	switch(action.type) {
		case FETCH_GRUPPO:
			return action.payload.data;

    case ADD_CANDIDATO_GRUPPO: {
			return {
				...state,

				candidati: [
					...state.candidati,
					action.payload.data
				]
			}
		}

		case REMOVE_CANDIDATO_GRUPPO:
			return {
				...state,
				candidati: state.candidati.filter(candidato => candidato.candidatoId !== action.payload)
			}

		default:
			return state;
	}

}
