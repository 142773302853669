import React from 'react'
import { Route, Switch } from 'react-router'
import GruppiList from './gruppi_list'
import Gruppo from './gruppo'

const Gruppi = (props) => {
  return (
    <div>
      <Switch>
        <Route exact path="/app/gruppi" component={GruppiList} />
        <Route exact path="/app/gruppi/:gruppoId" component={Gruppo} />
      </Switch>
    </div>
  )
}

export default Gruppi
