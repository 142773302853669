import { FETCH_NEWSLETTERS, CHANGE_PAGE_NEWSLETTERS, DELETE_NEWSLETTER } from '../actions/types'

const INITIAL_STATE = {
  data: [],
  pagination: {
    page: 1,
    totalPages: 1,
  },
  pageRequested: 1,
}

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_NEWSLETTERS:
      return {
        ...state,
        data: action.payload.data.items,
        pagination: action.payload.data.pagination,
      }
    case CHANGE_PAGE_NEWSLETTERS:
      return {
        ...state,
        pageRequested: action.payload,
      }
    case DELETE_NEWSLETTER:
      return {
        ...state,
        data: state.data.filter(newsletter => newsletter.newsletterId !== action.payload),
      }
    default:
      return state
  }
}
