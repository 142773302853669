import React from 'react'
import { Route, Switch } from 'react-router'
import AreeGeograficheList from '../../amministratore/aree_geografiche/aree_geografiche_list'
import CreateEditAreaGeografica from '../../amministratore/aree_geografiche/create_edit_area_geografica'

const AreeGeografiche = (props) => {
  return (
    <div>
      <Switch>
        <Route exact path="/app/regioni/" component={AreeGeograficheList} />
        <Route path="/app/regioni/new" component={CreateEditAreaGeografica} />
        <Route path="/app/regioni/:areaGeograficaId" component={CreateEditAreaGeografica} />
      </Switch>
    </div>
  )
}

export default AreeGeografiche
