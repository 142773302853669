import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Ink from 'react-ink'

import {
  fetchJobVacancies,
  setKeywordsJobVacancies,
  setSoloProprietarioFlag,
  setMostraJvAperte,
  changePageJobVacancies,
  createCandidatura,
  deleteCandidatura,
  fetchAzienda,
  fetchAgenzia,
} from '../../actions'

import { mancaDichiarazione, dichiarazioneDaApprovare } from '../../reducers/reducer_auth'

import config from '../../config'

import JobVacanciesComplete from './job_vacancies_complete'
import JobVacanciesAnonime from './job_vacancies_anonime'
import Pager from '../pager/pager'

import { FaSearch } from 'react-icons/fa'
import { MdAdd } from 'react-icons/md'

class JobVacancies extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showJvComplete: true,
    }
  }

  componentDidMount() {
    this.props.fetchJobVacancies({
      organizzazione: config.organizzazione,
      provincia: '',
      keywords: this.props.keywords,
      soloProprietarioFlag: this.props.soloProprietarioFlag,
      page: this.props.pageRequested,
    })

    const ruolo = this.props.user.ruolo

    if (ruolo === 'azienda' || ruolo === 'agenzia') {
      this.props.setSoloProprietarioFlag(true)
    } else {
      this.props.setSoloProprietarioFlag(false)
    }

    if (ruolo === 'azienda') {
      this.props.fetchAzienda(this.props.user.aziendaId)
    }

    if (ruolo === 'agenzia') {
      this.props.fetchAgenzia(this.props.user.agenziaId)
    }
  }

  componentDidUpdate(prevProps) {
    const filterChanged =
      prevProps.keywords !== this.props.keywords ||
      prevProps.soloProprietarioFlag !== this.props.soloProprietarioFlag ||
      prevProps.mostraJvAperte !== this.props.mostraJvAperte

    const pageChanged = this.props.pageRequested !== prevProps.pagination.page

    if (filterChanged) {
      this.props.changePageJobVacancies(1)
    }

    if (filterChanged || pageChanged) {
      this.props.fetchJobVacancies({
        organizzazione: config.organizzazione,
        provincia: '',
        keywords: this.props.keywords,
        soloProprietarioFlag: this.props.soloProprietarioFlag,
        mostraJvAperte: this.props.mostraJvAperte,
        page: filterChanged ? 1 : this.props.pageRequested,
      })
    }
  }

  changeKeyword = (event) => {
    this.props.setKeywordsJobVacancies(event.target.value)
  }

  changeSoloProprietarioFlag = (event) => {
    this.props.setSoloProprietarioFlag(event.target.checked)
  }

  changeMostraJvAperte = (event) => {
    this.props.setMostraJvAperte(event.target.checked)
  }

  switchJvView = (event) => {
    event.preventDefault()
    this.setState({
      showJvComplete: !this.state.showJvComplete,
    })
    this.props.setSoloProprietarioFlag(!this.props.soloProprietarioFlag)
  }

  createCandidatura = (jobVacancyId) => {
    // event.preventDefault()
    this.props.createCandidatura(jobVacancyId)
  }

  deleteCandidatura = (jobVacancyId) => (event) => {
    event.preventDefault()
    this.props.deleteCandidatura(jobVacancyId, this.props.candidatoId)
  }

  renderSearchBox(keywords) {
    return (
      <div className="search-box form-group">
        <input
          type="text"
          className="form-control"
          value={keywords}
          onChange={this.changeKeyword}
          placeholder="Filtra per parole chiave..."
        />
        <FaSearch />
      </div>
    )
  }

  render() {
    const {
      jobVacancies,
      user: { ruolo },
      keywords,
      soloProprietarioFlag,
      mostraJvAperte,
      pagination,
      changePageJobVacancies,
    } = this.props

    return (
      <div className="job-vacancies-list">
        {this.props.mancaDichiarazione && (
          <div className="alert alert-danger">
            ATTENZIONE: non è stata ancora caricata la dichiarazione firmata (il modello è stato inviato via e-mail). Le
            Job Vacancy inserite non saranno quindi visibili.
          </div>
        )}

        {this.props.dichiarazioneDaApprovare && (
          <div className="alert alert-warning">
            ATTENZIONE: la dichiarazione è in fase di verifica. Le Job Vacancy inserite non saranno visibili fino ad
            approvazione.
          </div>
        )}

        {!!(ruolo === 'azienda' || (ruolo === 'agenzia' && this.state.showJvComplete) || ruolo === 'operatore') && (
          <div>
            <h1 className="page-title">
              {ruolo === 'azienda' || ruolo === 'agenzia' ? (
                <span>Le mie job vacancies&nbsp;&nbsp;</span>
              ) : (
                <span>Job vacancies</span>
              )}

              {ruolo === 'agenzia' && (
                <a href="/#" className="btn btn-sm btn-primary" onClick={this.switchJvView}>
                  <Ink />
                  {this.state.showJvComplete ? 'Mostra tutte le job vacancies' : 'Mostra solo le mie job vacancies'}
                </a>
              )}
            </h1>

            {(ruolo === 'azienda' ||
              ruolo === 'agenzia' ||
              (ruolo === 'operatore' && !!this.props.user.permessi.gestioneJv)) && (
              <Link to="/app/job-vacancies/new" className="btn btn-success pull-right">
                <Ink />
                <MdAdd style={{ verticalAlign: 'middle' }} />
                Nuova job vacancy
              </Link>
            )}

            <div className="form-group solo-sportello-checkbox">
              {ruolo === 'operatore' && (
                <div className="checkbox checkbox-primary">
                  <input
                    type="checkbox"
                    id="soloSportello"
                    checked={soloProprietarioFlag}
                    onChange={this.changeSoloProprietarioFlag}
                  />
                  <label htmlFor="soloSportello">Mostra solo quelle del mio sportello</label>
                </div>
              )}
              <div className="checkbox checkbox-primary">
                <input
                  type="checkbox"
                  id="mostraJvAperte"
                  checked={mostraJvAperte}
                  onChange={this.changeMostraJvAperte}
                />
                <label htmlFor="mostraJvAperte">Mostra JV aperte</label>
              </div>
            </div>

            <div className="row">
              <div className="col-md-8">{this.renderSearchBox(keywords)}</div>
              <div className="col-md-4">
                <Pager
                  position={'top'}
                  page={pagination.page}
                  totalPages={pagination.totalPages}
                  onChangePage={changePageJobVacancies}
                />
              </div>
            </div>

            <JobVacanciesComplete jobVacancies={jobVacancies} ruolo={ruolo} />

            <Pager
              position={'bottom'}
              page={pagination.page}
              totalPages={pagination.totalPages}
              onChangePage={changePageJobVacancies}
            />
          </div>
        )}

        {(ruolo === 'candidato' || (ruolo === 'agenzia' && !this.state.showJvComplete)) && (
          <div>
            <h1 className="page-title">
              {ruolo === 'agenzia' ? <span>Tutte le job vacancies&nbsp;&nbsp;</span> : <span>Job vacancies</span>}
              {ruolo === 'agenzia' && (
                <a href="/#" className="btn btn-sm btn-primary" onClick={this.switchJvView}>
                  <Ink />
                  {this.state.showJvComplete ? 'Mostra tutte le job vacancies' : 'Mostra solo le mie job vacancies'}
                </a>
              )}
            </h1>

            <div className="row">
              <div className="col-md-8">{this.renderSearchBox()}</div>
              <div className="col-md-4">
                <Pager
                  position={'top'}
                  page={pagination.page}
                  totalPages={pagination.totalPages}
                  onChangePage={changePageJobVacancies}
                />
              </div>
            </div>
            <JobVacanciesAnonime
              jobVacancies={jobVacancies}
              ruolo={ruolo}
              onCreateCandidatura={this.createCandidatura}
              onDeleteCandidatura={this.deleteCandidatura}
            />

            <Pager
              position={'bottom'}
              page={pagination.page}
              totalPages={pagination.totalPages}
              onChangePage={changePageJobVacancies}
            />
          </div>
        )}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    jobVacancies: state.jobVacancies.data,
    user: state.auth.user,
    permessoJvComplete: state.auth.user.permessi.visualizzazioneJvComplete,
    keywords: state.jobVacancies.keywords,
    soloProprietarioFlag: state.jobVacancies.soloProprietarioFlag,
    mostraJvAperte: state.jobVacancies.mostraJvAperte,
    candidatoId: state.auth.user.candidatoId,
    pageRequested: state.jobVacancies.pageRequested,
    pagination: state.jobVacancies.pagination,
    mancaDichiarazione: mancaDichiarazione(state),
    dichiarazioneDaApprovare: dichiarazioneDaApprovare(state),
  }
}

export default connect(mapStateToProps, {
  fetchJobVacancies,
  setKeywordsJobVacancies,
  setSoloProprietarioFlag,
  setMostraJvAperte,
  changePageJobVacancies,
  createCandidatura,
  deleteCandidatura,
  fetchAzienda,
  fetchAgenzia,
})(JobVacancies)
