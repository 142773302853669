import React, { Component } from 'react'
import './header.less'
import HeaderMenu from './header_menu/header_menu'
import TopHeader from './top_header/top_header'

export default class Header extends Component {
  constructor(props) {
    super(props)
    this.state = { menuOpen: false }
  }

  openMenu(event) {
    //event.preventDefault();

    this.setState({
      menuOpen: !this.state.menuOpen,
    })
  }

  render() {
    return (
      <header>
        <TopHeader isOpen={this.state.menuOpen} onOpen={this.openMenu.bind(this)}></TopHeader>
        <HeaderMenu isOpen={this.state.menuOpen} onOpen={this.openMenu.bind(this)}></HeaderMenu>
      </header>
    )
  }
}
