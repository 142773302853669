import React, { Component } from 'react'
import { GoogleMap, LoadScript, Circle } from '@react-google-maps/api'

const containerStyle = {
  height: '300px',
}

class MyMapComponent extends Component {
  constructor(props) {
    super(props)
    this.ref = React.createRef()
    this.state = { mounted: false }
  }

  handleRadiusChanged = (node) => {
    if (node) {
      this.props.onRadiusChanged(node?.state.circle.radius)
    }
  }
  handleCenterChanged = (node) => {
    if (node) {
      this.props.onCenterChanged(node?.state.circle.center.lat(), node?.state.circle.center.lng())
    }
  }

  render() {
    return (
      <LoadScript
        googleMapsApiKey="AIzaSyAM01IWHDwkP3mTw_lu7fDKmKihjrDU4y4"
        onLoad={() => {
          setTimeout(() => {
            this.setState({ mounted: true })
          }, 200)
        }}
      >
        {this.state.mounted && (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={this.props.center}
            zoom={8}
            options={{ scrollwheel: false }}
          >
            <Circle
              ref={this.ref}
              center={this.props.center}
              radius={this.props.radius}
              options={{
                fillColor: this.props.color,
                fillOpacity: 0.4,
                strokeColor: this.props.color,
                strokeWeight: 2,
                editable: this.props.editable,
              }}
              draggable={false}
              onRadiusChanged={() => this.handleRadiusChanged(this.ref.current)}
              onCenterChanged={() => this.handleCenterChanged(this.ref.current)}
            />
          </GoogleMap>
        )}
      </LoadScript>
    )
  }
}

class DisponibilitaGoogleMap extends Component {
  onCenterChanged = (lat, lng) => {
    this.props.onCenterChanged(lat, lng)
  }

  onRadiusChanged = (radius) => {
    this.props.onRadiusChanged(radius)
  }

  render() { 
    return (
      <div>
        <MyMapComponent {...this.props} />
      </div>
    )
  }
}

export default class MappaDisponibilitaGeografica extends Component {
  render() {
    return (
      <DisponibilitaGoogleMap
        center={{ lat: this.props.lat, lng: this.props.lng }}
        radius={this.props.radius}
        color={this.props.color}
        editable={this.props.canEdit}
        onCenterChanged={this.props.onCenterChanged}
        onRadiusChanged={this.props.onRadiusChanged}
      />
    )
  }
}
