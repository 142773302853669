import React, { Component, PropTypes } from 'react'
import { connect } from 'react-redux'
import { Form, Field } from 'react-final-form'
import Ink from 'react-ink'
import swal from 'sweetalert'
import { MdAdd, MdEdit, MdCheck, MdClose } from 'react-icons/md'

import {
  fetchOperatoriAzag,
  setEditOperatoreAzag,
  saveOperatoreAzag,
  createOperatoreAzag,
  deleteOperatoreAzag,
} from '../../actions'
import { isEditing } from '../../reducers/reducer_operatori_azag'

class Operatori extends Component {
  componentDidMount() {
    this.props.fetchOperatoriAzag(this.props.tipo, this.props.aziendaAgenziaId)
  }

  deleteOperatore = () => {
    this.props.deleteOperatoreAzag(
      this.props.tipo,
      this.props.aziendaAgenziaId,
      this.state.deletingUtenteId
    )
  }

  createOperatore = (values) => {
    if (this.props.isEditing) {
      this.props
        .saveOperatoreAzag(this.props.tipo, this.props.aziendaAgenziaId, values)
        .then((res) => {
          this.setEditOperatore(values, false)
        })
    } else {
      this.props.createOperatoreAzag(this.props.tipo, this.props.aziendaAgenziaId, values)
    }
  }

  setEditOperatore = (operatore, isEdit) => {
    this.props.setEditOperatoreAzag(operatore.utenteId, isEdit)
  }

  setEditOperatoreClick = (operatore, isEdit) => (event) => {
    event.preventDefault()
    this.props.setEditOperatoreAzag(operatore.utenteId, isEdit)
  }

  renderOperatore = (operatore) => {
    if (operatore.isEdit) {
      const { agenziaId, dataFineBlocco, dataAggiornamentoPassword, isEdit, ...rest } = operatore
      return (
        <Form
          initialValues={rest}
          onSubmit={this.createOperatore}
          render={({ handleSubmit }) => (
            <tr key={operatore.utenteId}>
              <td>
                <Field
                  name="nome"
                  render={({ input, meta }) => (
                    <div
                      className={`form-group ${meta.touched && meta.invalid ? 'has-error' : ''}`}
                    >
                      <input type="text" className="form-control" {...input} />
                      <div className="help-block">{meta.touched && meta.error}</div>
                    </div>
                  )}
                />
              </td>
              <td>
                <Field
                  name="cognome"
                  render={({ input, meta }) => (
                    <div
                      className={`form-group ${meta.touched && meta.invalid ? 'has-error' : ''}`}
                    >
                      <input type="text" className="form-control" {...input} />
                      <div className="help-block">{meta.touched && meta.error}</div>
                    </div>
                  )}
                />
              </td>
              <td>
                <Field
                  name="login"
                  render={({ input, meta }) => (
                    <div
                      className={`form-group ${meta.touched && meta.invalid ? 'has-error' : ''}`}
                    >
                      <input type="text" className="form-control" {...input} />
                      <div className="help-block">{meta.touched && meta.error}</div>
                    </div>
                  )}
                />
              </td>
              <td>
                <Field
                  name="password"
                  render={({ input, meta }) => (
                    <div
                      className={`form-group ${meta.touched && meta.invalid ? 'has-error' : ''}`}
                    >
                      <input type="text" className="form-control" {...input} />
                      <div className="help-block">{meta.touched && meta.error}</div>
                    </div>
                  )}
                />
              </td>
              <td className="text-center">
                <div className="form-group">
                  <div className="checkbox checkbox-success">
                    <Field component="input" type="checkbox" name="attivoFlag" id="attivoFlag" />
                    <label htmlFor="attivoFlag"></label>
                  </div>
                </div>
              </td>
              <td className="text-right">
                <button
                  type="submit"
                  className="btn btn-sm btn-primary btn-block"
                  onClick={handleSubmit}
                >
                  <MdEdit />
                  Salva
                </button>
              </td>
              <td className="text-right">
                <button
                  type="button"
                  className="btn btn-sm btn-danger btn-block"
                  onClick={this.setEditOperatoreClick(operatore, false)}
                >
                  &times; Annulla
                </button>
              </td>
            </tr>
          )}
        />
      )
    }
    return (
      <tr key={operatore.utenteId}>
        <td>{operatore.nome}</td>
        <td>{operatore.cognome}</td>
        <td>{operatore.login}</td>
        <td>{operatore.password}</td>
        <td className="operatore-attivo text-center">
          {operatore.attivoFlag ? (
            <span>
              <MdCheck className="attivo" />
            </span>
          ) : (
            <span>&times;</span>
          )}
        </td>
        <td className="text-right">
          <button
            type="button"
            className={`btn btn-sm btn-block ${
              this.props.isEditing ? 'btn-default' : 'btn-primary'
            }`}
            onClick={this.setEditOperatoreClick(operatore, true)}
          >
            <MdEdit />
            Modifica
          </button>
        </td>
        <td>
          <button
            type="button"
            className={`btn btn-sm btn-block ${
              this.props.isEditing ? 'btn-default' : 'btn-danger'
            }`}
            onClick={() => {
              this.setState({ deletingUtenteId: operatore.utenteId })
              this.handleAlertDeleteOperatore()
            }}
          >
            &times; Elimina
          </button>
        </td>
      </tr>
    )
  }

  renderOperatori(operatori) {
    return operatori.map((operatore) => {
      return this.renderOperatore(operatore)
    })
  }

  handleAlertDeleteOperatore = () => {
    swal({
      type: 'warning',
      title: 'Eliminazione operatore',
      text: 'Sei sicuro di voler eliminare questo operatore? Questa azione è irreversibile.',
      buttons: ['Annulla', 'Sì, elimina operatore'],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.deleteOperatore()
      }
    })
  }

  render() {
    const { operatori } = this.props

    return (
      <div className="operatori">
        <div className="panel panel-border panel-success">
          <div className="panel-heading">
            <h3 className="panel-title">Operatori</h3>
          </div>
          <div className="panel-body">
            <Form
              onSubmit={this.createOperatore}
              validate={validate}
              render={({ handleSubmit, form }) => (
                <form onSubmit={handleSubmit}>
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Nome</th>
                          <th>Cognome</th>
                          <th>Login</th>
                          <th>Password</th>
                          <th className="text-center">Attivo?</th>
                          <th colSpan="2"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.renderOperatori(operatori)}

                        {!this.props.isEditing && (
                          <tr className="new-row">
                            <td>
                              <Field
                                name="nome"
                                render={({ input, meta }) => (
                                  <div
                                    className={`form-group ${
                                      meta.touched && meta.invalid ? 'has-error' : ''
                                    }`}
                                  >
                                    <input type="text" className="form-control" {...input} />
                                    <div className="help-block">{meta.touched && meta.error}</div>
                                  </div>
                                )}
                              />
                            </td>
                            <td>
                              <Field
                                name="cognome"
                                render={({ input, meta }) => (
                                  <div
                                    className={`form-group ${
                                      meta.touched && meta.invalid ? 'has-error' : ''
                                    }`}
                                  >
                                    <input type="text" className="form-control" {...input} />
                                    <div className="help-block">{meta.touched && meta.error}</div>
                                  </div>
                                )}
                              />
                            </td>
                            <td>
                              <Field
                                name="login"
                                render={({ input, meta }) => (
                                  <div
                                    className={`form-group ${
                                      meta.touched && meta.invalid ? 'has-error' : ''
                                    }`}
                                  >
                                    <input type="email" className="form-control" {...input} />
                                    <div className="help-block">{meta.touched && meta.error}</div>
                                  </div>
                                )}
                              />
                            </td>
                            <td>
                              <Field
                                name="password"
                                render={({ input, meta }) => (
                                  <div
                                    className={`form-group ${
                                      meta.touched && meta.invalid ? 'has-error' : ''
                                    }`}
                                  >
                                    <input type="password" className="form-control" {...input} />
                                    <div className="help-block">{meta.touched && meta.error}</div>
                                  </div>
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <div className="form-group">
                                <div className="checkbox checkbox-success">
                                  <Field
                                    name="attivoFlag"
                                    id="attivoFlag"
                                    component="input"
                                    type="checkbox"
                                  />
                                  <label htmlFor="attivoFlag"></label>
                                </div>
                              </div>
                            </td>
                            <td className="text-right" colSpan="2">
                              <button
                                type="submit"
                                className="btn btn-sm btn-success btn-block"
                              >
                                <MdAdd />
                                Aggiungi
                              </button>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </form>
              )}
            />
          </div>
        </div>
      </div>
    )
  }
}

function validate(values) {
  const errors = {}

  if (!values.nome) {
    errors.nome = 'Inserisci il nome'
  }

  if (!values.cognome) {
    errors.cognome = 'Inserisci il cognome'
  }

  if (!values.login) {
    errors.login = "Inserisci l'email"
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.login)) {
    errors.login = 'Email non valida'
  }

  if (!values.password) {
    errors.password = 'Inserisci la password'
  }

  if (values.password && values.password.length < 8) {
    errors.password = 'Almeno 8 caratteri'
  }

  var atLeastOneLetter = /[A-Z]/i
  if (values.password && !atLeastOneLetter.test(values.password)) {
    errors.password = 'Almeno una lettera'
  }

  var atLeastOneDigit = /\d/
  if (values.password && !atLeastOneDigit.test(values.password)) {
    errors.password = 'Almeno un numero'
  }

  return errors
}

const mapStateToProps = (state) => ({
  operatori: state.operatoriAzag.data,
  isEditing: isEditing(state),
})

export default connect(mapStateToProps, {
  fetchOperatoriAzag,
  setEditOperatoreAzag,
  saveOperatoreAzag,
  createOperatoreAzag,
  deleteOperatoreAzag,
})(Operatori)
