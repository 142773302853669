import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Ink from 'react-ink'
import swal from 'sweetalert'

import { removeConoscenzaLinguistica } from '../../actions'

import { MdModeEdit, MdRemoveCircle, MdLens } from 'react-icons/md'

class ConoscenzaLinguistica extends Component {
  onRemove() {
    this.props.removeConoscenzaLinguistica(
      this.props.conoscenzaLinguistica.conoscenzaLinguisticaId,
      this.props.candidatoId
    )
  }

  convertiLivelloInNumero(livello) {
    switch (livello) {
      case 'A1':
        return 1
      case 'A2':
        return 2
      case 'B1':
        return 3
      case 'B2':
        return 4
      case 'C1':
        return 5
      case 'C2':
        return 6
    }
  }

  getCssClass(livello, riferimento) {
    if (this.convertiLivelloInNumero(livello) === this.convertiLivelloInNumero(riferimento)) {
      return 'active'
    }
    if (this.convertiLivelloInNumero(livello) > this.convertiLivelloInNumero(riferimento)) {
      return 'light'
    }
    return ''
  }

  handleEliminaConoscenzaLinguistica = () => {
    swal({
      icon: 'warning',
      title: 'Eliminazione conoscenza linguistica',
      text: 'Sei sicuro di vole eliminare questa conoscenza linguistica? Questa azione è irreversibile.',
      buttons: ['Annulla', 'Si, elimina conoscenza linguistica'],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onRemove()
      }
    })
  }
  render() {
    const { conoscenzaLinguistica, candidatoId, canEdit } = this.props

    return (
      <div className={'panel panel-color panel-primary panel-show-section'}>
        <div className="panel-heading">
          <h3 className="panel-title">
            {conoscenzaLinguistica.lingua}
            {canEdit ? (
              <span className="pull-right">
                <Link
                  to={`/app/candidati/${candidatoId}/conoscenze-linguistiche/${conoscenzaLinguistica.conoscenzaLinguisticaId}`}
                  className="btn btn-sm btn-primary"
                >
                  <MdModeEdit />
                  <span>Modifica</span>
                </Link>
                <a
                  className="btn btn-sm btn-primary btn-remove"
                  onClick={() => this.handleEliminaConoscenzaLinguistica()}
                >
                  <MdRemoveCircle />
                  <span>Elimina</span>
                </a>
              </span>
            ) : null}
          </h3>
        </div>
        <div className="panel-body">
          <div className="row">
            <div className="col-sm-8">
              <table className="livello">
                <thead>
                  <tr>
                    <th>&nbsp;</th>
                    <th>A1</th>
                    <th>A2</th>
                    <th>B1</th>
                    <th>B2</th>
                    <th>C1</th>
                    <th>C2</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="tipo">Comprensione ascolto</td>
                    <td
                      className={this.getCssClass(conoscenzaLinguistica.comprensioneAscolto, 'A1')}
                    >
                      <MdLens />
                    </td>
                    <td
                      className={this.getCssClass(conoscenzaLinguistica.comprensioneAscolto, 'A2')}
                    >
                      <MdLens />
                    </td>
                    <td
                      className={this.getCssClass(conoscenzaLinguistica.comprensioneAscolto, 'B1')}
                    >
                      <MdLens />
                    </td>
                    <td
                      className={this.getCssClass(conoscenzaLinguistica.comprensioneAscolto, 'B2')}
                    >
                      <MdLens />
                    </td>
                    <td
                      className={this.getCssClass(conoscenzaLinguistica.comprensioneAscolto, 'C1')}
                    >
                      <MdLens />
                    </td>
                    <td
                      className={this.getCssClass(conoscenzaLinguistica.comprensioneAscolto, 'C2')}
                    >
                      <MdLens />
                    </td>
                  </tr>
                  <tr>
                    <td className="tipo">Comprensione lettura</td>
                    <td
                      className={this.getCssClass(conoscenzaLinguistica.comprensioneLettura, 'A1')}
                    >
                      <MdLens />
                    </td>
                    <td
                      className={this.getCssClass(conoscenzaLinguistica.comprensioneLettura, 'A2')}
                    >
                      <MdLens />
                    </td>
                    <td
                      className={this.getCssClass(conoscenzaLinguistica.comprensioneLettura, 'B1')}
                    >
                      <MdLens />
                    </td>
                    <td
                      className={this.getCssClass(conoscenzaLinguistica.comprensioneLettura, 'B2')}
                    >
                      <MdLens />
                    </td>
                    <td
                      className={this.getCssClass(conoscenzaLinguistica.comprensioneLettura, 'C1')}
                    >
                      <MdLens />
                    </td>
                    <td
                      className={this.getCssClass(conoscenzaLinguistica.comprensioneLettura, 'C2')}
                    >
                      <MdLens />
                    </td>
                  </tr>
                  <tr>
                    <td className="tipo">Parlato interazione</td>
                    <td
                      className={this.getCssClass(conoscenzaLinguistica.parlatoInterazione, 'A1')}
                    >
                      <MdLens />
                    </td>
                    <td
                      className={this.getCssClass(conoscenzaLinguistica.parlatoInterazione, 'A2')}
                    >
                      <MdLens />
                    </td>
                    <td
                      className={this.getCssClass(conoscenzaLinguistica.parlatoInterazione, 'B1')}
                    >
                      <MdLens />
                    </td>
                    <td
                      className={this.getCssClass(conoscenzaLinguistica.parlatoInterazione, 'B2')}
                    >
                      <MdLens />
                    </td>
                    <td
                      className={this.getCssClass(conoscenzaLinguistica.parlatoInterazione, 'C1')}
                    >
                      <MdLens />
                    </td>
                    <td
                      className={this.getCssClass(conoscenzaLinguistica.parlatoInterazione, 'C2')}
                    >
                      <MdLens />
                    </td>
                  </tr>
                  <tr>
                    <td className="tipo">Parlato produzione orale</td>
                    <td
                      className={this.getCssClass(
                        conoscenzaLinguistica.parlatoProduzioneOrale,
                        'A1'
                      )}
                    >
                      <MdLens />
                    </td>
                    <td
                      className={this.getCssClass(
                        conoscenzaLinguistica.parlatoProduzioneOrale,
                        'A2'
                      )}
                    >
                      <MdLens />
                    </td>
                    <td
                      className={this.getCssClass(
                        conoscenzaLinguistica.parlatoProduzioneOrale,
                        'B1'
                      )}
                    >
                      <MdLens />
                    </td>
                    <td
                      className={this.getCssClass(
                        conoscenzaLinguistica.parlatoProduzioneOrale,
                        'B2'
                      )}
                    >
                      <MdLens />
                    </td>
                    <td
                      className={this.getCssClass(
                        conoscenzaLinguistica.parlatoProduzioneOrale,
                        'C1'
                      )}
                    >
                      <MdLens />
                    </td>
                    <td
                      className={this.getCssClass(
                        conoscenzaLinguistica.parlatoProduzioneOrale,
                        'C2'
                      )}
                    >
                      <MdLens />
                    </td>
                  </tr>
                  <tr>
                    <td className="tipo">Produzione scritta</td>
                    <td className={this.getCssClass(conoscenzaLinguistica.produzioneScritta, 'A1')}>
                      <MdLens />
                    </td>
                    <td className={this.getCssClass(conoscenzaLinguistica.produzioneScritta, 'A2')}>
                      <MdLens />
                    </td>
                    <td className={this.getCssClass(conoscenzaLinguistica.produzioneScritta, 'B1')}>
                      <MdLens />
                    </td>
                    <td className={this.getCssClass(conoscenzaLinguistica.produzioneScritta, 'B2')}>
                      <MdLens />
                    </td>
                    <td className={this.getCssClass(conoscenzaLinguistica.produzioneScritta, 'C1')}>
                      <MdLens />
                    </td>
                    <td className={this.getCssClass(conoscenzaLinguistica.produzioneScritta, 'C2')}>
                      <MdLens />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-sm-4">
              <div className="dettagli">
                <p>{conoscenzaLinguistica.eventualiCertificazioni || 'Nessuna certificazione'}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(null, { removeConoscenzaLinguistica })(ConoscenzaLinguistica)
