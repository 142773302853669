import { v4 as uuid } from 'uuid'

import {
  RESET_NEWSLETTER,
  FETCH_NEWSLETTER,
  FETCH_NUMERO_DESTINATARI,
  SEND_NEWSLETTER,
  UPDATE_NEWSLETTER,
  ADD_PARAGRAFO_NEWSLETTER,
  REMOVE_PARAGRAFO_NEWSLETTER,
  UPDATE_PARAGRAFO_NEWSLETTER,
  ADD_SPORTELLO_NEWSLETTER,
  REMOVE_SPORTELLO_NEWSLETTER,
  UPLOAD_ALLEGATO_NEWSLETTER,
  REMOVE_ALLEGATO_NEWSLETTER,
} from '../actions/types'

const INITIAL_STATE = {
  oggetto: '',
  tipoDestinatari: 'sportelli',
  numeroDestinatari: 0,
  sportelli: [],
  paragrafi: [],
  allegato1: null,
  allegato1rendered: '',
  allegato2: null,
  allegato2rendered: '',
}

export default function newsletterAttivaReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case RESET_NEWSLETTER:
      return INITIAL_STATE

    case FETCH_NEWSLETTER:
      return {
        ...action.payload.data,
        tipoDestinatari: action.payload.data.gruppoCvSinteticoNome ? 'gruppo' : 'sportelli',
      }

    case FETCH_NUMERO_DESTINATARI:
      return action.payload.data.numeroDestinatari.count
        ? {
            ...state,
            numeroDestinatari: action.payload.data.numeroDestinatari.count,
          }
        : {
            ...state,
            numeroDestinatari: action.payload.data.numeroDestinatari,
          }

    case UPDATE_NEWSLETTER:
      let tipoDestinatariModificato = false

      if (action.payload.field === 'tipoDestinatari') {
        // Sto cambiando tipo destinatari:
        // => devo resettare i selezionati
        tipoDestinatariModificato = true
      }

      return {
        ...state,
        gruppoCvSinteticoId: tipoDestinatariModificato ? undefined : state.gruppoCvSinteticoId,
        sportelli: tipoDestinatariModificato ? [] : state.sportelli,
        [action.payload.field]: action.payload.value,
      }

    case ADD_PARAGRAFO_NEWSLETTER: {
      const nuovoParagrafo = {
        paragrafoId: uuid(),
        titolo: '',
        testo: '',
      }

      return {
        ...state,
        paragrafi: [...state.paragrafi, nuovoParagrafo],
      }
    }

    case REMOVE_PARAGRAFO_NEWSLETTER:
      return {
        ...state,
        paragrafi: state.paragrafi.filter((paragrafo) => paragrafo.paragrafoId !== action.payload),
      }

    case UPDATE_PARAGRAFO_NEWSLETTER:
      return {
        ...state,
        paragrafi: state.paragrafi.map((paragrafo) => {
          if (paragrafo.paragrafoId === action.payload.paragrafoId) {
            return {
              ...paragrafo,
              [action.payload.field]: action.payload.value,
            }
          }
          return paragrafo
        }),
      }

    case ADD_SPORTELLO_NEWSLETTER: {
      return {
        ...state,
        sportelli: [...state.sportelli, action.payload],
      }
    }

    case REMOVE_SPORTELLO_NEWSLETTER:
      return {
        ...state,
        sportelli: state.sportelli.filter((sportello) => sportello.sportelloId !== action.payload),
      }
    case UPLOAD_ALLEGATO_NEWSLETTER:
      return {
        ...state,
        [action.payload.allegatoId]: action.payload.value,
        [`${action.payload.allegatoId}rendered`]: action.payload.valueToBeRendered,
      }
    case REMOVE_ALLEGATO_NEWSLETTER:
      return {
        ...state,
        [action.payload]: null,
      }
    default:
      return state
  }
}
