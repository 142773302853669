import React, { useEffect } from 'react'
import Ink from 'react-ink'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { fetchCorsoAttivo } from '../../actions'

const Corso = ({ match, ruolo, corso, fetchCorsoAttivo, user }) => {
  useEffect(() => {
    fetchCorsoAttivo(match.params.corsoFormazionePropostoId)
  }, [fetchCorsoAttivo, match.params.corsoFormazionePropostoId])

  const renderCandidatiInteressati = () => {
    return corso.candidati.map((candidato) => (
      <li key={candidato.candidatoId}>
        <Link to={'/app/candidati/' + candidato.candidatoId}>
          <img className="avatar" alt="avatar" src={candidato.fotografia || '/images/senza-foto.jpg'} />
          {candidato.nome} {candidato.cognome}
        </Link>
      </li>
    ))
  }

  return (
    <div className="dettaglio-job-vacancy">
      <h1 className="page-title">Dettaglio corso</h1>

      <div className="row">
        <div className="col-sm-8">
          <div className="panel panel-border panel-primary">
            <div className="panel-heading">
              <h3 className="panel-title">{corso.titolo}</h3>
            </div>
            <div className="panel-body">
              <p className="heading mt0">Attestato rilasciato</p>
              <p>{corso.attestatoRilasciato || '-'}</p>

              <p className="heading">Costo</p>
              <p>{corso.costo || '-'} €</p>

              <p className="heading">Ente</p>
              <p>{corso.ente}</p>
              <p className="heading">requisiti</p>
              <p>{corso.requisiti || '-'}</p>
            </div>
          </div>
          <div className="panel panel-border panel-warning">
            <div className="panel-heading">
              <h3 className="panel-title">Info corso</h3>
            </div>
            <div className="panel-body">
              <p className="heading">Data inizio</p>
              <p>{corso.dataInizio || '-'}</p>
              <p className="heading mt0">modalitaErogazione</p>
              <p>{corso.modalitaErogazione || '-'}</p>

              <p className="heading">Obbligo di frequenza</p>
              <p>{corso.obbligoFrequenzaFlag ? 'si' : 'no' || '-'}</p>
              <p className="heading">Orario</p>
              <p>{corso.orario || '-'}</p>
              <p className="heading">Durata ore</p>
              <p>{corso.durataOre || '-'}</p>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          {!(ruolo === 'candidato') && (
            <div>
              <h4>Candidati interessati: {corso.numeroInteressati}</h4>
              {!!corso.candidati && corso.candidati.length > 0 && (
                <ul className="candidati">{renderCandidatiInteressati()}</ul>
              )}
            </div>
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-sm-8">
          <Link to="/app/corsi-di-formazione" className="btn btn-default btn-lg">
            <Ink />
            &laquo; Torna ai corsi
          </Link>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    ruolo: state.auth.user.ruolo,
    corso: state.corsoFormazioneAttivo.data,
    user: state.auth.user,
  }
}

export default connect(mapStateToProps, { fetchCorsoAttivo })(Corso)
