import React from 'react';

const CompletezzaCvItem = props => {
  return (
    <a href={`#${props.anchorName}`}>
      <div className={`completezza-cv-item ${!!props.isCompleto && 'completo'}`}>
        <div className="item-name">{props.name}</div>
      </div>
    </a>
  );
}

export default CompletezzaCvItem;
