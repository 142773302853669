import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fetchOperatori, deleteOperatore, resetOperatoreAttivo } from '../../../actions'
import { Link } from 'react-router-dom'

import { MdAdd } from 'react-icons/md'
import { FaSearch } from 'react-icons/fa'

import FiltroOperatoriSportelli from '../filtro_operatori_sportelli/filtro_operatori_sportelli'
import swal from 'sweetalert'

class OperatoriList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      operatoreToRemoveId: null,
      filter: {
        organizzazione: '',
        areaGeograficaId: '',
        keywords: '',
      },
    }
  }

  componentDidMount() {
    this.props.fetchOperatori()
    this.props.resetOperatoreAttivo()
  }

  handleChange = (field, value) => {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          [field]: value,
        },
      },
      () => {
        this.props.fetchOperatori(this.state.filter)
      }
    )
  }

  handleRemove = (operatoreId) => (event) => {
    event.preventDefault()
    this.setState({
      operatoreToRemoveId: operatoreId,
    })
    this.handleDeleteOperatore(event)
  }

  handleConfirmRemove = (event) => {
    event.preventDefault()
    this.props.deleteOperatore(this.state.operatoreToRemoveId)
    this.setState({
      operatoreToRemoveId: null,
    })
  }

  renderOperatori() {
    const { operatori } = this.props

    return operatori.map((operatore) => (
      <tr key={operatore.operatoreId}>
        <td>
          <Link to={'/app/operatori/' + operatore.operatoreId}>
            {operatore.nome} {operatore.cognome}
          </Link>
        </td>
        <td>{operatore.sportelli}</td>
        <td>
          <a href={`mailto:${operatore.email}`}>{operatore.email}</a>
        </td>
        <td>
          <button className="btn btn-sm btn-danger" onClick={this.handleRemove(operatore.operatoreId)}>
            &times; Elimina
          </button>
        </td>
      </tr>
    ))
  }

  handleDeleteOperatore = (event) => {
    swal({
      icon: 'warning',
      title: 'Eliminazione operatore',
      text: 'Sei sicuro di vole eliminare questo Operatore? Questa azione è irreversibile.',
      buttons: ['Annulla', 'Si, elimina operatore'],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.handleConfirmRemove(event)
      }
    })
  }

  render() {
    return (
      <div className="operatori-list">
        <h1 className="page-title">Operatori</h1>
        <div className="card-box">
          <FiltroOperatoriSportelli values={this.state.filter} onChange={this.handleChange} />

          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Sportelli</th>
                  <th>Email</th>
                  <th>Elimina</th>
                </tr>
              </thead>
              <tbody>
                {this.props.operatori ? (
                  this.renderOperatori()
                ) : (
                  <tr>
                    <td>Nessun operatore presente</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <Link to="/app/operatori/new" className="btn btn-success">
            <div className="create-center">
              <MdAdd />
              Crea nuovo operatore
            </div>
          </Link>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    operatori: state.operatori,
  }
}

export default connect(mapStateToProps, { fetchOperatori, deleteOperatore, resetOperatoreAttivo })(OperatoriList)
