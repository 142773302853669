import { FETCH_COMUNI } from '../actions/types';

const INITIAL_STATE = [];

const getComuniOptions = (comuni) => {
	return comuni.map((comune) => {
		return {
			value: comune.comune,
			label: comune.comuneEsteso
		}
	});
}

const getComuniEstesi = (comuni) => {
	return comuni.map((comune) => comune.comuneEsteso);
} 

export default function(state = INITIAL_STATE, action) {
	switch(action.type) {
		case FETCH_COMUNI:
			return getComuniEstesi(action.payload.data);// getComuniOptions(action.payload.data);
		default:
			return state;
	}
}

