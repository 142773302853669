import React, { Component, PropTypes } from 'react'
import { connect } from 'react-redux'
import { Form, Field } from 'react-final-form'

import BottoneAnnulla from './bottone_annulla'
import { fetchCandidato, saveInteressiCorsi } from '../../actions'

class EditInteressiCorsi extends Component {
  // static contextTypes = {
  // 	router: PropTypes.object
  // };

  componentDidMount() {
    // Ho già il candidato attivo se navigo normalmente
    // dal dettaglio candidato: questo controllo serve
    // nel caso in cui ricarico la pagina da qui (F5)

    if (!this.props.candidatoId) {
      this.props.fetchCandidato(this.props.match.params.id)
    }
  }

  onSubmit(props) {
    this.props.saveInteressiCorsi(this.props.match.params.id, props).then((action) => {
      this.props.history.goBack()
    })
  }

  render() {
    const initialValues = this.props.initialValues ? this.props.initialValues : {}

    return (
      <Form
        onSubmit={this.onSubmit.bind(this)}
        initialValues={initialValues}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="heading">
              <h1 className="page-title pull-left">Interessi a Corsi formativi</h1>
              <BottoneAnnulla />
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="panel panel-border panel-purple">
                  <div className="panel-heading">
                    <h3 className="panel-title">Corsi</h3>
                  </div>
                  <div className="panel-body">
                    <div className="form-group">
                      <Field
                        style={{ height: 103 + 'px' }}
                        className="form-control"
                        id="corsi"
                        name="corsi"
                        component="textarea"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="panel panel-border panel-purple">
                  <div className="panel-heading">
                    <h3 className="panel-title">Fasce orarie</h3>
                  </div>
                  <div className="panel-body">
                    <div className="form-group">
                      <div className="checkbox checkbox-purple">
                        <Field
                          id="fasciaOrariaDiurna"
                          name="fasciaOrariaDiurna"
                          type="checkbox"
                          component="input"
                        />

                        <label htmlFor="fasciaOrariaDiurna">Fascia diurna (9:00-13:00)</label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="checkbox checkbox-purple">
                        <Field
                          id="fasciaOrariaPomeridiana"
                          name="fasciaOrariaPomeridiana"
                          type="checkbox"
                          component="input"
                        />
                        <label htmlFor="fasciaOrariaPomeridiana">
                          Fascia pomeridiana (14:00-18:00)
                        </label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="checkbox checkbox-purple">
                        <Field
                          id="fasciaOrariaSerale"
                          name="fasciaOrariaSerale"
                          type="checkbox"
                          component="input"
                        />
                        <label htmlFor="fasciaOrariaSerale">Fascia serale (18:00-22:00)</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-center">
              <button type="submit" className="btn btn-purple btn-lg">
                Salva interessi a corsi &raquo;
              </button>
            </div>
          </form>
        )}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    initialValues: state.candidatoAttivo.interessiCorsi,
    candidatoId: state.candidatoAttivo.candidatoId, // per sapere se ho già il candidato attivo su F5
  }
}

export default connect(mapStateToProps, { fetchCandidato, saveInteressiCorsi })(EditInteressiCorsi)
