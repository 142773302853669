import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import Pager from '../pager/pager'
import { fetchNotifiche, changePageNotifiche, setNotificaLetta } from '../../actions'

import { MdMessage, MdPersonAdd, MdFileUpload, MdAssignment, MdThumbUp, MdEdit, MdWarning } from 'react-icons/md'

class Notifiche extends Component {
  componentDidMount() {
    this.props.fetchNotifiche(1)
  }

  componentDidUpdate(prevProps) {
    const pageChanged = prevProps.pageRequested !== this.props.pageRequested

    if (pageChanged) {
      this.props.fetchNotifiche(this.props.pageRequested)
    }
  }

  clickNotifica = (notifica) => (event) => {
    event.preventDefault()
    this.props.setNotificaLetta(notifica.notificaId)

    if (notifica.aziendaId) {
      this.props.history.push(`/app/aziende/${notifica.aziendaId}`)
    } else if (notifica.agenziaId) {
      this.props.history.push(`/app/agenzie/${notifica.agenziaId}`)
    } else if (notifica.candidatoId) {
      this.props.history.push(`/app/candidati/${notifica.candidatoId}`)
    } else if (notifica.jobVacancyId) {
      this.props.history.push(`/app/job-vacancies/${notifica.jobVacancyId}`)
    }
  }

  renderIconaNotifiche(tipo) {
    switch (tipo.toLowerCase()) {
      case 'registrazione_azienda':
      case 'registrazione_agenzia':
      case 'registrazione_candidato':
        return <MdPersonAdd className="icona-registrazione" />
      case 'upload_dichiarazione_azienda':
      case 'upload_dichiarazione_agenzia':
        return <MdFileUpload className="icona-upload" />
      case 'pubblicazione_jv':
        return <MdAssignment className="icona-pubblicazione" />
      case 'candidatura_jv':
        return <MdThumbUp className="icona-candidatura" />
      case 'modifica_candidato':
        return <MdEdit className="icona-modifica" />
      case 'cambio_sportello':
        return <MdWarning className="icona-cambio-sportello" />
      default:
        return <MdMessage />
    }
  }

  renderTipoNotifiche(tipo) {
    switch (tipo.toLowerCase()) {
      case 'registrazione_azienda':
        return <span>Registrazione azienda</span>
      case 'registrazione_agenzia':
        return <span>Registrazione agenzia</span>
      case 'registrazione_candidato':
        return <span>Registrazione candidato</span>
      case 'upload_dichiarazione_azienda':
        return <span>Upload dichiarazione azienda</span>
      case 'upload_dichiarazione_agenzia':
        return <span>Upload dichiarazione agenzia</span>
      case 'pubblicazione_jv':
        return <span>Pubblicazione JV</span>
      case 'candidatura_jv':
        return <span>Candidatura JV</span>
      case 'modifica_candidato':
        return <span>Modifica curriculum</span>
      case 'cambio_sportello':
        return <span>Cambio sportello</span>
      default:
        return <span>Messaggio</span>
    }
  }

  renderNotifiche(notifiche) {
    return notifiche.map((notifica) => {
      return (
        <div
          key={notifica.notificaId}
          id={notifica.notificaId}
          className={`media ${!notifica.flagLetto ? 'new' : ''}`}
          onClick={this.clickNotifica(notifica)}
        >
          <div className="pull-left">{this.renderIconaNotifiche(notifica.tipo)}</div>
          <div className="media-body">
            <p className="media-info">{moment(notifica.dataCreazione).format('DD MMMM YYYY [alle] HH:mm')}</p>
            <h5 className="media-heading">{notifica.testo}</h5>
          </div>
        </div>
      )
    })
  }

  render() {
    const { notifiche, pagination, changePageNotifiche } = this.props

    return (
      <div className="notifiche">
        <h1 className="page-title">Notifiche</h1>

        <Pager
          position="top"
          page={pagination.page}
          totalPages={pagination.totalPages}
          onChangePage={changePageNotifiche}
        />

        {this.renderNotifiche(notifiche)}

        <Pager
          position="bottom"
          page={pagination.page}
          totalPages={pagination.totalPages}
          onChangePage={changePageNotifiche}
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    notifiche: state.notifiche.notifiche,
    pagination: state.notifiche.pagination,
    pageRequested: state.notifiche.pageRequested,
  }
}

export default connect(mapStateToProps, { fetchNotifiche, changePageNotifiche, setNotificaLetta })(Notifiche)
