import React from 'react'
import { Route, Switch } from 'react-router'

import SportelliList from '../../amministratore/sportelli/sportelli_list'
import CreateEditSportello from '../../amministratore/sportelli/create_edit_sportello'

const Sportelli = (props) => {
  return (
    <div>
      <Switch>
        <Route exact path="/app/sportelli" component={SportelliList} />
        <Route path="/app/sportelli/new" component={CreateEditSportello} />
        <Route path="/app/sportelli/:sportelloId" component={CreateEditSportello} />
      </Switch>
    </div>
  )
}

export default Sportelli
