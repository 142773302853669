import React, { Component } from 'react';
import { connect } from 'react-redux';
import AsyncSelect from 'react-select/async'

import { fetchTypeaheadComuni, changeFilterCandidati } from '../../actions';

class FiltroComune extends Component {

  ///////////////////////////////////////////////////////////////
  // GESTIONE COMUNI AUTOCOMPLETE
  getComuniOptions = (comuni) => {
    return comuni.map((comune) => {
      return {
        value: comune.comune,
        label: comune.comuneEsteso
      }
    });
  }

  getComuni = (input) => {
    return this.props.fetchTypeaheadComuni(input).then((action) => {
      return this.getComuniOptions(action.payload.data)
    });
  }

  changeComune = option => {
    this.props.changeFilterCandidati(this.props.fieldName, { codice: option ? option.value : null, nome: option ? option.label : null });
  }

	render() {

    const { value: comune } = this.props

		return (
      <AsyncSelect className="filtro-comune" name="comuneDisponibilita"
        loadOptions={this.getComuni}
        placeholder="cerca comune..."
        loadingPlaceholder="Caricamento..."
        searchingText="Caricamento..."
        noResultsText="Nessun risultato"
        value={comune.codice ? {value: comune.codice, label: comune.nome} : null}
        onChange={this.changeComune} />
		);
	}
}

export default connect(null, { fetchTypeaheadComuni, changeFilterCandidati })(FiltroComune);
