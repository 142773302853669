import moment from 'moment'

import { SET_FILTER_CREDITI_SMS_CONSUMATI, FETCH_CREDITI_SMS_CONSUMATI } from '../actions/types'

const INITIAL_STATE = {
  data: [],
  filter: {
    daData: moment().startOf('month').format('YYYY-MM-DD'),
    aData: moment().endOf('month').format('YYYY-MM-DD'),
    areaGeograficaId: '',
    sportelloId: ''
  }
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_FILTER_CREDITI_SMS_CONSUMATI:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.payload.field]: action.payload.value
        }
      };
    case FETCH_CREDITI_SMS_CONSUMATI:
      return {
        ...state,
        data: action.payload.data
      }
    default:
      return state;
  }
}