import React, { Component } from 'react';
import YouTube from 'react-youtube';

class VideoCv extends Component {

	changeUrl = event => {
		this.props.onChangeUrl(event.target.value);
	}

	saveVideoCv = event => {
		event.preventDefault();
		this.props.onSaveVideoCv();
	}

	render() {

		const { url, canChangeServizi } = this.props;

		return (
			<div className="card-box video-cv">
				<h4 className="header-title">Video CV</h4>
				<div className="row">
					{canChangeServizi ?
						<div className="col-md-6">
							<div className="form-group">
								<label>Inserisci il codice del video YouTube (ad es. Pqzr4AZB7tY)</label>
								<input type="text" className="form-control" value={url} onChange={this.changeUrl} />
							</div>
							<button onClick={this.saveVideoCv} className="btn btn-primary">Salva Video CV</button>
						</div>
					:
						null
					}
					<div className="col-md-6">
						{!!url ?
							<div className="embed-responsive embed-responsive-16by9">
								<YouTube videoId={url} className="embed-responsive-item">
								</YouTube>
							</div>
						:
							<div className="no-video-cv-box">Non inserito</div>
						}
					</div>
				</div>

			</div>
		);

	}

}



export default VideoCv;