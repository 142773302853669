import React from 'react'
import { Field, Form } from 'react-final-form'
import { connect } from 'react-redux'
import { changePasswordFromReset, verificaToken } from '../../actions'
import config from '../../config'

const ResetPassword = ({ match, history, verificaToken, changePasswordFromReset, username, message }) => {
  const handleSaveNewPassword = (values) => {
    changePasswordFromReset(match.params.token, values.password, history)
  }
  React.useEffect(() => {
    verificaToken(match.params.token)
  }, [match.params.token, verificaToken])

  return (
    <div className="public">
      <div className="login">
        <div className="logo">
          {/*<MdExplore /> Sportello lavoro*/}
          <img src={`/images/${config.logo}`} alt="logo" />
        </div>
        {message === 'OK' ? (
          <Form
            onSubmit={handleSaveNewPassword}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div className="col-xs-12">
                  <h3>Password reset</h3>
                  <p>{`Inserisci la nuova password del profilo ${username}`}</p>
                  <Field
                    component="input"
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    name="password"
                  />

                  <button
                    style={{ marginTop: '15px', width: '100%' }}
                    type="submit"
                    className="btn  btn-custom btn-login btn-login-border-custom "
                  >
                    Salva nuova password
                  </button>
                </div>
              </form>
            )}
          />
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h3>Password reset</h3>
            <p>Qualcosa è andato storto, il link non è corretto</p>
          </div>
        )}
      </div>
    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    message: state.auth.resetMessage,
    username: state.auth.login,
  }
}
export default connect(mapStateToProps, { changePasswordFromReset, verificaToken })(ResetPassword)
