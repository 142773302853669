import React, { Component } from 'react'
import { connect } from 'react-redux'

import { addGruppoCandidato } from '../../actions'

import { MdAdd } from 'react-icons/md'

import './anagrafica_gruppi.less'

class AnagraficaGruppi extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  renderGruppiOptions() {
    if (this.props.gruppi && this.props.gruppi.length > 0) {
      return this.props.gruppi.map((gruppo) => (
        <option key={gruppo.gruppoCvSinteticoId} value={gruppo.gruppoCvSinteticoId}>
          {gruppo.nome}
        </option>
      ))
    }
  }

  renderGruppi() {
    if (this.props.gruppiCandidato && this.props.gruppiCandidato.length > 0) {
      return this.props.gruppiCandidato.map((gruppo) => (
        <span className="label label-primary" key={gruppo.gruppoCvSinteticoId}>
          {gruppo.nome}
        </span>
      ))
    }
  }

  render() {
    const { candidatoId } = this.props

    return (
      <div className="gruppi">
        <div className="title">Gruppi a cui appartiene il candidato:</div>
        <div className="gruppi-list">{this.renderGruppi()}</div>

        <select
          className="form-control"
          value={this.state.gruppoId}
          onChange={(e) => this.setState({ gruppoId: e.target.value })}
        >
          <option value="">-- Seleziona gruppo --</option>
          {this.renderGruppiOptions()}
        </select>

        <button
          className="btn btn-sm btn-success"
          onClick={() => this.props.addGruppoCandidato(this.props.candidatoId, this.state.gruppoId)}
        >
          <MdAdd />
          Aggiungi a gruppo
        </button>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    candidatoId: state.candidatoAttivo.candidatoId,
    gruppiCandidato: state.candidatoAttivo.gruppi,
    gruppi: state.gruppi,
  }
}

export default connect(mapStateToProps, { addGruppoCandidato })(AnagraficaGruppi)
