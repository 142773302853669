import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Tabs, Tab } from 'react-bootstrap'
import Ink from 'react-ink'
import { FaExclamationCircle } from 'react-icons/fa'
import { MdModeEdit } from 'react-icons/md'

import AnagraficaAltreInfo from './anagrafica_altre_info'
import AnagraficaStampe from './anagrafica_stampe'
import AnagraficaPrivacy from './anagrafica_privacy'
import AnagraficaGruppi from './anagrafica_gruppi'
import AnagraficaCredenziali from './anagrafica_credenziali'
import AnagraficaDiario from './anagrafica_diario'
import NoteAgenzie from './note_agenzie.js'
import NoteAgenzia from './note_agenzia.js'

import { fetchGruppi, fetchGruppiCandidato } from '../../actions'

import './anagrafica.less'

class Anagrafica extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  componentDidMount() {
    if (this.props.canAddGruppi && this.props.candidatoId) {
      this.props.fetchGruppiCandidato(this.props.candidatoId)
      this.props.fetchGruppi()
    }
  }

  renderGruppiTitle() {
    const { gruppiCandidato } = this.props
    return (
      <div>
        Gruppi
        <span className={`badge ${gruppiCandidato && gruppiCandidato.length > 0 ? 'badge-primary' : 'badge-default'}`}>
          {gruppiCandidato && gruppiCandidato.length}
        </span>
      </div>
    )
  }

  renderNoteAgenzieTitle() {
    const { acquisizioniAgenzie } = this.props
    return (
      <div>
        Note agenzie
        {!!acquisizioniAgenzie && (
          <span className={`badge ${acquisizioniAgenzie.length > 0 ? 'badge-primary' : 'badge-default'}`}>
            {acquisizioniAgenzie.filter((a) => a.note.trim().length > 3).length}
          </span>
        )}
      </div>
    )
  }

  renderPrivacyTitle() {
    const { consensoPrivacyFlag, consensoPrivacyMarketingFlag } = this.props
    const hasNewPrivacy =
      (consensoPrivacyFlag === true || consensoPrivacyFlag === false) &&
      (consensoPrivacyMarketingFlag === true || consensoPrivacyMarketingFlag === false)

    return (
      <div>
        Privacy&nbsp;
        {!hasNewPrivacy && <FaExclamationCircle style={{ fontSize: 18, color: '#c00' }} />}
      </div>
    )
  }

  render() {
    const { anagrafica, candidatoId, acquisizioniAgenzie, canChangeServizi, canEdit, ruolo } = this.props

    return (
      <div className="candidato-anagrafica">
        <div className="row">
          <div className="col-md-4">
            <div className="card-box">
              <div className="photo-box">
                <img alt="" src={anagrafica.fotografia || '/images/senza-foto.jpg'} className="img-responsive" />
                <div className="photo-border"></div>
              </div>

              <h4 className="header-title">
                {anagrafica.nome} {anagrafica.cognome}
              </h4>
              <h5 className="age">{!!anagrafica.eta ? anagrafica.eta + ' anni' : '-'}</h5>

              <hr />

              <address>
                {anagrafica.domicilioIndirizzo || anagrafica.domicilioCap || anagrafica.domicilioComuneEsteso ? (
                  <div>
                    <div>{anagrafica.domicilioIndirizzo}</div>
                    <div>
                      {anagrafica.domicilioCap} {anagrafica.domicilioComuneEsteso}
                    </div>
                  </div>
                ) : (
                  '-'
                )}
              </address>

              <hr />

              <div className="row">
                <div className="col-sm-6 right-border">
                  <div className="text-center">
                    <div className="title">Telefono</div>
                    <div>{!!anagrafica.telefono ? anagrafica.telefono : '-'}</div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="text-center">
                    <div className="title">Cellulare</div>
                    <div>{!!anagrafica.cellulare ? anagrafica.cellulare : '-'}</div>
                  </div>
                </div>
              </div>

              <hr />

              <div className="text-center">
                <div className="title">Email</div>
                <div>{!!anagrafica.email ? <a href={`mailto:${anagrafica.email}`}>{anagrafica.email}</a> : '-'}</div>
              </div>

              <hr />

              {this.props.canEdit ? (
                <Link to={`/app/candidati/${candidatoId}/anagrafica`} className="btn btn-primary btn-block btn-lg">
                  <Ink />
                  <MdModeEdit />
                  Modifica anagrafica
                </Link>
              ) : null}
            </div>
          </div>

          <div className="col-md-8">
            <div className="card-box">
              <Tabs defaultActiveKey={1} id="dettagli-anagrafica" className="navtab-custom">
                <Tab eventKey={1} title="Informazioni">
                  <AnagraficaAltreInfo canChangeServizi={canChangeServizi} ruolo={ruolo} />
                </Tab>

                {this.props.ruolo === 'operatore' && this.props.canEdit && (
                  <Tab eventKey={2} title={this.renderPrivacyTitle()}>
                    <AnagraficaPrivacy />
                  </Tab>
                )}

                <Tab eventKey={3} title="Stampe">
                  <AnagraficaStampe canChangeServizi={canChangeServizi} />
                </Tab>

                {this.props.canAddGruppi && (
                  <Tab eventKey={4} title={this.renderGruppiTitle()}>
                    <AnagraficaGruppi />
                  </Tab>
                )}

                {this.props.ruolo === 'operatore' && (
                  <Tab eventKey={5} title="Credenziali">
                    <AnagraficaCredenziali />
                  </Tab>
                )}

                {this.props.ruolo === 'operatore' && (
                  <Tab eventKey={6} title="Diario">
                    <AnagraficaDiario />
                  </Tab>
                )}

                {this.props.ruolo === 'operatore' && (
                  <Tab eventKey={7} title={this.renderNoteAgenzieTitle(acquisizioniAgenzie)}>
                    <NoteAgenzie note={acquisizioniAgenzie} canEdit={canEdit} />
                  </Tab>
                )}

                {this.props.ruolo === 'agenzia' && (
                  <Tab eventKey={8} title="Note agenzia">
                    <NoteAgenzia />
                  </Tab>
                )}
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    candidatoId: state.candidatoAttivo.candidatoId,
    anagrafica: state.candidatoAttivo.anagrafica,
    acquisizioniAgenzie: state.candidatoAttivo.acquisizioniAgenzie,
    gruppiCandidato: state.candidatoAttivo.gruppi,
    consensoPrivacyFlag: state.candidatoAttivo.consensoPrivacyFlag,
    consensoPrivacyMarketingFlag: state.candidatoAttivo.consensoPrivacyMarketingFlag,
    canEdit:
      !!state.auth.user.permessi['anagrafica'] &&
      state.candidatoAttivo.sportello &&
      state.candidatoAttivo.sportello.sportelloId === state.auth.user.sportelloCorrente.sportelloId,
    canAddGruppi: state.auth.user.ruolo === 'operatore',
  }
}

export default connect(mapStateToProps, { fetchGruppi, fetchGruppiCandidato })(Anagrafica)
