import React from 'react'
import { Link } from 'react-router-dom'

const CorsiCard = ({ corsi, ruolo, handleSubscription, handleUnscribe }) => {
  return (
    <div className="row">
      {corsi.map((corso) => (
        <div className="col-sm-6 col-lg-3" key={corso.corsoFormazionePropostoId}>
          <div className="card-box text-center">
            <p className="titoloCorso">
              <b>{corso.titolo}</b>
            </p>
            <p className="descrizione color">{corso.descrizione}</p>
            {ruolo === 'candidato' && !corso.iscritto && (
              <button
                className="btn btn-primary btn-block"
                onClick={handleSubscription(corso.corsoFormazionePropostoId)}
              >
                Iscriviti
              </button>
            )}
            {ruolo === 'candidato' && !!corso.iscritto && (
              <button className="btn btn-danger btn-block" onClick={handleUnscribe(corso.corsoFormazionePropostoId)}>
                Annulla iscrizione
              </button>
            )}
            <Link
              to={`/app/corsi-di-formazione/${corso.corsoFormazionePropostoId}`}
              className="btn btn-inverse btn-block"
            >
              Dettagli
            </Link>
          </div>
        </div>
      ))}
    </div>
  )
}

export default CorsiCard
