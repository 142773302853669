import React from 'react'
import { Route, Switch } from 'react-router'
import CandidatoShow from '../candidato_show'
import EditAnagrafica from '../candidato_edit/edit_anagrafica'
import EditStatoOccupazionale from '../candidato_edit/edit_stato_occupazionale'
import AddEditTitoloStudio from '../candidato_edit/add_edit_titolo_studio'
import AddEditEsperienzaLavorativa from '../candidato_edit/add_edit_esperienza_lavorativa'
import AddEditConoscenzaInformatica from '../candidato_edit/add_edit_conoscenza_informatica'
import AddEditConoscenzaLinguistica from '../candidato_edit/add_edit_conoscenza_linguistica'
import AddEditCorsoFormazione from '../candidato_edit/add_edit_corso_formazione'
import EditConoscenzeInformatiche from '../candidato_edit/edit_conoscenze_informatiche'
import EditInteressiCorsi from '../candidato_edit/edit_interessi_corsi'
import EditAltreCompetenze from '../candidato_edit/edit_altre_competenze'
import EditObiettiviProfessionali from '../candidato_edit/edit_obiettivi_professionali'
import EditAltreInformazioni from '../candidato_edit/edit_altre_informazioni'
import EditCvSintetico from '../candidato_edit/edit_cv_sintetico'
import EditNoteInterne from '../candidato_edit/edit_note_interne'
import RequireAuth from '../auth/require_auth'

const Candidato = (props) => {
  return (
    <div>
      <Switch>
        <Route exact path="/app/candidati/:id" component={CandidatoShow} />
        <Route
          exact
          path="/app/candidati/:id/anagrafica"
          component={RequireAuth(['operatore', 'candidato'], 'anagrafica')(EditAnagrafica)}
        />
        <Route
          exact
          path="/app/candidati/:id/stato-occupazionale"
          component={RequireAuth(
            ['operatore', 'candidato'],
            'statoOccupazionale'
          )(EditStatoOccupazionale)}
        />
        <Route
          exact
          path="/app/candidati/:id/esperienze-lavorative/new"
          component={RequireAuth(
            ['operatore', 'candidato'],
            'esperienzeLavorative'
          )(AddEditEsperienzaLavorativa)}
        />
        <Route
          exact
          path="/app/candidati/:id/esperienze-lavorative/:esperienzaLavorativaId"
          component={RequireAuth(
            ['operatore', 'candidato'],
            'esperienzeLavorative'
          )(AddEditEsperienzaLavorativa)}
        />
        <Route
          exact
          path="/app/candidati/:id/titoli-studio/new"
          component={RequireAuth(['operatore', 'candidato'], 'titoliStudio')(AddEditTitoloStudio)}
        />
        <Route
          exact
          path="/app/candidati/:id/titoli-studio/:titoloStudioId"
          component={RequireAuth(['operatore', 'candidato'], 'titoliStudio')(AddEditTitoloStudio)}
        />
        <Route
          exact
          path="/app/candidati/:id/corsi-formazione/new"
          component={RequireAuth(
            ['operatore', 'candidato'],
            'corsiFormazione'
          )(AddEditCorsoFormazione)}
        />
        <Route
          exact
          path="/app/candidati/:id/corsi-formazione/:corsoFormazioneId"
          component={RequireAuth(
            ['operatore', 'candidato'],
            'corsiFormazione'
          )(AddEditCorsoFormazione)}
        />
        <Route
          exact
          path="/app/candidati/:id/conoscenze-linguistiche/new"
          component={RequireAuth(
            ['operatore', 'candidato'],
            'conoscenzeLinguistiche'
          )(AddEditConoscenzaLinguistica)}
        />
        <Route
          exact
          path="/app/candidati/:id/conoscenze-linguistiche/:conoscenzaLinguisticaId"
          component={RequireAuth(
            ['operatore', 'candidato'],
            'conoscenzeLinguistiche'
          )(AddEditConoscenzaLinguistica)}
        />
        <Route
          exact
          path="/app/candidati/:id/conoscenze-informatiche/new"
          component={RequireAuth(
            ['operatore', 'candidato'],
            'conoscenzeInformatiche'
          )(AddEditConoscenzaInformatica)}
        />
        <Route
          exact
          path="/app/candidati/:id/conoscenze-informatiche/:conoscenzaInformaticaId"
          component={RequireAuth(
            ['operatore', 'candidato'],
            'conoscenzeInformatiche'
          )(AddEditConoscenzaInformatica)}
        />
        <Route
          exact
          path="/app/candidati/:id/conoscenze-informatiche"
          component={RequireAuth(
            ['operatore', 'candidato'],
            'conoscenzeInformatiche'
          )(EditConoscenzeInformatiche)}
        />
        <Route
          exact
          path="/app/candidati/:id/interessi-corsi"
          component={RequireAuth(['operatore', 'candidato'], 'interessiCorsi')(EditInteressiCorsi)}
        />
        <Route
          exact
          path="/app/candidati/:id/altre-competenze"
          component={RequireAuth(
            ['operatore', 'candidato'],
            'altreCompetenze'
          )(EditAltreCompetenze)}
        />
        <Route
          exact
          path="/app/candidati/:id/obiettivi-professionali"
          component={RequireAuth(
            ['operatore', 'candidato'],
            'obiettiviProfessionali'
          )(EditObiettiviProfessionali)}
        />
        <Route
          exact
          path="/app/candidati/:id/altre-informazioni"
          component={RequireAuth(
            ['operatore', 'candidato'],
            'altreInformazioni'
          )(EditAltreInformazioni)}
        />
        {/* <Route path="uso-interno" component={RequireAuth(['operatore','candidato'], 'usoInterno')(EditUsoInterno)} /> */}
        <Route
          exact
          path="/app/candidati/:id/cv-sintetico"
          component={RequireAuth(['operatore', 'candidato'], 'usoInterno')(EditCvSintetico)}
        />
        <Route
          exact
          path="/app/candidati/:id/note-interne"
          component={RequireAuth(['operatore', 'candidato'], 'usoInterno')(EditNoteInterne)}
        />
      </Switch>
    </div>
  )
}

export default Candidato
