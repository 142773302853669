import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form, Field } from 'react-final-form'

import BottoneAnnulla from './bottone_annulla'
import { fetchCandidato, saveNoteInterne } from '../../actions'

class EditNoteInterne extends Component {
  componentDidMount() {
    // Ho già il candidato attivo se navigo normalmente
    // dal dettaglio candidato: questo controllo serve
    // nel caso in cui ricarico la pagina da qui (F5)
    if (!this.props.candidatoId) {
      this.props.fetchCandidato(this.props.match.params.id)
    }
  }

  onSubmit(props) {
    if (Object.keys(props).length === 0) console.log(props)
    this.props.saveNoteInterne(this.props.match.params.id, props).then((action) => {
      this.props.history.goBack()
    })
  }

  render() {
    const initialValues = this.props.initialValues ? this.props.initialValues : {}

    return (
      <Form
        onSubmit={this.onSubmit.bind(this)}
        initialValues={initialValues}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="heading">
              <h1 className="page-title pull-left">Note interne</h1>
              <BottoneAnnulla />
            </div>

            <div className="panel panel-border panel-success">
              <div className="panel-heading">
                <h3 className="panel-title">Note interne</h3>
              </div>
              <div className="panel-body">
                <div className="form-group">
                  <label htmlFor="noteInterne">Note interne</label>
                  <Field
                    id="noteInterne"
                    name="noteInterne"
                    className="form-control"
                    component="textarea"
                    style={{ height: '130px', fontStyle: 'italic', color: '#999' }}
                  />
                </div>
              </div>
            </div>

            <div className="text-center">
              <button type="submit" className="btn btn-success btn-lg">
                Salva note interne &raquo;
              </button>
            </div>
          </form>
        )}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    initialValues: { noteInterne: state.candidatoAttivo.noteInterne },
    candidatoId: state.candidatoAttivo.candidatoId, // per sapere se ho già il candidato attivo su F5
  }
}

export default connect(mapStateToProps, { fetchCandidato, saveNoteInterne })(EditNoteInterne)
