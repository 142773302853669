import React, { Component } from 'react'
import Ink from 'react-ink'
import { connect } from 'react-redux'
import { saveServizio, setNoteServizio, setStatoServizio } from '../../actions'
// Selector
import { isCvCompleto } from '../../reducers/reducer_candidato_attivo'
import './servizi_richiesti.less'

class ServiziRichiesti extends Component {
  setStato = (servizio) => (event) => {
    this.props.setStatoServizio(servizio, event.target.value)
  }

  setNote = (servizio) => (event) => {
    this.props.setNoteServizio(servizio, event.target.value)
  }

  saveServizio = (tipoServizio) => (event) => {
    event.preventDefault()
    this.props.saveServizio(
      this.props.candidatoId,
      tipoServizio,
      this.props.servizio[tipoServizio].stato,
      this.props.servizio[tipoServizio].note
    )
  }

  render() {
    const { servizio, canChangeServizi, cvCompleto } = this.props
    return (
      <div className="servizi-richiesti">
        <div className="card-box">
          <h4 className="header-title">Servizi richiesti</h4>
          <div className="panel panel-color panel-primary">
            <h4 className="panel-heading">Ricerca Lavoro</h4>
            <div className="flex panel-body">
              <div className="col-lg-6 flex-between center">
                <div className="radio radio-primary" style={{ marginTop: -5 }}>
                  <input
                    type="radio"
                    name="stato"
                    onChange={this.setStato('lavoro')}
                    id="non_richiesto"
                    value="non_richiesto"
                    checked={servizio.lavoro.stato === 'non_richiesto' || servizio.lavoro.stato === ''}
                  />
                  <label htmlFor="non_richiesto" className="text-capitalize">
                    Non Richiesto
                  </label>
                </div>
                <div className="radio radio-primary">
                  <input
                    type="radio"
                    name="stato"
                    onChange={this.setStato('lavoro')}
                    id="richiesto"
                    value="cv_non_completo"
                    checked={servizio.lavoro.stato === 'cv_non_completo'}
                  />
                  <label htmlFor="richiesto">Richiesto</label>
                </div>
                <div className="radio radio-primary">
                  <input
                    type="radio"
                    name="stato"
                    onChange={this.setStato('lavoro')}
                    id="completato"
                    value="cv_completo"
                    checked={servizio.lavoro.stato !== 'non_richiesto' && servizio.lavoro.stato !== 'cv_non_completo'}
                  />
                  <label htmlFor="completato">Completato</label>
                </div>
                <div />
              </div>

              <div className="col-lg-6 flex-between" style={{ alignItems: 'center' }}>
                <div className="form-group flex" style={{ alignItems: 'center', flexGrow: 1, marginRight: 24 }}>
                  <label style={{ marginRight: 12 }}>Note: </label>
                  <textarea type="text" className="form-control" />
                </div>
                {!!canChangeServizi && (
                  <div className="form-group">
                    <button
                      disabled={servizio.lavoro.stato === 'cv_completo' && !cvCompleto}
                      className="btn btn-primary"
                      onClick={this.saveServizio('lavoro')}
                    >
                      <Ink />
                      Salva
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="panel panel-color panel-primary">
            <h4 className="panel-heading">Presentazione DID</h4>
            <div className="flex panel-body">
              <div className="col-lg-6 flex-between center">
                <div className="radio radio-primary" style={{ marginTop: -5 }}>
                  <input
                    type="radio"
                    name="stato_did"
                    onChange={this.setStato('did')}
                    id="non_richiesto_did"
                    value="non_richiesto"
                    checked={servizio.did.stato === 'non_richiesto' || servizio.did.stato === ''}
                  />
                  <label htmlFor="non_richiesto_did" className="text-capitalize">
                    Non Richiesto
                  </label>
                </div>
                <div className="radio radio-primary">
                  <input
                    type="radio"
                    name="stato_did"
                    onChange={this.setStato('did')}
                    id="richiesto_did"
                    value="richiesto"
                    checked={servizio.did.stato === 'richiesto'}
                  />
                  <label htmlFor="richiesto_did">Richiesto</label>
                </div>
                <div className="radio radio-primary">
                  <input
                    type="radio"
                    name="stato_did"
                    onChange={this.setStato('did')}
                    id="completato_did"
                    value="completato"
                    checked={servizio.did.stato !== 'non_richiesto' && servizio.did.stato !== 'richiesto'}
                  />
                  <label htmlFor="completato_did">Completato</label>
                </div>
                <div />
              </div>

              <div className="col-lg-6 flex-between" style={{ alignItems: 'center' }}>
                <div className="form-group flex" style={{ alignItems: 'center', flexGrow: 1, marginRight: 24 }}>
                  <label style={{ marginRight: 12 }}>Note: </label>
                  <textarea type="text" className="form-control" />
                </div>
                {!!canChangeServizi && (
                  <div className="form-group">
                    <button className="btn btn-primary" onClick={this.saveServizio('did')}>
                      <Ink />
                      Salva
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="panel panel-color panel-primary">
            <h4 className="panel-heading">Dimissioni online</h4>
            <div className="flex panel-body">
              <div className="col-lg-6 flex-between">
                <div className="radio radio-primary" style={{ marginTop: -5 }}>
                  <input
                    type="radio"
                    name="stato_dimissioni"
                    onChange={this.setStato('dimissioni')}
                    id="non_richiesto_dimissioni"
                    value="non_richiesto"
                    checked={servizio.dimissioni.stato === 'non_richiesto' || servizio.dimissioni.stato === ''}
                  />
                  <label htmlFor="non_richiesto_dimissioni" className="text-capitalize">
                    Non Richiesto
                  </label>
                </div>
                <div className="radio radio-primary">
                  <input
                    type="radio"
                    name="stato_dimissioni"
                    onChange={this.setStato('dimissioni')}
                    id="richiesto_dimissioni"
                    value="richiesto"
                    checked={servizio.dimissioni.stato === 'richiesto'}
                  />
                  <label htmlFor="richiesto_dimissioni">Richiesto</label>
                </div>
                <div className="radio radio-primary">
                  <input
                    type="radio"
                    name="stato_dimissioni"
                    onChange={this.setStato('dimissioni')}
                    id="completato_dimissioni"
                    value="completato"
                    checked={servizio.dimissioni.stato !== 'non_richiesto' && servizio.dimissioni.stato !== 'richiesto'}
                  />
                  <label htmlFor="completato_dimissioni">Completato</label>
                </div>
                <div />
              </div>

              <div className="col-lg-6 flex-between" style={{ alignItems: 'center' }}>
                <div className="form-group flex" style={{ alignItems: 'center', flexGrow: 1, marginRight: 24 }}>
                  <label style={{ marginRight: 12 }}>Note: </label>
                  <textarea type="text" className="form-control" />
                </div>
                {!!canChangeServizi && (
                  <div className="form-group">
                    <button className="btn btn-primary" onClick={this.saveServizio('dimissioni')}>
                      <Ink />
                      Salva
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="panel panel-color panel-primary">
            <h4 className="panel-heading">Consulenza</h4>
            <div className="flex panel-body">
              <div className="col-lg-6 flex-between center">
                <div className="radio radio-primary" style={{ marginTop: -5 }}>
                  <input
                    type="radio"
                    name="stato_consulenza"
                    onChange={this.setStato('consulenza')}
                    id="non_richiesto_consulenza"
                    value="non_richiesto"
                    checked={servizio.consulenza.stato === 'non_richiesto' || servizio.consulenza.stato === ''}
                  />
                  <label htmlFor="non_richiesto_consulenza" className="text-capitalize">
                    Non Richiesto
                  </label>
                </div>
                <div className="radio radio-primary">
                  <input
                    type="radio"
                    name="stato_consulenza"
                    onChange={this.setStato('consulenza')}
                    id="richiesto_consulenza"
                    value="richiesto"
                    checked={servizio.consulenza.stato === 'richiesto'}
                  />
                  <label htmlFor="richiesto_consulenza">Richiesto</label>
                </div>
                <div className="radio radio-primary">
                  <input
                    type="radio"
                    name="stato_consulenza"
                    onChange={this.setStato('consulenza')}
                    id="completato_consulenza"
                    value="completato"
                    checked={servizio.consulenza.stato !== 'non_richiesto' && servizio.consulenza.stato !== 'richiesto'}
                  />
                  <label htmlFor="completato_consulenza">Completato</label>
                </div>
                <div />
              </div>

              <div className="col-lg-6 flex-between" style={{ alignItems: 'center' }}>
                <div className="form-group flex" style={{ alignItems: 'center', flexGrow: 1, marginRight: 24 }}>
                  <label style={{ marginRight: 12 }}>Note: </label>
                  <textarea type="text" className="form-control" />
                </div>
                {!!canChangeServizi && (
                  <div className="form-group">
                    <button className="btn btn-primary" onClick={this.saveServizio('consulenza')}>
                      <Ink />
                      Salva
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  servizio: state.candidatoAttivo.servizio,
  cvCompleto: isCvCompleto(state),
})

const actions = {
  saveServizio,
  setStatoServizio,
  setNoteServizio,
}

export default connect(mapStateToProps, actions)(ServiziRichiesti)
