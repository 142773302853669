import {
  FETCH_CREDITI_SMS
} from '../actions/types';

const initialState = null

export default function (state = initialState, action) {
  switch(action.type) {
    case FETCH_CREDITI_SMS:
      return action.payload.data.crediti || 0;
    default:
			return state;
  }
}
