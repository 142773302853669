import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Ink from 'react-ink'

import { MdModeEdit, MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md'

class InteressiCorsi extends Component {
  render() {
    const { candidatoId, interessiCorsi, canEdit } = this.props

    return (
      <div className="card-box interessi-corsi">
        <h4 className="header-title">Interesse a corsi</h4>

        {interessiCorsi ? (
          <div className="row">
            <div className="col-md-4">
              <p>Interessi:</p>
              <p>
                <b className="interessi">{interessiCorsi.corsi}</b>
              </p>
            </div>
            <div className="col-md-8">
              <p>Fasce orarie:</p>
              <ul>
                <li className={interessiCorsi.fasciaOrariaDiurna ? 'sel' : ''}>
                  {interessiCorsi.fasciaOrariaDiurna ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />}{' '}
                  Fascia diurna (8:00 - 13:00)
                </li>
                <li className={interessiCorsi.fasciaOrariaPomeridiana ? 'sel' : ''}>
                  {interessiCorsi.fasciaOrariaPomeridiana ? (
                    <MdCheckBox />
                  ) : (
                    <MdCheckBoxOutlineBlank />
                  )}{' '}
                  Fascia pomeridiana (14:00 - 18:00)
                </li>
                <li className={interessiCorsi.fasciaOrariaSerale ? 'sel' : ''}>
                  {interessiCorsi.fasciaOrariaSerale ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />}{' '}
                  Fascia serale (18:00 - 22:00)
                </li>
              </ul>
            </div>
          </div>
        ) : null}

        {canEdit ? (
          <div className="text-right">
            <Link
              to={`/app/candidati/${candidatoId}/interessi-corsi`}
              className="btn btn-sm btn-purple"
            >
              <Ink />
              <MdModeEdit />
              Modifica interesse corsi
            </Link>
          </div>
        ) : null}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    candidatoId: state.candidatoAttivo.candidatoId,
    interessiCorsi: state.candidatoAttivo.interessiCorsi,
    canEdit:
      !!state.auth.user.permessi['interessiCorsi'] &&
      state.candidatoAttivo.sportello &&
      state.candidatoAttivo.sportello.sportelloId === state.auth.user.sportelloCorrente.sportelloId,
  }
}

export default connect(mapStateToProps)(InteressiCorsi)
