import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import { connect } from 'react-redux'
import swal from 'sweetalert'
import { API_ROOT_URL, removeAllegato, uploadAllegati } from '../../actions'

class Allegati extends Component {
  constructor(props) {
    super(props)

    this.state = {
      allegatoId: 0,
    }
  }

  onRemove() {
    this.props.removeAllegato(this.state.allegatoId, this.props.candidatoId)
  }

  renderAllegati() {
    const { candidatoId, allegati, canEdit } = this.props
    if (allegati && allegati.length > 0) {
      return allegati.map((allegato) => {
        return (
          <li key={allegato.allegatoId}>
            <a
              rel="noreferrer"
              target="_blank"
              href={`${API_ROOT_URL}/candidati/${candidatoId}/allegati/${allegato.allegatoId}?t=${localStorage.getItem(
                'token'
              )}`}
            >
              {allegato.nomeFile} ({(allegato.dimensione / (1024 * 1024)).toFixed(2)} MB)
            </a>
            {canEdit ? (
              <a
                className="remove-link"
                href="/#"
                onClick={(event) => {
                  event.preventDefault()
                  this.setState({ allegatoId: allegato.allegatoId })
                  this.handleDeleteAllegato()
                }}
              >
                &times;
              </a>
            ) : null}
          </li>
        )
      })
    }
    return <div>Non sono presenti allegati</div>
  }

  onDropFile(files) {
    var data = new FormData()
    files.forEach((file) => {
      data.append('file', file)
    })
    this.props.uploadAllegati(data, this.props.candidatoId)
  }

  handleDeleteAllegato = () => {
    swal({
      icon: 'warning',
      title: 'Eliminazione allegato',
      text: 'Sei sicuro di vole eliminare questo allegato? Questa azione è irreversibile.',
      buttons: ['Annulla', 'Si, elimina allegato'],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onRemove()
      }
    })
  }

  render() {
    const { canEdit } = this.props

    return (
      <div className="card-box allegati">
        <h4 className="header-title">Allegati</h4>
        <div className="row">
          <div className="col-md-6">{this.renderAllegati()}</div>
          <div className="col-md-6">
            <div className="dropzone">
              {canEdit && (
                <Dropzone onDrop={this.onDropFile.bind(this)}>
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                      Trascina qui i file
                      <input {...getInputProps()} />
                      <br />
                      oppure clicca per selezionarli
                    </div>
                  )}
                </Dropzone>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    candidatoId: state.candidatoAttivo.candidatoId,
    allegati: state.candidatoAttivo.allegati,
    canEdit:
      !!state.auth.user.permessi['allegati'] &&
      state.candidatoAttivo.sportello &&
      state.candidatoAttivo.sportello.sportelloId === state.auth.user.sportelloCorrente.sportelloId,
  }
}

export default connect(mapStateToProps, { uploadAllegati, removeAllegato })(Allegati)
