import React, { useState } from 'react'
import { Field, Form } from 'react-final-form'
import { connect } from 'react-redux'
import { resetPassword } from '../../actions'
import config from '../../config'

const ForgotPassword = ({ resetPassword }) => {
  const [sentStatus, setSentStatus] = useState('')
  const handleResetPassword = (values) => {
    resetPassword(values.username, setSentStatus)
  }
  return (
    <div className="public">
      <div className="login">
        <div className="logo">
          {/*<MdExplore /> Sportello lavoro*/}
          <img src={`/images/${config.logo}`} alt="logo" />
        </div>
        <Form
          onSubmit={handleResetPassword}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className="col-xs-12">
                <h3>Password dimenticata?</h3>
                <p>Inserisci il tuo username e attendi una mail con il link di reset password</p>
                <Field component="input" type="text" className="form-control" placeholder="Username" name="username" />

                <button
                  style={{ marginTop: '15px', width: '100%' }}
                  type="submit"
                  disabled={!!sentStatus}
                  className="btn  btn-custom btn-login btn-login-border-custom "
                >
                  {sentStatus === 'success' ? 'Inviato' : sentStatus === 'error' ? 'Errore' : 'Invia link'}
                </button>
              </div>
            </form>
          )}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    resetMessage: state.auth.resetMessage,
  }
}

export default connect(mapStateToProps, { resetPassword })(ForgotPassword)
