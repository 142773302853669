import React, { Component } from 'react'

import './sportello_selezionato.less'

class SportelloSelezionato extends Component {
  handleRemove = event => {
    event.preventDefault()
    this.props.onRemoveSportello(this.props.sportello.sportelloId)
  }

  render() {
    const { sportello } = this.props

    return (
      <div className="sportello-selezionato">
        <a href="" onClick={this.handleRemove}>
          &times;
        </a>
        {sportello.organizzazione} {sportello.nome}
      </div>
    )
  }
}

export default SportelloSelezionato
