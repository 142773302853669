import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Ink from 'react-ink'
import swal from 'sweetalert'

import { removeEsperienzaLavorativa, duplicateEsperienzaLavorativa } from '../../actions'
import { MdModeEdit, MdRemoveCircle, MdContentCopy } from 'react-icons/md'

class EsperienzaLavorativa extends Component {
  remove() {
    this.props.removeEsperienzaLavorativa(
      this.props.esperienzaLavorativa.esperienzaLavorativaId,
      this.props.candidatoId
    )
  }

  duplica = (event) => {
    event.preventDefault()
    this.props.duplicateEsperienzaLavorativa(
      this.props.esperienzaLavorativa.esperienzaLavorativaId,
      this.props.candidatoId
    )
  }

  renderMotivazioneFine() {
    const { esperienzaLavorativa } = this.props
    let motivazione = ''

    if (esperienzaLavorativa.dataFine && esperienzaLavorativa.motivazioneTermine) {
      motivazione = esperienzaLavorativa.motivazioneTermine

      if (
        esperienzaLavorativa.motivazioneTermine === 'Altro' &&
        esperienzaLavorativa.motivazioneAltro
      ) {
        motivazione += ' - ' + esperienzaLavorativa.motivazioneAltro
      }
    }

    return motivazione ? `(${motivazione})` : '' // (motivazione)
  }

  handleDeleteEsperienzaLavorativa = () => {
    swal({
      icons: 'warning',
      title: 'Eliminazione esperienza lavorativa',
      text: 'Sei sicuro di vole eliminare questa esperienza lavorativa? Questa azione è irreversibile.',
      buttons: ['Annulla', 'Si, elimina esperienza lavorativa'],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.remove()
      }
    })
  }

  render() {
    const { esperienzaLavorativa, candidatoId, canEdit } = this.props

    return (
      <div className={'panel panel-color panel-primary panel-show-section'}>
        <div className="panel-heading">
          <div className="row">
            <div className="col-xs-6">
              <h3 className="panel-title">
                {esperienzaLavorativa.azienda}
                <br className="hidden-md hidden-lg" />
                <small>
                  {esperienzaLavorativa.settoreAttivita} - {esperienzaLavorativa.indirizzo}
                </small>
              </h3>
            </div>
            <div className="col-xs-6 actions">
              {canEdit ? (
                <h3 className="panel-title">
                  <a className="btn btn-sm btn-primary" onClick={this.duplica}>
                    <MdContentCopy />
                    Duplica
                  </a>
                  <Link
                    to={`/app/candidati/${candidatoId}/esperienze-lavorative/${esperienzaLavorativa.esperienzaLavorativaId}`}
                    className="btn btn-sm btn-primary"
                  >
                    <MdModeEdit />
                    <span>Modifica</span>
                  </Link>
                  <a
                    className="btn btn-sm btn-primary btn-remove"
                    onClick={() => this.handleDeleteEsperienzaLavorativa()}
                  >
                    <MdRemoveCircle />
                    <span>Elimina</span>
                  </a>
                </h3>
              ) : null}
            </div>
          </div>
        </div>
        <div className="panel-body">
          <div className="row">
            <div className="col-sm-6">
              <p>
                <b>
                  {esperienzaLavorativa.posizioneProfessionale} -{' '}
                  {esperienzaLavorativa.tipoQualificaLegale}&nbsp;
                  {esperienzaLavorativa.tipoContratto !== 'Altro'
                    ? esperienzaLavorativa.tipoContratto
                    : esperienzaLavorativa.altroTipo}
                </b>
              </p>
              <p>
                <b>
                  {esperienzaLavorativa.dataInizioMese}/{esperienzaLavorativa.dataInizioAnno} -{' '}
                  {esperienzaLavorativa.dataFineMese && esperienzaLavorativa.dataFineAnno
                    ? esperienzaLavorativa.dataFineMese + '/' + esperienzaLavorativa.dataFineAnno
                    : 'Oggi'}
                </b>{' '}
                {this.renderMotivazioneFine()}
              </p>
            </div>
            <div className="col-sm-6">
              <div className="dettagli">{esperienzaLavorativa.mansioni}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(null, { removeEsperienzaLavorativa, duplicateEsperienzaLavorativa })(
  EsperienzaLavorativa
)
