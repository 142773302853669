import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import Ink from 'react-ink'
import moment from 'moment'

import { fetchJobVacancy } from '../../actions'

class JobVacancy extends Component {
  componentDidMount() {
    this.props.fetchJobVacancy(this.props.match.params.jobVacancyId)
  }

  renderCandidatiInteressati() {
    const { jobVacancy } = this.props

    return jobVacancy.candidatiInteressati.map((candidato) => (
      <li key={candidato.candidatoId}>
        <Link to={'/app/candidati/' + candidato.candidatoId}>
          <img className="avatar" src={candidato.fotografia || '/images/senza-foto.jpg'} />
          {candidato.nomeCognome}
        </Link>
      </li>
    ))
  }

  render() {
    const { jobVacancy, ruolo, permessoJvComplete, agenziaId } = this.props

    return (
      <div className="dettaglio-job-vacancy">
        <h1 className="page-title">
          Dettaglio job vacancy{' '}
          {!(
            ruolo === 'candidato' ||
            (ruolo === 'agenzia' && agenziaId !== jobVacancy.agenziaId)
          ) && (
            <small>
              inserita da{' '}
              {jobVacancy.agenziaRagioneSociale ||
                jobVacancy.aziendaRagioneSociale ||
                jobVacancy.sportelloNome}
            </small>
          )}
        </h1>

        <div className="row">
          <div className="col-sm-8">
            <div className="panel panel-border panel-primary">
              <div className="panel-heading">
                <h3 className="panel-title">Figura richiesta</h3>
              </div>
              <div className="panel-body">
                <p className="heading mt0">Descrizione sintetica</p>
                <p>{jobVacancy.descrizioneSintetica || '-'}</p>

                <p className="heading">Figura professionale</p>
                <p>{jobVacancy.figuraProfessionale || '-'}</p>

                <p className="heading">Mansione</p>
                <p>{jobVacancy.mansione || '-'}</p>

                <p className="heading">Descrizione completa</p>
                <p>{jobVacancy.descrizioneCompleta || '-'}</p>

                {!(
                  ruolo === 'candidato' ||
                  (ruolo === 'agenzia' && agenziaId !== jobVacancy.agenziaId)
                ) && (
                  <div>
                    <p className="heading">Mostra su sito web</p>
                    <p>{jobVacancy.sitoWebFlag ? 'Sì' : 'No'}</p>

                    <p className="heading">Mostra in home</p>
                    <p>{jobVacancy.homeFlag ? 'Sì' : 'No'}</p>
                  </div>
                )}

                <p className="heading">Stato</p>
                <p>{jobVacancy.statoVacancy}</p>
              </div>
            </div>

            <div className="panel panel-border panel-warning">
              <div className="panel-heading">
                <h3 className="panel-title">Lavoro offerto</h3>
              </div>
              <div className="panel-body">
                {ruolo !== 'candidato' ? (
                  <div>
                    <p className="heading mt0">Luogo di lavoro</p>
                    <p>{jobVacancy.luogoLavoroComuneEsteso || '-'}</p>
                  </div>
                ) : (
                  <div>
                    <p className="heading mt0">Luogo di lavoro</p>
                    <p>Provinvia di {jobVacancy.luogoLavoroProvincia || '-'}</p>
                  </div>
                )}

                <p className="heading">Datore di lavoro</p>
                <p>{jobVacancy.datoreLavoro || '-'}</p>

                <p className="heading">Data inizio</p>
                <p>
                  {jobVacancy.dataInizio ? moment(jobVacancy.dataInizio).format('DD/MM/YYYY') : '-'}
                </p>

                <p className="heading">Orario lavoro</p>
                <p>{jobVacancy.orarioLavoro || '-'}</p>

                {!(
                  ruolo === 'candidato' ||
                  (ruolo === 'agenzia' && agenziaId !== jobVacancy.agenziaId)
                ) && (
                  <div>
                    <p className="heading">Tipologia di contratto</p>
                    <p>{jobVacancy.tipologiaContratto || '-'}</p>

                    <p className="heading">Range retributivo</p>
                    <p>{jobVacancy.rangeRetributivo || '-'}</p>
                  </div>
                )}
              </div>
            </div>

            <div className="panel panel-border panel-success">
              <div className="panel-heading">
                <h3 className="panel-title">Esperienza richiesta</h3>
              </div>
              <div className="panel-body">
                <p className="heading mt0">Livello di studi</p>
                <p>{jobVacancy.livelloStudi || '-'}</p>

                <p className="heading">Titolo di studio</p>
                <p>{jobVacancy.titoloStudio || '-'}</p>

                <p className="heading">Esperienza richiesta</p>
                <p>{jobVacancy.esperienzaRichiesta || '-'}</p>

                <p className="heading">Skill professionali richieste</p>
                <p>{jobVacancy.skill || '-'}</p>
              </div>
            </div>

            {!(
              ruolo === 'candidato' ||
              (ruolo === 'agenzia' && agenziaId !== jobVacancy.agenziaId)
            ) && (
              <div className="panel panel-border panel-pink">
                <div className="panel-heading">
                  <h3 className="panel-title">Altre richieste</h3>
                </div>
                <div className="panel-body">
                  <p className="heading mt0">Altri requisiti</p>
                  <p>{jobVacancy.altriRequisiti || '-'}</p>

                  <p className="heading">
                    Particolarità{' '}
                    <small>(appartenente a cat. protette, Naspi, età in apprendistato)</small>
                  </p>
                  <p>{jobVacancy.particolarita || '-'}</p>

                  <p className="heading">Data scadenza richiesta</p>
                  <p>
                    {jobVacancy.dataScadenza
                      ? moment(jobVacancy.dataScadenza).format('DD/MM/YYYY')
                      : '-'}
                  </p>
                </div>
              </div>
            )}
          </div>
          <div className="col-sm-4">
            {!(
              ruolo === 'candidato' ||
              (ruolo === 'agenzia' && agenziaId !== jobVacancy.agenziaId)
            ) && (
              <div>
                <h4>Candidati interessati: {jobVacancy.numeroInteressati}</h4>
                {!!jobVacancy.candidatiInteressati &&
                  jobVacancy.candidatiInteressati.length > 0 && (
                    <ul className="candidati">{this.renderCandidatiInteressati()}</ul>
                  )}
              </div>
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-sm-8">
            <Link to="/app/job-vacancies" className="btn btn-default btn-lg">
              <Ink />
              &laquo; Torna alle job vacancies
            </Link>
            {(ruolo === 'candidato' ||
              (ruolo === 'operatore' && !permessoJvComplete) ||
              (ruolo === 'agenzia' && agenziaId !== jobVacancy.agenziaId)) && (
              <Link
                to={`/contatto-app?jv=${jobVacancy.jobVacancyId}`}
                target="_blank"
                className="btn btn-inverse btn-lg pull-right"
              >
                Richiedi info &raquo;
              </Link>
            )}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    jobVacancy: state.jobVacancyAttiva.data,
    ruolo: state.auth.user.ruolo,
    permessoJvComplete: state.auth.user.permessi.visualizzazioneJvComplete,
    agenziaId: state.auth.user.agenziaId,
  }
}

JobVacancy = withRouter(JobVacancy)

export default connect(mapStateToProps, { fetchJobVacancy })(JobVacancy)
