import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fetchLookups } from '../../actions'

class Lookups extends Component {
  componentDidMount() {
    // Se non ho già i lookups, li prendo...
    if (!this.props.lookups.tipoDocumento) {
      this.props.fetchLookups()
    }
  }

  renderOptions() {
    return this.props.lookups[this.props.tipo].map((valore) => {
      return (
        <option key={valore} value={valore}>
          {valore}
        </option>
      )
    })
  }

  render() {
    const { lookups, tipo, fetchLookups, ...rest } = this.props
    if (!lookups[tipo]) {
      return <select></select>
    }

    return (
      <select className="form-control" {...rest}>
        <option value="">-- Seleziona --</option>
        {this.renderOptions()}
      </select>
    )
  }
}

function mapStateToProps(state) {
  return { lookups: state.lookups }
}

export default connect(mapStateToProps, { fetchLookups })(Lookups)
