import React, { Component } from 'react'
import { connect } from 'react-redux'

import { setCentroDisponibilitaGeografica, setRaggioDisponibilitaGeografica } from '../../actions'
import MappaDisponibilitaGeografica from './mappa_disponibilita_geografica'

class DisponibilitaGeografica extends Component {
  render() {
    const {
      lat = 0,
      lng = 0,
      raggio = 0,
      color,
      canEdit,
      setCentroDisponibilitaGeografica,
      setRaggioDisponibilitaGeografica,
    } = this.props
    return (
      <MappaDisponibilitaGeografica
        lat={lat}
        lng={lng}
        radius={raggio}
        color={color}
        canEdit={canEdit}
        onCenterChanged={setCentroDisponibilitaGeografica}
        onRadiusChanged={setRaggioDisponibilitaGeografica}
      />
    )
  }
}

function mapStateToProps(state) {
  const candidato = state.candidatoAttivo
  const { lat, lng, raggio } = (state.candidatoAttivo.obiettiviProfessionali &&
    state.candidatoAttivo.obiettiviProfessionali.disponibilitaGeografica) || {
    lat: 0,
    lng: 0,
    raggio: 0,
  }
  return {
    lat: lat || candidato.domicilioComuneLat || 45.6833,
    lng: lng || candidato.domicilioComuneLng || 9.7167,
    raggio: raggio || 20000,
    color: lat && lng && raggio ? '#3bafda' : '#a88',
    canEdit:
      !!state.auth.user.permessi['obiettiviProfessionali'] &&
      state.candidatoAttivo.sportello &&
      state.candidatoAttivo.sportello.sportelloId === state.auth.user.sportelloCorrente.sportelloId,
  }
}

export default connect(mapStateToProps, {
  setCentroDisponibilitaGeografica,
  setRaggioDisponibilitaGeografica,
})(DisponibilitaGeografica)
