import React, { Component } from 'react'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import _find from 'lodash/find'
import { Form, Field } from 'react-final-form'
import { connect } from 'react-redux'

import BottoneAnnulla from './bottone_annulla'
import { fetchCandidato, addCorsoFormazione, updateCorsoFormazione } from '../../actions'

import Ink from 'react-ink'

class AddEditCorsoFormazione extends Component {
  // static contextTypes = {
  // 	router: PropTypes.object
  // };

  ///////////////////////////////////////////////////////////////
  // SE EDIT E RELOAD PAGINA -> FETCH CANDIDATO
  componentDidMount() {
    // Ho già il candidato attivo se navigo normalmente
    // dal dettaglio candidato: questo controllo serve
    // nel caso in cui ricarico la pagina da qui (F5)
    if (this.props.match.params.id && !this.props.candidatoId) {
      this.props.fetchCandidato(this.props.match.params.id)
    }
  }

  ///////////////////////////////////////////////////////////////
  // SUBMIT
  onSubmit(values) {
    if (this.props.match.params.corsoFormazioneId) {
      // Siamo in UPDATE
      this.props
        .updateCorsoFormazione(
          this.props.match.params.corsoFormazioneId,
          values,
          this.props.match.params.id
        )
        .then((action) => {
          // Redirect a dettagli candidato
          this.props.history.goBack()
        })
    } else {
      // Siamo in NEW
      this.props.addCorsoFormazione(values, this.props.match.params.id).then((action) => {
        // Redirect a dettagli candidato
        this.props.history.goBack()
      })
    }
  }

  ///////////////////////////////////////////////
  // NEW / EDIT
  renderTitolo() {
    let titolo = ''
    if (this.props.match.params.corsoFormazione) {
      titolo = 'Modifica corso di formazione'
    } else {
      titolo = 'Nuovo corso di formazione'
    }
    return <h1 className="page-title pull-left">{titolo}</h1>
  }

  ///////////////////////////////////////////////////////////////
  // RENDER
  render() {
    const initialValues = this.props.initialValues ? this.props.initialValues : {}

    return (
      <Form
        onSubmit={this.onSubmit.bind(this)}
        initialValues={initialValues}
        validate={validate}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit}>
            <div className="heading">
              {this.renderTitolo()}
              <BottoneAnnulla />
            </div>

            <div className="row">
              <div className="col-md-8">
                <div className="panel panel-border panel-purple">
                  <div className="panel-heading">
                    <h3 className="panel-title">Corso di formazione</h3>
                  </div>
                  <div className="panel-body">
                    <div className="row">
                      <div className="col-sm-6">
                        <Field
                          name="titolo"
                          id="titolo"
                          render={({ input, meta }) => (
                            <div
                              className={`form-group ${
                                meta.touched && meta.invalid ? 'has-error' : ''
                              }`}
                            >
                              <label htmlFor="titolo">Titolo</label>
                              <input type="text" className="form-control" {...input} />
                              <div className="help-block">{meta.touched && meta.error}</div>
                            </div>
                          )}
                        />
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="enteErogatore">Ente erogatore</label>
                          <Field
                            type="text"
                            className="form-control"
                            id="enteErogatore"
                            name="enteErogatore"
                            component="input"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="durata">Durata</label>
                          <Field
                            type="text"
                            className="form-control"
                            id="durata"
                            name="durata"
                            component="input"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="attestazioneRilasciata">Attestazione rilasciata</label>
                          <Field
                            type="text"
                            className="form-control"
                            id="attestazioneRilasciata"
                            name="attestazioneRilasciata"
                            component="input"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="dataInizioMese">Inizio (mese/anno)</label>
                          {/*<DatePicker
                      id="dataInizio"
                      selected={dataInizio.value ? moment(dataInizio.value) : null}
                      onChange={(momentDate) => dataInizio.onChange(momentDate.format('YYYY-MM-DD'))}
                      locale="it-IT"
                      className="form-control"/>*/}
                          <div className="row">
                            <div className="col-sm-6">
                              <Field
                                name="dataInizioMese"
                                id="dataInizioMese"
                                render={({ input }) => (
                                  <select className="form-control" {...input}>
                                    <option value="">Seleziona mese</option>
                                    <option value="1">Gennaio</option>
                                    <option value="2">Febbraio</option>
                                    <option value="3">Marzo</option>
                                    <option value="4">Aprile</option>
                                    <option value="5">Maggio</option>
                                    <option value="6">Giugno</option>
                                    <option value="7">Luglio</option>
                                    <option value="8">Agosto</option>
                                    <option value="9">Settembre</option>
                                    <option value="10">Ottobre</option>
                                    <option value="11">Novembre</option>
                                    <option value="12">Dicembre</option>
                                  </select>
                                )}
                              />
                            </div>
                            <div className="col-sm-6">
                              <Field
                                type="number"
                                min="1930"
                                max={moment().format('YYYY')}
                                className="form-control"
                                id="dataInizioAnno"
                                name="dataInizioAnno"
                                placeholder="Inserisci anno"
                                component="input"
                              />
                              <div className="help-block">
                                {form.getFieldState('dataInizioAnno')?.touched &&
                                  form.getFieldState('dataInizioAnno')?.error}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="dataFineMese">Fine (mese/anno)</label>
                          {/*<DatePicker
                      id="dataFine"
                      selected={dataFine.value ? moment(dataFine.value) : null}
                      onChange={(momentDate) => dataFine.onChange(momentDate.format('YYYY-MM-DD'))}
                      locale="it-IT"
                      className="form-control"/>*/}
                          <div className="row">
                            <div className="col-sm-6">
                              <Field
                                id="dataFineMese"
                                name="dataFineMese"
                                render={({ input }) => (
                                  <select className="form-control" {...input}>
                                    <option value="">Seleziona mese</option>
                                    <option value="1">Gennaio</option>
                                    <option value="2">Febbraio</option>
                                    <option value="3">Marzo</option>
                                    <option value="4">Aprile</option>
                                    <option value="5">Maggio</option>
                                    <option value="6">Giugno</option>
                                    <option value="7">Luglio</option>
                                    <option value="8">Agosto</option>
                                    <option value="9">Settembre</option>
                                    <option value="10">Ottobre</option>
                                    <option value="11">Novembre</option>
                                    <option value="12">Dicembre</option>
                                  </select>
                                )}
                              />
                            </div>
                            <div className="col-sm-6">
                              <Field
                                type="number"
                                min="1930"
                                max={moment().format('YYYY')}
                                className="form-control"
                                id="dataFineAnno"
                                name="dataFineAnno"
                                placeholder="Inserisci anno"
                                component="input"
                              />
                              <div className="help-block">
                                {form.getFieldState('dataFineAnno')?.touched &&
                                  form.getFieldState('dataFineAnno')?.error}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="panel panel-border panel-purple">
                  <div className="panel-heading">
                    <h3 className="panel-title">Descrizione Curriculum</h3>
                  </div>
                  <div className="panel-body">
                    <div className="form-group">
                      <label htmlFor="descrizioneCv">Descrizione corso</label>
                      <Field
                        component="textarea"
                        className="form-control"
                        rows="6"
                        id="descrizioneCv"
                        name="descrizioneCv"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-center">
              <button type="submit" className="btn btn-purple btn-lg">
                Salva corso di formazione &raquo;
              </button>
            </div>
          </form>
        )}
      />
    )
  }
}

function validate(values) {
  const errors = {}

  if (!values.titolo) {
    errors.titolo = 'Indica il titolo'
  }

  return errors
}

const mapStateToProps = (state, props) => {
  // Sono in edit, devo inizializzare il form
  if (props.match.params.corsoFormazioneId) {
    return {
      initialValues: _find(state.candidatoAttivo.corsiFormazione, {
        corsoFormazioneId: parseInt(props.match.params.corsoFormazioneId),
      }),
      candidatoId: state.candidatoAttivo.candidatoId, // per sapere se ho già il candidato attivo su F5
    }
  }
  return {}
}

// connect: 1st argument mapStateToProps, 2nd mapDispatchToProps
// reduxForm: 1st form config, 2nd mapStateToProps, 3rd mapDipatchToProps
export default connect(mapStateToProps, {
  fetchCandidato,
  addCorsoFormazione,
  updateCorsoFormazione,
})(AddEditCorsoFormazione)
