import React from 'react';


///////////////////////////////////////
// OCCUPATO
const Occupato = ({statoOccupazionale}) => {
	if (statoOccupazionale.stato != 'Occupato') {
		return <span></span>;
	}

	const renderCassaIntegrazione = () => {
		if (!statoOccupazionale.occupatoCassaIntegrazioneFlag) {
			return '';
		}
		return (
			<p>
				Cassa integrazione {statoOccupazionale.occupatoCassaIntegrazioneTipo} dal {statoOccupazionale.occupatoCassaIntegrazioneInizioF} al {statoOccupazionale.occupatoCassaIntegrazioneFineF}
			</p>
		);
	}

	const renderContrattoSolidarieta = () => {
		if (!statoOccupazionale.occupatoContrattoSolidarietaFlag) {
			return '';
		}
		return (
			<p>
				Contratto solidarietà dal {statoOccupazionale.occupatoContrattoSolidarietaInizioF} al {statoOccupazionale.occupatoContrattoSolidarietaFineF}
			</p>
		);
	}


	return (
		<div>
			<p>
				{statoOccupazionale.occupatoTipoQualificaLegale} presso <b>{statoOccupazionale.occupatoAzienda}</b>
				&nbsp;({statoOccupazionale.occupatoSettore}, {statoOccupazionale.occupatoIndirizzo})
			</p>
			<p>
				Contratto:&nbsp;
					{statoOccupazionale.occupatoTipoContratto!=='Altro' ?
					statoOccupazionale.occupatoTipoContratto :
					statoOccupazionale.occupatoAltroTipo}&nbsp;

				({statoOccupazionale.occupatoTempo}, {statoOccupazionale.occupatoOreSettimanali} ore)
			</p>
			<div className="sostegni">
				{renderCassaIntegrazione()}
				{renderContrattoSolidarieta()}
			</div>
		</div>
	);
}

export default Occupato;
