import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'
import Ink from 'react-ink'
import { MdAdd, MdEdit } from 'react-icons/md'
import { FaCopy } from 'react-icons/fa'
import swal from 'sweetalert'

import Pager from '../pager/pager'
import {
  fetchNewsletters,
  duplicateNewsletter,
  deleteNewsletter,
  changePageNewsletters,
} from '../../actions'

class NewsletterList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      newsletterToDeleteId: 0,
    }
  }

  componentDidMount() {
    this.props.fetchNewsletters(this.props.pageRequested)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pageRequested !== this.props.pageRequested) {
      this.props.fetchNewsletters(this.props.pageRequested)
    }
  }

  handleDeleteNewsletter = (newsletterId) => (event) => {
    this.setState({
      newsletterToDeleteId: newsletterId,
    })
    this.handleAlertDeleteNewsletter()
  }

  handleConfirmDeleteNewsletter = () => {
    this.props.deleteNewsletter(this.state.newsletterToDeleteId)
  }

  handleDuplica = (newsletterId) => (event) => {
    this.props.duplicateNewsletter(newsletterId).then(() => {
      this.props.fetchNewsletters(1)
    })
  }

  renderNewsletters(newsletters) {
    return newsletters.map((newsletter) => (
      <tr key={newsletter.newsletterId}>
        <td>{moment(newsletter.dataCreazione).format('DD/MM/YYYY')}</td>
        {/* <td>{newsletter.operatoreNome}</td> */}
        <td>
          {newsletter.dataInvio ? (
            <span>{moment(newsletter.dataInvio).format('DD/MM/YYYY')}</span>
          ) : (
            <div className="label label-default">NON INVIATA</div>
          )}
        </td>
        <td>{newsletter.oggetto}</td>
        <td>
          {newsletter.gruppoCvSinteticoNome ? (
            <span>Gruppo {newsletter.gruppoCvSinteticoNome}</span>
          ) : (
            <div>
              {/* Sportelli: {newsletter.sportelli.join(', ')} */}
              Sportelli:{' '}
              {newsletter.sportelli.map((sportello) => (
                <span key={sportello}>
                  <span className="label label-success">
                    {sportello}
                  </span>{' '}
                </span>
              ))}
            </div>
          )}
        </td>
        <td>{newsletter.numeroDestinatari}</td>

        <td className="text-right" style={{ width: 300 }}>
          {!!this.props.permessoNewsletter && (
            <div className="actions">
              <Link
                className="btn btn-sm btn-primary"
                style={{ marginRight: 10 }}
                to={'/app/newsletter/' + newsletter.newsletterId}
              >
                <MdEdit />
                Modifica
              </Link>

              <button
                className="btn btn-sm btn-default"
                style={{ marginRight: 10 }}
                onClick={this.handleDuplica(newsletter.newsletterId)}
              >
                <FaCopy /> Duplica
              </button>

              <button
                className="btn btn-sm btn-danger"
                onClick={this.handleDeleteNewsletter(newsletter.newsletterId)}
              >
                &times; Elimina
              </button>
            </div>
          )}
        </td>
      </tr>
    ))
  }

  handleAlertDeleteNewsletter = () => {
    swal({
      type: 'warning',
      title: 'Eliminazione newsletter',
      text: 'Sei sicuro di voler eliminare questa newsletter? Questa azione è irreversibile.',
      buttons: ['Annulla', 'Sì, elimina newsletter'],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.handleConfirmDeleteNewsletter()
      }
    })
  }

  render() {
    const { newsletters, pagination, changePageNewsletters, permessoNewsletter } = this.props

    return (
      <div className="newsletter-list">
        <h1 className="page-title">Newsletter</h1>
        {!!permessoNewsletter && (
          <Link to="/app/newsletter/new" className="btn btn-success">
            <Ink />
            <MdAdd style={{ verticalAlign: 'middle' }} />
            Nuova newsletter
          </Link>
        )}

        <Pager
          position={'top'}
          page={pagination.page}
          totalPages={pagination.totalPages}
          onChangePage={changePageNewsletters}
        />

        <div className="card-box" style={{ marginBottom: 10 }}>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Data creazione</th>
                  {/* <th>Utente</th> */}
                  <th>Data invio</th>
                  <th>Oggetto</th>
                  <th>Destinatari</th>
                  <th>N° dest</th>
                  <th />
                </tr>
              </thead>
              <tbody>{this.renderNewsletters(newsletters)}</tbody>
            </table>
          </div>
        </div>

        <Pager
          position={'bottom'}
          page={pagination.page}
          totalPages={pagination.totalPages}
          onChangePage={changePageNewsletters}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  newsletters: state.newsletters.data,
  pagination: state.newsletters.pagination,
  pageRequested: state.newsletters.pageRequested,
  permessoNewsletter: state.auth.user.permessi.newsletter,
})

export default connect(mapStateToProps, {
  fetchNewsletters,
  duplicateNewsletter,
  deleteNewsletter,
  changePageNewsletters,
})(NewsletterList)
