import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Ink from 'react-ink'

import EsperienzaLavorativa from './esperienza_lavorativa'

import { MdAdd } from 'react-icons/md'

class EsperienzeLavorative extends Component {
  renderEsperienze() {
    if (this.props.esperienzeLavorative) {
      return this.props.esperienzeLavorative.map((esperienzaLavorativa) => {
        return (
          <EsperienzaLavorativa
            key={esperienzaLavorativa.esperienzaLavorativaId}
            esperienzaLavorativa={esperienzaLavorativa}
            candidatoId={this.props.candidatoId}
            canEdit={this.props.canEdit}
          />
        )
      })
    }
    return <span>Caricamento in corso...</span>
  }

  render() {
    return (
      <div className="card-box">
        <h4 className="header-title">Esperienze lavorative</h4>
        {this.renderEsperienze()}

        {this.props.canEdit ? (
          <div className="text-right">
            <Link
              to={`/app/candidati/${this.props.candidatoId}/esperienze-lavorative/new`}
              className="btn btn-sm btn-primary"
            >
              <Ink />
              <MdAdd />
              Aggiungi esperienza
            </Link>
          </div>
        ) : null}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    candidatoId: state.candidatoAttivo.candidatoId,
    esperienzeLavorative: state.candidatoAttivo.esperienzeLavorative,
    canEdit:
      !!state.auth.user.permessi['esperienzeLavorative'] &&
      state.candidatoAttivo.sportello &&
      state.candidatoAttivo.sportello.sportelloId === state.auth.user.sportelloCorrente.sportelloId,
  }
}

export default connect(mapStateToProps)(EsperienzeLavorative)
