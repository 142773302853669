import {
	FETCH_AGENZIA
} from '../actions/types';

const INITIAL_STATE = {
	data: {}
};

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case FETCH_AGENZIA:
			return {
				...state,
				data: action.payload.data
			};
		default:
			return state;
	}
}
