import { SET_FILTER_PANNELLO_EXPORT_CSV } from '../actions/types'

const INITIAL_STATE = {
  areaGeograficaId: '',
  sportelloId: '',
}

export default function reducerExportCSV(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_FILTER_PANNELLO_EXPORT_CSV:
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      }
    default:
      return state
  }
}
