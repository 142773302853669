import React, { Component } from 'react';

class SportelloDaSelezionare extends Component {

  handleAdd = event => {
    event.preventDefault()
    this.props.onAddSportello(this.props.sportello)
  }

  render() {

    const { sportello } = this.props

    return (
      <tr className="sportello-da-selezionare">
        <td>{sportello.tipo} {sportello.nome}</td>
        <td className="text-right"><button type="button" className="btn btn-success" onClick={this.handleAdd}>Aggiungi</button></td>
      </tr>
    );
  }
}

export default SportelloDaSelezionare;