import {
  FETCH_AGENZIE,
  DELETE_AGENZIA,
  SET_KEYWORDS_AGENZIE,
  SET_SPORTELLO_CORRENTE_FLAG_AGENZIE,
  CHANGE_PAGE_AGENZIE,
  ADD_CREDITI_AGENZIA,
} from '../actions/types'

const INITIAL_STATE = {
  data: [],
  pagination: {
    page: 1,
    totalPages: 1,
  },
  keywords: '',
  sportelloCorrenteFlag: true,
  pageRequested: 1,
}

export default function reducerAgenzie(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_AGENZIE:
      return {
        ...state,
        data: action.payload.data.items,
        pagination: action.payload.data.pagination,
      }
    case DELETE_AGENZIA:
      return {
        ...state,
        data: state.data.filter(agenzia => agenzia.agenziaId !== action.payload),
      }
    case SET_KEYWORDS_AGENZIE:
      return {
        ...state,
        keywords: action.payload,
      }
    case SET_SPORTELLO_CORRENTE_FLAG_AGENZIE:
      return {
        ...state,
        sportelloCorrenteFlag: action.payload,
      }
    case CHANGE_PAGE_AGENZIE:
      return {
        ...state,
        pageRequested: action.payload,
      }
    case ADD_CREDITI_AGENZIA:
      return {
        ...state,
        data: state.data.map(agenzia => {
          if (agenzia.agenziaId === action.payload.data.agenziaId) {
            return {
              ...agenzia,
              crediti: action.payload.data.crediti,
            }
          }
          return agenzia
        }),
      }
    default:
      return state
  }
}
