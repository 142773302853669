import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Ink from 'react-ink'

import './job_vacancies_anonime.less'
import swal from 'sweetalert'

class JobVacanciesAnonime extends Component {
  constructor(props) {
    super(props)

    this.state = {
      id: 0,
    }
  }

  handleCreateCandidatura = (id) => {
    swal({
      icon: 'warning',
      title: 'Dichiarazione Dei requisiti',
      text: 'Dichiaro di avere i requisiti richiesti per procedere a questa canditdatura',
      buttons: ['Annulla', 'Si, dichiaro di avere i requisiti'],
      dangerMode: true,
    }).then((prosegui) => {
      if (prosegui) {
        this.props.onCreateCandidatura(id)
      }
    })
  }

  render() {
    const { jobVacancies, ruolo, onDeleteCandidatura } = this.props

    return (
      <div className="job-vacancies-anonime">
        <div className="row">
          {jobVacancies.map((jobVacancy) => (
            <div className="col-sm-6 col-lg-3" key={jobVacancy.jobVacancyId}>
              <div className="card-box text-center">
                <p className="provincia">
                  <b>{jobVacancy.luogoLavoroProvinciaNome}</b>
                </p>
                <h4 className="figura-professionale color">{jobVacancy.figuraProfessionale}</h4>
                <p className="descrizione">{jobVacancy.descrizioneSintetica}</p>
                {ruolo === 'candidato' && !jobVacancy.interessato && (
                  <button
                    className="btn btn-primary btn-block"
                    onClick={() => this.handleCreateCandidatura(jobVacancy.jobVacancyId)}
                  >
                    Candidati
                  </button>
                )}
                {ruolo === 'candidato' && !!jobVacancy.interessato && (
                  <button
                    className="btn btn-danger btn-block"
                    onClick={onDeleteCandidatura(jobVacancy.jobVacancyId)}
                  >
                    Revoca candidatura
                  </button>
                )}
                {/* <Link to={`/contatti/sportello/${jobVacancy.sportelloId}?m=Interessato a job vacancy n. ${jobVacancy.jobVacancyId}`} target="_blank" className="btn btn-inverse btn-block">Richiedi info</Link> */}
                <Link
                  to={`/app/job-vacancies/${jobVacancy.jobVacancyId}`}
                  className="btn btn-inverse btn-block"
                >
                  Dettagli
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export default JobVacanciesAnonime
