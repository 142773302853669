import React from 'react';

///////////////////////////////////////
// DISOCCUPATO
const Disoccupato = ({statoOccupazionale}) => {
	if (statoOccupazionale.stato != 'Disoccupato') {
		return <span></span>;
	}

	return (
		<div>
			<div className="sostegni">
				<p>Strumenti di sostegno:<br /><b>{statoOccupazionale.disoccupatoStrumentiSostegno} dal {statoOccupazionale.disoccupatoStrumentiSostegnoInizioF}</b></p>
			</div>

			<p>
				Ultimo lavoro terminato il <b>{statoOccupazionale.disoccupatoUltimoLavoroDataFineF}</b> come {statoOccupazionale.disoccupatoUltimoLavoroTipoQualificaLegale} presso <b>{statoOccupazionale.disoccupatoUltimoLavoroAzienda}</b>
				&nbsp;({statoOccupazionale.disoccupatoUltimoLavoroSettore}, {statoOccupazionale.disoccupatoUltimoLavoroIndirizzo})
			</p>

		</div>
	);

}

export default Disoccupato;
