import { FETCH_ACQUISIZIONE_CANDIDATI_SPORTELLO } from '../actions/types'

const INITIAL_STATE = []

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_ACQUISIZIONE_CANDIDATI_SPORTELLO:
      return action.payload.data || []
    default:
      return state
  }
}
