import React from 'react'
import { PieChart, Pie, Tooltip, Cell } from 'recharts'

const StatisticheGrafico = ({ titolo, nCandidati, COLORS, dataset }) => {
  return (
    <div className="flex-between">
      <div className="grow-1">
        <div className="grid-row">
          <div className="bold">{titolo}</div>
          <div>N.</div>
          <div>%</div>
        </div>
        <div style={{ backgroundColor: '#959595' }}>
          <div className="grid-1">
            {dataset.map((statistica,index) => (
              <div key={`statistica-${index}`} className="grid-row">
                <div>{`${statistica.name}`}</div>
                <div>{statistica.value}</div>
                <div>{Math.round((statistica.value / nCandidati) * 100)}%</div>
                <div style={{ backgroundColor: `${statistica.color}` }} />
              </div>
            ))}
          </div>
        </div>
      </div>

      <PieChart width={200} height={200}>
        <Tooltip />
        <Pie data={dataset} dataKey="value">
          {dataset.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </div>
  )
}

export default StatisticheGrafico
