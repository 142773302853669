import _find from 'lodash/find';

import { 
	FETCH_OPERATORI_AZAG,
	SET_EDIT_OPERATORE_AZAG,
	SAVE_OPERATORE_AZAG,
	CREATE_OPERATORE_AZAG,
	DELETE_OPERATORE_AZAG
} from '../actions/types';

const INITIAL_STATE = {
	data: []
};

export default function(state = INITIAL_STATE, action) {
	switch(action.type) {
		case FETCH_OPERATORI_AZAG:
			return {
				...state,
				data: action.payload.data
			};
		case SET_EDIT_OPERATORE_AZAG:
			return {
				...state,
				data: state.data.map(operatore => {
					if (operatore.utenteId === action.payload.utenteId) {
						return {
							...operatore,
							isEdit: action.payload.isEdit
						};
					}
					return {
						...operatore,
						isEdit: false
					};
				})
			};
		case SAVE_OPERATORE_AZAG:
			return {
				...state,
				data: state.data.map(operatore => {
					console.log(action.payload, 'SAVE_OPERATORE_AZAG')
					if (operatore.utenteId === action.payload.data.utenteId) {
						return {
							...operatore,
							...action.payload.data
						};
					}
					return operatore;
				})
			};
		case CREATE_OPERATORE_AZAG:
			return {
				...state,
				data: [
					...state.data,
					action.payload.data
				]
			};
    case DELETE_OPERATORE_AZAG:
			return {
				...state,
				data: state.data.filter(operatore => operatore.utenteId !== action.payload)
			};
		default:
			return state;
	}
}

export function isEditing(globalState) {
	const operatoreEditing = _find(globalState.operatoriAzag.data, {isEdit: true});

	if (operatoreEditing) {
		return true;
	}
	return false;

}
