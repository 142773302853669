import {
  LOGIN_START,
  AUTH_USER,
  UNAUTH_USER,
  AUTH_ERROR,
  FETCH_USER,
  PASSWORD_CHANGED,
  SBLOCCA_CANDIDATO,
  RESET_PASSWORD,
  VERIFY_TOKEN,
  CHANGE_PASSWORD,
} from '../actions/types'

const initalState = {
  loading: false,
  authenticated: false,
  error: '',
  user: {},
  resetMessage: '',
  login: '', //login è lo username da inserire nella pagina /reset-password/:token
}

export default function reducerAuth(state = initalState, action) {
  switch (action.type) {
    case LOGIN_START:
      return {
        ...state,
        loading: true,
        authenticated: false,
        user: {},
        resetMessage: '',
        login: '',
        error: '',
      }
    case AUTH_USER:
    case FETCH_USER:
      return {
        ...state,
        loading: false,
        authenticated: true,
        user: action.payload,
        error: '',
        resetMessage: '',
        login: '',
      }
    case PASSWORD_CHANGED:
      return { ...state, user: action.payload, error: '' }
    case RESET_PASSWORD:
      return { ...state }
    case VERIFY_TOKEN:
      return { ...state, resetMessage: action.payload.message, login: action.payload.login }
    case CHANGE_PASSWORD:
      return {
        ...state,
        loading: false,
        authenticated: true,
        user: action.payload,
        error: '',
      }
    case UNAUTH_USER:
      return {
        ...state,
        loading: false,
        authenticated: false,
        user: {},
        resetMessage: '',
        login: '',
      }
    case AUTH_ERROR:
      return {
        ...state,
        loading: false,
        authenticated: false,
        user: {},
        error: action.payload,
      }
    case SBLOCCA_CANDIDATO:
      return {
        ...state,
        user: {
          ...state.user,
          crediti: action.payload.data.crediti,
        },
      }
    default:
      return state
  }
}

/////////////////////////////
// SELECTORS

export const mancaDichiarazione = (globalState) => {
  const isAzienda = globalState.auth.user.ruolo === 'azienda'
  const dichiarazioneAzienda = !!globalState.aziendaAttiva.data.dichiarazioneJvFlag

  const isAgenzia = globalState.auth.user.ruolo === 'agenzia'
  const dichiarazioneAgenzia = !!globalState.agenziaAttiva.data.dichiarazioneJvFlag

  if ((isAzienda && !dichiarazioneAzienda) || (isAgenzia && !dichiarazioneAgenzia)) {
    return true
  }
  return false
}

export const dichiarazioneDaApprovare = (globalState) => {
  const isAzienda = globalState.auth.user.ruolo === 'azienda'
  const dichiarazioneAzienda = !!globalState.aziendaAttiva.data.dichiarazioneJvFlag
  const permessoAzienda = !!globalState.aziendaAttiva.data.permessoJobVacancyFlag

  const isAgenzia = globalState.auth.user.ruolo === 'agenzia'
  const dichiarazioneAgenzia = !!globalState.agenziaAttiva.data.dichiarazioneJvFlag
  const permessoAgenzia = !!globalState.agenziaAttiva.data.permessoJobVacancyFlag

  if (
    (isAzienda && dichiarazioneAzienda && !permessoAzienda) ||
    (isAgenzia && dichiarazioneAgenzia && !permessoAgenzia)
  ) {
    return true
  }
  return false
}
