import React, { useEffect } from 'react'
import { FaSearch } from 'react-icons/fa'
import { MdAdd } from 'react-icons/md'
import Ink from 'react-ink'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import swal from 'sweetalert'
import {
  cambiaPaginaCorsiFormazione,
  setKeywordsCorsiFormazione,
  fetchCorsiDiFormazione,
  deleteCorsoFormazione,
  subscribeCorso,
  fetchCorsiDiFormazioneCandidato,
  unscribeCorso,
} from '../../actions'
import Pager from '../pager/pager'
import CorsiCard from './corsi_card'
import CorsiCompleti from './corsi_visuale_operatore'

const CorsiList = ({
  pagination,
  keywords,
  cambiaPaginaCorsiFormazione,
  setKeywordsCorsiFormazione,
  fetchCorsiDiFormazione,
  ruolo,
  corsiDiFormazione,
  permessoGestioneCorsi,
  deleteCorsoFormazione,
  subscribeCorso,
  user,
  fetchCorsiDiFormazioneCandidato,
}) => {
  useEffect(() => {
    fetchCorsiDiFormazione()
  }, [fetchCorsiDiFormazione, fetchCorsiDiFormazioneCandidato, ruolo, user.candidatoId])

  const changeKeyword = (event) => {
    setKeywordsCorsiFormazione(event.target.value)
  }
  const renderSearchBox = (keywords) => {
    return (
      <div className="search-box form-group">
        <input
          type="text"
          className="form-control"
          value={keywords}
          onChange={changeKeyword}
          placeholder="Filtra per parole chiave..."
        />
        <FaSearch />
      </div>
    )
  }

  const handleDeleteCorso = (a) => (event) => {
    deleteCorsoFormazione(a.corsoFormazionePropostoId)
  }
  const handleSubscription = (corsoFormazionePropostoId) => (event) => {
    swal({
      icon: 'warning',
      title: 'Dichiarazione Dei requisiti',
      text: 'Dichiaro di avere i requisiti richiesti per procedere a questa iscrizione',
      buttons: ['Annulla', 'Si, dichiaro di avere i requisiti'],
      dangerMode: true,
    }).then((prosegui) => {
      if (prosegui) {
        subscribeCorso(user.candidatoId, corsoFormazionePropostoId).then(() => fetchCorsiDiFormazione())
      }
    })
  }
  const handleUnscribe = (corsoFormazionePropostoId) => (event) => {
    unscribeCorso(user.candidatoId, corsoFormazionePropostoId).then(() => fetchCorsiDiFormazione())
  }
  return (
    <div>
      <h1 className="page-title">CORSI FORMAZIONE</h1>

      {ruolo === 'operatore' && (
        <div>
          {ruolo === 'operatore' && !!permessoGestioneCorsi && (
            <Link to="/app/corsi-di-formazione/new" className="btn btn-success pull-right">
              <Ink />
              <MdAdd style={{ verticalAlign: 'middle' }} />
              Nuovo Corso
            </Link>
          )}

          <div className="row">
            <div className="col-md-8">{renderSearchBox(keywords)}</div>
            <div className="col-md-4">
              <Pager
                position={'top'}
                page={pagination.page}
                totalPages={pagination.totalPages}
                onChangePage={cambiaPaginaCorsiFormazione}
              />
            </div>
          </div>

          <CorsiCompleti
            corsi={corsiDiFormazione}
            ruolo={ruolo}
            permessoGesioneCorsi={permessoGestioneCorsi}
            handleDeleteCorso={handleDeleteCorso}
          />

          <Pager
            position={'bottom'}
            page={pagination.page}
            totalPages={pagination.totalPages}
            onChangePage={cambiaPaginaCorsiFormazione}
          />
        </div>
      )}

      {ruolo === 'candidato' && (
        <>
          <div className="row">
            <div className="col-md-8">{renderSearchBox(keywords)}</div>

            <div className="col-md-4">
              <Pager
                position={'top'}
                page={pagination.page}
                totalPages={pagination.totalPages}
                onChangePage={cambiaPaginaCorsiFormazione}
              />
            </div>
          </div>
          <CorsiCard
            corsi={corsiDiFormazione}
            ruolo={ruolo}
            handleSubscription={handleSubscription}
            handleUnscribe={handleUnscribe}
          />

          <Pager
            position={'bottom'}
            page={pagination.page}
            totalPages={pagination.totalPages}
            onChangePage={cambiaPaginaCorsiFormazione}
          />
        </>
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    pagination: state.corsiFormazione.pagination,
    ruolo: state.auth.user.ruolo,
    keywords: state.corsiFormazione.keywords,
    corsiDiFormazione: state.corsiFormazione.data,
    permessoGestioneCorsi: state.auth.user.permessi.gestioneCorsiFormazione,
    user: state.auth.user,
  }
}

export default connect(mapStateToProps, {
  cambiaPaginaCorsiFormazione,
  setKeywordsCorsiFormazione,
  fetchCorsiDiFormazione,
  deleteCorsoFormazione,
  subscribeCorso,
  fetchCorsiDiFormazioneCandidato,
})(CorsiList)
