import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { fetchAreeGeografiche } from '../../../actions'

class FiltroOperatoriSportelli extends Component {
  componentDidMount() {
    if (this.props.ruolo !== 'subamministratore') {
      this.props.fetchAreeGeografiche()
    }
  }

  handleChange = (field) => (event) => {
    this.props.onChange(field, event.target.value)
  }

  render() {
    const { values, areeGeografiche, ruolo } = this.props

    return (
      <div className="filtro-operatori-sportelli">
        <div className="row">
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="organizzazione">Organizzazione</label>
              <select
                id="organizzazione"
                className="form-control"
                value={values.organizzazione}
                onChange={this.handleChange('organizzazione')}
              >
                <option value="">-- Qualsiasi --</option>
                <option value="CISL">CISL</option>
                <option value="IAL">IAL</option>
              </select>
            </div>
          </div>
          {ruolo === 'amministratore' && (
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="areaGeograficaId">Regione</label>
                <select
                  id="areaGeograficaId"
                  className="form-control"
                  value={values.areaGeograficaId}
                  onChange={this.handleChange('areaGeograficaId')}
                >
                  <option value="">-- Qualsiasi --</option>
                  {areeGeografiche.map((a) => (
                    <option key={a.areaGeograficaId} value={a.areaGeograficaId}>
                      {a.nome}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="keywords">Nome</label>
              <input
                type="text"
                id="keywords"
                className="form-control"
                placeholder="Cerca..."
                value={values.keywords}
                onChange={this.handleChange('keywords')}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

FiltroOperatoriSportelli.propTypes = {
  values: PropTypes.shape({
    organizzazione: PropTypes.string,
    areaGeograficaId: PropTypes.string,
    keywords: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  areeGeografiche: state.areeGeografiche,
  ruolo: state.auth.user.ruolo,
})

export default connect(mapStateToProps, { fetchAreeGeografiche })(FiltroOperatoriSportelli)
