import isPromise from 'is-promise';
import { isFSA } from 'flux-standard-action';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

export default function promiseMiddleware({ dispatch }) {
  return next => action => {
    if (!isFSA(action)) {
      return isPromise(action) ? action.then(dispatch) : next(action);
    }

    if (isPromise(action.payload)) {
      dispatch(showLoading())
      return action.payload
      .then(result => {
        dispatch(hideLoading());
        dispatch({ ...action, payload: result });
        return { ...action, payload: result };
      })
      .catch(error => {
        dispatch(hideLoading());
        dispatch({ ...action, payload: error, error: true });
        return Promise.reject(error);
      })
    }

    return next(action);
  };
}